import React, { memo, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';
import { DEFAULT_FILTERS, INITIAL_FILTERS } from 'reducers/advertiserReducer';

// Libs
import { hasPermission } from 'libs/storageLibs';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';

// Hook
import useGetParameters from 'hooks/useGetParameters';
import useQueryParams from 'hooks/useQueryParams';
import advertiserActions from 'actions/advertiserActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useAdvertiserList from './useAdvertiserList';

// Components
import Filters from './Filters';
import TableAdvertisers from './TableAdvertisers';
import RefillBalanceModal from '../../AdvertiserPopups/Popups/RefillBalanceModal';
import BillingHistoryModal from '../../AdvertiserPopups/Popups/BillingHistoryModal';

const PARAM_NAMES = ['search', 'status', 'balance'];

const AdvertiserList = ({ match }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.advertisers.filters);
  const setFilters = useCallback((values) => dispatch(advertiserActions.setFilters(values)), [dispatch]);
  const location = useLocation();

  const tableRef = useRef();

  const {
    meta,
    refillBalanceData,
    billingHistoryData,
    toggleRefillBalanceModal,
    toggleBillingHistoryModal,
    refillBalanceByAdvertiserId,
    requestAdvertisers,
    updateAdvertiserStatus,
  } = useAdvertiserList(filters);

  useEffect(() => {
    if (location.state?.refillBalanceAdvertiserId) {
      toggleRefillBalanceModal({ advertiserId: location.state.refillBalanceAdvertiserId, open: true });
    }
  }, [toggleRefillBalanceModal, location.state]);

  const params = useGetParameters(PARAM_NAMES);
  const { debouncedReplaceQueryParams, replaceQueryParams } = useQueryParams();

  const refFilters = useRef(filters);

  useEffect(() => {
    if (Object.keys(params).length) {
      const filterValues = { ...INITIAL_FILTERS, ...params };
      setFilters(filterValues);
      refFilters.current = filterValues;
    } else {
      replaceQueryParams(refFilters.current);
    }
  }, [params, setFilters, replaceQueryParams]);

  const refreshAdvertiserList = useCallback(() => {
    if (!tableRef.current) return;
    tableRef.current.onQueryChange();
  }, []);

  const handleChangeFilters = useCallback((values) => {
    setFilters(values);
    refFilters.current = values;
    debouncedReplaceQueryParams(values, refreshAdvertiserList);
  }, [setFilters, debouncedReplaceQueryParams, refreshAdvertiserList]);

  const resetFilters = useCallback(() => {
    handleChangeFilters(DEFAULT_FILTERS);
  }, [handleChangeFilters]);

  const refillBalance = useCallback(async (...args) => {
    await refillBalanceByAdvertiserId(...args);
    refreshAdvertiserList();
  }, [refillBalanceByAdvertiserId, refreshAdvertiserList]);

  const updateStatus = useCallback((id, status) => {
    updateAdvertiserStatus(id, status).then(() => (tableRef?.current?.onQueryChange()));
  }, [updateAdvertiserStatus]);

  const isEditable = hasPermission([UserPermissions.types.ADVERTISER_WRITE]);

  return (
    <div>
      <Grid container spacing={ 2 } justify="space-between">
        <Grid item>
          <PageTitle className="mb-5" title="Advertisers Management" showBreadcrumbs />
        </Grid>
        {isEditable && (
          <Grid item>
            <Button
              component={ Link }
              to={ `${match.url}/add` }
              variant="contained"
              color="primary"
            >
              Add advertiser
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={ 3 } alignItems="center" className="mb-3">
        <Filters
          values={ filters }
          onChange={ handleChangeFilters }
          onReset={ resetFilters }
        />
      </Grid>

      <TableAdvertisers
        tableRef={ tableRef }
        getAdvertiserList={ requestAdvertisers }
        updateStatus={ updateStatus }
        meta={ meta }
        openRefillBalanceModal={ toggleRefillBalanceModal }
        openBillingHistoryModal={ toggleBillingHistoryModal }
      />

      <BillingHistoryModal
        isOpen={ billingHistoryData.isOpen }
        advertiserId={ billingHistoryData.advertiserId }
        handleClose={ toggleBillingHistoryModal }
        openRefillBalanceModal={ toggleRefillBalanceModal }
      />

      <RefillBalanceModal
        isOpen={ refillBalanceData.isOpen }
        handleClose={ toggleRefillBalanceModal }
        handleSubmit={ refillBalance }
        advertiserId={ refillBalanceData.advertiserId }
      />
    </div>
  );
};

AdvertiserList.propTypes = { match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired };

export default memo(AdvertiserList);
