const types = {
  ADD: 'ADD',
  WRITE_OFF: 'WRITE_OFF',
};

const dictionary = [
  { id: types.ADD, name: 'Credit' },
  { id: types.WRITE_OFF, name: 'Debit' },
];

export default {
  types,
  dictionary,
};
