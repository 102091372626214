import { useState } from 'react';

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    handleClick,
    handleClose,
    isOpen,
    anchorEl,
  };
};

export default usePopover;
