import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

/* Utils */
import cn from 'classnames';

/* Libs */
import { List } from 'react-virtualized';
import { Checkbox, SvgIcon, Menu, MenuItem, Grid } from '@material-ui/core';

/* Icons */
import { ReactComponent as UncheckedCheckbox } from '../../../assets/images/icons/checkbox.svg';
import { ReactComponent as CheckedCheckbox } from '../../../assets/images/icons/checkboxChecked.svg';

/* Modules */
import ClearableInput from '../Input/ClearableInput/ClearableInput';

/* Styles */
import classes from './SelectVirtualized.module.scss';
import Chip from '../Chip/Chip';

const ITEM_HEIGHT = 96;
const ITEM_PADDING_TOP = 8;
const MenuProps = {

  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderRadius: '16px',
      marginTop: '16px',
    },
  },
};

const SelectVirtualized = ({
  list,
  value,
  onChange,
  name,
  label,
  error,
  disabled,
  multiple,
  tree,
  isTouched,
  filterText,
  setFieldTouched,
  className,
  showChip,
}) => {
  const fakeSelectRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');

  const filteredList = useMemo(() => {
    if (!search) return list;
    const searchLowCase = search.toLowerCase();
    return list
      .filter((item) => ((tree && item.parent) ? true : item.name.toLowerCase().includes(searchLowCase)))
      .filter((item, index, arr) => {
        if (tree && item.parent) {
          return arr.some((element) => element.parentId === item.id);
        }
        return true;
      });
  }, [list, search, tree]);

  const handleClick = useCallback(
    (event) => {
      if (disabled) return;
      setAnchorEl(event.currentTarget);
    },
    [disabled],
  );

  const handleClose = useCallback(
    () => {
      setAnchorEl(null);
      setFieldTouched(name, true);
    },
    [name, setFieldTouched],
  );

  const handleChange = useCallback((itemValue, itemName, isChecked) => {
    let result;

    if (multiple) {
      if (Array.isArray(itemValue)) {
        result = isChecked ? value.filter((item) => !itemValue.includes(item)) : Array.from(new Set([...value, ...itemValue]));
      } else {
        result = isChecked ? value.filter((item) => item !== itemValue) : [...value, itemValue];
      }
    } else {
      result = itemValue;
    }

    onChange({ target: { value: result, name: itemName } });
  }, [onChange, value, multiple]);

  const removeElement = (id) => {
    handleChange(id, name, true);
  };

  const handleChangeSearch = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const hasError = useMemo(() => Boolean(error) && isTouched, [error, isTouched]);

  const handleRow = useCallback((rowValue, rowName, isChecked) => {
    handleChange(rowValue, rowName, isChecked);
    if (!multiple) setTimeout(handleClose, 0);
  }, [handleClose, handleChange, multiple]);

  const renderRow = useCallback(
    ({ index, style, key }) => {
      const isParent = filteredList[index].parent;
      let children = [];
      let isChecked = multiple && value.includes(filteredList[index].id);
      let selectedValue = filteredList[index].id;

      if (tree && isParent) {
        children = filteredList.filter((item) => item.parentId === filteredList[index].id).map((item) => item.id);
        isChecked = children.every((child) => value.includes(child));
        selectedValue = children;
      }

      return (
        <MenuItem
          className={ cn(classes.menuItem, {
            [classes.menuItemParent]: isParent,
            [classes.menuItemChecked]: isChecked,
            [classes.menuItemParentSingle]: isParent && !multiple,
          }) }
          style={ style }
          key={ key }
          disabled={ filteredList[index].disableOption }
          value={ filteredList[index].id }
          onClick={ () => !disabled && handleRow(selectedValue, name, isChecked) }
        >
          {multiple && (
            <Checkbox
              color="primary"
              checked={ isChecked }
              icon={ <UncheckedCheckbox /> }
              checkedIcon={ <CheckedCheckbox /> }
            />
          )}
          <span className={ classes.menuItemText }>{filteredList[index].name}</span>
        </MenuItem>
      );
    },
    [disabled, filteredList, name, value, multiple, tree, handleRow],
  );

  const calculateHeight = () => {
    const height = filteredList.length * 35 + 60;
    return height > 400 ? 400 : height;
  };

  const selectedValue = useMemo(() => {
    if (multiple) return value?.length;

    return list.find((item) => item.id === value)?.name;
  }, [multiple, value, list]);

  const selectedChip = useMemo(() => {
    if (multiple) return list.filter((item) => value.includes(item.id) && !item.parent);

    return list.find((item) => item.id === value) || null;
  }, [list, value, multiple]);

  const selectText = selectedValue || filterText;

  return (
    <div className={ cn([classes.wrapper, className, {
      [classes.opened]: Boolean(anchorEl),
      [classes.hasError]: hasError,
      [classes.isDisabled]: disabled,
    }]) }
    >
      <div
        role="button"
        tabIndex={ 0 }
        className={ cn([classes.fakeSelect]) }
        onClick={ handleClick }
        onKeyPress={ handleClick }
        ref={ fakeSelectRef }
      >
        <span className={ classes.fakeSelectText }>{label}</span>
        <span className={ cn({
          [classes.fakeSelectCounter]: label,
          [classes.fakeSelectText]: !label,
        }) }
        >
          {label ? `(${selectText})` : selectText}
        </span>
        <SvgIcon className={ classes.fakeSelectArrow }>
          <path d="M7 10l5 5 5-5z" />
        </SvgIcon>
      </div>

      { hasError &&
        <span className={ classes.fakeSelectHelperText }>{error}</span>}

      <Menu
        className={ classes.menu }
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ handleClose }
        onKeyDownCapture={ (e) => {
          e.stopPropagation();
        } }
        { ...MenuProps }
      >
        <div className={ classes.menuSearchWrapper }>
          <ClearableInput
            autoFocus
            placeholder="Search"
            value={ search }
            onClear={ handleClearSearch }
            label=""
            name="search"
            onChange={ handleChangeSearch }
            fullWidth
          />

          { !filteredList.length && (
          <div className={ classes.noResults }>No results</div>
          ) }
        </div>

        {fakeSelectRef?.current && (
          <List
            className={ classes.menuList }
            width={ fakeSelectRef?.current?.offsetWidth > 400 ? fakeSelectRef?.current?.offsetWidth : 400 }
            height={ calculateHeight() }
            rowRenderer={ renderRow }
            rowCount={ filteredList.length }
            rowHeight={ 35 }
          />
        )}
      </Menu>

      {showChip && !!selectedChip.length && (
        <Grid container wrap="wrap" className={ classes.chipList }>
          {selectedChip.map((item) => (
            <Chip
              key={ item.id }
              labelKey="name"
              valueKey="id"
              option={ item }
              onClick={ removeElement }
              disabled={ disabled }
            />
          ))}
        </Grid>
      )}
    </div>
  );
};

SelectVirtualized.defaultProps = {
  list: [],
  value: [],
  className: null,
  name: '',
  label: '',
  error: '',
  disabled: false,
  isTouched: false,
  multiple: false,
  tree: false,
  showChip: false,
  filterText: 'none',
  onChange: () => {},
  setFieldTouched: () => {},
};

SelectVirtualized.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    disableOption: PropTypes.bool, // deny to choose option
    parent: PropTypes.bool,
    parentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // should be in child element if property 'tree' = true
  })),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  disabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  multiple: PropTypes.bool,
  filterText: PropTypes.string,
  tree: PropTypes.bool, // if tree = true and you click on parent element all children will be checked
  showChip: PropTypes.bool,
};

export default SelectVirtualized;
