import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as ClearSVG } from 'assets/images/icons/clear.svg';

// Modules
import Input from '../Input';
import InputAdornmentEnd from '../InputAdornmentEnd/InputAdornmentEnd';
import InputAdornmentButton from '../InputAdornmentEnd/InputAdornmentButton/InputAdornmentButton';
import InputAdornmentIcon from '../InputAdornmentEnd/InputAdornmentIcon/InputAdornmentIcon';

// Styles
import classes from './ClearableInput.module.scss';

const ClearableInput = ({
  label,
  name,
  type,
  onChange,
  onBlur,
  onClear,
  value,
  fullWidth,
  labelBackgroundColor,
  autoComplete,
  errors,
  touched,
  ...props
}) => (
  <Input
    { ...props }
    label={ label }
    type={ type }
    value={ value }
    name={ name }
    onChange={ onChange }
    onBlur={ onBlur }
    fullWidth={ fullWidth }
    labelBackgroundColor={ labelBackgroundColor }
    autoComplete={ autoComplete }
    errors={ errors }
    touched={ touched }
    onClear={ onClear }
    endAdornment={ (
      <InputAdornmentEnd className={ classes.inputAdornmentEnd }>
        <InputAdornmentButton onClick={ onClear } className={ classes.inputAdornmentButton }>
          <InputAdornmentIcon icon={ ClearSVG } />
        </InputAdornmentButton>
      </InputAdornmentEnd>
      ) }
  />
);

ClearableInput.defaultProps = {
  fullWidth: false,
  autoComplete: 'true',
  labelBackgroundColor: '#f9f9f9',
  label: '',
  type: 'text',
  errors: {},
  touched: {},
  onBlur: () => { },
};

ClearableInput.propTypes = {
  onClear: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  labelBackgroundColor: PropTypes.string,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
};

export default ClearableInput;
