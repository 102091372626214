import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

// Libs
import { get } from 'lodash';
import { separateByStatus } from 'libs/separateByStatus';

// Modules
import Datepicker from 'modules/_Factories/Datepicker/Datepicker';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';
import FilledCheckbox from 'modules/_Factories/Checkbox/FilledCheckbox/FilledCheckbox';
import Input from 'modules/_Factories/Input/Input';
import { StepContainer } from 'modules/Steps';

// Dictionaries
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import useTimeZones from 'hooks/dictionary/useTimeZones';

const now = new Date();

const General = ({ formik, shortAdvertiserList, editable }) => {
  const { timeZones } = useTimeZones();
  const advertiserOptions = useMemo(() => separateByStatus(shortAdvertiserList)
    .map((item) => ({
      ...item,
      disableOption: item.id === 'INACTIVE' || item.parentId === 'INACTIVE',
    })), [shortAdvertiserList]);

  const isAudience = formik.values.systemType === CampaignTypes.types.AUDIENCE;

  const showRunningAfterApproval = [
    CampaignStatuses.types.DRAFT,
    CampaignStatuses.types.DECLINED,
    CampaignStatuses.types.PENDING,
  ].includes(formik.initialValues.status) && isAudience;

  return (
    <StepContainer
      title="Choose advertiser and the flight dates for the campaign"
      subtitle="Your campaign will have to be approved prior to the start date"
    >
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 } sm={ 6 }>
          <Input
            name="name"
            label="Campaign Name"
            value={ get(formik.values, 'name') || '' }
            // Options
            fullWidth
            required
            disabled={ !editable }
            // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <SelectVirtualized
            name="advertiser"
            label="Advertiser"
            value={ get(formik.values, 'advertiser') || '' }
            list={ advertiserOptions }
            disabled={ !!formik.values.id || !editable }
            onChange={ formik.handleChange }
            setFieldTouched={ formik.setFieldTouched }
            error={ formik.errors.advertiser }
            isTouched={ formik.touched.advertiser }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 12 }>
          <SelectVirtualized
            name="timeZone"
            label="Time Zone"
            value={ formik.values.timeZone }
            list={ timeZones }
            disabled={ !editable }
            onChange={ formik.handleChange }
            setFieldTouched={ formik.setFieldTouched }
            error={ formik.errors.timeZone }
            isTouched={ formik.touched.timeZone }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Datepicker
            name="start"
            label="Start date"
            minDate={ now }
            maxDate={ get(formik.values, 'end') }
            selected={ get(formik.values, 'start') }
            fullWidth
            required={ !isAudience }
            disabled={ !editable || get(formik.values, 'runningAfterApproval') }
            onChange={ (startValue) => formik.setFieldValue('start', startValue) }
            onReset={ () => formik.resetField('start') }
            setFieldTouched={ formik.setFieldTouched }
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <Datepicker
            name="end"
            label="End Date"
            minDate={ get(formik.values, 'start') || now }
            selected={ get(formik.values, 'end') }
            fullWidth
            required={ !isAudience }
            disabled={ !editable }
            onChange={ (endValue) => formik.setFieldValue('end', endValue) }
            onReset={ () => formik.resetField('end') }
            setFieldTouched={ formik.setFieldTouched }
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>

        {showRunningAfterApproval && (
          <Grid item xs={ 12 } sm={ 12 }>
            <FilledCheckbox
              simpleCheckbox
              label="Start Running After Approval"
              onChange={ formik.handleChange }
              value={ get(formik.values, 'runningAfterApproval') }
              name="runningAfterApproval"
              checked={ get(formik.values, 'runningAfterApproval') }
              disabled={ !editable || !!get(formik.values, 'start') }
            />
          </Grid>
        )}
      </Grid>
    </StepContainer>
  );
};

General.defaultProps = { shortAdvertiserList: [] };

General.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      id: PropTypes.number,
      advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      systemType: PropTypes.string,
      timeZone: PropTypes.string,
    }),
    initialValues: PropTypes.shape({ status: PropTypes.string }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({ advertiser: PropTypes.string, timeZone: PropTypes.string }),
    touched: PropTypes.shape({ advertiser: PropTypes.bool, timeZone: PropTypes.bool }),
  }).isRequired,
  shortAdvertiserList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  editable: PropTypes.bool.isRequired,
};

export default General;
