import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { Button, Grid } from '@material-ui/core';

// Constants
import dashboardMetrics from 'constants/dashboard-metrics';
import periodDictionary from 'constants/dictionary/periodDictionary';

// Modules
import Select from 'modules/_Factories/Select/Select';
import PageTitle from 'modules/PageTitle/PageTitle';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';

// Libs
import { textFormat } from 'libs/textFormat';
import { separateByStatus } from 'libs/separateByStatus';

// Icons
import { ReactComponent as IconRefresh } from 'assets/images/icons/refresh.svg';

// Components
import useShortLists from 'hooks/useShortLists';
import usePermissions from 'hooks/usePermissions';
import CardMetric from './CardMetric/CardMetric';
import ActivityLineChart from './ActivityLineChart/ActivityLineChart';

// Styles
import classes from './ActivityOverview.module.scss';

const ActivityOverview = ({
  fetchData,
  total,
  previousTotal,
  data,
  period,
  advertisers,
  campaigns,
  handleFilterChange,
  resetFilters,
}) => {
  const [metric, setMetric] = useState(dashboardMetrics.IMPRESSIONS);
  const {
    shortAdvertiserList,
    shortCampaignList,
  } = useShortLists(['getShortAdvertiserList', 'getShortCampaignList']);
  const permissions = usePermissions();
  const advertiserOptions = useMemo(() => separateByStatus(shortAdvertiserList), [shortAdvertiserList]);

  const periods = useMemo(() => {
    const excludePeriods = [periodDictionary.types.CUSTOM, periodDictionary.types.LAST_90_DAYS];
    return periodDictionary.dictionary.filter((item) => !excludePeriods.includes(item.id));
  }, []);

  const metricsData = useMemo(() => [
    {
      id: 0,
      metric: dashboardMetrics.IMPRESSIONS,
      title: 'Impressions',
      subtitle: `previous period ${textFormat(previousTotal.impressions, 'number') || '-'}`,
      value: textFormat(total.impressions, 'number') || '-',
    },
    {
      id: 1,
      metric: dashboardMetrics.SPEND,
      title: 'Spend',
      subtitle: `previous period ${textFormat(previousTotal.spend, 'currency') || '-'} `,
      value: textFormat(total.spend, 'currency') || '-',
    },
    {
      id: 2,
      metric: dashboardMetrics.ECPM,
      title: 'CPM',
      subtitle: `previous period ${textFormat(previousTotal.ecpm, 'currency') || '-'} `,
      value: textFormat(total.ecpm, 'currency') || '-',
    },
  ], [previousTotal, total]);

  return (
    <div className={ classes.wrapper }>
      <PageTitle title="Activity Overview" />

      <Grid container spacing={ 3 } alignItems="center" className="mb-3">
        <Grid item sm="auto">
          <Button
            variant="text"
            color="primary"
            onClick={ resetFilters }
          >
            Reset
          </Button>
        </Grid>
        <Grid item sm="auto">
          <IconRefresh
            className={ classes.iconRefresh }
            onClick={ () => fetchData() }
            data-test="icon-refresh"
          />
        </Grid>
        <Grid item sm lg={ 3 }>
          <Select
            name="period"
            label="Period"
            value={ period }
            itemList={ periods }
            // Options
            showNoneItem={ false }
            // Events
            onChange={ (e) => { handleFilterChange(e.target.name, e.target.value); } }
            data-test="periods"
          />
        </Grid>
        {permissions.advertiserRead && (
          <Grid item sm lg={ 3 }>
            <SelectVirtualized
              multiple
              tree
              list={ advertiserOptions }
              name="advertisers"
              label="Advertisers"
              onChange={ (e) => handleFilterChange(e.target.name, e.target.value) }
              value={ advertisers }
            />
          </Grid>
        )}
        {permissions.campaignRead && (
          <Grid item sm lg={ 3 }>
            <SelectVirtualized
              multiple
              list={ shortCampaignList }
              name="campaigns"
              label="Campaigns"
              onChange={ (e) => handleFilterChange(e.target.name, e.target.value) }
              value={ campaigns }
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={ 3 }>
        <Grid item md={ 3 }>
          {metricsData.map((item) => (
            <CardMetric
              key={ item.id }
              title={ item.title }
              subTitle={ item.subtitle }
              value={ item.value }
              isActive={ item.metric === metric }
              metric={ item.metric }
              onClick={ setMetric }
            />
          ))}
        </Grid>
        <Grid item md={ 9 } sm={ 12 }>
          <ActivityLineChart
            data={ data }
            xDataKey="item"
            yDataKey={ `metric.${metric}` }
            period={ period }
            metric={ metric }
          />
        </Grid>
      </Grid>
    </div>
  );
};

ActivityOverview.defaultProps = {
  total: {},
  previousTotal: {},
  data: [],
  fetchData: () => {},
};
ActivityOverview.propTypes = {
  fetchData: PropTypes.func,
  total: PropTypes.shape({
    impressions: PropTypes.number,
    spend: PropTypes.number,
    vastCompleteRate: PropTypes.number,
    ecpm: PropTypes.number,
  }),
  previousTotal: PropTypes.shape({
    impressions: PropTypes.number,
    spend: PropTypes.number,
    vastCompleteRate: PropTypes.number,
    ecpm: PropTypes.number,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.number,
      metrics: PropTypes.shape({
        impressions: PropTypes.number,
        spend: PropTypes.number,
        vastCompleteRate: PropTypes.number,
        ecpm: PropTypes.number,
      }),
    }),
  ),
  period: PropTypes.string.isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.number).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default memo(ActivityOverview);
