import React, { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import StepTitle from '../StepTitle';
import classes from './Creatives.module.scss';
import CreativePreview from '../../Creatives/CreativePreview';

const Creatives = ({ creatives }) => {
  const renderCreativeList = (creativeList, title) => {
    if (!creativeList.length) return null;

    return (
      <Grid container item spacing={ 3 }>
        {title && (
          <Grid item xs={ 12 } sm={ 12 }>
            <Typography variant="h6" className={ classes.titleText }>{title}</Typography>
          </Grid>
        )}
        <Grid container item spacing={ 3 } wrap="nowrap" className={ classes.creativeWrapper }>
          {creativeList.map((item) => (
            <Grid item xs={ 3 } sm={ 3 } key={ item.id } className={ classes.previewWrapper }>
              <CreativePreview className={ classes.preview } id={ item.id } />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <section>
      <StepTitle name="Creatives" />
      <Grid container spacing={ 3 }>
        {renderCreativeList(creatives)}
      </Grid>
    </section>
  );
};

Creatives.defaultProps = { creatives: [] };

Creatives.propTypes = {
  creatives: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default memo(Creatives);
