import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import authActions from 'actions/authActions';
import { useHistory } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';

const useResetPassword = () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isValidToken, setIsValidToken] = useState(true);

  const setPassword = useCallback((passwordInfo) => dispatch(authActions.setPassword(passwordInfo)), [
    dispatch,
  ]);

  const checkToken = useCallback((token) => dispatch(authActions.checkResetPasswordToken(token)), [
    dispatch,
  ]);

  const token = query.get('token');

  useEffect(() => {
    if (token) {
      checkToken(token)
        .then(() => setIsValidToken(true))
        .catch(() => setIsValidToken(false));
    } else {
      history.push('/login');
    }
  }, [checkToken, history, token]);

  return { setPassword, isValidToken, token };
};

export default useResetPassword;
