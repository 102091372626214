export default {
  GET_AD_UNIT_LIST: 'SHORT/GET_AD_UNIT_LIST',
  GET_ADVERTISER_LIST: 'SHORT/GET_ADVERTISER_LIST',
  GET_CAMPAIGN_LIST: 'SHORT/GET_CAMPAIGN_LIST',
  GET_CREATIVE_LIST: 'SHORT/GET_CREATIVE_LIST',
  GET_DEAL_LIST: 'SHORT/GET_DEAL_LIST',
  GET_DSP_LIST: 'SHORT/GET_DSP_LIST',
  GET_INVENTORY_LIST: 'SHORT/GET_INVENTORY_LIST',
  GET_PUBLISHER_LIST: 'SHORT/GET_PUBLISHER_LIST',
  GET_ROLE_LIST: 'SHORT/GET_ROLE_LIST',
  GET_SSP_LIST: 'SHORT/GET_SSP_LIST',
  GET_SUPPLY_PARTNER_LIST: 'SHORT/GET_SUPPLY_PARTNER_LIST',
  GET_USER_LIST: 'SHORT/GET_USER_LIST',
  GET_DSP_EMBEDDED: 'SHORT/GET_DSP_EMBEDDED',
};
