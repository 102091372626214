import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { formatISO } from 'date-fns';
// import { TargetingClass } from 'classes/targetingClass_new';

// Actions
// import insightActions from 'actions/insightActions';

// Selector
import insightSelectors from 'selectors/insightSelectors';

// import { createCampaignModel } from '../useAddCampaign';

const useInfo = () => {
  // Hooks
  // const dispatch = useDispatch();

  // const getInsightTotal = useCallback((params) => dispatch(insightActions.getInsightTotal(params, false)), [dispatch]);
  // const getInsightTarget = useCallback((model) => dispatch(insightActions.getInsightTarget(model, {}, false)), [dispatch]);
  // const getInsightCpm = useCallback((model) => dispatch(insightActions.getInsightCpm(model, {}, false)), [dispatch]);

  const insightTotal = useSelector(insightSelectors.insightTotalSelector);
  const insightTarget = useSelector(insightSelectors.insightTargetSelector);
  const insightCpm = useSelector(insightSelectors.insightCpmSelector);

  const changeStatus = useCallback((form, status) => {
    if (form && form.values) form.values.status = status;
    return form;
  }, []);

  // const { start, end, timeZone } = formik.values;

  // useEffect(() => {
  //   if (!start || !end) return;
  //   const campaign = createCampaignModel(formik.values);

  //   const targeting = transformTargetingProfileModel(new TargetingClass(formik.values.targetingProfile));
  //   const model = { campaign, targeting };

  //   getInsightCpm(model);
  //   getInsightTarget(model);

  // }, [end, start, getInsightTarget, getInsightCpm, formik.values]);

  // useEffect(() => () => {
  //   dispatch(insightActions.clearInsightTotal());
  //   dispatch(insightActions.clearInsightTarget());
  //   dispatch(insightActions.clearInsightCpm());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (!start || !end) return;

  //   const params = {
  //     start: formatISO(new Date(start), { representation: 'date' }),
  //     end: formatISO(new Date(end), { representation: 'date' }),
  //     timeZone: timeZone || 'GMT',
  //   };
  //   getInsightTotal(params);
  // }, [end, start, timeZone, getInsightTotal]);

  return { insightTotal, insightTarget, insightCpm, changeStatus };
};

useInfo.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    }),
    handleChange: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  tabIndex: PropTypes.number.isRequired,
  buildThirdPartyBeacons: PropTypes.func.isRequired,
};

export default useInfo;
