import React, { memo } from 'react';
import PropTypes from 'prop-types';

const VideoCreative = ({ url, className }) => (
  <video controls width="100%" height="100%" className={ className }>
    <source src={ url } />
    <track src="" kind="captions" srcLang="en" label="english_captions" />
    Browser not supported
  </video>
);

VideoCreative.defaultProps = { className: null };

VideoCreative.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default memo(VideoCreative);
