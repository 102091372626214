import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Dialog, Slide } from '@material-ui/core';

// Icons
import { ReactComponent as IconClose } from 'assets/images/icons/close.svg';

// Styles
import classes from './Modal.module.scss';

function Transition(props) {
  return <Slide direction="up" { ...props } />;
}

const Modal = ({ open, onClose, children, fullScreen }) => {
  const extendedClassNames = { paper: classes.paper };

  return (
    <Dialog
      fullScreen={ fullScreen }
      open={ open }
      classes={ extendedClassNames }
      onClose={ onClose }
      scroll="body"
      maxWidth={ false }
      TransitionComponent={ Transition }
    >
      <IconClose className={ classes.modalCloseButton } onClick={ onClose } />
      {children}
    </Dialog>
  );
};

Modal.defaultProps = {
  onClose: () => { },
  fullScreen: false,
};
Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]).isRequired,
  onClose: PropTypes.func,
};

export default Modal;
