import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Components
import Auth from 'components/Auth/Auth';
import Main from 'components/Main';

const Router = () => (
  <Switch>
    <Route path={ ['/login', '/signup', '/forgot-password', '/reset-password'] } component={ Auth } />
    <Route
      path={ ['/dashboard', '/advertisers', '/campaigns', '/creatives', '/reports', '/insights', '/profile', '/404-not-found'] }
      component={ Main }
    />
    <Redirect to="/login" />
  </Switch>
);

export default Router;
