import React, { memo, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import creativeActions from 'actions/creativeActions';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';
import { DEFAULT_FILTERS, INITIAL_FILTERS } from 'reducers/creativeReducer';

// Libs
import { hasPermission } from 'libs/storageLibs';
import { separateByStatus } from 'libs/separateByStatus';
import { strToArrayNumber, transformParams } from 'libs/query-string';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';

// Hooks
import useShortLists from 'hooks/useShortLists';
import useGetParameters from 'hooks/useGetParameters';
import useQueryParams from 'hooks/useQueryParams';
import usePermissions from 'hooks/usePermissions';
import useCreativeList from './useCreativeList';

// Components
import Filters from './Filters';
import TableCreatives from './TableCreatives';
import PreviewModal from './PreviewModal';

const getParamNames = (permissions) => {
  const paramNames = ['search', 'type', 'status'];
  if (permissions.advertiserRead) paramNames.push('advertisers');
  if (permissions.campaignRead) paramNames.push('campaigns');

  return paramNames;
};

const CreativeList = ({ match }) => {
  const [previewModal, setPreviewModal] = useState({
    isOpen: false,
    creative: null,
  });

  const dispatch = useDispatch();
  const filters = useSelector((state) => state.creatives.filters);
  const setFilters = useCallback((values) => dispatch(creativeActions.setFilters(values)), [dispatch]);

  const tableRef = useRef();
  const refFilters = useRef(filters);

  const { shortAdvertiserList, shortCampaignList } = useShortLists(['getShortAdvertiserList', 'getShortCampaignList']);
  const advertiserOptions = useMemo(() => separateByStatus(shortAdvertiserList), [shortAdvertiserList]);
  const { requestCreatives, meta, updateCreativeStatus } = useCreativeList(filters);
  const permissions = usePermissions();
  const PARAM_NAMES = useMemo(() => getParamNames(permissions), [permissions]);
  const params = useGetParameters(PARAM_NAMES);
  const { debouncedReplaceQueryParams, replaceQueryParams } = useQueryParams();

  const updateStatus = useCallback((id, status) => {
    updateCreativeStatus(id, status).then(() => (tableRef?.current?.onQueryChange()));
  }, [updateCreativeStatus]);

  useEffect(() => {
    const config = {
      search: (val) => val,
      type: (val) => val,
      status: (val) => val,
      advertisers: strToArrayNumber,
      campaigns: strToArrayNumber,
    };

    if (Object.keys(params).length) {
      const filterValues = { ...INITIAL_FILTERS, ...transformParams(params, config) };
      setFilters(filterValues);
      refFilters.current = filterValues;
    } else {
      replaceQueryParams(refFilters.current);
    }
  }, [params, setFilters, replaceQueryParams]);

  const refreshCreativeList = useCallback(() => {
    if (!tableRef.current) return;
    tableRef.current.onQueryChange();
  }, []);

  const handleChangeFilters = useCallback((values) => {
    setFilters(values);
    refFilters.current = values;
    debouncedReplaceQueryParams(values, refreshCreativeList);
  }, [setFilters, debouncedReplaceQueryParams, refreshCreativeList]);

  const resetFilters = useCallback(() => {
    handleChangeFilters(DEFAULT_FILTERS);
  }, [handleChangeFilters]);

  const togglePreviewModal = useCallback((creative = null) => {
    setPreviewModal((prevState) => ({
      isOpen: !prevState.isOpen,
      creative,
    }));
  }, [setPreviewModal]);

  const isEditable = hasPermission([UserPermissions.types.CREATIVE_WRITE]);

  return (
    <div>
      <Grid container spacing={ 2 } justify="space-between">
        <Grid item>
          <PageTitle className="mb-5" title="Creatives Management" showBreadcrumbs />
        </Grid>
        {isEditable && (
          <Grid item>
            <Button
              component={ Link }
              to={ `${match.url}/add` }
              variant="contained"
              color="primary"
            >
              Add Creative
            </Button>
          </Grid>
        )}
      </Grid>

      <div className="mb-3">
        <Filters
          values={ filters }
          onChange={ handleChangeFilters }
          onReset={ resetFilters }
          advertiserList={ advertiserOptions }
          campaignList={ shortCampaignList }
        />
      </div>

      <TableCreatives
        tableRef={ tableRef }
        getCreativeList={ requestCreatives }
        updateStatus={ updateStatus }
        meta={ meta }
        openPreview={ togglePreviewModal }
      />

      <PreviewModal
        isOpen={ previewModal.isOpen }
        creative={ previewModal.creative }
        handleClose={ togglePreviewModal }
      />
    </div>
  );
};

CreativeList.propTypes = { match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired };

export default memo(CreativeList);
