import { createSelector } from 'reselect';

// Classes
import { UserClass } from 'classes/userClass';

const user = (state) => state.user.user;
const scripts = (state) => state.user.scripts;

const userSelector = createSelector(
  user,
  (item) => ({
    ...new UserClass(item),
    name: `${item.firstName} ${item.lastName}`,
  }),
);

const scriptsSelector = createSelector(scripts, (items) => items);

export default { userSelector, scriptsSelector };
