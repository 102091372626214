import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

// Actions
import shortListActions from 'actions/shortListActions';
import shortListSelectors from '../selectors/shortListSelectors';

/**
 * Possible items[].
 * @getShortAdUnitList
 * @getShortAdvertiserList
 * @getShortCampaignList
 * @getShortCreativeList
 * @getShortDealList
 * @getShortDemandPartnerList
 * @getShortSiteAppList
 * @getShortRoleList
 * @getShortSupplySourceList
 * @getShortSupplyPartnerList
 * @getShortUserList
 */
const useShortLists = (items = [], getParams = []) => {
  const dispatch = useDispatch();

  const [requestedLists] = useState(items);
  const [requestedListsParams, setRequestedListParams] = useState(getParams);

  const getShortAdUnitList = useCallback((params) => dispatch(shortListActions.getShortAdUnitList(params)), [dispatch]);
  const getShortAdvertiserList = useCallback((params) => dispatch(shortListActions.getShortAdvertiserList(params)), [dispatch]);
  const getShortCampaignList = useCallback((params) =>
    /* eslint-disable-next-line */
     dispatch(shortListActions.getShortCampaignList({ type: 'VIDEO', ...params })),
  [dispatch]);
  const getShortCreativeList = useCallback((params) => dispatch(shortListActions.getShortCreativeList(params)), [dispatch]);
  const getShortDealList = useCallback((params) => dispatch(shortListActions.getShortDealList(params)), [dispatch]);
  const getShortDemandPartnerList = useCallback((params) => dispatch(shortListActions.getShortDemandPartnerList(params)), [dispatch]);
  const getShortSiteAppList = useCallback((params) => dispatch(shortListActions.getShortSiteAppList(params)), [dispatch]);
  const getShortRoleList = useCallback((params) => dispatch(shortListActions.getShortRoleList(params)), [dispatch]);
  const getShortSupplySourceList = useCallback((params) => dispatch(shortListActions.getShortSupplySourceList(params)), [dispatch]);
  const getShortSupplyPartnerList = useCallback((params) => dispatch(shortListActions.getShortSupplyPartnerList(params)), [dispatch]);
  const getShortUserList = useCallback((params) => dispatch(shortListActions.getShortUserList(params)), [dispatch]);
  const getShortDSPEmbedded = useCallback((params) => dispatch(shortListActions.getShortDSPEmbedded(params)), [dispatch]);

  // Selectors
  const { shortDSPEmbedded } = useSelector((state) => state.shortLists);

  const shortAdUnitList = useSelector(shortListSelectors.shortAdUnitListSelector);
  const shortAdvertiserList = useSelector(shortListSelectors.shortAdvertiserListSelector);
  const shortCampaignList = useSelector(shortListSelectors.shortCampaignListSelector);
  const shortCreativeList = useSelector(shortListSelectors.shortCreativeListSelector);
  const shortDealList = useSelector(shortListSelectors.shortDealListSelector);
  const shortDemandPartnerList = useSelector(shortListSelectors.shortDemandPartnerListSelector);
  const shortSiteAppList = useSelector(shortListSelectors.shortSiteAppListSelector);
  const shortPublisherList = useSelector(shortListSelectors.shortPublisherListSelector);
  const shortRoleList = useSelector(shortListSelectors.shortRoleListSelector);
  const shortSupplySourceList = useSelector(shortListSelectors.shortSupplySourceListSelector);
  const shortSupplyPartnerList = useSelector(shortListSelectors.shortSupplyPartnerListSelector);
  const shortUserList = useSelector(shortListSelectors.shortUserListSelector);

  const shortLists = useMemo(() => ({
    getShortAdUnitList,
    getShortAdvertiserList,
    getShortCampaignList,
    getShortCreativeList,
    getShortDealList,
    getShortDemandPartnerList,
    getShortSiteAppList,
    getShortRoleList,
    getShortSupplySourceList,
    getShortSupplyPartnerList,
    getShortUserList,
    getShortDSPEmbedded,
  }), [
    getShortAdUnitList,
    getShortAdvertiserList,
    getShortCampaignList,
    getShortCreativeList,
    getShortDealList,
    getShortDemandPartnerList,
    getShortRoleList,
    getShortSiteAppList,
    getShortSupplyPartnerList,
    getShortSupplySourceList,
    getShortUserList,
    getShortDSPEmbedded,
  ]);

  useEffect(() => {
    if (isEqual(getParams, requestedListsParams)) return;
    setRequestedListParams(getParams);
  }, [getParams, requestedListsParams]);

  useEffect(() => {
    requestedLists.forEach((item, i) => shortLists[item] && shortLists[item](requestedListsParams[i] || {}));
  }, [requestedLists, requestedListsParams, shortLists]);

  return {
    shortAdUnitList,
    shortAdvertiserList,
    shortCampaignList,
    shortCreativeList,
    shortDealList,
    shortDemandPartnerList,
    shortSiteAppList,
    shortPublisherList,
    shortRoleList,
    shortSupplySourceList,
    shortSupplyPartnerList,
    shortUserList,
    shortDSPEmbedded,
  };
};

export default useShortLists;
