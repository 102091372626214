import { useCallback } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import PAYMENT_METHODS from 'constants/dictionary/paymentMethodDictionary';
import BALANCE_OPERATIONS from 'constants/dictionary/balanceOperationDictionary';
import { validateNumber, validateRequired } from 'libs/validateErrors';

export const getInitialValues = (overrideValues) => ({
  paymentMethod: PAYMENT_METHODS.types.PAY_BY_CARD,
  operation: BALANCE_OPERATIONS.types.ADD,
  amount: null,
  notes: null,
  ...overrideValues,
});

const useFormikAdvertiserBalance = (initialValues = getInitialValues()) => {
  const validate = useCallback((values) => {
    const amount = validateNumber(values.amount) || validateRequired(values.amount, 'Amount');
    return { ...amount && { amount } };
  }, []);

  const formik = useFormik({
    initialValues,
    validate,
  });

  const { setFieldValue } = formik;

  const setFormValue = useCallback((name, value) => {
    setFieldValue(name, value);
  }, [setFieldValue]);

  return {
    formAdvertiserBalance: formik,
    setFormValue,
  };
};

useFormikAdvertiserBalance.propTypes = {
  initialValues: PropTypes.shape({
    paymentMethod: PropTypes.oneOf([PAYMENT_METHODS.types]),
    operation: PropTypes.oneOf([BALANCE_OPERATIONS.types]),
    amount: PropTypes.number,
    notes: PropTypes.string,
  }),
};

export default useFormikAdvertiserBalance;
