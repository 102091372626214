export class UserClass {
  id = null;
  advertisers = [];
  company = null;
  dsps = [];
  email = null;
  firstName = '';
  lastName = '';
  listOnlyDsps = [];
  listOnlySsps = [];
  phone = null;
  roles = [];
  ssps = [];
  status = null;
  timeZone = null;
  website = null;

  constructor(...args) {
    args.forEach((item) => {
      this.id = item.id || null;
      this.advertisers = item.advertisers || [];
      this.company = item.company || null;
      this.dsps = item.dsps || [];
      this.email = item.email || null;
      this.firstName = item.firstName || '';
      this.lastName = item.lastName || '';
      this.listOnlyDsps = item.listOnlyDsps || [];
      this.listOnlySsps = item.listOnlySsps || [];
      this.roles = item.roles || [];
      this.ssps = item.ssps || [];
      this.status = item.status || null;
      this.timeZone = item.timeZone || null;
      this.website = item.website || null;
    });
  }
}
