import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

// Material
import { Button, Grid, Paper, Typography } from '@material-ui/core';

// Libs
import { getFormattedDate } from 'libs/dateConvertors';
import { textFormat } from 'libs/textFormat';

// Dictionaries
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';

// Hooks
import useInfo from './useInfo';

// Styles
import classes from './Info.module.scss';
import DeclineCampaignModal from '../../DeclineCampaignModal/DeclineCampaignModal';

const Info = ({
  formik,
  editable,
  approval,
}) => {
  const {
    // insightTotal: totalImps,
    // insightTarget: targetImps,
    // insightCpm,
    changeStatus,
  } = useInfo({ formik });
  const [openDeclineCampaignModal, setOpenDeclineCampaignModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { values, errors, initialValues } = formik;

  const isProgrammaticDirect = formik.values.systemType === CampaignTypes.types.PROGRAMMATIC_DIRECT;

  const getButton = useCallback((text, status) => {
    const handleClick = (e) => {
      if (initialValues.status === CampaignStatuses.types.DRAFT && errors.creatives) {
        enqueueSnackbar('Please fill in all obligatory fields and select at least one creative', { variant: 'error' });
      } else {
        changeStatus(formik, status);
        formik.handleSubmit(e);
      }
    };
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={ !formik.isValid }
        onClick={ handleClick }
        fullWidth
      >
        {text}
      </Button>
    );
  }, [formik, enqueueSnackbar, changeStatus, initialValues, errors]);

  const renderApprovalButton = useMemo(() => {
    switch (values.status) {
      case CampaignStatuses.types.DRAFT:
      case CampaignStatuses.types.PENDING:
      case CampaignStatuses.types.DECLINED: {
        if (approval && isProgrammaticDirect) {
          return getButton('Send To Publisher', CampaignStatuses.types.PENDING_PUBLISHER_REVIEW);
        }
        if (approval) {
          return getButton('Approve campaign', CampaignStatuses.types.APPROVED);
        }
        return getButton('Request approval', CampaignStatuses.types.PENDING);
      }

      default:
        return null;
    }
  }, [approval, getButton, isProgrammaticDirect, values.status]);

  const toggleDeclineCampaignModal = useCallback(() => {
    setOpenDeclineCampaignModal((isOpen) => !isOpen);
  }, [setOpenDeclineCampaignModal]);

  const handleDeclineCampaign = useCallback((comment) => {
    changeStatus(formik, CampaignStatuses.types.DECLINED);
    formik.setFieldValue('approvalNotes', comment);
    formik.handleSubmit();
  }, [changeStatus, formik]);

  return (
    <Paper className={ classes.wrapper } elevation={ 0 }>
      <Typography variant="h6" className={ classes.title }>DETAILS</Typography>

      <div className={ classes.detailValues }>
        <span>{ `${getFormattedDate(values.start) || ''} - ${getFormattedDate(values.end) || ''}` }</span>
      </div>
      <div className={ classes.detailText }>Flight Dates</div>

      <Grid container item xs className="mt-3 mb-5">
        <Grid item xs>
          <div className={ classes.detailValues }>
            { values.budgetTarget ? textFormat(values.budgetTarget, 'currency') : '-' }
          </div>
          <div className={ classes.detailText }>Campaign Budget</div>
        </Grid>
        <Grid item xs>
          <div className={ classes.detailValues }>
            { values.pricingStrategy?.cpm ? textFormat(values.pricingStrategy.cpm, 'currency') : '-' }
          </div>
          <div className={ classes.detailText }>CPM</div>
        </Grid>
      </Grid>

      {formik.values.approvalNotes && formik.initialValues.status === CampaignStatuses.types.DECLINED && (
      <Grid container spacing={ 4 }>
        <Grid item>
          <Typography variant="h6" className={ classes.declineReason }>
            Decline Reason:
            <span className={ classes.declineReasonText }>{ formik.values.approvalNotes}</span>
          </Typography>

        </Grid>

      </Grid>
      )}

      {editable && (
        <Grid container spacing={ 2 }>
          <Grid item sm={ 12 }>
            {renderApprovalButton}
          </Grid>

          {formik.initialValues.status === CampaignStatuses.types.PENDING && approval && (
            <Grid item sm={ 12 }>
              <Button
                variant="contained"
                color="secondary"
                disabled={ !formik.isValid }
                fullWidth
                onClick={ toggleDeclineCampaignModal }
              >
                Decline Campaign
              </Button>
              <DeclineCampaignModal
                isOpen={ openDeclineCampaignModal }
                handleClose={ toggleDeclineCampaignModal }
                handleDecline={ handleDeclineCampaign }
                campaign={ formik.values }
              />
            </Grid>
          )}

        </Grid>
      )}
    </Paper>
  );
};

Info.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      budgetTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      pricingStrategy: PropTypes.shape({ cpm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
      status: PropTypes.string.isRequired,
      systemType: PropTypes.string,
      approvalNotes: PropTypes.string,
      creatives: PropTypes.arrayOf(PropTypes.number),
    }),
    initialValues: PropTypes.shape({ status: PropTypes.string.isRequired }),
    handleChange: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({
      creatives: PropTypes.shape({
        adCreatives: PropTypes.string,
        triviaCreatives: PropTypes.string,
      }),
    }),
    touched: PropTypes.shape({}),
    isValid: PropTypes.bool,
    handleSubmit: PropTypes.func,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
  approval: PropTypes.bool.isRequired,
};

export default Info;
