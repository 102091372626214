import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import dictionaryActions from 'actions/dictionaryActions';

const useLanguages = (sendRequest = false) => {
  const dispatch = useDispatch();

  const getLanguages = useCallback(() => dispatch(dictionaryActions.getLanguages()), [dispatch]);

  useEffect(() => {
    if (sendRequest) {
      getLanguages();
    }
  }, [getLanguages, sendRequest]);

  const languages = useSelector((state) => state.dictionary.languages);

  return { languages };
};

export default useLanguages;
