import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Constants
import campaignSelectors from 'selectors/campaignSelectors';
import dayPartTargeting from 'constants/dictionary/dayPartTargetingDictionary';

const { dayDictionary, amIntervals, pmIntervals } = dayPartTargeting;

const useReview = ({ targetingProfile, creatives }) => {
  const { values: periods } = targetingProfile.periods;
  const creativeList = useSelector(campaignSelectors.creativeListSelector);

  const selectedCreatives = creatives.map((creativeId) => creativeList.find((item) => item.id === creativeId));

  const getTimeValue = useCallback((fullTime = '00:00:00') => {
    const isAm = fullTime.split(':')[0] < 12;
    const dayIntervals = isAm ? amIntervals : pmIntervals;
    const dayInterval = dayIntervals.find((item) => item.id === fullTime) || {};
    return `${dayInterval.name} ${dayInterval.type}`;
  }, []);

  const flightSchedule = useMemo(() => {
    const values = dayDictionary.reduce((cur, next) => {
      const intervals = (periods || [])
        .reduce((acc, val) => (val.dayOfWeek === next.id ? [...acc, val.start] : acc), [])
        .sort()
        .map((time) => getTimeValue(time));

      return intervals.length ? [...cur, { ...next, intervals }] : cur;
    }, []);

    return values;
  }, [periods, getTimeValue]);

  return { selectedCreatives, flightSchedule };
};

useReview.propTypes = { values: PropTypes.shape({}).isRequired };

export default useReview;
