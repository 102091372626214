import React from 'react';
import PropTypes from 'prop-types';
import ReactDatepicker from 'react-datepicker';
import { format } from 'date-fns';
import classNames from 'classnames';

// Material
import { Typography, IconButton, Icon, Button, FormHelperText } from '@material-ui/core';

// Components
import InputDatepicker from './InputDatepicker/InputDatepicker';

// Hooks
import useDatepicker from './useDatepicker';

// Styles
import classes from './Datepicker.module.scss';

const DatepickerCustomHeader = (props) => {
  const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled } = props;

  return (
    <div className={ classes.calendarHeader }>
      <IconButton color="primary" component="span" onClick={ decreaseMonth } disabled={ prevMonthButtonDisabled }>
        <Icon>keyboard_arrow_left</Icon>
      </IconButton>
      <Typography variant="h4">
        { format(date, 'MMMM yyyy') }
      </Typography>
      <IconButton color="primary" component="span" onClick={ increaseMonth } disabled={ nextMonthButtonDisabled }>
        <Icon>keyboard_arrow_right</Icon>
      </IconButton>
    </div>
  );
};

DatepickerCustomHeader.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
  prevMonthButtonDisabled: PropTypes.bool.isRequired,
  nextMonthButtonDisabled: PropTypes.bool.isRequired,
};

const Datepicker = (props) => {
  const { selected, name, label, minDate, maxDate, disabled, dateFormat, popperPlacement, className } = props;
  // Options
  const { labelBackgroundColor, fullWidth, required } = props;
  // Events
  const { onChange, onReset, setFieldTouched } = props;
  // Extra
  const { errors, touched } = props;

  const { setCalendarReference, handlerClickOutside, handlerReset } = useDatepicker();

  const error = errors[name];
  const hasError = Boolean(touched[name] && errors[name]);

  return (
    <>
      <div className={ classNames(classes.wrapper, className, { [classes.wrapperFullWidth]: fullWidth }) }>
        <ReactDatepicker
          ref={ (el) => setCalendarReference(el) }
          selected={ selected }
          name={ name }
          disabled={ disabled }
          minDate={ minDate }
          maxDate={ maxDate }
          popperClassName={ classes.calendar }
          popperPlacement={ popperPlacement }
          popperModifiers={ { preventOverflow: { enabled: true } } }
          shouldCloseOnSelect={ false }
          dateFormat={ dateFormat }
          showPopperArrow={ false }
          customInput={ (
            <InputDatepicker
              name={ name }
              label={ label }
              // Options
              labelBackgroundColor={ labelBackgroundColor }
              fullWidth={ fullWidth }
              requiredField={ required }
              // Extra
              errors={ errors }
              touched={ touched }
            />
          ) }
          renderCustomHeader={ (innerProps) => <DatepickerCustomHeader { ...innerProps } /> }
          // Events
          onChange={ onChange }
          onCalendarClose={ () => setFieldTouched(name, true) }
        >
          <div className={ classes.calendarFooter }>
            <Button size="large" className="mr-2" onClick={ () => handlerReset(onReset) }>Reset</Button>
            <Button variant="outlined" size="large" color="secondary" onClick={ handlerClickOutside }>Ok</Button>
          </div>
        </ReactDatepicker>

        { hasError && <FormHelperText error={ hasError } id={ name }>{ error }</FormHelperText> }
      </div>
    </>
  );
};

Datepicker.defaultProps = {
  selected: null,
  minDate: null,
  maxDate: null,
  disabled: false,
  dateFormat: 'MM/dd/yyyy',
  popperPlacement: 'bottom-end',
  // Options
  labelBackgroundColor: '#f9f9f9',
  className: null,
  fullWidth: false,
  required: false,
  // Events
  setFieldTouched: () => null,
  // Extra
  errors: {},
  touched: {},
};

Datepicker.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date)]),
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  popperPlacement: PropTypes.string,
  // Options
  labelBackgroundColor: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  // Events
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func,
  // Extra
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
};

export default Datepicker;
