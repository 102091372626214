import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import statsByStatesHashMapSelector from 'selectors/statsByStatesSelector';
import stateMapMatching from 'constants/stateMapMatching';

const useStateMap = (popoverId) => {
  const [activeElement, setActiveElement] = useState(null);
  const [top, setTop] = useState(null);
  const [left, setLeft] = useState(null);
  const [mapId, setMapId] = useState(null);

  const statsByStatesHashMap = useSelector(statsByStatesHashMapSelector);

  const getShortStateInfoByMapId = useCallback((activeMapId) => stateMapMatching[activeMapId], []);

  const stateInfo = useMemo(() => {
    if (!mapId) {
      return null;
    }

    const shortStateInfo = getShortStateInfoByMapId(mapId);

    const fullStateInfo = statsByStatesHashMap[shortStateInfo.id];

    if (fullStateInfo) {
      return fullStateInfo;
    }

    return {
      item: shortStateInfo,
      metric: {},
    };
  }, [getShortStateInfoByMapId, mapId, statsByStatesHashMap]);

  /* Converter from mapId => stateId to stateId => mapId */
  const hashMapConverterFormMapIdToEntityId = useCallback(
    (mapHashMap) => Object.keys(mapHashMap).reduce((prev, curr) => {
      const { id } = mapHashMap[curr];
      prev[id] = curr;

      return prev;
    }, {}),
    [],
  );

  /* Converted Dictionaries */
  const stateIdToMapIdHashMap = useMemo(
    () => hashMapConverterFormMapIdToEntityId(stateMapMatching),
    [hashMapConverterFormMapIdToEntityId],
  );

  const mouseMoveHandler = useCallback((props) => {
    const { target, nativeEvent } = props;

    const leftVal = nativeEvent?.offsetX || nativeEvent?.layerX || 0;
    const topVal = nativeEvent?.offsetY || nativeEvent?.layerY || 0;

    if (target.id === popoverId) {
      return;
    }

    const { parentElement } = target;

    if (activeElement && parentElement.id === activeElement.id) {
      return;
    }

    if (parentElement.tagName === 'g' && parentElement.id !== '') {
      if (activeElement) { // remove class from previous state area
        activeElement.classList.remove('active');
      }

      parentElement.classList.add('active'); // add class for state area

      setLeft(leftVal);
      setTop(topVal);
      setMapId(parentElement.id);
      setActiveElement(parentElement);
    } else {
      if (activeElement) {
        activeElement.classList.remove('active');
      }

      setMapId(null);
      setLeft(null);
      setTop(null);
      setActiveElement(null);
    }
  }, [activeElement, popoverId]);

  const closeTooltip = useCallback(() => {
    if (activeElement) activeElement.classList.remove('active');

    setMapId(null);
    setLeft(null);
    setTop(null);
    setActiveElement(null);
  }, [setMapId, setLeft, setTop, activeElement, setActiveElement]);

  const mouseMoveDebounced = debounce(mouseMoveHandler, 50);

  return {
    activeElement,
    setActiveElement,
    top,
    setTop,
    left,
    setLeft,
    mouseMoveDebounced,
    closeTooltip,
    stateInfo,
    mapId,
    stateIdToMapIdHashMap,
    statsByStatesHashMap,
  };
};

export default useStateMap;
