import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

// Reducers
import rootReducer from 'reducers/rootReducer';

const middlewares = [thunk];

const logger = createLogger({
  collapsed: true,
  predicate: (getState, action) => !action.type.includes('LOADING'),
});

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = createStore(rootReducer(), composeWithDevTools(applyMiddleware(...middlewares)));

export default store;
