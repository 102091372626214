import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const useResetPasswordForm = (setPassword, token) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = { password: '', confirmedPassword: '' };

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Required';
    } else if (values.password.length < 6) {
      errors.password = 'Min length should not be less than 6 symbols';
    }

    if (!values.confirmedPassword) {
      errors.confirmedPassword = 'Confirm password is required';
    } else if (values.password !== values.confirmedPassword) {
      errors.confirmedPassword = 'Passwords do not match';
    }

    return errors;
  };

  const submitHandler = async ({ password }, { setSubmitting }) => {
    try {
      const data = {
        password,
        token,
      };
      await setPassword(data);
      enqueueSnackbar(
        'Password successfully saved',
        {
          variant: 'success',
          onEntered: () => history.push('/login'),
        },
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    setSubmitting(false);
  };

  const resetPasswordForm = useFormik({
    initialValues,
    onSubmit: submitHandler,
    validate,
  });

  return { resetPasswordForm };
};

export default useResetPasswordForm;
