import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import dictionaryActions from 'actions/dictionaryActions';

// Selectors
import dictionarySelectors from 'selectors/dictionarySelectors';

const useCategories = (sendRequest = false) => {
  const dispatch = useDispatch();

  const getCategories = useCallback(() => dispatch(dictionaryActions.getCategories()), [dispatch]);

  useEffect(() => {
    if (sendRequest) {
      getCategories();
    }
  }, [getCategories, sendRequest]);

  const categories = useSelector(dictionarySelectors.categoriesSelector);

  return { categories };
};

export default useCategories;
