import React from 'react';
import PropTypes from 'prop-types';

// Styles
import classes from './TableCell.module.scss';

const TableCell = ({ firstRow, secondRow }) => (
  <>
    <div className={ classes.cellFirstRow }>{ firstRow }</div>
    { secondRow && <div className={ classes.cellSecondRow }>{ secondRow }</div> }
  </>
);

TableCell.defaultProps = { secondRow: '' };

TableCell.propTypes = {
  firstRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  secondRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TableCell;
