import React from 'react';
import PropTypes from 'prop-types';

// Material
import { FormControl, Input, FormHelperText, InputAdornment, IconButton, InputLabel } from '@material-ui/core';

// Icons
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg';
import { ReactComponent as ClearFilledIcon } from 'assets/images/icons/clearFilled.svg';

// Styles
import classes from './SearchInput.module.scss';

const SearchInput = (props) => {
  const {
    name, value, type, autoFocus, autoComplete, placeholder, label,
    helperText, onClear, isShowEndAdornment, isShowStartAdornment,
    ...InputProps
  } = props;
  // Options
  const { fullWidth, className } = props;
  // Events
  const { onChange, onBlur, onKeyPress, onClick, onFocus, onKeyDown } = props;

  const customClasses = { underline: classes.underline };

  return (
    <FormControl fullWidth={ fullWidth } className={ className }>
      <InputLabel htmlFor={ name }>
        { label }
      </InputLabel>

      <Input
        { ...InputProps }
        classes={ customClasses }
        id={ name }
        type={ type }
        value={ value }
        name={ name }
        placeholder={ placeholder }
        onChange={ onChange }
        onBlur={ onBlur }
        onKeyPress={ onKeyPress }
        onClick={ onClick }
        onFocus={ onFocus }
        onKeyDown={ onKeyDown }
        autoFocus={ autoFocus }
        fullWidth={ fullWidth }
        autoComplete={ autoComplete }
        startAdornment={ isShowStartAdornment && (
          <InputAdornment position="start"><SearchIcon /></InputAdornment>
        ) }
        endAdornment={ isShowEndAdornment && (
          <InputAdornment position="start" className={ classes.endAdornment }>
            <IconButton
              onClick={ (e) => {
                e.stopPropagation();
                onClear(e);
              } }
            >
              <ClearFilledIcon />
            </IconButton>
          </InputAdornment>
        ) }
      />

      <FormHelperText id={ name }>
        { helperText }
      </FormHelperText>
    </FormControl>

  );
};

SearchInput.defaultProps = {
  value: '',
  type: 'text',
  autoComplete: 'true',
  autoFocus: false,
  fullWidth: false,
  className: '',
  helperText: '',
  isShowEndAdornment: true,
  isShowStartAdornment: true,
  onClear: () => {},
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  onFocus: () => {},
  onKeyPress: () => {},
  onKeyDown: () => {},
};

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  onClear: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  isShowEndAdornment: PropTypes.bool,
  isShowStartAdornment: PropTypes.bool,
};

export default SearchInput;
