import React, { memo, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Libs
import { convertDateToTimezone } from 'libs/convertDate';
import { strToArrayNumber, transformParams } from 'libs/query-string';

// Hooks
import useQueryParams from 'hooks/useQueryParams';
import useGetParameters from 'hooks/useGetParameters';
import usePermissions from 'hooks/usePermissions';
import useDashboard from './useDashboard';

// Components
import ActivityOverview from './ActivityOverview/ActivityOverview';
import Top from './Top/Top';
import StateMap from './StateMap/StateMap';
import OverlayPreloader from '../../modules/OverlayPreloader/OverlayPreloader';

const getParamNames = (permissions) => {
  const paramNames = ['period'];
  if (permissions.advertiserRead) paramNames.push('advertisers');
  if (permissions.campaignRead) paramNames.push('campaigns');

  return paramNames;
};

const transformDashboardQueryParams = (params) => {
  const config = {
    advertisers: strToArrayNumber,
    campaigns: strToArrayNumber,
    period: (val) => val,
  };
  return transformParams(params, config);
};

const Dashboard = () => {
  const { user } = useSelector((state) => state.user);
  const permissions = usePermissions();
  const PARAM_NAMES = useMemo(() => getParamNames(permissions), [permissions]);
  const params = useGetParameters(PARAM_NAMES);
  const { replaceQueryParams } = useQueryParams();

  const {
    advertisersActivity,
    topAdvertisers,
    topCampaigns,
    topCreatives,
    period,
    advertisers,
    campaigns,
    fetchData,
    handleFilterChange,
    resetFilters,
    fetchingStatsByState,
    fetchingAdvertisersActivity,
    fetchingTopCampaigns,
    fetchingTopAdvertisers,
  } = useDashboard(transformDashboardQueryParams(params));

  useEffect(() => {
    replaceQueryParams({ period, advertisers, campaigns });
  }, [period, advertisers, campaigns, replaceQueryParams]);

  const formattedData = useMemo(() => {
    if (!Array.isArray(advertisersActivity?.rows)) return [];

    return advertisersActivity.rows.map((dataItem) => ({
      ...dataItem,
      item: convertDateToTimezone(dataItem.item, user?.timeZone),
    }));
  }, [advertisersActivity, user]);

  return (
    <>
      <OverlayPreloader isLoading={ fetchingAdvertisersActivity }>
        <ActivityOverview
          period={ period }
          advertisers={ advertisers }
          campaigns={ campaigns }
          total={ advertisersActivity.total }
          previousTotal={ advertisersActivity.previousTotal }
          data={ formattedData }
          handleFilterChange={ handleFilterChange }
          resetFilters={ resetFilters }
          fetchData={ fetchData }
        />
      </OverlayPreloader>

      <OverlayPreloader isLoading={ fetchingTopAdvertisers || fetchingTopCampaigns }>
        <Top
          topAdvertisers={ topAdvertisers }
          topCampaigns={ topCampaigns }
          topCreatives={ topCreatives }
        />
      </OverlayPreloader>

      <OverlayPreloader isLoading={ fetchingStatsByState }>
        <StateMap />
      </OverlayPreloader>
    </>
  );
};

export default memo(Dashboard);
