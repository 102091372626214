export default class ImageAssetClass {
    id = null;
    type = 'IMAGE';
    name = '';
    advertiser = '';
    imageType = null;
    url = null;
    mime = '';
    expectedSize = null;
    file = null;

    constructor(item = {}) {
      this.id = item.id || null;
      this.type = item.type || 'IMAGE';
      this.name = item.name || '';
      this.advertiser = item.advertiser || '';
      this.imageType = item.imageType || null;
      this.url = item.url || null;
      this.mime = item.mime || '';
      this.expectedSize = item.expectedSize || null;
      this.file = item.file || null;
    }
}
