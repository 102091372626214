import { get } from 'lodash';
import { checkUrl } from 'actions/commonActions';
// Validators
import isEmail from 'validator/lib/isEmail';
import { regExpNumber } from 'constants/regExp';

const getErrorText = (key, validatorType) => {
  let text = '';

  switch (validatorType) {
    case 'required':
      text = 'is required';
      break;
    case 'invalid':
      text = 'is invalid';
      break;
    default:
      break;
  }

  const errorsHashMap = {
    advertiser: `Advertiser ${text}`,
    budgetTarget: `Campaign target ${text}`,
    impsTarget: `Campaign impressions ${text}`,
    cpm: `Bid price ${text}`,
    delivery: `Budget delivery ${text}`,
    domain: `Domain ${text}`,
    email: `Email ${text}`,
    end: `End date ${text}`,
    fullName: `Full name ${text}`,
    name: `Name ${text}`,
    start: `Start date ${text}`,
    status: `Status ${text}`,
    timeZone: `Time zone ${text}`,
    type: `Type ${text}`,
    thirdPartyImpressionBeacons: `Third Party Impression Beacons ${text}`,
  };

  return errorsHashMap[key];
};

export const validateErrors = (actualValues, validation) => {
  const { requiredValues, shouldBeValidValues } = validation;
  const errors = {};

  const checkKey = (key, validatorType) => {
    const path = key.split('.');

    switch (validatorType) {
      case 'required': {
        const value = get(actualValues, path);
        if ((!value && value !== 0) || (Array.isArray(value) && !value.length)) {
          errors[key] = getErrorText(path[path.length - 1], validatorType);
        }
        break;
      }
      case 'invalid':
        if (key.includes('email')) {
          if (!isEmail(get(actualValues, path))) {
            errors[key] = getErrorText(path[path.length - 1], validatorType);
          }
        }
        break;
      default:
        break;
    }
  };

  if (shouldBeValidValues) shouldBeValidValues.forEach((value) => checkKey(value, 'invalid'));
  if (requiredValues) requiredValues.forEach((value) => checkKey(value, 'required'));

  return errors;
};

export const validateVideoFile = (file) => {
  const possibleFormats = ['video/mp4', 'video/m4v', 'video/x-m4v'];
  if (!file) return null;
  if (file.size / 1048576 > 100) return 'File size must be less than 100 MB';
  if (!possibleFormats.includes(file.type)) return 'File extension is not supported';
  return null;
};

export const validateRequired = (value, name) => {
  if (value) return null;
  return name ? `${name} is required` : 'Required';
};

export const validateEmail = (value, name) => {
  if (!value || isEmail(value)) return null;
  return name ? `${name} is invalid` : 'Invalid';
};

export const validateFileExtension = (fileName, acceptableExtensions) => {
  const splitted = fileName.split('.') || [];
  const extension = splitted[splitted.length - 1];
  return acceptableExtensions.includes(extension) ? null : 'File extension is not supported';
};

export const validateUrl = async (url, acceptableMimeTypes) => {
  try {
    const data = await checkUrl({ url })();
    const contentType = data['Content-Type'];

    if (!contentType || !acceptableMimeTypes.includes(contentType)) return 'Content-Type is invalid';

    return data;
  } catch (error) {
    return 'URL is invalid';
  }
};

export const validateMin = (value, min, name) => {
  if (typeof value !== 'number') return null;
  if (value < min) return `Please increase ${name}`;
  return null;
};

export const validateMax = (value, max, name) => {
  if (typeof value !== 'number') return null;
  if (value > max) return `Please decrease ${name}`;
  return null;
};

export const validateDomain = (value, name) => {
  const domainRegExp = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})?$/;
  if (value && !value.match(domainRegExp)) return name ? `${name} is invalid` : 'Invalid';

  return null;
};

export const validateNumber = (value, name) => {
  if (value && !regExpNumber.test(value)) return name ? `${name} is invalid` : 'Invalid number';

  return null;
};
