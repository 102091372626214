export class IdNameClass {
  id = null;
  name = '';
  logoBase64 = null;
  clientName = null;
  status = null;

  constructor(...args) {
    args.forEach((item) => {
      this.id = item.id || null;
      this.name = item.name || '';
      this.logoBase64 = item.logoBase64 || null;
      this.clientName = item.clientName || null;
      this.status = item.status || null;
    });
  }
}
