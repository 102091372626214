const IMPRESSIONS = 'impressions';
const SPEND = 'spend';
const ENGAGEMENT_RATE = 'vastCompleteRate';
const ECPM = 'ecpm';

export default {
  IMPRESSIONS,
  SPEND,
  ENGAGEMENT_RATE,
  ECPM,
};
