import { buildHashMap } from 'libs/buildHashMap';

const districtMapMatching = {
  _1: { id: 'US-AL-1', name: "Alabama's 1st district" },
  _2: { id: 'US-AL-2', name: "Alabama's 2nd district" },
  _3: { id: 'US-AL-3', name: "Alabama's 3rd district" },
  _4: { id: 'US-AL-4', name: "Alabama's 4th district" },
  _5: { id: 'US-AL-5', name: "Alabama's 5th district" },
  _6: { id: 'US-AL-6', name: "Alabama's 6th district" },
  _7: { id: 'US-AL-7', name: "Alabama's 7th district" },
  _8: { id: 'US-AZ-1', name: "Arizona's 1st district" },
  _9: { id: 'US-AZ-2', name: "Arizona's 2nd district" },
  _10: { id: 'US-AZ-3', name: "Arizona's 3rd district" },
  _11: { id: 'US-AZ-4', name: "Arizona's 4th district" },
  _12: { id: 'US-AK-', name: 'District of Alaska' },
  _13: { id: 'US-AZ-5', name: "Arizona's 5th district" },
  _14: { id: 'US-AZ-6', name: "Arizona's 6th district" },
  _15: { id: 'US-AZ-7', name: "Arizona's 7th district" },
  _16: { id: 'US-AZ-8', name: "Arizona's 8th district" },
  _17: { id: 'US-AZ-9', name: "Arizona's 9th district" },
  _18: { id: 'US-AR-1', name: "Arkansas's 1st district" },
  _19: { id: 'US-AR-2', name: "Arkansas's 2nd district" },
  _20: { id: 'US-AR-3', name: "Arkansas's 3rd district" },
  _21: { id: 'US-AR-4', name: "Arkansas's 4th district" },
  _22: { id: 'US-CA-1', name: "California's 1st district" },
  _23: { id: 'US-CA-2', name: "California's 2nd district" },
  _24: { id: 'US-CA-3', name: "California's 3rd district" },
  _25: { id: 'US-CA-4', name: "California's 4th district" },
  _26: { id: 'US-CA-5', name: "California's 5th district" },
  _27: { id: 'US-CA-6', name: "California's 6th district" },
  _28: { id: 'US-CA-7', name: "California's 7th district" },
  _29: { id: 'US-CA-8', name: "California's 8th district" },
  _30: { id: 'US-CA-9', name: "California's 9th district" },
  _31: { id: 'US-CA-10', name: "California's 10th district" },
  _32: { id: 'US-CA-11', name: "California's 11th district" },
  _33: { id: 'US-CA-12', name: "California's 12th district" },
  _34: { id: 'US-CA-13', name: "California's 13th district" },
  _35: { id: 'US-CA-14', name: "California's 14th district" },
  _36: { id: 'US-CA-15', name: "California's 15th district" },
  _37: { id: 'US-CA-16', name: "California's 16th district" },
  _38: { id: 'US-CA-17', name: "California's 17th district" },
  _39: { id: 'US-CA-18', name: "California's 18th district" },
  _40: { id: 'US-CA-19', name: "California's 19th district" },
  _41: { id: 'US-CA-20', name: "California's 20th district" },
  _42: { id: 'US-CA-21', name: "California's 21st district" },
  _43: { id: 'US-CA-22', name: "California's 22nd district" },
  _44: { id: 'US-CA-23', name: "California's 23rd district" },
  _45: { id: 'US-CA-24', name: "California's 24th district" },
  _46: { id: 'US-CA-25', name: "California's 25th district" },
  _47: { id: 'US-CA-26', name: "California's 26th district" },
  _48: { id: 'US-CA-27', name: "California's 27th district" },
  _49: { id: 'US-CA-28', name: "California's 28th district" },
  _50: { id: 'US-CA-29', name: "California's 29th district" },
  _51: { id: 'US-CA-30', name: "California's 30th district" },
  _52: { id: 'US-CA-31', name: "California's 31st district" },
  _53: { id: 'US-CA-32', name: "California's 32nd district" },
  _54: { id: 'US-CA-33', name: "California's 33rd district" },
  _55: { id: 'US-CA-34', name: "California's 34th district" },
  _56: { id: 'US-CA-35', name: "California's 35th district" },
  _57: { id: 'US-CA-36', name: "California's 36th district" },
  _58: { id: 'US-CA-37', name: "California's 37th district" },
  _59: { id: 'US-CA-38', name: "California's 38th district" },
  _60: { id: 'US-CA-39', name: "California's 39th district" },
  _61: { id: 'US-CA-40', name: "California's 40th district" },
  _62: { id: 'US-CA-41', name: "California's 41st district" },
  _63: { id: 'US-CA-42', name: "California's 42nd district" },
  _64: { id: 'US-CA-43', name: "California's 43rd district" },
  _65: { id: 'US-CA-44', name: "California's 44th district" },
  _66: { id: 'US-CA-45', name: "California's 45th district" },
  _67: { id: 'US-CA-46', name: "California's 46th district" },
  _68: { id: 'US-CA-47', name: "California's 47th district" },
  _69: { id: 'US-CA-48', name: "California's 48th district" },
  _70: { id: 'US-CA-49', name: "California's 49th district" },
  _71: { id: 'US-CA-50', name: "California's 50th district" },
  _72: { id: 'US-CA-51', name: "California's 51st district" },
  _73: { id: 'US-CA-52', name: "California's 52nd district" },
  _74: { id: 'US-CA-53', name: "California's 53rd district" },
  _75: { id: 'US-CO-1', name: "Colorado's 1st district" },
  _76: { id: 'US-CO-2', name: "Colorado's 2nd district" },
  _77: { id: 'US-CO-3', name: "Colorado's 3rd district" },
  _78: { id: 'US-CO-4', name: "Colorado's 4th district" },
  _79: { id: 'US-CO-5', name: "Colorado's 5th district" },
  _80: { id: 'US-CO-6', name: "Colorado's 6th district" },
  _81: { id: 'US-CO-7', name: "Colorado's 7th district" },
  _82: { id: 'US-CT-1', name: "Connecticut's 1st district" },
  _83: { id: 'US-CT-2', name: "Connecticut's 2nd district" },
  _84: { id: 'US-CT-3', name: "Connecticut's 3rd district" },
  _85: { id: 'US-CT-4', name: "Connecticut's 4th district" },
  _86: { id: 'US-CT-5', name: "Connecticut's 5th district" },
  _87: { id: 'US-DE-', name: 'District of Delaware' },
  _88: { id: 'US-FL-1', name: "Florida's 1st district" },
  _89: { id: 'US-FL-2', name: "Florida's 2nd district" },
  _90: { id: 'US-FL-3', name: "Florida's 3rd district" },
  _91: { id: 'US-FL-4', name: "Florida's 4th district" },
  _92: { id: 'US-FL-5', name: "Florida's 5th district" },
  _93: { id: 'US-FL-6', name: "Florida's 6th district" },
  _94: { id: 'US-FL-7', name: "Florida's 7th district" },
  _95: { id: 'US-FL-8', name: "Florida's 8th district" },
  _96: { id: 'US-FL-9', name: "Florida's 9th district" },
  _97: { id: 'US-FL-10', name: "Florida's 10th district" },
  _98: { id: 'US-FL-11', name: "Florida's 11th district" },
  _99: { id: 'US-FL-12', name: "Florida's 12th district" },
  _100: { id: 'US-FL-13', name: "Florida's 13th district" },
  _101: { id: 'US-FL-14', name: "Florida's 14th district" },
  _102: { id: 'US-FL-15', name: "Florida's 15th district" },
  _103: { id: 'US-FL-16', name: "Florida's 16th district" },
  _104: { id: 'US-FL-17', name: "Florida's 17th district" },
  _105: { id: 'US-FL-18', name: "Florida's 18th district" },
  _106: { id: 'US-FL-19', name: "Florida's 19th district" },
  _107: { id: 'US-FL-20', name: "Florida's 20th district" },
  _108: { id: 'US-FL-21', name: "Florida's 21st district" },
  _109: { id: 'US-FL-22', name: "Florida's 22nd district" },
  _110: { id: 'US-FL-23', name: "Florida's 23rd district" },
  _111: { id: 'US-FL-24', name: "Florida's 24th district" },
  _112: { id: 'US-FL-25', name: "Florida's 25th district" },
  _113: { id: 'US-FL-26', name: "Florida's 26th district" },
  _114: { id: 'US-FL-27', name: "Florida's 27th district" },
  _115: { id: 'US-GA-1', name: "Georgia's 1st district" },
  _116: { id: 'US-GA-8', name: "Georgia's 8th district" },
  _117: { id: 'US-GA-3', name: "Georgia's 3rd district" },
  _118: { id: 'US-GA-4', name: "Georgia's 4th district" },
  _119: { id: 'US-GA-5', name: "Georgia's 5th district" },
  _120: { id: 'US-GA-6', name: "Georgia's 6th district" },
  _121: { id: 'US-GA-7', name: "Georgia's 7th district" },
  _122: { id: 'US-GA-2', name: "Georgia's 2nd district" },
  _123: { id: 'US-GA-9', name: "Georgia's 9th district" },
  _124: { id: 'US-GA-10', name: "Georgia's 10th district" },
  _125: { id: 'US-GA-11', name: "Georgia's 11th district" },
  _126: { id: 'US-GA-12', name: "Georgia's 12th district" },
  _127: { id: 'US-GA-13', name: "Georgia's 13th district" },
  _128: { id: 'US-GA-14', name: "Georgia's 14th district" },
  _129: { id: 'US-HI-1', name: "Hawaii's 1st district" },
  _130: { id: 'US-HI-2', name: "Hawaii's 2nd district" },
  _131: { id: 'US-ID-1', name: "Idaho's 1st district" },
  _132: { id: 'US-ID-2', name: "Idaho's 2nd district" },
  _133: { id: 'US-IL-1', name: "Illinois's 1st district" },
  _134: { id: 'US-IL-2', name: "Illinois's 2nd district" },
  _135: { id: 'US-IL-3', name: "Illinois's 3rd district" },
  _136: { id: 'US-IL-4', name: "Illinois's 4th district" },
  _137: { id: 'US-IL-5', name: "Illinois's 5th district" },
  _138: { id: 'US-IL-6', name: "Illinois's 6th district" },
  _139: { id: 'US-IL-7', name: "Illinois's 7th district" },
  _140: { id: 'US-IL-8', name: "Illinois's 8th district" },
  _141: { id: 'US-IL-9', name: "Illinois's 9th district" },
  _142: { id: 'US-IL-10', name: "Illinois's 10th district" },
  _143: { id: 'US-IL-11', name: "Illinois's 11th district" },
  _144: { id: 'US-IL-12', name: "Illinois's 12th district" },
  _145: { id: 'US-IL-13', name: "Illinois's 13th district" },
  _146: { id: 'US-IL-14', name: "Illinois's 14th district" },
  _147: { id: 'US-IL-15', name: "Illinois's 15th district" },
  _148: { id: 'US-IL-16', name: "Illinois's 16th district" },
  _149: { id: 'US-IL-17', name: "Illinois's 17th district" },
  _150: { id: 'US-IL-18', name: "Illinois's 18th district" },
  _151: { id: 'US-IN-1', name: "Indiana's 1st district" },
  _152: { id: 'US-IN-2', name: "Indiana's 2nd district" },
  _153: { id: 'US-IN-3', name: "Indiana's 3rd district" },
  _154: { id: 'US-IN-4', name: "Indiana's 4th district" },
  _155: { id: 'US-IN-5', name: "Indiana's 5th district" },
  _156: { id: 'US-IN-6', name: "Indiana's 6th district" },
  _157: { id: 'US-IN-7', name: "Indiana's 7th district" },
  _158: { id: 'US-IN-8', name: "Indiana's 8th district" },
  _159: { id: 'US-IN-9', name: "Indiana's 9th district" },
  _160: { id: 'US-IA-1', name: "Iowa's 1st district" },
  _161: { id: 'US-IA-2', name: "Iowa's 2nd district" },
  _162: { id: 'US-IA-3', name: "Iowa's 3rd district" },
  _163: { id: 'US-IA-4', name: "Iowa's 4th district" },
  _164: { id: 'US-KS-1', name: "Kansas's 1st district" },
  _165: { id: 'US-KS-2', name: "Kansas's 2nd district" },
  _166: { id: 'US-KS-3', name: "Kansas's 3rd district" },
  _167: { id: 'US-KS-4', name: "Kansas's 4th district" },
  _168: { id: 'US-KY-1', name: "Kentucky's 1st district" },
  _169: { id: 'US-KY-2', name: "Kentucky's 2nd district" },
  _170: { id: 'US-KY-3', name: "Kentucky's 3rd district" },
  _171: { id: 'US-KY-4', name: "Kentucky's 4th district" },
  _172: { id: 'US-KY-5', name: "Kentucky's 5th district" },
  _173: { id: 'US-KY-6', name: "Kentucky's 6th district" },
  _174: { id: 'US-LA-1', name: "Louisiana's 1st district" },
  _175: { id: 'US-LA-2', name: "Louisiana's 2nd district" },
  _176: { id: 'US-LA-3', name: "Louisiana's 3rd district" },
  _177: { id: 'US-LA-4', name: "Louisiana's 4th district" },
  _178: { id: 'US-LA-5', name: "Louisiana's 5th district" },
  _179: { id: 'US-LA-6', name: "Louisiana's 6th district" },
  _180: { id: 'US-ME-1', name: "Maine's 1st district" },
  _181: { id: 'US-ME-2', name: "Maine's 2nd district" },
  _182: { id: 'US-MD-1', name: "Maryland's 1st district" },
  _183: { id: 'US-MD-2', name: "Maryland's 2nd district" },
  _184: { id: 'US-MD-3', name: "Maryland's 3rd district" },
  _185: { id: 'US-MD-4', name: "Maryland's 4th district" },
  _186: { id: 'US-MD-5', name: "Maryland's 5th district" },
  _187: { id: 'US-MD-6', name: "Maryland's 6th district" },
  _188: { id: 'US-MD-7', name: "Maryland's 7th district" },
  _189: { id: 'US-MD-8', name: "Maryland's 8th district" },
  _190: { id: 'US-MA-1', name: "Massachusetts's 1st district" },
  _191: { id: 'US-MA-2', name: "Massachusetts's 2nd district" },
  _192: { id: 'US-MA-3', name: "Massachusetts's 3rd district" },
  _193: { id: 'US-MA-4', name: "Massachusetts's 4th district" },
  _194: { id: 'US-MA-5', name: "Massachusetts's 5th district" },
  _195: { id: 'US-MA-6', name: "Massachusetts's 6th district" },
  _196: { id: 'US-MA-7', name: "Massachusetts's 7th district" },
  _197: { id: 'US-MA-8', name: "Massachusetts's 8th district" },
  _198: { id: 'US-MA-9', name: "Massachusetts's 9th district" },
  _199: { id: 'US-MI-1', name: "Michigan's 1st district" },
  _200: { id: 'US-MI-2', name: "Michigan's 2nd district" },
  _201: { id: 'US-MI-3', name: "Michigan's 3rd district" },
  _202: { id: 'US-MI-4', name: "Michigan's 4th district" },
  _203: { id: 'US-MI-5', name: "Michigan's 5th district" },
  _204: { id: 'US-MI-6', name: "Michigan's 6th district" },
  _205: { id: 'US-MI-7', name: "Michigan's 7th district" },
  _206: { id: 'US-MI-8', name: "Michigan's 8th district" },
  _207: { id: 'US-MI-9', name: "Michigan's 9th district" },
  _208: { id: 'US-MI-10', name: "Michigan's 10th district" },
  _209: { id: 'US-MI-11', name: "Michigan's 11th district" },
  _210: { id: 'US-MI-12', name: "Michigan's 12th district" },
  _211: { id: 'US-MI-13', name: "Michigan's 13th district" },
  _212: { id: 'US-MI-14', name: "Michigan's 14th district" },
  _213: { id: 'US-MN-1', name: "Minnesota's 1st district" },
  _214: { id: 'US-MN-2', name: "Minnesota's 2nd district" },
  _215: { id: 'US-MN-3', name: "Minnesota's 3rd district" },
  _216: { id: 'US-MN-4', name: "Minnesota's 4th district" },
  _217: { id: 'US-MN-5', name: "Minnesota's 5th district" },
  _218: { id: 'US-MN-6', name: "Minnesota's 6th district" },
  _219: { id: 'US-MN-7', name: "Minnesota's 7th district" },
  _220: { id: 'US-MN-8', name: "Minnesota's 8th district" },
  _221: { id: 'US-MS-1', name: "Mississippi's 1st district" },
  _222: { id: 'US-MS-2', name: "Mississippi's 2nd district" },
  _223: { id: 'US-MS-3', name: "Mississippi's 3rd district" },
  _224: { id: 'US-MS-4', name: "Mississippi's 4th district" },
  _225: { id: 'US-MO-1', name: "Missouri's 1st district" },
  _226: { id: 'US-MO-2', name: "Missouri's 2nd district" },
  _227: { id: 'US-MO-3', name: "Missouri's 3rd district" },
  _228: { id: 'US-MO-4', name: "Missouri's 4th district" },
  _229: { id: 'US-MO-5', name: "Missouri's 5th district" },
  _230: { id: 'US-MO-6', name: "Missouri's 6th district" },
  _231: { id: 'US-MO-7', name: "Missouri's 7th district" },
  _232: { id: 'US-MO-8', name: "Missouri's 8th district" },
  _233: { id: 'US-MT-', name: 'District of Montana' },
  _234: { id: 'US-NE-1', name: "Nebraska's 1st district" },
  _235: { id: 'US-NE-2', name: "Nebraska's 2nd district" },
  _236: { id: 'US-NE-3', name: "Nebraska's 3rd district" },
  _237: { id: 'US-NV-1', name: "Nevada's 1st district" },
  _238: { id: 'US-NV-2', name: "Nevada's 2nd district" },
  _239: { id: 'US-NV-3', name: "Nevada's 3rd district" },
  _240: { id: 'US-NV-4', name: "Nevada's 4th district" },
  _241: { id: 'US-NH-1', name: "New Hampshire's 1st district" },
  _242: { id: 'US-NH-2', name: "New Hampshire's 2nd district" },
  _243: { id: 'US-NJ-1', name: "New Jersey's 1st district" },
  _244: { id: 'US-NJ-2', name: "New Jersey's 2nd district" },
  _245: { id: 'US-NJ-3', name: "New Jersey's 3rd district" },
  _246: { id: 'US-NJ-4', name: "New Jersey's 4th district" },
  _247: { id: 'US-NJ-5', name: "New Jersey's 5th district" },
  _248: { id: 'US-NJ-6', name: "New Jersey's 6th district" },
  _249: { id: 'US-NJ-7', name: "New Jersey's 7th district" },
  _250: { id: 'US-NJ-8', name: "New Jersey's 8th district" },
  _251: { id: 'US-NJ-9', name: "New Jersey's 9th district" },
  _252: { id: 'US-NJ-10', name: "New Jersey's 10th district" },
  _253: { id: 'US-NJ-11', name: "New Jersey's 11th district" },
  _254: { id: 'US-NJ-12', name: "New Jersey's 12th district" },
  _255: { id: 'US-NM-1', name: "New Mexico's 1st district" },
  _256: { id: 'US-NM-2', name: "New Mexico's 2nd district" },
  _257: { id: 'US-NM-3', name: "New Mexico's 3rd district" },
  _258: { id: 'US-NY-1', name: "New York's 1st district" },
  _259: { id: 'US-NY-2', name: "New York's 2nd district" },
  _260: { id: 'US-NY-3', name: "New York's 3rd district" },
  _261: { id: 'US-NY-4', name: "New York's 4th district" },
  _262: { id: 'US-NY-5', name: "New York's 5th district" },
  _263: { id: 'US-NY-6', name: "New York's 6th district" },
  _264: { id: 'US-NY-7', name: "New York's 7th district" },
  _265: { id: 'US-NY-8', name: "New York's 8th district" },
  _266: { id: 'US-NY-9', name: "New York's 9th district" },
  _267: { id: 'US-NY-10', name: "New York's 10th district" },
  _268: { id: 'US-NY-11', name: "New York's 11th district" },
  _269: { id: 'US-NY-12', name: "New York's 12th district" },
  _270: { id: 'US-NY-13', name: "New York's 13th district" },
  _271: { id: 'US-NY-14', name: "New York's 14th district" },
  _272: { id: 'US-NY-15', name: "New York's 15th district" },
  _273: { id: 'US-NY-16', name: "New York's 16th district" },
  _274: { id: 'US-NY-17', name: "New York's 17th district" },
  _275: { id: 'US-NY-18', name: "New York's 18th district" },
  _276: { id: 'US-NY-19', name: "New York's 19th district" },
  _277: { id: 'US-NY-20', name: "New York's 20th district" },
  _278: { id: 'US-NY-21', name: "New York's 21st district" },
  _279: { id: 'US-NY-22', name: "New York's 22nd district" },
  _280: { id: 'US-NY-23', name: "New York's 23rd district" },
  _281: { id: 'US-NY-24', name: "New York's 24th district" },
  _282: { id: 'US-NY-25', name: "New York's 25th district" },
  _283: { id: 'US-NY-26', name: "New York's 26th district" },
  _284: { id: 'US-NY-27', name: "New York's 27th district" },
  _285: { id: 'US-NC-1', name: "North Carolina's 1st district" },
  _286: { id: 'US-NC-2', name: "North Carolina's 2nd district" },
  _287: { id: 'US-NC-3', name: "North Carolina's 3rd district" },
  _288: { id: 'US-NC-4', name: "North Carolina's 4th district" },
  _289: { id: 'US-NC-5', name: "North Carolina's 5th district" },
  _290: { id: 'US-NC-6', name: "North Carolina's 6th district" },
  _291: { id: 'US-NC-7', name: "North Carolina's 7th district" },
  _292: { id: 'US-NC-8', name: "North Carolina's 8th district" },
  _293: { id: 'US-NC-9', name: "North Carolina's 9th district" },
  _294: { id: 'US-NC-10', name: "North Carolina's 10th district" },
  _295: { id: 'US-NC-11', name: "North Carolina's 11th district" },
  _296: { id: 'US-NC-12', name: "North Carolina's 12th district" },
  _297: { id: 'US-NC-13', name: "North Carolina's 13th district" },
  _298: { id: 'US-ND-', name: 'District of North Dakota' },
  _299: { id: 'US-OH-1', name: "Ohio's 1st district" },
  _300: { id: 'US-OH-2', name: "Ohio's 2nd district" },
  _301: { id: 'US-OH-3', name: "Ohio's 3rd district" },
  _302: { id: 'US-OH-4', name: "Ohio's 4th district" },
  _303: { id: 'US-OH-5', name: "Ohio's 5th district" },
  _304: { id: 'US-OH-6', name: "Ohio's 6th district" },
  _305: { id: 'US-OH-7', name: "Ohio's 7th district" },
  _306: { id: 'US-OH-8', name: "Ohio's 8th district" },
  _307: { id: 'US-OH-9', name: "Ohio's 9th district" },
  _308: { id: 'US-OH-10', name: "Ohio's 10th district" },
  _309: { id: 'US-OH-11', name: "Ohio's 11th district" },
  _310: { id: 'US-OH-12', name: "Ohio's 12th district" },
  _311: { id: 'US-OH-13', name: "Ohio's 13th district" },
  _312: { id: 'US-OH-14', name: "Ohio's 14th district" },
  _313: { id: 'US-OH-15', name: "Ohio's 15th district" },
  _314: { id: 'US-OH-16', name: "Ohio's 16th district" },
  _315: { id: 'US-OK-1', name: "Oklahoma's 1st district" },
  _316: { id: 'US-OK-2', name: "Oklahoma's 2nd district" },
  _317: { id: 'US-OK-3', name: "Oklahoma's 3rd district" },
  _318: { id: 'US-OK-4', name: "Oklahoma's 4th district" },
  _319: { id: 'US-OK-5', name: "Oklahoma's 5th district" },
  _320: { id: 'US-OR-1', name: "Oregon's 1st district" },
  _321: { id: 'US-OR-2', name: "Oregon's 2nd district" },
  _322: { id: 'US-OR-3', name: "Oregon's 3rd district" },
  _323: { id: 'US-OR-4', name: "Oregon's 4th district" },
  _324: { id: 'US-OR-5', name: "Oregon's 5th district" },
  _325: { id: 'US-PA-1', name: "Pennsylvania's 1st district" },
  _326: { id: 'US-PA-2', name: "Pennsylvania's 2nd district" },
  _327: { id: 'US-PA-3', name: "Pennsylvania's 3rd district" },
  _328: { id: 'US-PA-4', name: "Pennsylvania's 4th district" },
  _329: { id: 'US-PA-5', name: "Pennsylvania's 5th district" },
  _330: { id: 'US-PA-6', name: "Pennsylvania's 6th district" },
  _331: { id: 'US-PA-7', name: "Pennsylvania's 7th district" },
  _332: { id: 'US-PA-8', name: "Pennsylvania's 8th district" },
  _333: { id: 'US-PA-9', name: "Pennsylvania's 9th district" },
  _334: { id: 'US-PA-10', name: "Pennsylvania's 10th district" },
  _335: { id: 'US-PA-11', name: "Pennsylvania's 11th district" },
  _336: { id: 'US-PA-12', name: "Pennsylvania's 12th district" },
  _337: { id: 'US-PA-13', name: "Pennsylvania's 13th district" },
  _338: { id: 'US-PA-14', name: "Pennsylvania's 14th district" },
  _339: { id: 'US-PA-15', name: "Pennsylvania's 15th district" },
  _340: { id: 'US-PA-16', name: "Pennsylvania's 16th district" },
  _341: { id: 'US-PA-17', name: "Pennsylvania's 17th district" },
  _342: { id: 'US-PA-18', name: "Pennsylvania's 18th district" },
  _343: { id: 'US-RI-1', name: "Rhode Island's 1st district" },
  _344: { id: 'US-RI-2', name: "Rhode Island's 2nd district" },
  _345: { id: 'US-SC-1', name: "South Carolina's 1st district" },
  _346: { id: 'US-SC-2', name: "South Carolina's 2nd district" },
  _347: { id: 'US-SC-3', name: "South Carolina's 3rd district" },
  _348: { id: 'US-SC-4', name: "South Carolina's 4th district" },
  _349: { id: 'US-SC-5', name: "South Carolina's 5th district" },
  _350: { id: 'US-SC-6', name: "South Carolina's 6th district" },
  _351: { id: 'US-SC-7', name: "South Carolina's 7th district" },
  _352: { id: 'US-SD-', name: 'District of South Dakota' },
  _353: { id: 'US-TN-1', name: "Tennessee's 1st district" },
  _354: { id: 'US-TN-2', name: "Tennessee's 2nd district" },
  _355: { id: 'US-TN-3', name: "Tennessee's 3rd district" },
  _356: { id: 'US-TN-4', name: "Tennessee's 4th district" },
  _357: { id: 'US-TN-5', name: "Tennessee's 5th district" },
  _358: { id: 'US-TN-6', name: "Tennessee's 6th district" },
  _359: { id: 'US-TN-7', name: "Tennessee's 7th district" },
  _360: { id: 'US-TN-8', name: "Tennessee's 8th district" },
  _361: { id: 'US-TN-9', name: "Tennessee's 9th district" },
  _362: { id: 'US-TX-1', name: "Texas's 1st district" },
  _363: { id: 'US-TX-2', name: "Texas's 2nd district" },
  _364: { id: 'US-TX-3', name: "Texas's 3rd district" },
  _365: { id: 'US-TX-4', name: "Texas's 4th district" },
  _366: { id: 'US-TX-5', name: "Texas's 5th district" },
  _367: { id: 'US-TX-6', name: "Texas's 6th district" },
  _368: { id: 'US-TX-7', name: "Texas's 7th district" },
  _369: { id: 'US-TX-8', name: "Texas's 8th district" },
  _370: { id: 'US-TX-18', name: "Texas's 18th district" },
  _371: { id: 'US-TX-10', name: "Texas's 10th district" },
  _372: { id: 'US-TX-11', name: "Texas's 11th district" },
  _373: { id: 'US-TX-12', name: "Texas's 12th district" },
  _374: { id: 'US-TX-13', name: "Texas's 13th district" },
  _375: { id: 'US-TX-14', name: "Texas's 14th district" },
  _376: { id: 'US-TX-15', name: "Texas's 15th district" },
  _377: { id: 'US-TX-16', name: "Texas's 16th district" },
  _378: { id: 'US-TX-17', name: "Texas's 17th district" },
  _379: { id: 'US-TX-9', name: "Texas's 9th district" },
  _380: { id: 'US-TX-19', name: "Texas's 19th district" },
  _381: { id: 'US-TX-20', name: "Texas's 20th district" },
  _382: { id: 'US-TX-21', name: "Texas's 21st district" },
  _383: { id: 'US-TX-22', name: "Texas's 22nd district" },
  _384: { id: 'US-TX-23', name: "Texas's 23rd district" },
  _385: { id: 'US-TX-24', name: "Texas's 24th district" },
  _386: { id: 'US-TX-25', name: "Texas's 25th district" },
  _387: { id: 'US-TX-26', name: "Texas's 26th district" },
  _388: { id: 'US-TX-27', name: "Texas's 27th district" },
  _389: { id: 'US-TX-28', name: "Texas's 28th district" },
  _390: { id: 'US-TX-29', name: "Texas's 29th district" },
  _391: { id: 'US-TX-30', name: "Texas's 30th district" },
  _392: { id: 'US-TX-31', name: "Texas's 31st district" },
  _393: { id: 'US-TX-32', name: "Texas's 32nd district" },
  _394: { id: 'US-TX-33', name: "Texas's 33rd district" },
  _395: { id: 'US-TX-34', name: "Texas's 34th district" },
  _396: { id: 'US-TX-35', name: "Texas's 35th district" },
  _397: { id: 'US-TX-36', name: "Texas's 36th district" },
  _398: { id: 'US-UT-1', name: "Utah's 1st district" },
  _399: { id: 'US-UT-2', name: "Utah's 2nd district" },
  _400: { id: 'US-UT-3', name: "Utah's 3rd district" },
  _401: { id: 'US-UT-4', name: "Utah's 4th district" },
  _402: { id: 'US-VT-', name: 'District of Vermont' },
  _403: { id: 'US-VA-1', name: "Virginia's 1st district" },
  _404: { id: 'US-VA-2', name: "Virginia's 2nd district" },
  _405: { id: 'US-VA-3', name: "Virginia's 3rd district" },
  _406: { id: 'US-VA-4', name: "Virginia's 4th district" },
  _407: { id: 'US-VA-5', name: "Virginia's 5th district" },
  _408: { id: 'US-VA-6', name: "Virginia's 6th district" },
  _409: { id: 'US-VA-7', name: "Virginia's 7th district" },
  _410: { id: 'US-VA-8', name: "Virginia's 8th district" },
  _411: { id: 'US-VA-9', name: "Virginia's 9th district" },
  _412: { id: 'US-VA-10', name: "Virginia's 10th district" },
  _413: { id: 'US-VA-11', name: "Virginia's 11th district" },
  _414: { id: 'US-WA-1', name: "Washington's 1st district" },
  _415: { id: 'US-WA-2', name: "Washington's 2nd district" },
  _416: { id: 'US-WA-3', name: "Washington's 3rd district" },
  _417: { id: 'US-WA-4', name: "Washington's 4th district" },
  _418: { id: 'US-WA-5', name: "Washington's 5th district" },
  _419: { id: 'US-WA-6', name: "Washington's 6th district" },
  _420: { id: 'US-WA-7', name: "Washington's 7th district" },
  _421: { id: 'US-WA-8', name: "Washington's 8th district" },
  _422: { id: 'US-WA-9', name: "Washington's 9th district" },
  _423: { id: 'US-WA-10', name: "Washington's 10th district" },
  _424: { id: 'US-WV-1', name: "West Virginia's 1st district" },
  _425: { id: 'US-WV-2', name: "West Virginia's 2nd district" },
  _426: { id: 'US-WV-3', name: "West Virginia's 3rd district" },
  _427: { id: 'US-WI-1', name: "Wisconsin's 1st district" },
  _428: { id: 'US-WI-2', name: "Wisconsin's 2nd district" },
  _429: { id: 'US-WI-3', name: "Wisconsin's 3rd district" },
  _430: { id: 'US-WI-4', name: "Wisconsin's 4th district" },
  _431: { id: 'US-WI-5', name: "Wisconsin's 5th district" },
  _432: { id: 'US-WI-6', name: "Wisconsin's 6th district" },
  _433: { id: 'US-WI-7', name: "Wisconsin's 7th district" },
  _434: { id: 'US-WI-8', name: "Wisconsin's 8th district" },
  _435: { id: 'US-WY-', name: 'District of Wyoming' },
  _436: { id: 'US-DC-', name: 'District of Columbia' },
  _437: { id: 'US-PR-98', name: "Puerto Rico's district" },
  _438: { id: 'US-VI-98', name: "Virgin Islands's at-large district" },
  _439: { id: 'US-GU-98', name: "Guam's at-large district" },
  _440: { id: 'US-MP-98', name: "Northern Mariana Islands's at-large district" },
  _441: { id: 'US-AS-98', name: "American Samoa's at-large district" },
};

export const DISTRICTS = Object.values(districtMapMatching);

export const DISTRICTS_HASH_MAP = buildHashMap(DISTRICTS);

export default districtMapMatching;
