import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';

// Modules
import Input from 'modules/_Factories/Input/Input';
import Select from 'modules/_Factories/Select/Select';
import StepContainer from 'modules/Steps/StepContainer';

// Constants
import { Statuses } from 'constants/index';

const General = ({ formik, permissionEdit }) => (
  <StepContainer
    subtitle="Adding an advertiser allows you to run campaigns for multiple brands, and keep account balances and reporting separately"
  >
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } sm={ 6 }>
        <Input
          name="name"
          label="Name"
          value={ get(formik.values, 'name') }
          // Options
          fullWidth
          required
          disabled={ !permissionEdit }
          // Events
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          // Extra
          errors={ formik.errors }
          touched={ formik.touched }
        />
      </Grid>
      <Grid item xs={ 12 } sm={ 6 }>
        <Input
          name="domain"
          label="Domain"
          value={ get(formik.values, 'domain') }
          // Options
          fullWidth
          disabled={ !permissionEdit }
          // Events
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          // Extra
          errors={ formik.errors }
          touched={ formik.touched }
        />
      </Grid>
      <Grid item xs={ 12 } sm={ 6 }>
        <Select
          name="status"
          label="Status *"
          value={ get(formik.values, 'status') }
          itemList={ Statuses }
          // Options
          isFilter
          isFilterText="none"
          fullWidth
          required
          disabled={ !permissionEdit }
          // Events
          onChange={ formik.handleChange }
          setFieldTouched={ formik.setFieldTouched }
          // Extra
          errors={ formik.errors }
          touched={ formik.touched }
        />
      </Grid>
    </Grid>
  </StepContainer>
);

General.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      domain: PropTypes.string,
      status: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  permissionEdit: PropTypes.bool.isRequired,
};

export default memo(General);
