export default class DataAssetClass {
  id = null;
  type = 'DATA';
  name = '';
  advertiser = '';
  dataType = null;
  value = '';
  url = null;
  expectedSize = null;

  constructor(item = {}) {
    this.id = item.id || null;
    this.type = item.type || 'DATA';
    this.name = item.name || '';
    this.advertiser = item.advertiser || '';
    this.dataType = item.dataType || null;
    this.value = item.value || '';
    this.url = item.url || null;
    this.expectedSize = item.expectedSize || null;
  }
}
