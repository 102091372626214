import { toNumber } from 'lodash';

export class CappingClass {
  period = 'DAY';
  value = '';

  constructor(...args) {
    args.forEach((item) => {
      this.period = item.period || 'DAY';
      this.value = this.getNumericValue(item.value);
    });
  }

  getNumericValue(val) {
    if (val === null) {
      return '';
    }
    if (typeof val === 'string') {
      return toNumber(val) ? toNumber(val) : null;
    }
    return val;
  }
}
