import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

// Material
import { Grid } from '@material-ui/core';

// Modules
import { StepContainer } from 'modules/Steps';

// Constants
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

// Hooks
import useAddCreative from 'components/Creatives/routes/AddCreative/useAddCreative';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';
import useCreatives from './useCreatives';

import CreativeListTitle from './CreativeListTitle';
import CreativePreview from './CreativePreview';
import classes from './Creatives.module.scss';

const Creatives = ({ formik, editable, getCreativeList }) => {
  const { groupedCreatives } = useCreatives(formik);
  const { transformCreativeValues, createCreative } = useAddCreative();
  const { enqueueSnackbar } = useSnackbar();

  const { setFieldValue, values } = formik;

  const createCreativeRequest = useCallback(async (creativeFormValues, callbackFinally) => {
    try {
      const formData = await transformCreativeValues(creativeFormValues);
      const newCreative = await createCreative(formData);
      return newCreative;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong! Please, try again.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
      return null;
    } finally {
      callbackFinally();
    }
  }, [createCreative, enqueueSnackbar, transformCreativeValues]);

  const handleChangeCreative = useCallback((e) => {
    setFieldValue('creatives', e.target.value);
  }, [setFieldValue]);

  const addCreative = useCallback(async (newCreativeValues, callbackFinally) => {
    const newCreative = await createCreativeRequest(newCreativeValues, callbackFinally);

    if (newCreative) {
      setFieldValue('creatives', [...values.creatives, newCreative.id]);
      getCreativeList();
    }

    return newCreative;
  }, [createCreativeRequest, getCreativeList, setFieldValue, values.creatives]);

  const creativeInitialValues = useMemo(() => ({
    name: '',
    advertiser: values.advertiser,
    status: 'ACTIVE',
    subType: CREATIVE_TYPES.types.DISPLAY,
    type: CREATIVE_TYPES.types.NATIVE,
    language: '',
    categories: [],
    dataType: CREATIVE_DATA_TYPES.types.UPLOAD_FILE,
    url: '',
    file: null,
    pageUrl: '',
    content: '',
    size: null,
    template: CREATIVE_TEMPLATES.types.COLLAPSABLE,
    assets: [],
  }), [values.advertiser]);

  const subtitle = editable ?
    'You can select one or more creatives from previously created'
    : 'In order to add new creatives pause campaign and submit it for approval with new creatives';

  return (
    <StepContainer
      title="Upload your creatives"
      subtitle={ subtitle }
    >
      <Grid container spacing={ 4 }>
        <Grid item xs={ 12 }>
          <SelectVirtualized
            multiple
            tree
            list={ groupedCreatives }
            label="Creatives"
            onChange={ handleChangeCreative }
            value={ values.creatives || [] }
            disabled={ !editable }
          />

        </Grid>
        <Grid item xs={ 12 }>
          <CreativeListTitle
            title="Creatives"
            selectedLength={ (values.creatives || []).length }
            addCreative={ addCreative }
            creativeInitialValues={ creativeInitialValues }
            allowAddCreative={ editable }
          />
        </Grid>

        <Grid item container xs={ 12 } spacing={ 2 }>
          {values.creatives.map((creativeId) => (
            <Grid item xs={ 4 } lg={ 3 } key={ creativeId }>
              <CreativePreview className={ classes.creativePreviewWrapper } id={ creativeId } />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </StepContainer>
  );
};

Creatives.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      creatives: PropTypes.arrayOf(PropTypes.number),
    }),
    handleChange: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  editable: PropTypes.bool.isRequired,
  getCreativeList: PropTypes.func.isRequired,
};

export default Creatives;
