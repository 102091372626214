export const MIME_TYPES = [
  'application/vnd.apple.mpegurl',
  'application/x-mpegurl',
  'video/3gpp',
  'video/mp4',
  'video/x-m4v',
  'video/mpeg',
  'video/ogg',
  'video/quicktime',
  'video/webm',
  'video/x-m4v',
  'video/ms-asf',
  'video/x-ms-wmv',
  'video/x-msvideo',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
];
