import React, { memo } from 'react';
import classNames from 'classnames';
import classes from './PreviewCreative.module.scss';

const CollapsableCreative = ({ expandedUrl, collapsedUrl, classNamePreviewWrapper, classNamePreview }) => (
  <div className={ classNames(classes.collapsablePreviewWrapper, classNamePreviewWrapper) }>
    <div className={ classNames(classes.collapsablePreview, classNamePreview) }>
      {expandedUrl ?
        <img src={ expandedUrl } className={ classes.expanded } alt="expanded" />
        : <div className={ classes.expanded }>Expanded image asset - 1920x360</div>}
      {collapsedUrl ?
        <img src={ collapsedUrl } className={ classes.collapsed } alt="collapsed" />
        : <div className={ classes.collapsed }>Collapsed image asset - 695x360</div>}
    </div>
  </div>
);

export default memo(CollapsableCreative);
