const types = {
  ASAP: 'ASAP',
  SMOOTH: 'SMOOTH',
};

const dictionary = [
  { id: types.ASAP, name: 'ASAP' },
  { id: types.SMOOTH, name: 'Even' },
];

export default {
  types,
  dictionary,
};
