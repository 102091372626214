import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

// Material
import { Grid } from '@material-ui/core';

// Components
import PublicRoute from 'router/PublicRoute';
import Logo from './Logo/Logo';
import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Signup from './Signup/Signup';

// Styles
import classes from './Auth.module.scss';

const Auth = () => (
  <Grid container direction="row" className={ classes.container }>
    <Grid item sm={ 12 } md={ 4 } className={ classes.leftCol }>
      <Logo className={ classes.logo } to="/login" />
      <Switch>
        <PublicRoute path="/login" component={ Login } />
        <PublicRoute path="/signup" component={ Signup } />
        <PublicRoute path="/forgot-password" component={ ForgotPassword } />
        <PublicRoute path="/reset-password" component={ ResetPassword } />
      </Switch>
    </Grid>
  </Grid>
);

Auth.propTypes = { match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired };

export default Auth;
