import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getFormattedDate } from 'libs/dateConvertors';
import StepTitle from '../StepTitle';
import classes from '../Review.module.scss';

const Dates = ({ start, end }) => (
  <section>
    <StepTitle name="Flight Dates" />
    <Grid container spacing={ 3 }>
      <Grid item xs sm>
        <div className={ classes.primaryText }>
          <span>{ getFormattedDate(start) }</span>
          <span> - </span>
          <span>{ getFormattedDate(end) }</span>
        </div>
      </Grid>
    </Grid>
  </section>
);

Dates.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
};

export default memo(Dates);
