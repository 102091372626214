import React from 'react';
import PropTypes from 'prop-types';

// Styles
import classes from './InputAdornmentIcon.module.scss';

const InputAdornmentIcon = ({ icon: Icon }) => <Icon className={ classes.icon } />;

InputAdornmentIcon.propTypes = { icon: PropTypes.shape({ render: PropTypes.func.isRequired }).isRequired };

export default InputAdornmentIcon;
