import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Modules
import { TabPanel } from 'modules/Tabs/TabPanel';

// Actions
import campaignActions from 'actions/campaignActions';

// Hooks
import useShortLists from 'hooks/useShortLists';
import useDemographics from 'hooks/dictionary/useDemographics';

// Constants
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';

// Components
import useStepMenu from 'hooks/useStepMenu';
import { AppBar, Grid } from '@material-ui/core';
import { AltTab, AltTabs } from 'modules/Tabs/AltTabs';
import { a11yProps } from 'modules/Tabs';
import { StepTransitions } from 'modules/Steps';
import usePermissions from 'hooks/usePermissions';
import General from './General/General';
import Inventory from './Inventory/Inventory';
import Geography from './Geography/Geography';
import Demographics from './Demographics/Demographics';
import TimeOfDay from './TimeOfDay/TimeOfDay';
import BudgetAndConstraints from './BudgetAndConstraints/BudgetAndConstraints';
import Creatives from './Creatives/Creatives';
import Options from './Options';
import Review from './Review/Review';

import classes from './Steps.module.scss';
import Info from '../Info/Info';

const getMenuSteps = (permissions, isEditForm) => [
  { id: 0, name: 'General', touched: true, valid: isEditForm, visible: true },
  { id: 1, name: 'Creatives', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 2, name: 'Budget and Limits', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 3, name: 'Inventory', touched: isEditForm, valid: isEditForm, visible: permissions.adUnitRead },
  { id: 4, name: 'Geography', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 5, name: 'Time of day', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 6, name: 'Demographics', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 7, name: 'Options', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 8, name: 'Review', touched: isEditForm, valid: isEditForm, visible: true },
];

const Steps = ({
  formik,
  editable,
  approval,
  isEditForm,
}) => {
  const dispatch = useDispatch();
  const { demographics } = useDemographics(true);
  const permissions = usePermissions();
  const menuStepRef = useRef(getMenuSteps(permissions, isEditForm));

  const { advertiser, systemType } = formik.values;
  const isPDCampaign = systemType === CampaignTypes.types.PROGRAMMATIC_DIRECT;

  const { shortAdvertiserList } = useShortLists(['getShortAdvertiserList']);

  const { shortSupplySourceList, shortAdUnitList } = useShortLists(
    ['getShortSupplySourceList', 'getShortAdUnitList'],
    [{ advertisers: advertiser ? [advertiser] : null }, { advertisers: advertiser ? [advertiser] : null }],
  );

  const [activeTab, setActiveTab] = useState(0);
  const { steps, setValuesStep, stepHasErrors, stepIsDisabled } = useStepMenu(menuStepRef.current);

  const globalFields = useMemo(() => ['name'], []);

  const stepFields = useMemo(() => [
    { fields: ['name', 'advertiser', 'timeZone', 'start', 'end'], visible: true }, // General
    { fields: ['creatives'], visible: true }, // Creatives
    {
      fields: [
        'budgetTarget',
        'impsTarget',
        'pricingStrategy.cpm',
        'delivery',
        'budgetCapping',
        'impCapping',
        'ifaCapping',
      ],
      visible: true,
    }, // Budget and Limits
    { fields: ['ssps.values', 'adUnits.values'], visible: permissions.adUnitRead && !isPDCampaign }, // Inventory
    {
      fields: [
        'targetingProfile.countries.values',
        'targetingProfile.states.values',
        'targetingProfile.cities.values',
        'targetingProfile.dmas.values',
        'targetingProfile.districts.values',
        'targetingProfile.counties.values',
        'targetingProfile.zips.values',
      ],
      visible: true,
    }, // Geography
    { fields: [], visible: true }, // Time of day
    { fields: [], visible: true }, // Demographics
    { fields: ['thirdPartyImpressionBeacons', 'thirdPartyBeacons'], visible: true }, // Options
    { fields: [], visible: true }, // Review
  ].filter((item) => item.visible).map((item) => item.fields), [isPDCampaign, permissions]);

  const hasErrorsActiveTab = stepHasErrors([...globalFields, ...stepFields[activeTab]], formik.errors);

  const handleChangeTab = useCallback((index) => {
    setActiveTab(index);
    setValuesStep(index, { touched: true });
  }, [setActiveTab, setValuesStep]);

  const handleNextStep = useCallback(() => {
    if (steps[activeTab].valid) handleChangeTab(activeTab + 1);
    [...globalFields, ...stepFields[activeTab]].forEach((name) => formik.setFieldTouched(name, true));
  }, [steps, activeTab, handleChangeTab, globalFields, stepFields, formik]);

  const handlePrevStep = useCallback(() => {
    handleChangeTab(activeTab - 1);
  }, [handleChangeTab, activeTab]);

  useEffect(() => {
    setValuesStep(activeTab, { valid: !hasErrorsActiveTab });
  }, [activeTab, hasErrorsActiveTab, setValuesStep]);

  useEffect(() => {
    menuStepRef.current = getMenuSteps(permissions, isEditForm);
  }, [isEditForm, permissions]);

  const getTabClass = useCallback((item, index) => {
    if (!item.visible) return classes.hidden;
    if (index === activeTab) return classes.active;
    if (item.valid && item.touched) return classes.valid;
    return null;
  }, [activeTab]);

  const disabledCreatives = [
    CampaignStatuses.types.CANCELED,
    CampaignStatuses.types.CLOSED,
    CampaignStatuses.types.APPROVED,
  ].includes(formik.initialValues.status) ||
    (formik.initialValues.status === CampaignStatuses.types.RUNNING && !permissions.campaignApproval);

  const getCreativeList = useCallback(async () => {
    if (!formik.values.advertiser) return;

    dispatch(campaignActions.getCreativeList({ advertisers: [formik.values.advertiser], size: 10000 }, false));
  }, [dispatch, formik.values.advertiser]);

  useEffect(() => {
    getCreativeList();
  }, [getCreativeList]);

  const TabPanelContentList = useMemo(() => {
    const tabList = [
      <General
        shortAdvertiserList={ shortAdvertiserList }
        formik={ formik }
        editable={ editable }
      />,
      <Creatives
        formik={ formik }
        editable={ !disabledCreatives }
        getCreativeList={ getCreativeList }
      />,
      <BudgetAndConstraints
        formik={ formik }
        editable={ editable }
      />,
      <Inventory
        shortSupplySourceList={ shortSupplySourceList }
        shortAdUnitList={ shortAdUnitList }
        formik={ formik }
        editable={ editable }
      />,
      <Geography
        shortAdvertiserList={ shortAdvertiserList }
        formik={ formik }
        editable={ editable }
      />,
      <TimeOfDay
        formik={ formik }
        editable={ editable }
      />,
      <Demographics
        demographics={ demographics }
        formik={ formik }
        editable={ editable }
      />,
      <Options
        formik={ formik }
        editable={ editable }
      />,
      <Review
        formik={ formik }
        shortSupplySourceList={ shortSupplySourceList }
        shortAdUnitList={ shortAdUnitList }
      />,
    ];

    return tabList.filter((_, index) => steps.find((item) => item.id === index));
  }, [
    formik,
    getCreativeList,
    demographics,
    disabledCreatives,
    editable,
    shortAdvertiserList,
    shortSupplySourceList,
    shortAdUnitList,
    steps,
  ]);

  return (
    <Grid container className="mb-5" spacing={ 3 }>
      <Grid item xs={ 12 }>
        <AppBar position="static" color="inherit" elevation={ 0 } className={ classes.bar }>
          <AltTabs value={ activeTab } onChange={ (e, index) => handleChangeTab(index) } variant={ 1 }>
            { steps.map((item, i) => (
              <AltTab
                key={ item.id }
                disableRipple
                label={ item.name }
                disabled={ (hasErrorsActiveTab && i > activeTab) || stepIsDisabled(i) }
                variant={ 1 }
                className={ getTabClass(item, i) }
                { ...a11yProps(item.id) }
              />
            )) }
          </AltTabs>
        </AppBar>
      </Grid>
      <Grid item xs={ 9 }>
        <div className={ classes.content }>
          {TabPanelContentList.map((item, index) => (
            <TabPanel key={ item.type.name } value={ activeTab } index={ index }>{item}</TabPanel>
          ))}
        </div>
        <StepTransitions
          handleNext={ handleNextStep }
          handlePrev={ handlePrevStep }
          hidePrevButton={ activeTab === 0 }
          hideNextButton={ activeTab === steps.length - 1 }
        />
      </Grid>

      <Grid item xs={ 3 }>
        <Info
          formik={ formik }
          editable={ editable }
          approval={ approval }
        />
      </Grid>
    </Grid>
  );
};

Steps.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      status: PropTypes.string,
      systemType: PropTypes.string,
      ssps: PropTypes.shape({
        allow: PropTypes.bool,
        values: PropTypes.arrayOf(PropTypes.number),
      }),
      advertiser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    initialValues: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      status: PropTypes.string,
      advertiser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    errors: PropTypes.shape({}),
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    resetField: PropTypes.func,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
  approval: PropTypes.bool.isRequired,
  isEditForm: PropTypes.bool.isRequired,
};

export default Steps;
