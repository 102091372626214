export default class TitleAssetClass {
  id = null;
  type = 'TITLE';
  name = '';
  advertiser = '';
  text = '';

  constructor(item = {}) {
    this.id = item.id || null;
    this.type = item.type || 'TITLE';
    this.name = item.name || '';
    this.advertiser = item.advertiser || '';
    this.text = item.text || '';
  }
}
