import { createSelector } from 'reselect';

// Libs
import { sortByKey } from 'libs/sortByKey';

// Classes
import { IdNameClass } from 'classes/idNameClass';

const shortAdUnitList = (state) => state.shortLists.shortAdUnitList;
const shortAdvertiserList = (state) => state.shortLists.shortAdvertiserList;
const shortCampaignList = (state) => state.shortLists.shortCampaignList;
const shortCreativeList = (state) => state.shortLists.shortCreativeList;
const shortDealList = (state) => state.shortLists.shortDealList;
const shortDemandPartnerList = (state) => state.shortLists.shortDemandPartnerList;
const shortSiteAppList = (state) => state.shortLists.shortSiteAppList;
const shortPublisherList = (state) => state.shortLists.shortPublisherList;
const shortRoleList = (state) => state.shortLists.shortRoleList;
const shortSupplySourceList = (state) => state.shortLists.shortSupplySourceList;
const shortSupplyPartnerList = (state) => state.shortLists.shortSupplyPartnerList;
const shortUserList = (state) => state.shortLists.shortUserList;

const shortAdUnitListSelector = createSelector(
  shortAdUnitList,
  (items) => sortByKey(items.map((item) => ({ ...item }))),
);

const shortAdvertiserListSelector = createSelector(
  shortAdvertiserList,
  (items) => sortByKey(items.map((item) => ({ ...item }))),
);

const shortCampaignListSelector = createSelector(
  shortCampaignList,
  (items) => sortByKey(items.map((item) => ({ ...item }))),
);

const shortCreativeListSelector = createSelector(
  shortCreativeList,
  (items) => sortByKey(items.map((item) => ({ ...item }))),
);

const shortDealListSelector = createSelector(
  shortDealList,
  (items) => sortByKey(items.map((item) => ({ ...item }))),
);

const shortDemandPartnerListSelector = createSelector(
  shortDemandPartnerList,
  (items) => sortByKey(items.map((item) => ({ ...item }))),
);

const shortSiteAppListSelector = createSelector(
  shortSiteAppList,
  (items) => sortByKey(items.map((item) => ({ ...item }))),
);

const shortPublisherListSelector = createSelector(
  shortPublisherList,
  (items) => sortByKey(items.map((item) => (new IdNameClass(item)))),
);

const shortRoleListSelector = createSelector(
  shortRoleList,
  (items) => sortByKey(items.map((item) => ({ ...item }))),
);

const shortSupplySourceListSelector = createSelector(
  shortSupplySourceList,
  (items) => sortByKey(items.map((item) => (new IdNameClass(item)))),
);

const shortSupplyPartnerListSelector = createSelector(
  shortSupplyPartnerList,
  (items) => sortByKey(items.map((item) => (new IdNameClass(item)))),
);

const shortUserListSelector = createSelector(
  shortUserList,
  (items) => sortByKey(items.map((item) => ({ ...item }))),
);

export default {
  shortAdUnitListSelector,
  shortAdvertiserListSelector,
  shortCampaignListSelector,
  shortCreativeListSelector,
  shortDealListSelector,
  shortDemandPartnerListSelector,
  shortSiteAppListSelector,
  shortPublisherListSelector,
  shortRoleListSelector,
  shortSupplySourceListSelector,
  shortSupplyPartnerListSelector,
  shortUserListSelector,
};
