import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const useFetchDataWithLoading = (request, defaultValue = false, withDispatch = true) => {
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(defaultValue);

  const fetchData = useCallback(
    async (...args) => {
      try {
        setIsFetching(true);

        if (withDispatch) {
          await dispatch(request(...args));
        } else {
          await request(args);
        }

        setIsFetching(false);
      } catch (e) {
        setIsFetching(false);
      }
    },
    [dispatch, setIsFetching, request, withDispatch],
  );

  return {
    isFetching,
    setIsFetching,
    fetchData,
  };
};

export default useFetchDataWithLoading;
