import React, { useMemo, memo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Tooltip } from '@material-ui/core';
import usePermissions from 'hooks/usePermissions';

// Icons
import { ReactComponent as EditIcon } from 'assets/images/icons/optionsEdit.svg';
import { ReactComponent as PreviewIcon } from 'assets/images/icons/preview.svg';

// Modules
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';
import MaterialTablesSortIcon from 'modules/_MaterialTable/MaterialTablesSortIcon/MaterialTablesSortIcon';
import MaterialTableContainer from 'modules/_MaterialTable/MaterialTableContainer/MaterialTableContainer';
import MaterialTableBody from 'modules/_MaterialTable/MaterialTableBody/MaterialTableBody';
import OptionsButtonNav from 'modules/OptionsButtonNav/OptionsButtonNav';
import TableCell from 'modules/_Table/TableCell/TableCell';

// Dictionaries
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import MaterialTableToolbar from 'modules/_MaterialTable/MaterialTableToolbar/MaterialTableToolbar';
import MaterialTableAjaxPagination from 'modules/_MaterialTable/MaterialTableAjaxPagination/MaterialTableAjaxPagination';
import StatusField from 'modules/StatusField/StatusField';

const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

const TableCreatives = ({
  tableRef,
  getCreativeList,
  updateStatus,
  openPreview,
  meta,
}) => {
  const match = useRouteMatch();
  const permissions = usePermissions();

  const columns = useMemo(() => [
    {
      title: 'ID',
      field: 'id',
      width: 120,
      minWidth: 120,
      render: (rowData) => (
        <MaterialTableText variant="light">
          {rowData.id}
        </MaterialTableText>
      ),
    },
    {
      title: 'CREATIVE',
      field: 'name',
      defaultSort: 'asc',
      cellStyle: { wordBreak: 'break-all' },
      render: (rowData) => (
        <Link to={ `${match.url}/${rowData.id}` } className="link">
          <MaterialTableText>
            {rowData.name}
          </MaterialTableText>
        </Link>
      ),
    },
    {
      title: 'TYPE',
      field: 'subType',
      width: 160,
      minWidth: 160,
      render: (rowData) => (
        <MaterialTableText variant="light">
          {CREATIVE_TYPES.hashMap[rowData.subType]}
        </MaterialTableText>
      ),
    },
    {
      title: 'ADVERTISER',
      field: 'advertiser',
      width: 160,
      minWidth: 160,
      maxWidth: 160,
      hidden: !permissions.advertiserRead,
      render: (rowData) => {
        const advertiser = rowData.advertiser?.name || '';
        return (
          <Tooltip title={ advertiser }>
            <div className="text-truncate">
              <MaterialTableText variant="light" nowrap>
                {advertiser}
              </MaterialTableText>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'CAMPAIGNS',
      field: 'campaignsCount',
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      hidden: !permissions.campaignRead,
      render: (rowData) => (
        <Link to={ `campaigns?creative=${rowData.id}` } className="link">
          <TableCell firstRow={ rowData.campaignsCount } />
        </Link>
      ),
    },
    {
      title: 'STATUS',
      field: 'status',
      width: 112,
      minWidth: 112,
      render: (rowData) => (
        <StatusField
          value={ rowData.status }
          handleChange={ (e, element) => (updateStatus(rowData.id, element.props.value)) }
        />
      ),
    },
    {
      title: '',
      field: 'options',
      sorting: false,
      width: 24,
      minWidth: 24,
      cellStyle: { textAlign: 'right' },
      render: (rowData) => {
        const items = [{ to: `${match.url}/${rowData.id}`, label: permissions.creativeWrite ? 'Edit' : 'Show', icon: EditIcon }];
        if (rowData.type === CREATIVE_TYPES.types.VIDEO) {
          items.push({ type: 'button', label: 'Creative Preview', icon: PreviewIcon, onClick: () => openPreview(rowData) });
        }
        return <OptionsButtonNav items={ items } />;
      },
    },
  ], [match.url, permissions, openPreview, updateStatus]);

  return (
    <MaterialTable
      columns={ columns }
      data={ getCreativeList }
      tableRef={ tableRef }
      localization={ { body: { emptyDataSourceMessage: <h4>Sorry, we couldn&apos;t find any results matching.</h4> } } }
      options={ {
        draggable: false,
        pageSizeOptions: ROWS_PER_PAGE_OPTIONS,
        pageSize: meta?.size || ROWS_PER_PAGE_OPTIONS[0],
      } }
      icons={ { SortArrow: MaterialTablesSortIcon } }
      components={ {
        Toolbar: (props) => <MaterialTableToolbar tableRef={ tableRef } entityName="Creatives" { ...props } />,
        Pagination: MaterialTableAjaxPagination,
        Container: MaterialTableContainer,
        Body: MaterialTableBody,
      } }
    />
  );
};

TableCreatives.defaultProps = { openPreview: Function.prototype };

TableCreatives.propTypes = {
  tableRef: PropTypes.objectOf(PropTypes.object).isRequired,
  getCreativeList: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  openPreview: PropTypes.func,
  meta: PropTypes.shape({
    perPage: PropTypes.number,
    total: PropTypes.number,
    size: PropTypes.number,
  }).isRequired,
};

export default memo(TableCreatives);
