import { getToken, getRefreshToken } from 'libs/storageLibs';

export const headersRequestHandler = (request) => {
  const { method, url } = request;

  const authToken = getToken();
  const refreshToken = getRefreshToken();

  let headers = {};

  switch (method.toUpperCase()) {
    case 'GET':
      if (url.includes('auth/refresh')) {
        headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'X-Authorization': `Bearer ${refreshToken}`,
          'X-Requested-With': 'XMLHttpRequest',
        };
      } else if (url.includes('_download')) {
        headers = {
          'Cache-Control': 'no-cache',
          'X-Authorization': `Bearer ${authToken}`,
        };
      } else {
        headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'X-Authorization': `Bearer ${authToken}`,
        };
      }
      break;

    case 'POST':
      if (url.includes('auth/login')) {
        headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        };
      } else if (url.includes('auth/password-reset') || url.includes('auth/password-set')) {
        headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        };
      } else if (
        url.includes('v2/parse') ||
        url.includes('settings/ipblacklist') ||
        (url.includes('ssps') && url.includes('/ipblacklist')) ||
        url.includes('targeting/devices') ||
        url.includes('targetings/lists') ||
        url.includes('creatives')
      ) {
        headers = {
          'Cache-Control': 'no-cache',
          'X-Authorization': `Bearer ${authToken}`,
        };
      } else {
        headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'X-Authorization': `Bearer ${authToken}`,
        };
      }
      break;

    case 'PUT':
      if (url.includes('targetings/lists')) {
        headers = {
          'Cache-Control': 'no-cache',
          'X-Authorization': `Bearer ${authToken}`,
        };
      } else {
        headers = {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'X-Authorization': `Bearer ${authToken}`,
        };
      }

      break;

    case 'DELETE':
      headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'X-Authorization': `Bearer ${authToken}`,
      };
      break;

    default:
      throw new Error('Method is not matched');
  }

  return { ...request, headers };
};
