import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import Select from 'modules/_Factories/Select';
import InputFile from 'modules/InputFile';
import Input from 'modules/_Factories/Input/Input';
import AsyncSelect from 'modules/_Factories/AsyncSelect';
import CropImage, { CropImageDialog } from 'modules/CropImage';

const getDataType = (value) => {
  if (value && typeof value === 'string') return CREATIVE_DATA_TYPES.types.UPLOAD_FROM_URL;

  return CREATIVE_DATA_TYPES.types.UPLOAD_FILE;
};

const UploadFile = ({
  title,
  value,
  onChangeValue,
  fileExtensions,
  dataTypeList,
  disabled,
  expectedFileSize,
  allowEditImage,
}) => {
  const [dataType, setDataType] = useState(getDataType(value));
  const [openCropImageDialog, setOpenCropImageDialog] = useState(false);

  const toggleCropImageDialog = useCallback(() => {
    setOpenCropImageDialog((prevValue) => !prevValue);
  }, [setOpenCropImageDialog]);

  const handleChangeDataType = (e) => {
    setDataType(e.target.value);
    onChangeValue(null, e.target.value);
  };

  const renderFieldByDataType = (type) => {
    switch (type) {
      case CREATIVE_DATA_TYPES.types.UPLOAD_FROM_URL: {
        return (
          <Input
            name="url"
            label="Creative URL"
            value={ value }
            // Options
            fullWidth
            required
            disabled={ disabled }
            // Events
            onChange={ (e) => onChangeValue(e.target.value, type) }
          />
        );
      }
      case CREATIVE_DATA_TYPES.types.STOCK_LIB:
        return (
          <AsyncSelect
            label="Select Image"
            name="url"
            value={ value }
            onChange={ (e) => onChangeValue(e.target.value, type) }
          />
        );
      case CREATIVE_DATA_TYPES.types.UPLOAD_FILE:
      default: {
        return (
          <InputFile
            labelButton="Add File"
            accept={ fileExtensions }
            name="file"
            value={ value }
            disabled={ disabled }
            onChange={ (e) => {
              const [file] = e.target.files;
              onChangeValue(file, type);
            } }
          />
        );
      }
    }
  };

  const saveCroppedImage = useCallback((croppedImgBlob) => {
    const croppedImgFile = new File([croppedImgBlob], 'Cropped Image', { type: croppedImgBlob.type });
    setDataType(CREATIVE_DATA_TYPES.types.UPLOAD_FILE);
    onChangeValue(croppedImgFile, CREATIVE_DATA_TYPES.types.UPLOAD_FILE);
    toggleCropImageDialog();
  }, [onChangeValue, toggleCropImageDialog, setDataType]);

  const getImageUrl = () => {
    if (!value) return null;
    return value instanceof File ? URL.createObjectURL(value) : value;
  };

  const imgUrl = getImageUrl();

  return (
    <Grid container spacing={ 2 }>
      <Grid item container justify="space-between" alignItems="center" xs={ 12 }>
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        {!disabled && allowEditImage && imgUrl && (
          <Grid item>
            <Button onClick={ toggleCropImageDialog } color="primary">Edit Image</Button>
            <CropImageDialog open={ openCropImageDialog } onClose={ toggleCropImageDialog }>
              <CropImage imgUrl={ imgUrl } onSave={ saveCroppedImage } defaultCropSize={ expectedFileSize } />
            </CropImageDialog>
          </Grid>

        )}
      </Grid>
      {!disabled && (
        <Grid item xs={ 12 }>
          <Select
            name="dataType"
            label="Upload Type"
            value={ dataType }
            itemList={ dataTypeList }
            // Options
            isFilter
            isFilterText="None"
            fullWidth
            required
            disabled={ disabled }
            showNoneItem={ false }
            // Events
            onChange={ handleChangeDataType }
          />
        </Grid>
      )}
      <Grid item xs={ 12 }>
        {renderFieldByDataType(dataType)}
      </Grid>
    </Grid>
  );
};

UploadFile.defaultProps = {
  dataTypeList: CREATIVE_DATA_TYPES.dictionary,
  fileExtensions: '*',
  disabled: false,
  value: null,
  title: null,
  expectedFileSize: null,
  allowEditImage: false,
};

UploadFile.propTypes = {
  dataTypeList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  fileExtensions: PropTypes.string,
  disabled: PropTypes.bool,
  allowEditImage: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  expectedFileSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default memo(UploadFile);
