import types from 'actions/actionTypes/advertiserActionTypes';
import { Statuses } from 'constants/index';

export const INITIAL_FILTERS = {
  search: '',
  status: '',
  balance: '',
};

export const DEFAULT_FILTERS = {
  ...INITIAL_FILTERS,
  status: Statuses[0].id,

};

const initialState = {
  advertiserList: {
    payload: [],
    _meta: {
      page: 0,
      size: 0,
      total: 0,
    },
  },
  advertiser: {},
  balanceHistory: [],
  filters: DEFAULT_FILTERS,
};

const advertiserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ADVERTISER_LIST:
      return {
        ...state,
        advertiserList: action.payload,
      };

    case types.GET_ADVERTISER_BY_ID:
    case types.CLEAR_ADVERTISER_BY_ID:
      return {
        ...state,
        advertiser: action.payload,
      };

    case types.GET_BALANCE_HISTORY:
    case types.CLEAR_BALANCE_HISTORY:
      return {
        ...state,
        balanceHistory: action.payload,
      };

    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    default:
      return state;
  }
};

export default advertiserReducer;
