export class SchedulePeriodClass {
  dayOfWeek = null;
  start = null;
  end = null;

  constructor(...args) {
    args.forEach((item) => {
      this.dayOfWeek = item.dayOfWeek || null;
      this.start = item.start || null;
      this.end = item.end || null;
    });
  }
}
