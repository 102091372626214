import { set } from 'lodash';

const transformValue = (value) => {
  if (Array.isArray(value)) return value.join(',');

  return value;
};

export const noop = (val) => val;

export const formatObjectToQueryParams = (obj) => {
  if (!obj) return {};

  return Object.entries(obj).reduce((accum, cur) => {
    const [key, value] = cur;
    const transformedValue = transformValue(value);
    return transformedValue || typeof transformedValue === 'boolean' ? { ...accum, [key]: transformedValue } : accum;
  }, {});
};

export const getQueryParamsFromObject = (obj) => {
  const params = new URLSearchParams(formatObjectToQueryParams(obj));
  return params.toString();
};

export const strToArrayString = (val) => {
  if (!val) return [];
  return val.split(',');
};

export const strToDate = (val) => {
  if (!val) return null;
  return new Date(val);
};

export const strToNumber = (val) => {
  if (!val) return null;
  return +val;
};

export const strToBoolean = (val) => {
  if (!val) return false;
  return val !== 'false';
};

export const arrToJSON = (val) => {
  if (!val || !val.length) return null;
  return JSON.stringify(val);
};

export const JSONToArr = (val) => {
  if (val) return JSON.parse(val);
  return [];
};

export const strToArrayNumber = (val) => strToArrayString(val).map(Number);

/**
 *
 * @param {[key: string]: any} params
 * @param {[key: string]: function | { path: string, transform: function } } config
 */
export const transformParams = (params, config) => {
  const paramEntries = Object.entries(params);
  return paramEntries.reduce((accum, entry) => {
    const [key, value] = entry;
    if (typeof config[key] === 'object') {
      let configValue = config[key].transform(value);
      if (config[key].filterTransformedValues) {
        configValue = config[key].filterTransformedValues(configValue);
      }
      set(accum, config[key].path, configValue);
      return accum;
    }
    return { ...accum, [key]: config[key](value) };
  }, {});
};

export const transformIdsToObjArray = (ids, list) => {
  const res = [];
  for (let i = 0; i < ids.length; i++) {
    const element = list.find((item) => item.id === ids[i]);
    if (element) res.push(element);
  }
  return res;
};

export const transformObjArrayToIds = (arr) => arr.map((item) => item.id);
