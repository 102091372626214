import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import PAYMENT_METHODS from 'constants/dictionary/paymentMethodDictionary';

// Actions
import advertiserActions from 'actions/advertiserActions';
import useAdvertiserBalance from 'modules/AdvertiserBalance/useAdvertiserBalance';

const useBalance = () => {
  const dispatch = useDispatch();
  const { fillAdvertiserBalance } = useAdvertiserBalance();

  const getAdvertiserById = useCallback((id) => dispatch(advertiserActions.getAdvertiserById(id)), [dispatch]);

  const replenishAdvertiserBalance = useCallback(async (advertiserId, values) => {
    const result = await fillAdvertiserBalance(advertiserId, values);
    if (result && values.paymentMethod === PAYMENT_METHODS.types.DEPOSIT) {
      getAdvertiserById(advertiserId);
    }
  }, [fillAdvertiserBalance, getAdvertiserById]);

  return { replenishAdvertiserBalance };
};

export default useBalance;
