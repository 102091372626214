// CRUD
import crud from 'actions';
import types from './actionTypes/creativeActionTypes';

/** *********************************************** POST **************************************************** */

/**
 * CREATE Creative
 */
const createCreative = (model) => crud.post('creatives', model, true);

/**
 * CREATE Asset
 */
const createAsset = (model) => crud.post('assets', model, true);

/** ************************************************ GET ****************************************************** */

/**
 * GET list of Advertisers.
 */
const getCreativeList = (params) => crud.get('creatives', types.GET_CREATIVE_LIST, params, true);

/**
 * GET Advertiser by ID.
 */
const getCreativeById = (
  id,
  actionType = types.GET_CREATIVE_BY_ID,
  params = {},
  loading = true,
) => crud.get(`creatives/${id}`, actionType, params, loading);

/**
 * GET Asset by ID.
 */
const getAssetById = (id, actionType = null, params = {}, loading = true) => crud.get(`assets/${id}`, actionType, params, loading);

/** ************************************************ PUT ****************************************************** */

/**
 * UPDATE Advertiser by ID.
 */
const updateCreative = (id, model) => crud.put(`creatives/${id}`, model, true);

/**
 * UPDATE Creative status by ID.
 */
const updateCreativeStatus = (id, status) => crud.put(`creatives/${id}/status`, JSON.stringify(status), true);

/**
 * Set filters
 */
const setFilters = (filters) => (dispatch) => dispatch({
  type: types.SET_FILTERS,
  payload: filters,
});

const clearCreativeList = () => crud.clear(types.CLEAR_CREATIVE_LIST, { payload: [], _meta: { page: 0, size: 0, total: 0 } });
const clearCreativeById = () => crud.clear(types.CLEAR_CREATIVE_BY_ID, {});

export default {
  createAsset,
  createCreative,
  getCreativeList,
  getCreativeById,
  updateCreative,
  setFilters,
  clearCreativeList,
  clearCreativeById,
  updateCreativeStatus,
  getAssetById,
};
