import React, { memo, useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Constants
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';

// Classes
import CreativeClass from 'classes/creative/creativeClass';

// Actions
import creativeActions from 'actions/creativeActions';

// Selectors
import creativeSelectors from 'selectors/creativeSelectors';

// Components
import FormCreative from '../../FormCreative';

const EditCreative = ({ match, history }) => {
  const dispatch = useDispatch();
  const creative = useSelector(creativeSelectors.creativeSelector);
  const { enqueueSnackbar } = useSnackbar();
  const [creativeInitialValues, setCreativeInitialValues] = useState(creative);

  const { id } = match.params;
  const updateCreative = useCallback(async (creativeId, model) => dispatch(creativeActions.updateCreative(creativeId, model)), [dispatch]);
  const getCreative = useCallback(async (creativeId) => dispatch(creativeActions.getCreativeById(creativeId)), [dispatch]);
  const getAsset = useCallback(async (assetId) => dispatch(creativeActions.getAssetById(assetId)), [dispatch]);

  const updateCreativeInitialValues = useCallback(async (values) => {
    const { id: creativeId, type, assets, subType } = values;
    const [assetId] = assets || [];
    if (!creativeId || !assetId || type === CREATIVE_TYPES.types.VIDEO) {
      setCreativeInitialValues(values);
      return;
    }

    if (subType === CREATIVE_TYPES.types.DISPLAY) {
      const asset = await getAsset(assetId);

      setCreativeInitialValues({
        ...values,
        template: null,
        assets: [asset],
        url: asset.value,
      });
      return;
    }

    const assetValues = await Promise.all(assets.map((item) => getAsset(item)));
    setCreativeInitialValues({
      ...values,
      assets: assetValues,
    });
  }, [setCreativeInitialValues, getAsset]);

  useEffect(() => {
    if (!id) return;
    getCreative(id);
  }, [id, getCreative]);

  useEffect(() => {
    updateCreativeInitialValues(creative);
  }, [creative, updateCreativeInitialValues]);

  const handleSubmit = useCallback(async (values, callbackFinally) => {
    try {
      const creativeModel = new CreativeClass({
        ...values,
        assets: values.assets?.map((item) => item.id) || null,
      });
      const response = await updateCreative(values.id, JSON.stringify(creativeModel));
      history.push('/creatives');
      enqueueSnackbar(`Creative (id: ${response.id}) updated successfully`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
    } finally {
      callbackFinally();
    }
  }, [enqueueSnackbar, history, updateCreative]);

  const getDataType = useCallback((creativeModel) => {
    if (creativeModel.content) return CREATIVE_DATA_TYPES.types.BANNER_TAG;
    if (creativeModel.url) return CREATIVE_DATA_TYPES.types.UPLOAD_FROM_URL;
    return null;
  }, []);

  const initialValues = useMemo(() => ({
    ...creativeInitialValues,
    subType: creativeInitialValues.subType || creativeInitialValues.type,
    dataType: getDataType(creativeInitialValues),
  }), [creativeInitialValues, getDataType]);

  return (
    <FormCreative handleSubmit={ handleSubmit } initialValues={ initialValues } isEditForm />
  );
};

EditCreative.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }).isRequired,
};

export default memo(EditCreative);
