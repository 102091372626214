import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Text } from 'recharts';

const XTick = ({ x, y, payload, stroke, index, activeTooltipIndex, isTimePeriod }) => {
  const delta = 10;
  const isActive = index === activeTooltipIndex;

  return (
    <Text
      angle={ -45 }
      x={ x }
      y={ y + delta }
      dy={ 16 }
      textAnchor="middle"
      fill={ isActive ? '#6a237c' : stroke }
    >
      {isTimePeriod ? format(payload.value, 'h aaaa') : format(payload.value, 'LLL dd')}
    </Text>
  );
};

XTick.defaultProps = {
  x: null,
  y: null,
  payload: {},
  stroke: null,
  index: null,
  activeTooltipIndex: null,
  isTimePeriod: false,
};

XTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
  stroke: PropTypes.string,
  index: PropTypes.number,
  activeTooltipIndex: PropTypes.number,
  isTimePeriod: PropTypes.bool,
};

export default XTick;
