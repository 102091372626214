import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { textFormat } from 'libs/textFormat';
import StepTitle from '../StepTitle';
import classes from '../Review.module.scss';

const Demographics = ({ segmentGroups }) => (
  <section>
    <StepTitle name="Demographics" />
    { segmentGroups.map((segmentGroup) => (
      <React.Fragment key={ segmentGroup.id }>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 5 } sm={ 5 } className={ classes.primaryText }>
            { segmentGroup.name }
          </Grid>
          <Grid item xs={ 5 } sm={ 5 }>
            <span className={ classes.primaryText }>Segments:&nbsp;&nbsp;</span>
            <span className={ classes.secondaryText }>{segmentGroup.segments.length}</span>
          </Grid>
          <Grid item xs={ 2 } sm={ 2 } className="figure-flex-end">
            <span className={ classes.primaryText }>CPM:&nbsp;&nbsp;</span>
            <span className={ classes.secondaryText }>
              {textFormat(segmentGroup.segments.reduce((cur, next) => cur + (next.cpm || next.segment.cpm), 0), 'currency')}
            </span>
          </Grid>
        </Grid>
        {segmentGroup.segments.map(({ cpm, segment }) => (
          <Grid container spacing={ 3 } key={ segment.id }>
            <Grid item xs={ 5 } sm={ 5 } />
            <Grid item xs={ 5 } sm={ 5 } className={ classNames(classes.secondaryText) }>
              <span>{segment.name}</span>
            </Grid>
            <Grid item xs={ 2 } sm={ 2 } className={ classNames('figure-flex-end', classes.secondaryText) }>
              <span>
                {textFormat(cpm || segment.cpm || 0, 'currency')}
              </span>
            </Grid>
          </Grid>
        ))}
      </React.Fragment>
    )) }
  </section>
);

Demographics.defaultProps = { segmentGroups: [] };

Demographics.propTypes = {
  segmentGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(PropTypes.shape({
      cpm: PropTypes.number,
      segment: PropTypes.shape({
        cpm: PropTypes.number,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string,
      }).isRequired,
    })).isRequired,
  })),
};

export default memo(Demographics);
