import { createSelector } from 'reselect';
import { DISTRICTS_HASH_MAP } from 'constants/districtMapMatching';

// Libs
import { sortByKey } from 'libs/sortByKey';
import { keyBy } from 'lodash';
import InsightClass from '../classes/insight/insightClass';

const insightList = (state) => state.insights.insightList;
const insightTotal = (state) => state.insights.insightTotal;
const insightTarget = (state) => state.insights.insightTarget;
const insightCpmSelector = (state) => state.insights.insightCpm;
const insightFilterSelector = (state) => state.insights.filters;

const insightListSelector = createSelector(
  insightList,
  (insights) => sortByKey(insights
    .filter((insight) => insight.item)
    .map((insight) => new InsightClass({
      ...insight,
      ...insight.item && {
        item: {
          ...insight.item,
          name: DISTRICTS_HASH_MAP[insight.item.id] || insight.item.name,
        },
      },
    }))),
);

const insightTotalSelector = createSelector(
  insightTotal,
  (item) => item,
);

const insightTargetSelector = createSelector(
  insightTarget,
  (item) => item,
);

const insightListHashMapSelector = createSelector(
  insightListSelector,
  (items) => keyBy(items, (o) => o.item?.id),
);

export default {
  insightListSelector,
  insightTotalSelector,
  insightTargetSelector,
  insightListHashMapSelector,
  insightCpmSelector,
  insightFilterSelector,
};
