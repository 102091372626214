import React from 'react';
import Router from 'router/Router';

// Hooks
import useDynamicBackground from './hooks/useDynamicBackground';
import useAuth from './hooks/useAuth';

// Components
import GlobalPreloader from './modules/GlobalPreloader/GlobalPreloader';

const App = () => {
  useDynamicBackground();

  const { isChecked } = useAuth({ initialState: true });
  return (
    <>
      <GlobalPreloader />
      { isChecked && <Router /> }
    </>
  );
};

export default App;
