import React from 'react';
import PropTypes from 'prop-types';
import { textFormat } from 'libs/textFormat';
import classes from './Tooltip.module.scss';

const Tooltip = ({ active, payload, tooltipTextFormat }) => active && payload && payload.length && (
  <div className={ classes.tooltip }>
    {textFormat(payload[0].value, tooltipTextFormat)}
  </div>
);

Tooltip.defaultProps = {
  payload: [],
  tooltipTextFormat: '',
};

Tooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) }),
  ),
  tooltipTextFormat: PropTypes.string,
};

export default Tooltip;
