import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, intersectionWith } from 'lodash';
// Libs
import { textFormat } from 'libs/textFormat';

// Dictionaries
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';
import CappingPeriodDictionary from 'constants/dictionary/cappingPeriodDictionary';

// Hooks
import { StepContainer } from 'modules/Steps';
import useReview from './useReview';

// Components
import Dates from './Dates';
import Inventory from './Inventory';
import Geography from './Geography';
import Demographics from './Demographics';
import TimeOfDay from './TimeOfDay';
import Budget from './Budget';
import Creatives from './Creatives';

const Review = ({ formik, shortSupplySourceList, shortAdUnitList }) => {
  const {
    ssps,
    systemType,
    adUnits,
    start,
    end,
    targetingProfile,
    pricingStrategy,
  } = formik.values;
  const { values: segmentGroups } = targetingProfile.segmentGroups;

  const { selectedCreatives, flightSchedule } = useReview(formik.values);

  const fullSspsData = useMemo(
    () => intersectionWith(shortSupplySourceList, ssps.values, (a, b) => a.id === b),
    [shortSupplySourceList, ssps.values],
  );

  const fullAdUnitData = useMemo(
    () => intersectionWith(shortAdUnitList, adUnits.values, (a, b) => a.id === b),
    [shortAdUnitList, adUnits.values],
  );

  const segmentCpm = useMemo(() => {
    let total = +pricingStrategy.cpm || 0;
    (segmentGroups || []).forEach((segmentGroup) => {
      total += segmentGroup.segments.reduce((sum, cur) => sum + (cur.cpm || cur.segment.cpm), 0);
    });

    return total;
  }, [segmentGroups, pricingStrategy]);

  const isProgrammaticDirect = systemType === CampaignTypes.types.PROGRAMMATIC_DIRECT;

  const showGeography = useMemo(() => {
    const {
      cities,
      districts,
      counties,
      countries,
      dmas,
      states,
      zips,
    } = targetingProfile;
    const categories = [cities, districts, counties, countries, dmas, states, zips];
    return categories.some((category) => get(category, 'values.length'));
  }, [targetingProfile]);

  return (
    <StepContainer
      title={ `Review your ${CampaignTypes.hashMap[systemType] || ''} campaign` }
      subtitle="Сheck entered data and request for approval or save and do it later"
    >
      {/* Flight dates */}
      { !!(start || end) && <Dates start={ start } end={ end } /> }

      {/* Inventory */}
      { !!(fullSspsData.length || fullAdUnitData.length) && <Inventory ssps={ fullSspsData } adUnits={ fullAdUnitData } />}

      {/* Geography */}
      { showGeography && (
        <Geography
          categories={ {
            cities: targetingProfile.cities,
            districts: targetingProfile.districts,
            counties: targetingProfile.counties,
            countries: targetingProfile.countries,
            dmas: targetingProfile.dmas,
            states: targetingProfile.states,
            zipCodes: targetingProfile.zips,
          } }
        />
      )}

      {/* Demographics */}
      { !!(segmentGroups && segmentGroups.length) && <Demographics segmentGroups={ segmentGroups } />}

      {/* Time of day */}
      { !!flightSchedule.length && <TimeOfDay schedule={ flightSchedule } />}

      {/* Budget and constraints */}
      <Budget
        isProgrammaticDirect={ isProgrammaticDirect }
        budgetTarget={ formik.values.budgetTarget && textFormat(formik.values.budgetTarget, 'currency') }
        budgetCapping={ {
          value: textFormat(formik.values.budgetCapping?.value, 'currency'),
          period: CappingPeriodDictionary.hashMap[formik.values.budgetCapping?.period || CappingPeriodDictionary.types.DAY],
        } }
        impsTarget={ formik.values.impsTarget && textFormat(formik.values.impsTarget, 'number') }
        impCapping={ {
          value: textFormat(formik.values.impCapping?.value, 'number'),
          period: CappingPeriodDictionary.hashMap[formik.values.impCapping?.period || CappingPeriodDictionary.types.DAY],
        } }
        cpm={ formik.values.pricingStrategy?.cpm && textFormat(formik.values.pricingStrategy.cpm, 'currency') }
        segmentCpm={ segmentCpm && textFormat(segmentCpm, 'currency') }
      />

      {/* Creatives */}
      {!!selectedCreatives.length && (
        <Creatives creatives={ selectedCreatives } />
      )}
    </StepContainer>
  );
};

Review.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      status: PropTypes.string.isRequired,
      creatives: PropTypes.arrayOf(PropTypes.number),
      budgetTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      targetingProfile: PropTypes.shape({
        zips: PropTypes.shape({}),
        cities: PropTypes.shape({}),
        districts: PropTypes.shape({}),
        counties: PropTypes.shape({}),
        countries: PropTypes.shape({}),
        dmas: PropTypes.shape({}),
        states: PropTypes.shape({}),
        segmentGroups: PropTypes.oneOfType([
          PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              name: PropTypes.string.isRequired,
              segments: PropTypes.arrayOf(PropTypes.shape({})),
            }),
          ),
          PropTypes.any,
        ]),
        periods: PropTypes.shape({
          values: PropTypes.arrayOf(PropTypes.shape({
            dayOfWeek: PropTypes.string,
            start: PropTypes.string,
            end: PropTypes.string,
          })),
        }),
      }),
      budgetCapping: PropTypes.shape({
        period: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      impsTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      impCapping: PropTypes.shape({
        period: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      pricingStrategy: PropTypes.shape({ cpm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
      delivery: PropTypes.string,
      ssps: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.number) }),
      adUnits: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.number) }),
      systemType: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    isValid: PropTypes.bool,
    handleSubmit: PropTypes.func,
  }).isRequired,
  shortSupplySourceList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shortAdUnitList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Review;
