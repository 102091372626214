import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Selectors
import userSelectors from '../selectors/userSelectors';

// Actions
import userActions from '../actions/userActions';

// Hooks
import useAuth from './useAuth';

const useUser = ({ initialState }) => {
  const dispatch = useDispatch();
  // Hooks
  const { isAuthenticated } = useAuth({});

  // Selectors
  const user = useSelector(userSelectors.userSelector);

  // Callbacks
  const getCurrentUser = useCallback(() => dispatch(userActions.getCurrentUser()), [dispatch]);

  useEffect(() => {
    if (initialState && isAuthenticated) {
      getCurrentUser();
    }
  }, [getCurrentUser, initialState, isAuthenticated]);

  return {
    user,
    getCurrentUser,
  };
};

useUser.defaultProps = { initialState: false };

useUser.propTypes = { initialState: PropTypes.bool };

export default useUser;
