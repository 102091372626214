import { buildHashMap } from 'libs/buildHashMap';
import PermissionDictionary from './userPermissionsDictionary';

const types = {
  DISPLAY: 'DISPLAY',
  NATIVE: 'NATIVE',
  VIDEO: 'VIDEO',
  // VAST_TAG: 'VAST_TAG',
};

const dictionary = [
  {
    id: types.DISPLAY,
    name: 'Display Ad',
    permissions: [PermissionDictionary.types.ASSET_READ, PermissionDictionary.types.ASSET_WRITE],
  },
  {
    id: types.NATIVE,
    name: 'Native Ad',
    permissions: [PermissionDictionary.types.ASSET_READ, PermissionDictionary.types.ASSET_WRITE],
  },
  { id: types.VIDEO, name: 'Video Ad' },
  // { id: types.VAST_TAG, name: 'VAST Tag' },
];

const hashMap = buildHashMap(dictionary);

export default {
  types,
  dictionary,
  hashMap,
};
