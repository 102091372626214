import { useRef } from 'react';
import userPermissionsDictionary from 'constants/dictionary/userPermissionsDictionary';
import { hasPermission } from 'libs/storageLibs';
import { isEqual } from 'lodash';

const usePermissions = () => {
  const permissions = {
    advertiserBalanceManual: hasPermission([userPermissionsDictionary.types.ADVERTISER_BALANCE_MANUAL]),
    advertiserRead: hasPermission([userPermissionsDictionary.types.ADVERTISER_READ]),
    advertiserWrite: hasPermission([userPermissionsDictionary.types.ADVERTISER_WRITE]),
    campaignApproval: hasPermission([userPermissionsDictionary.types.CAMPAIGN_APPROVAL]),
    campaignRead: hasPermission([userPermissionsDictionary.types.CAMPAIGN_READ]),
    campaignWrite: hasPermission([userPermissionsDictionary.types.CAMPAIGN_WRITE]),
    creativeRead: hasPermission([userPermissionsDictionary.types.CREATIVE_READ]),
    creativeWrite: hasPermission([userPermissionsDictionary.types.CREATIVE_WRITE]),
    adUnitRead: hasPermission([userPermissionsDictionary.types.ADUNIT_READ]),
    adUnitWrite: hasPermission([userPermissionsDictionary.types.ADUNIT_WRITE]),
    publisherRead: hasPermission([userPermissionsDictionary.types.PUBLISHER_READ]),
    publisherWrite: hasPermission([userPermissionsDictionary.types.PUBLISHER_WRITE]),
  };
  const permissionsRef = useRef(permissions);
  if (!isEqual(permissions, permissionsRef.current)) {
    permissionsRef.current = permissions;
  }

  return permissionsRef.current;
};

export default usePermissions;
