import { toNumber } from 'lodash';

export class AdvertiserBalanceClass {
  amount = 0;
  notes = '';

  constructor(...args) {
    args.forEach((item) => {
      this.amount = toNumber(item.amount) || 0;
      this.notes = item.notes || '';
    });
  }
}
