import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

// Libs
import { hasPermission } from 'libs/storageLibs';

// Hooks
import useAuth from 'hooks/useAuth';

const ProtectedRoute = ({ component: Component, render, exact, path, requiredPermissions, redirectTo }) => {
  const { isAuthenticated } = useAuth({});

  const checkPermissions = useCallback((reqPerm) => {
    if (reqPerm.length) {
      return hasPermission(reqPerm);
    }
    return true;
  }, []);

  const renderRoute = useMemo(() => (
    checkPermissions(requiredPermissions) ? (
      <Route
        exact={ exact }
        path={ path }
        { ...render ? { render } : { component: Component } }
      />
    ) : (
      <Route
        path={ path }
        render={ () => <Redirect to="/404-not-found" /> }
      />
    )
  ), [Component, checkPermissions, path, requiredPermissions, exact, render]);

  return (
    isAuthenticated ? (
      renderRoute
    ) : (
      <Route
        path={ path }
        render={ () => <Redirect to={ redirectTo } /> }
      />
    )
  );
};

ProtectedRoute.defaultProps = {
  redirectTo: '/login',
  requiredPermissions: [],
  exact: false,
  render: null,
  component: null,
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
  path: PropTypes.string.isRequired,
  redirectTo: PropTypes.string,
  exact: PropTypes.bool,
  render: PropTypes.func,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default ProtectedRoute;
