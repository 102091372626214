import { combineReducers } from 'redux';

import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';
import campaignReducer from './campaignReducer';
import creativeReducer from './creativeReducer';
import userReducer from './userReducer';
import shortListReducer from './shortListReducer';
import advertiserReducer from './advertiserReducer';
import dictionaryReducer from './dictionaryReducer';
import reportReducer from './reportReducer';
import loadingReducer from './loadingReducer';
import insightReducer from './insightReducer';

const rootReducer = () => combineReducers({
  advertisers: advertiserReducer,
  auth: authReducer,
  campaigns: campaignReducer,
  creatives: creativeReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  shortLists: shortListReducer,
  dictionary: dictionaryReducer,
  reports: reportReducer,
  loading: loadingReducer,
  insights: insightReducer,
});

export default rootReducer;
