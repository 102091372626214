import types from 'actions/actionTypes/dictionaryActionTypes';

const initialState = {
  categories: [],
  languages: [],
  sizes: [],
  timeZones: [],
  countries: [],
  DMAs: [],
  demographics: [],
  filteredStates: [],
  filteredCities: [],
  filteredCongressionalDistricts: [],
  filteredCounties: [],
};

const dictionaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SIZES:
      return {
        ...state,
        sizes: action.payload,
      };
    case types.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case types.GET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case types.GET_TIME_ZONES:
      return {
        ...state,
        timeZones: [...action.payload],
      };
    case types.GET_COUNTRIES:
      return {
        ...state,
        countries: [...action.payload],
      };
    case types.GET_DMAS:
      return {
        ...state,
        DMAs: [...action.payload],
      };
    case types.GET_DEMOGRAPHICS:
      return {
        ...state,
        demographics: [...action.payload],
      };
    case types.CLEAR_FILTERED_STATES:
    case types.GET_FILTERED_STATES:
      return {
        ...state,
        filteredStates: [...action.payload],
      };
    case types.CLEAR_FILTERED_CITIES:
    case types.GET_FILTERED_CITIES:
      return {
        ...state,
        filteredCities: [...action.payload],
      };
    case types.CLEAR_FILTERED_CONGRESSIONAL_DISTRICTS:
    case types.GET_FILTERED_CONGRESSIONAL_DISTRICTS:
      return {
        ...state,
        filteredCongressionalDistricts: [...action.payload],
      };
    case types.CLEAR_FILTERED_COUNTIES:
    case types.GET_FILTERED_COUNTIES:
      return {
        ...state,
        filteredCounties: [...action.payload],
      };
    default:
      return state;
  }
};

export default dictionaryReducer;
