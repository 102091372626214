import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// Material
import { Grid } from '@material-ui/core';

// Constants
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';

// Modules
import StepContainer from 'modules/Steps/StepContainer';
import Input from 'modules/_Factories/Input/Input';
import PreviewCreative from 'modules/PreviewCreative';
import NativeOptions from './NativeOptions';
import UploadFile from '../../UploadFile';

import { getAcceptableFileExtensions } from '../../useFormCreative';

const Options = ({ formik, isEditForm }) => {
  const { subType, url, file } = formik.values;
  const { setFieldValue } = formik;
  const isVideo = subType === CREATIVE_TYPES.types.VIDEO;
  const [creativeDataTypes, setCreativeDataTypes] = useState([]);

  useEffect(() => {
    const dataTypes = isVideo ?
      CREATIVE_DATA_TYPES.dictionary.filter((item) => item.id !== CREATIVE_DATA_TYPES.types.STOCK_LIB)
      : CREATIVE_DATA_TYPES.dictionary;
    setCreativeDataTypes(dataTypes);
  }, [isVideo, setCreativeDataTypes]);

  const getHelperText = (creativeType) => {
    const helperTextOptions = {
      [CREATIVE_TYPES.types.DISPLAY]: 'Upload JPG, JPEG or PNG image file up to 10MB',
      [CREATIVE_TYPES.types.NATIVE]: 'Build your own Telly ads',
      [CREATIVE_TYPES.types.VIDEO]: 'Upload MP4, M4V or MOV video file up to 100MB',
    };
    return helperTextOptions[creativeType];
  };

  const handleChangeCreative = useCallback((value) => {
    if (typeof value === 'string') {
      setFieldValue('file', null);
      setFieldValue('url', value);
    } else {
      setFieldValue('url', null);
      setFieldValue('file', value);
    }
  }, [setFieldValue]);

  const helpText = getHelperText(formik.values.subType);
  const fileExtensions = getAcceptableFileExtensions(subType).map((item) => `.${item}`).join(',');
  const creativeUrl = file ? URL.createObjectURL(file) : url;

  const renderOptionsContent = () => (
    <Grid container alignItems="flex-start" spacing={ 4 }>
      <Grid container item xs={ 8 } spacing={ 4 }>
        <Grid item xs={ 12 }>
          <UploadFile
            title={ subType === CREATIVE_TYPES.types.DISPLAY ? 'Display image asset - 300x250' : null }
            dataTypeList={ creativeDataTypes }
            fileExtensions={ fileExtensions }
            expectedFileSize={ { width: 300, height: 250 } }
            value={ formik.values.file || formik.values.url }
            disabled={ isEditForm }
            allowEditImage={ formik.values.subType !== CREATIVE_TYPES.types.VIDEO }
            onChangeValue={ handleChangeCreative }
          />
        </Grid>
        {/* SIZE/WIDTH/HEIGHT/MIME_TYPE/DURATION */}
        {isEditForm && subType === CREATIVE_TYPES.types.VIDEO && (
        <>
          <Grid item container spacing={ 4 }>
            <Grid item xs={ 12 } sm={ 6 }>
              <Input
                name="size.width"
                label="Width"
                value={ get(formik, 'values.size.width') }
                  // Options
                fullWidth
                required={ get(formik, 'values.sizeType') === 'CUSTOM' }
                disabled={ get(formik, 'values.sizeType') !== 'CUSTOM' }
                  // Events
                onChange={ formik.handleChange }
                onBlur={ formik.handleBlur }
                setFieldTouched={ formik.setFieldTouched }
                  // Extra
                errors={ formik.errors }
                touched={ formik.touched }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
              <Input
                name="size.height"
                label="Height"
                value={ get(formik, 'values.size.height') }
                  // Options
                fullWidth
                required={ get(formik, 'values.sizeType') === 'CUSTOM' }
                disabled={ get(formik, 'values.sizeType') !== 'CUSTOM' }
                  // Events
                onChange={ formik.handleChange }
                onBlur={ formik.handleBlur }
                setFieldTouched={ formik.setFieldTouched }
                  // Extra
                errors={ formik.errors }
                touched={ formik.touched }
              />
            </Grid>
          </Grid>

          <Grid item container spacing={ 4 }>
            <Grid item xs={ 12 } sm={ 6 }>
              <Input
                name="mime"
                label="Mime Type"
                value={ get(formik, 'values.mime') || '' }
                  // Options
                fullWidth
                disabled
                  // Events
                onChange={ formik.handleChange }
                onBlur={ formik.handleBlur }
                setFieldTouched={ formik.setFieldTouched }
                  // Extra
                errors={ formik.errors }
                touched={ formik.touched }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
              <Input
                name="duration"
                label="Video duration, sec"
                value={ get(formik, 'values.duration') }
                  // Options
                fullWidth
                disabled
                  // EventsC
                onChange={ formik.handleChange }
                onBlur={ formik.handleBlur }
                setFieldTouched={ formik.setFieldTouched }
                  // Extra
                errors={ formik.errors }
                touched={ formik.touched }
              />
            </Grid>
          </Grid>
        </>
        )}
      </Grid>
      {creativeUrl && (
      <Grid item xs={ 4 }>
        <PreviewCreative
          url={ creativeUrl }
          type={ isVideo ? 'video' : 'image' }
        />
      </Grid>
      )}
    </Grid>
  );

  return (
    <StepContainer subtitle={ helpText }>
      {subType === CREATIVE_TYPES.types.NATIVE ?
        <NativeOptions formik={ formik } isEditForm={ isEditForm } />
        : renderOptionsContent()}

    </StepContainer>
  );
};

Options.defaultProps = { isEditForm: false };

Options.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      subType: PropTypes.string,
      dataType: PropTypes.string,
      file: PropTypes.shape({}),
    }),
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
  }).isRequired,
  isEditForm: PropTypes.bool,
};

export default memo(Options);
