import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { Grid } from '@material-ui/core';

// Icons
import { ReactComponent as AddIcon } from 'assets/images/icons/add.svg';

// Libs
import { getRandomNumber } from 'libs/getRandomNumber';
import { textFormat } from 'libs/textFormat';

// Modules
import OptionsButtonNav from 'modules/OptionsButtonNav/OptionsButtonNav';

// Icons
import { ReactComponent as EditIcon } from 'assets/images/icons/optionsEdit.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/recycle_blue.svg';

// Classes
import { DemographicGroupClass } from 'classes/campaign/demographicGroupClass';

// Components
import { StepContainer } from 'modules/Steps';
import DemographicGroup from './DemographicGroup/DemographicGroup';
import AddDemography from './AddDemography/AddDemography';

// Styles
import classes from './Demographics.module.scss';

const Demographics = ({ formik, editable }) => {
  const segmentGroups = formik.values.targetingProfile.segmentGroups?.values || [];

  const getCurrentGroupItem = useCallback((groupId) => (
    segmentGroups.find((seg) => seg.id === groupId)
  ), [segmentGroups]);

  // State
  const [view, setView] = useState('general');
  const [currentGroupItem, setCurrentGroupItem] = useState(new DemographicGroupClass());
  const [editMode, setEditMode] = useState(false);
  const [unsavedSegments, setUnsavedSegments] = useState([]);

  const openDemographicGroup = useCallback(({ cbItem }) => {
    if (cbItem && cbItem.id) {
      const curItem = getCurrentGroupItem(cbItem.id);
      setCurrentGroupItem(curItem); // Set Link of the current group item to state
      setEditMode(true); // Set current mode to Edit
      setUnsavedSegments(curItem.segments); // Set previously saved segments to temporary state
    } else {
      const demographicGroups = segmentGroups || [];
      const newDemographicGroup = new DemographicGroupClass({
        id: `id=${getRandomNumber()}`,
        name: `Targeting group #${demographicGroups.length + 1}`,
      });
      const newDemographicGroups = demographicGroups.concat(newDemographicGroup);
      formik.setFieldValue('targetingProfile.segmentGroups.values', newDemographicGroups);
      setCurrentGroupItem(newDemographicGroup);
      setEditMode(false); // Set current mode to Add new group
      setUnsavedSegments([]); // Reset unsaved segments
    }
    setView('group');
  }, [formik, getCurrentGroupItem, segmentGroups]);

  const renderGroup = useCallback((item) => {
    const items = [
      {
        id: item.id,
        type: 'staleButton',
        label: 'Edit',
        icon: EditIcon,
        callbackFn: ({ cbItem }) => openDemographicGroup({ cbItem }),
      },
      {
        id: item.id,
        type: 'staleButton',
        label: 'Delete',
        icon: DeleteIcon,
        callbackFn: ({ cbItem }) => {
          const filtered = segmentGroups.filter((val) => val.id !== cbItem.id);
          formik.setFieldValue('targetingProfile.segmentGroups.values', filtered);
        },
      },
    ];

    return (
      <div className={ classes.wrapper } key={ `${item.id}-${item.name}` }>
        <Grid container spacing={ 2 }>
          <Grid item xs sm className="figure-flex-start pointer">
            <div
              className={ classes.groupName }
              role="button"
              aria-label="button"
              tabIndex={ 0 }
              onClick={ () => editable && openDemographicGroup({ cbItem: item }) }
              onKeyPress={ () => editable && openDemographicGroup({ cbItem: item }) }
            >
              { item.name }
            </div>
          </Grid>

          <Grid item xs sm className={ classNames('figure-flex-end', classes.groupInfo) }>
            <span>Segments:&nbsp;&nbsp;</span>
            <span>{ item.segments.length }</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;CPM:&nbsp;&nbsp;</span>
            <span>{ textFormat(item.segments.reduce((cur, next) => cur + (next.cpm || next.segment.cpm), 0), 'currency') }</span>
          </Grid>

          <Grid item xs="auto" sm="auto" className="figure-flex-end">
            <div className={ classes.optionsButton }>
              <OptionsButtonNav
                items={ items }
                disabled={ !editable }
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }, [formik, editable, openDemographicGroup, segmentGroups]);

  const renderGroups = useMemo(() => {
    const groups = segmentGroups || [];

    switch (view) {
      case 'group':
        return (
          <div className={ classNames(classes.demographicGroup, 'mt-3', 'mb-6') }>
            <DemographicGroup
              formik={ formik }
              currentGroupItem={ currentGroupItem }
              editMode={ editMode }
              setView={ setView }
              unsavedSegments={ unsavedSegments }
              setUnsavedSegments={ setUnsavedSegments }
            />
          </div>
        );

      case 'add':
        return (
          <div className={ classNames(classes.addDemography, 'mt-3', 'mb-6') }>
            <AddDemography
              formik={ formik }
              currentGroupItem={ currentGroupItem }
              setView={ setView }
              unsavedSegments={ unsavedSegments }
              setUnsavedSegments={ setUnsavedSegments }
            />
          </div>
        );

      default:
        return (
          <>
            <div className={ classes.wrapper }>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 6 } sm={ 6 } className="figure-flex-start">
                  <div
                    className={ classNames(classes.newGroupName, { disabled: !editable }) }
                    role="button"
                    aria-label="button"
                    tabIndex={ 0 }
                    onClick={ () => editable && openDemographicGroup({}) }
                    onKeyPress={ () => editable && openDemographicGroup({}) }
                  >
                    New group
                  </div>
                </Grid>

                <Grid item xs={ 6 } sm={ 6 } className="figure-flex-end">
                  <AddIcon
                    className={ classNames(classes.icon, { disabledIcon: !editable }) }
                    role="button"
                    aria-label="button"
                    onClick={ () => editable && openDemographicGroup({}) }
                    onKeyPress={ () => editable && openDemographicGroup({}) }
                  />
                </Grid>
              </Grid>
            </div>

            { groups.map((item) => renderGroup(item)) }
          </>
        );
    }
  }, [
    formik,
    currentGroupItem,
    editMode,
    editable,
    openDemographicGroup,
    renderGroup,
    segmentGroups,
    unsavedSegments,
    view,
  ]);

  return (
    <StepContainer
      containerClassName={ classes.stepContainer }
      title="Demographic targeting"
      subtitle="Choose age, gender and interest groups"
    >
      { renderGroups }
    </StepContainer>
  );
};

Demographics.defaultProps = { };

Demographics.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      targetingProfile: PropTypes.shape({
        segmentGroups: PropTypes.oneOfType([
          PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              name: PropTypes.string.isRequired,
              segments: PropTypes.arrayOf(PropTypes.shape({})),
            }),
          ),
          PropTypes.any,
        ]),
      }),
    }),
    handleChange: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  editable: PropTypes.bool.isRequired,
};

export default Demographics;
