// CRUD
import crud from 'actions';

// Types
import types from './actionTypes/campaignActionTypes';

/** *********************************************** POST **************************************************** */

/**
 * CREATE Campaign
 */
const createCampaign = (model) => crud.post('campaigns', model, true);

/**
 * CREATE Creative
 */
const createCreative = (formData) => crud.post('creatives', formData, true);

const parseFile = (formData) => crud.post('parse/_lines', formData);

/** ************************************************ GET ****************************************************** */
/**
 * GET list of Campaigns.
 */
const getCampaignList = (params) => crud.get('campaigns', types.GET_CAMPAIGN_LIST, params, true);

/**
 * GET Campaign by ID.
 */
const getCampaignById = (id) => crud.get(`campaigns/${id}`, types.GET_CAMPAIGN_BY_ID, {}, true);

/**
 * GET Creative by ID.
 */
const getCreativeList = (params, withLoading) => crud.get('creatives', types.GET_CREATIVE_LIST, params || {}, withLoading);

/** ************************************************ PUT ****************************************************** */

/**
 * UPDATE Campaign by ID.
 */
const updateCampaign = (model) => crud.put(`campaigns/${model.id}`, model, true);

/**
 * UPDATE Campaign by ID.
 */
const updateCampaignStatus = (ids, status) => crud.put(`campaigns/${ids}/status`, JSON.stringify(status), true);

/**
 * Set filters
 */
const setFilters = (filters) => (dispatch) => dispatch({
  type: types.SET_FILTERS,
  payload: filters,
});

/** ************************************************ Clear Store ****************************************************** */

/**
 * CLEAR Campaign(s).
 */
const clearCampaignList = () => crud.clear(types.GET_CAMPAIGN_LIST, { payload: [], _meta: { page: 0, size: 0, total: 0 } });
const clearCampaignById = () => crud.clear(types.GET_CAMPAIGN_BY_ID, {});

const addCreative = (creative) => ({
  type: types.ADD_CREATIVE,
  payload: creative,
});

const removeCreative = (creative) => ({
  type: types.REMOVE_CREATIVE,
  payload: creative,
});

/** ************************************************ EXPORTS ****************************************************** */
export default {
  createCampaign,
  createCreative,
  getCampaignList,
  getCampaignById,
  getCreativeList,
  updateCampaign,
  updateCampaignStatus,
  addCreative,
  removeCreative,
  parseFile,
  setFilters,
  // Clear
  clearCampaignList,
  clearCampaignById,
};
