import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Tab } from '@material-ui/core';

// Icons
import { ReactComponent as DotIcon } from 'assets/images/icons/dot.svg';

// Styles
import classes from './AltTab.module.scss';

const AltTab = (props) => {
  const { label, className, disableRipple, showDot, variant, disabled, ...TabProps } = props;

  const getCustomClasses = (val) => {
    switch (val) {
      case 1:
        return { root: classes.rootAddCampaign, labelIcon: classes.labelIconAddCampaign };
      default:
        return { root: classes.root, labelIcon: classes.labelIcon };
    }
  };

  const customClasses = getCustomClasses(variant);

  return (
    <Tab
      { ...TabProps }
      disabled={ disabled }
      label={ label }
      classes={ customClasses }
      className={ className }
      disableRipple={ disableRipple }
      icon={ showDot && <DotIcon className={ classes.icon } /> }
    />
  );
};

AltTab.defaultProps = {
  className: '',
  disableRipple: false,
  showDot: false,
  variant: 0,
  disabled: false,
};

AltTab.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disableRipple: PropTypes.bool,
  showDot: PropTypes.bool,
  variant: PropTypes.number,
  disabled: PropTypes.bool,
};

export default AltTab;
