import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { Button } from '@material-ui/core';

// Formik
import { Formik, Field } from 'formik';

// Modules
import ClearableInput from 'modules/_Factories/Input/ClearableInput/ClearableInput';
import InputPassword from 'modules/_Factories/Input/InputPassword/InputPassword';

// Hooks
import useLoginForm from './useLoginForm';

// Styles
import classes from './LoginForm.module.scss';

const LoginForm = ({ className, login }) => {
  const { initialValues, validate, submitHandler } = useLoginForm(login);

  return (
    <Formik initialValues={ initialValues } validate={ validate } onSubmit={ submitHandler }>
      {({ handleSubmit, isSubmitting, errors, touched, setFieldValue }) => (
        <form onSubmit={ handleSubmit } className={ classNames(className) }>
          <div className={ classes.fieldWrapper }>
            <Field
              errors={ errors }
              touched={ touched }
              name="email"
              as={ ClearableInput }
              labelBackgroundColor="#fff"
              onClear={ () => setFieldValue('email', '') }
              fullWidth
              label="Email or login"
            />
            <Field
              errors={ errors }
              touched={ touched }
              name="password"
              as={ InputPassword }
              labelBackgroundColor="#fff"
              fullWidth
              label="Password"
            />
          </div>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            type="submit"
            size="large"
            disabled={ isSubmitting }
          >
            SIGN IN
          </Button>
        </form>
      )}
    </Formik>
  );
};

LoginForm.defaultProps = { className: '' };

LoginForm.propTypes = {
  className: PropTypes.string,
  login: PropTypes.func.isRequired,
};

export default LoginForm;
