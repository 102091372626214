import { createMuiTheme } from '@material-ui/core/styles';

const pxToRem = (px, isImportant) => `${px / 16}rem ${isImportant ? '!important' : ''}`;

const palette = {
  primary: { main: '#0092DD' },
  secondary: {
    main: '#B7D317',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#f44336',
    contrastText: '#FFFFFF',
  },
};

const theme = createMuiTheme({
  palette,
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    htmlFontSize: 16,
  },
  overrides: {
    MuiInputBase: { root: { lineHeight: '1.375rem' } },
    MuiInputLabel: {
      root: { color: '#BBBBBB' },
      outlined: {
        transform: 'translate(22px, 20px) scale(1)',
        '&$shrink': {
          transform: 'translate(20px, -6px) scale(0.75)',
          color: '#888888',
          textTransform: 'uppercase',
        },
        '&.Mui-error': { color: '#f44336' },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: pxToRem(11),
        height: pxToRem(56),
        '& legend': { marginLeft: '6px' },
      },
      input: {
        fontSize: pxToRem(18),
        padding: pxToRem(17),
        paddingLeft: pxToRem(20),
        paddingRight: pxToRem(20),
      },
      notchedOutline: { borderWidth: '1px !important' },
      multiline: { height: 'unset' },
    },
    MuiInputAdornment: {
      positionEnd: {
        position: 'relative',
        borderRadius: '0 11px 11px 0',
        height: 'auto',
        width: pxToRem(56),
        alignSelf: 'stretch',
        maxHeight: 'none',
        marginRight: pxToRem(-14),
        marginLeft: pxToRem(20),
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        top: '100%',
        textTransform: 'none',
        marginTop: 0,
      },
    },
    MuiTypography: {
      h2: {
        fontWeight: 800,
        fontSize: pxToRem(30),
        lineHeight: pxToRem(36),
        textTransform: 'capitalize',
        fontStyle: 'normal',
      },
      h3: {
        fontWeight: 700,
        fontSize: pxToRem(24),
        lineHeight: pxToRem(29),
      },
      h4: {
        fontWeight: 600,
        fontSize: pxToRem(20),
        lineHeight: pxToRem(24),
      },
      h5: {
        fontWeight: 500,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(22),
      },
      h6: {
        fontWeight: 'bold',
        fontSize: pxToRem(14),
        lineHeight: pxToRem(17),
      },
    },
    MuiButton: {
      root: { textTransform: 'none' },
      outlined: { color: '#BBBBBB' },
      text: { color: '#BBBBBB' },
      sizeLarge: {
        padding: '17px 35px',
        minWidth: '7.5rem',
        fontWeight: 600,
        height: pxToRem(56),
        fontSize: pxToRem(18),
        lineHeight: pxToRem(22),
        borderRadius: pxToRem(11),
      },
      containedPrimary: {
        color: '#fff',
        backgroundColor: '#B7D317',
        borderRadius: pxToRem(11),
        textTransform: 'none',
        padding: '17px 35px',
        fontWeight: 600,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(22),
        '&:hover': { backgroundColor: '#B7D317' },
      },
      containedSecondary: {
        color: '#fff',
        backgroundColor: '#f44336',
        borderRadius: pxToRem(11),
        textTransform: 'none',
        padding: '17px 35px',
        fontWeight: 600,
        fontSize: pxToRem(18),
        lineHeight: pxToRem(22),
        '&:hover': { backgroundColor: '#f44336' },
      },
    },
    MuiMenu: {
      paper: { borderRadius: '16px', boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.12)' },
      list: { padding: '20px 0' },
    },
    MuiMenuItem: {
      root: {
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '30px',
        paddingRight: '30px',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        '&:focus': { backgroundColor: 'transparent' },
        '&$selected': {
          backgroundColor: 'transparent',
          fontWeight: 600,
          color: '#0092dd',
          '&:hover': { backgroundColor: 'transparent' },
        },
      },
    },
  },
});

export default theme;
