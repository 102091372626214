import { createSelector } from 'reselect';

const categories = (state) => state.dictionary.categories;
const sizes = (state) => state.dictionary.sizes;
const countries = (state) => state.dictionary.countries;
const DMAs = (state) => state.dictionary.DMAs;
const demographics = (state) => state.dictionary.demographics;
const filteredStates = (state) => state.dictionary.filteredStates;
const filteredCities = (state) => state.dictionary.filteredCities;
const filteredCounties = (state) => state.dictionary.filteredCounties;
const filteredCongressionalDistricts = (state) => state.dictionary.filteredCongressionalDistricts;

const getAllCategories = (array) => {
  const formattedCategories = array.reduce((accum, cur) => {
    const subcategories = cur?.children?.length ? getAllCategories(cur.children) : [];
    return [...accum, { id: cur.id, name: `${cur.name} (${cur.id})`, parent: Boolean(cur?.children?.length) }, ...subcategories];
  }, []);
  return formattedCategories;
};

const categoriesSelector = createSelector(
  categories,
  getAllCategories,
);

const sizesSelector = createSelector(
  sizes,
  (items) => items.map((item) => ({
    ...item,
    id: `${item.width}X${item.height} ${item.name}`,
    name: `${item.width} X ${item.height} ${item.name}`,
  })),
);

const countriesSelector = createSelector(
  countries,
  (items) => items.map((item) => ({
    ...item,
    name: `${item.name} (${item.alpha3})`,
    id: item.id,
  })),
);

const DMAsSelector = createSelector(
  DMAs,
  (items) => items.map((item) => ({
    ...item,
    name: `${item.name} (${item.code})`,
    id: item.code,
  })),
);

const demographicsSelector = createSelector(
  demographics,
  (items) => items.map((item) => ({
    ...item,
    path: item.name,
  })),
);

const filteredStatesSelector = createSelector(
  filteredStates,
  (items) => items.map((item) => ({
    ...item,
    name: `${item.name} (${item.id})`,
    id: item.id,
  })),
);

const filteredCitiesSelector = createSelector(
  filteredCities,
  (items) => items.map((item) => ({
    ...item,
    name: `${item.name} (${item.state ? item.state.name : item.code})`,
    id: item.code,
  })),
);

const filteredCountiesSelector = createSelector(
  filteredCounties,
  (items) => items.map((item) => ({
    ...item,
    name: `${item.name} (${item.id})`,
    id: item.id,
  })),
);

const filteredCongressionalDistrictsSelector = createSelector(
  filteredCongressionalDistricts,
  (items) => items.map((item) => ({
    ...item,
    name: item.id,
    id: item.id,
  })),
);

export default {
  countriesSelector,
  categoriesSelector,
  sizesSelector,
  DMAsSelector,
  demographicsSelector,
  filteredStatesSelector,
  filteredCitiesSelector,
  filteredCountiesSelector,
  filteredCongressionalDistrictsSelector,
};
