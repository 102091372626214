import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@material-ui/core';
import classNames from 'classnames';
import classes from './MainTab.module.scss';

const MainTab = ({ label, size, className, disableRipple, ...TabProps }) => {
  const customClasses = { root: classes.root, selected: classes.selected };

  const customization = classNames(className, { [classes.sizeSmall]: size === 'small' });

  return (
    <Tab
      { ...TabProps }
      label={ label }
      classes={ customClasses }
      className={ customization }
      disableRipple={ disableRipple }
    />
  );
};

MainTab.defaultProps = {
  className: '',
  disableRipple: false,
  size: null,
};

MainTab.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disableRipple: PropTypes.bool,
  size: PropTypes.oneOf(['small']),
};

export default MainTab;
