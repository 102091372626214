import React, { memo, useState, useMemo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@material-ui/core';

// Modules
import { AltTab, AltTabs } from 'modules/Tabs/AltTabs';
import { a11yProps } from 'modules/Tabs';
import { TabPanel } from 'modules/Tabs/TabPanel';
import StepTransitions from 'modules/Steps/StepTransitions';

// Hooks
import useStepMenu from 'hooks/useStepMenu';

// Steps
import General from './General';
import Options from './Options';

// Styles
import classes from './Steps.module.scss';
import Details from './Details';

const getMenuSteps = (isEditForm) => [
  { id: 0, name: 'General', touched: true, valid: isEditForm, visible: true },
  { id: 1, name: 'Creative', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 2, name: 'Details', touched: isEditForm, valid: isEditForm, visible: true },
];

const Steps = ({ formik, isEditForm }) => {
  const [activeTab, setActiveTab] = useState(0);
  const menuStepRef = useRef(getMenuSteps(isEditForm));
  const { steps, setValuesStep, stepHasErrors, stepIsDisabled } = useStepMenu(menuStepRef.current);

  const stepFields = useMemo(() => [
    ['name', 'subType', 'advertiser', 'status'],
    ['dataType', 'url', 'sizeType', 'size.width', 'size.height'],
    ['categories', 'languages', 'pageUrl'],
  ], []);

  const hasErrorsActiveTab = stepHasErrors(stepFields[activeTab], formik.errors);

  const handleChangeTab = useCallback((index) => {
    setActiveTab(index);
    setValuesStep(index, { touched: true });
  }, [setActiveTab, setValuesStep]);

  const handleNextStep = useCallback(() => {
    if (steps[activeTab].valid) handleChangeTab(activeTab + 1);
    stepFields[activeTab].forEach((name) => formik.setFieldTouched(name, true));
  }, [steps, activeTab, handleChangeTab, stepFields, formik]);

  const handlePrevStep = useCallback(() => {
    handleChangeTab(activeTab - 1);
  }, [handleChangeTab, activeTab]);

  useEffect(() => {
    menuStepRef.current = getMenuSteps(isEditForm);
  }, [isEditForm]);

  useEffect(() => {
    setValuesStep(activeTab, { valid: !hasErrorsActiveTab });
  }, [activeTab, hasErrorsActiveTab, setValuesStep]);

  const getTabClass = useCallback((item, index) => {
    if (index === activeTab) return classes.active;
    if (item.valid && item.touched) return classes.valid;
    return null;
  }, [activeTab]);

  return (
    <div>
      <AppBar position="relative" color="inherit" elevation={ 0 } className={ classes.bar }>
        <AltTabs value={ activeTab } onChange={ (e, index) => handleChangeTab(index) } variant={ 1 }>
          { steps.map((item, i) => (
            <AltTab
              key={ item.id }
              disableRipple
              label={ item.name }
              disabled={ (hasErrorsActiveTab && i > activeTab) || stepIsDisabled(i) }
              variant={ 1 }
              className={ getTabClass(item, i) }
              { ...a11yProps(item.id) }
            />
          )) }
        </AltTabs>
      </AppBar>

      <TabPanel value={ activeTab } index={ 0 }>
        <General formik={ formik } isEditForm={ isEditForm } />
      </TabPanel>
      <TabPanel value={ activeTab } index={ 1 }>
        <Options formik={ formik } isEditForm={ isEditForm } />
      </TabPanel>
      <TabPanel value={ activeTab } index={ 2 }>
        <Details formik={ formik } isEditForm={ isEditForm } />
      </TabPanel>
      <StepTransitions
        handleNext={ handleNextStep }
        handlePrev={ handlePrevStep }
        hidePrevButton={ activeTab === 0 }
        hideNextButton={ activeTab === steps.length - 1 }
      />
    </div>
  );
};

Steps.defaultProps = { isEditForm: false };

Steps.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
  }).isRequired,
  isEditForm: PropTypes.bool,
};

export default memo(Steps);
