import React, { memo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import usePermissions from 'hooks/usePermissions';

// Material
import { Grid, Tooltip } from '@material-ui/core';

// Dictionaries
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';

// Modules
import LinearProgress from 'modules/LinearProgress/LinearProgress';
import StatusTableCell from 'modules/_Table/TableCell/StatusTableCell/StatusTableCell';
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';
import MaterialTablesSortIcon from 'modules/_MaterialTable/MaterialTablesSortIcon/MaterialTablesSortIcon';
import MaterialTableContainer from 'modules/_MaterialTable/MaterialTableContainer/MaterialTableContainer';
import OptionsButtonNav from 'modules/OptionsButtonNav/OptionsButtonNav';
import MaterialTableToolbar from 'modules/_MaterialTable/MaterialTableToolbar/MaterialTableToolbar';
import MaterialTableAjaxPagination from 'modules/_MaterialTable/MaterialTableAjaxPagination/MaterialTableAjaxPagination';
import MaterialTableBody from 'modules/_MaterialTable/MaterialTableBody/MaterialTableBody';
import TableCell from 'modules/_Table/TableCell/TableCell';

// Libs
import { textFormat } from 'libs/textFormat';
import { getPercentValue } from 'libs/getPercentValue';
import { hasPermission } from 'libs/storageLibs';
import { keyBy } from 'lodash';

// Icons
import { ReactComponent as EditIcon } from 'assets/images/icons/optionsEdit.svg';
import { ReactComponent as PlayIcon } from 'assets/images/icons/optionsPlay.svg';
import { ReactComponent as PauseIcon } from 'assets/images/icons/optionsPause.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/optionsClose.svg';
import { ReactComponent as ApproveIcon } from 'assets/images/icons/optionsApprove.svg';
import { ReactComponent as CopyIcon } from 'assets/images/icons/copy.svg';
import WarningIcon from '@material-ui/icons/Warning';

// Styles
import classes from './CampaignTable.module.scss';

const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

const CampaignTable = ({ getCampaignList, meta, tableRef, changeCampaignStatus }) => {
  const match = useRouteMatch();
  const permissions = usePermissions();

  // const shortAdvertiserListHashName = buildHashMap(shortAdvertiserList); shortAdvertiserList is came from props
  const campaignTypesHashMap = keyBy(CampaignTypes.dictionary, 'id');

  // State
  const [editable] = useState(hasPermission([UserPermissions.types.CAMPAIGN_WRITE]));
  const [approval] = useState(hasPermission([UserPermissions.types.CAMPAIGN_APPROVAL]));

  const getOptionsItems = (rowData) => {
    const items = [];
    const isProgrammaticDirect = rowData.systemType === CampaignTypes.types.PROGRAMMATIC_DIRECT;

    items.push({
      to: {
        pathname: `${match.url}/create/${campaignTypesHashMap[rowData.systemType]?.link || ''}`,
        state: { id: rowData.id },
      },
      type: 'staleButton',
      label: 'Copy',
      icon: CopyIcon,
    });

    switch (rowData.status) {
      case CampaignStatuses.types.PENDING_PUBLISHER_REVIEW:
        items.push({
          type: 'button',
          label: 'Cancel',
          icon: CloseIcon,
          onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.CANCELED),
        });
        break;
      case CampaignStatuses.types.DRAFT:
      case CampaignStatuses.types.DECLINED:
        items.push({ to: null, type: 'staleButton', label: editable ? 'Edit' : 'Show', icon: EditIcon });

        if (approval) {
          const status = isProgrammaticDirect ? CampaignStatuses.types.PENDING_PUBLISHER_REVIEW : CampaignStatuses.types.APPROVED;
          const label = isProgrammaticDirect ? 'Send to Publisher' : 'Approve';

          items.push(
            {
              type: 'button',
              label,
              icon: ApproveIcon,
              onClick: () => changeCampaignStatus(rowData, status),
            },
          );
        } else {
          items.push(
            {
              type: 'button',
              label: 'Request approval',
              icon: ApproveIcon,
              onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.PENDING),
            },
          );
        }
        break;

      case CampaignStatuses.types.APPROVED:
        items.push({ to: null, type: 'staleButton', label: editable ? 'Edit' : 'Show', icon: EditIcon });
        items.push({
          type: 'button',
          label: 'Run',
          icon: PlayIcon,
          onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.RUNNING),
        });
        items.push({
          type: 'button',
          label: 'Cancel',
          icon: CloseIcon,
          onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.CANCELED),
        });
        break;

      case CampaignStatuses.types.PENDING:
        items.push({ to: null, type: 'staleButton', label: editable ? 'Edit' : 'Show', icon: EditIcon });
        if (approval) {
          const status = isProgrammaticDirect ? CampaignStatuses.types.PENDING_PUBLISHER_REVIEW : CampaignStatuses.types.APPROVED;
          const label = isProgrammaticDirect ? 'Send to Publisher' : 'Approve';

          items.push(
            {
              type: 'button',
              label,
              icon: ApproveIcon,
              onClick: () => changeCampaignStatus(rowData, status),
            },
          );
          // items.push({
          //   type: 'button',
          //   label: 'Decline',
          //   icon: CloseIcon,
          //   onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.DECLINED),
          // });
        }
        items.push({
          type: 'button',
          label: 'Cancel',
          icon: CloseIcon,
          onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.CANCELED),
        });
        break;

      case CampaignStatuses.types.RUNNING:
        items.push({ to: null, type: 'staleButton', label: 'Show', icon: EditIcon });
        items.push({
          type: 'button',
          label: 'Pause',
          icon: PauseIcon,
          onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.PAUSED),
        });
        items.push({
          type: 'button',
          label: 'Cancel',
          icon: CloseIcon,
          onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.CANCELED),
        });
        break;

      case CampaignStatuses.types.PAUSED:
        items.push({ to: null, type: 'staleButton', label: 'Show', icon: EditIcon });
        items.push({
          type: 'button',
          label: 'Play',
          icon: PlayIcon,
          onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.RUNNING),
        });
        items.push({
          type: 'button',
          label: 'Cancel',
          icon: CloseIcon,
          onClick: () => changeCampaignStatus(rowData, CampaignStatuses.types.CANCELED),
        });
        break;

      case CampaignStatuses.types.CLOSED:
      case CampaignStatuses.types.FINISHED:
      case CampaignStatuses.types.CANCELED:
        items.push({ to: null, type: 'staleButton', label: 'Show', icon: EditIcon });
        break;

      default:
        break;
    }

    return items;
  };

  const columns = [
    {
      title: 'ID',
      field: 'id',
      width: 120,
      minWidth: 120,
      render: (rowData) => (
        <MaterialTableText variant="light">
          <div className={ classes.warningWrapper }>
            {rowData.advertiserBalanceRate && rowData.advertiserBalanceRate < 1 && (
              <Tooltip title="Advertiser balance is not sufficient for all running campaigns">
                <WarningIcon className={ classes.warningIcon } />
              </Tooltip>
            )}
            <span>{rowData.id}</span>
          </div>
        </MaterialTableText>
      ),
    },
    {
      title: 'NAME',
      field: 'name',
      defaultSort: 'asc',
      cellStyle: { wordBreak: 'break-all' },
      render: (rowData) => (
        <Link to={ `${match.url}/${rowData.id}` } className="link">
          <MaterialTableText>
            { rowData.name }
          </MaterialTableText>
        </Link>

      ),
    },
    {
      title: 'STATUS',
      field: 'status',
      minWidth: 120,
      width: 120,
      render: (rowData) => <StatusTableCell statusId={ rowData.status } statusName={ CampaignStatuses.hashMap[rowData.status] } />,
    },
    {
      title: 'ADVERTISER',
      field: 'advertiser.name',
      width: 160,
      minWidth: 160,
      hidden: !permissions.advertiserRead,
      render: (rowData) => (
        <Link to={ `advertisers/${rowData.advertiser.id}` } className="link">
          <TableCell firstRow={ rowData.advertiser.name } />
        </Link>
      ),
    },
    {
      title: 'CREATIVES',
      field: 'creativesCount',
      width: 60,
      minWidth: 60,
      hidden: !permissions.creativeRead,
      render: (rowData) => (
        <Link to={ `creatives?campaigns=${rowData.id}` } className="link">
          <TableCell firstRow={ rowData.creativesCount || 0 } />
        </Link>
      ),
    },
    {
      title: 'PACING RATE',
      field: 'pacingRate',
      width: 170,
      minWidth: 170,
      render: (rowData) => (
        <MaterialTableText>
          { textFormat(rowData.pacingRate || 0, 'totalPercent') }
        </MaterialTableText>
      ),
    },
    {
      title: 'BUDGET',
      field: 'spendAmount',
      width: 180,
      minWidth: 180,
      render: (rowData) => (
        <div>
          <Grid container wrap="nowrap" spacing={ 1 }>
            <Grid item md className={ classes.budgetSpend }>{ textFormat((rowData.spendAmount || 0), 'currency') }</Grid>
            <Grid item md className={ classes.budgetTarget }>{ textFormat((rowData.budgetTarget || 0), 'currency') }</Grid>
          </Grid>

          <LinearProgress
            className="mt-1"
            variant="determinate"
            color="secondary"
            value={ getPercentValue(rowData.spendAmount, rowData.budgetTarget) }
          />
        </div>
      ),
    },
    {
      title: '',
      field: 'options',
      sorting: false,
      width: 24,
      minWidth: 24,
      cellStyle: { textAlign: 'right' },
      render: (rowData) => (
        <OptionsButtonNav
          defaultTo={ `${match.url}/${rowData.id}` }
          items={ getOptionsItems(rowData) }
        />
      ),
    },
  ];

  return (
    <MaterialTable
      columns={ columns }
      tableRef={ tableRef }
      data={ getCampaignList }
      localization={ { body: { emptyDataSourceMessage: <h4>Sorry, we couldn&apos;t find any results matching.</h4> } } }
      options={ {
        draggable: false,
        pageSizeOptions: ROWS_PER_PAGE_OPTIONS,
        pageSize: meta?.size || ROWS_PER_PAGE_OPTIONS[0],
      } }
      icons={ { SortArrow: MaterialTablesSortIcon } }
      components={ {
        Toolbar: (props) => <MaterialTableToolbar tableRef={ tableRef } entityName="Campaigns" { ...props } />,
        Pagination: MaterialTableAjaxPagination,
        Container: MaterialTableContainer,
        Body: MaterialTableBody,
      } }
    />
  );
};

CampaignTable.propTypes = {
  tableRef: PropTypes.objectOf(PropTypes.object).isRequired,
  getCampaignList: PropTypes.func.isRequired,
  changeCampaignStatus: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    perPage: PropTypes.number,
    total: PropTypes.number,
    size: PropTypes.number,
  }).isRequired,

};

export default memo(CampaignTable);
