import React from 'react';
import { Typography } from '@material-ui/core';
import Link from 'modules/_Router/RouterLink/RouterLink';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import classes from './ForgotPassword.module.scss';
import useForgotPassword from './useForgotPassword';

const ForgotPassword = () => {
  const { resetPassword } = useForgotPassword();

  return (
    <>
      <Typography variant="h2" className={ classes.title }>
        Forgot Password
      </Typography>

      <ForgotPasswordForm resetPassword={ resetPassword } className={ classes.form } />

      <div className={ classes.link }>
        <Link to="/login">Back to login</Link>
      </div>
    </>
  );
};

export default ForgotPassword;
