import { Button, Input, Slider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import classes from './CropImage.module.scss';
import getCroppedImg from './utils';

const getDefaultCropSize = (value) => {
  if (value && value.width && value.height) return value;
  return { width: 100, height: 100 };
};

const CropImage = ({ onSave, imgUrl, defaultCropSize }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState(getDefaultCropSize(defaultCropSize));
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [mediaSize, setMediaSize] = useState({ width: 0, height: 0, naturalWidth: 0, naturalHeight: 0 });

  const onCropComplete = useCallback((newCroppedArea, newCroppedAreaPixels) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  }, []);

  const onMediaLoaded = useCallback((size) => {
    setMediaSize(size);
  }, [setMediaSize]);

  const handleChangeCropSize = useCallback((value) => {
    setCropSize((prevValue) => ({
      ...prevValue,
      ...value,
    }));
  }, [setCropSize]);

  const getCroppedImage = async () => {
    const croppedImage = await getCroppedImg(
      imgUrl,
      croppedAreaPixels,
      rotation,
      cropSize,
    );
    return croppedImage;
  };

  const handleSave = async () => {
    const croppedImgUrl = await getCroppedImage();
    onSave(croppedImgUrl);
  };

  return (
    <div className={ classes.container }>
      <div className={ classes.imageContainer }>
        <Cropper
          image={ imgUrl }
          crop={ crop }
          rotation={ rotation }
          zoom={ zoom }
          cropSize={ cropSize }
          onCropChange={ setCrop }
          onRotationChange={ setRotation }
          onCropComplete={ onCropComplete }
          onMediaLoaded={ onMediaLoaded }
          onZoomChange={ setZoom }
        />
      </div>

      <div className={ classes.controlsContainer }>
        <div className={ classes.sliderContainer }>
          <Typography
            variant="overline"
            classes={ { root: classes.sliderLabel } }
          >
            Zoom
          </Typography>
          <Slider
            value={ zoom }
            min={ 0.1 }
            max={ 3 }
            step={ 0.1 }
            aria-labelledby="Zoom"
            classes={ { root: classes.slider } }
            onChange={ (e, zoomValue) => setZoom(zoomValue) }
          />
        </div>
        <div className={ classes.sliderContainer }>
          <Typography
            variant="overline"
            classes={ { root: classes.sliderLabel } }
          >
            Rotation
          </Typography>
          <Slider
            value={ rotation }
            min={ 0 }
            max={ 360 }
            step={ 1 }
            aria-labelledby="Rotation"
            classes={ { root: classes.slider } }
            onChange={ (e, rotationValue) => setRotation(rotationValue) }
          />
        </div>
        <div className={ classes.sliderContainer }>
          <Typography
            variant="overline"
            classes={ { root: classes.sliderLabel } }
          >
            Width
          </Typography>
          <div className={ classes.sliderInput }>
            <Slider
              value={ cropSize.width }
              min={ 0 }
              max={ mediaSize.naturalWidth }
              step={ 1 }
              aria-labelledby="Width"
              classes={ { root: classes.slider } }
              onChange={ (e, width) => handleChangeCropSize({ width }) }
            />

            <Input
              name="width"
              type="number"
              className={ classes.inputWrapper }
              value={ cropSize.width || '' }
              fullWidth
              onChange={ (e) => handleChangeCropSize({ [e.target.name]: Math.min(+e.target.value || 0, mediaSize.naturalWidth) }) }
            />

          </div>
        </div>
        <div className={ classes.sliderContainer }>
          <Typography
            variant="overline"
            classes={ { root: classes.sliderLabel } }
          >
            Height
          </Typography>

          <div className={ classes.sliderInput }>
            <Slider
              value={ cropSize.height }
              min={ 0 }
              max={ mediaSize.naturalHeight }
              step={ 1 }
              aria-labelledby="Height"
              classes={ { root: classes.slider } }
              onChange={ (e, height) => handleChangeCropSize({ height }) }
            />

            <Input
              name="height"
              type="number"
              className={ classes.inputWrapper }
              value={ cropSize.height || '' }
              fullWidth
              onChange={ (e) => handleChangeCropSize({ [e.target.name]: Math.min(+e.target.value || 0, mediaSize.naturalHeight) }) }
            />

          </div>
        </div>

        <Button variant="outlined" color="primary" onClick={ handleSave }>Save</Button>
      </div>
    </div>
  );
};

CropImage.defaultProps = {
  defaultCropSize: null,
  onSave: Function.prototype,
};

CropImage.propTypes = {
  onSave: PropTypes.func,
  imgUrl: PropTypes.string.isRequired,
  defaultCropSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default memo(CropImage);
