import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import CreativeList from './routes/CreativeList';
import AddCreative from './routes/AddCreative';
import EditCreative from './routes/EditCreative';

const Creatives = ({ match }) => (
  <Switch>
    <Route exact path={ match.url } component={ CreativeList } />
    <Route exact path={ `${match.url}/add` } component={ AddCreative } />
    <Route exact path={ `${match.url}/:id` } component={ EditCreative } />
  </Switch>
);

Creatives.propTypes = { match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired };

export default memo(Creatives);
