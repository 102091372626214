import types from 'actions/actionTypes/campaignActionTypes';
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';

export const INITIAL_FILTERS = {
  advertiser: [],
  search: '',
  status: [],
  creative: [],
};

export const DEFAULT_FILTERS = {
  ...INITIAL_FILTERS,
  status: [CampaignStatuses.types.RUNNING],
};

const initialState = {
  campaignList: {
    payload: [],
    _meta: {
      page: 0,
      size: 0,
      total: 0,
    },
  },
  campaign: {},
  creativeList: [],
  filters: DEFAULT_FILTERS,
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.payload,
      };

    case types.GET_CAMPAIGN_BY_ID:
      return {
        ...state,
        campaign: action.payload,
      };

    case types.GET_CREATIVE_LIST:
      return {
        ...state,
        creativeList: action.payload.payload,
      };

    case types.ADD_CREATIVE:
      return {
        ...state,
        creativeList: [...state.creativeList, action.payload],
      };

    case types.REMOVE_CREATIVE:
      return {
        ...state,
        creativeList: state.creativeList.filter((item) => item !== action.payload.id),
      };

    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    default:
      return state;
  }
};

export default campaignReducer;
