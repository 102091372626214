import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';

// Icons
import { ReactComponent as ArrowLeft } from 'assets/images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/arrowRight.svg';

// Styles
import classes from './StepTransitions.module.scss';

const StepTransitions = ({
  handlePrev,
  handleNext,
  hidePrevButton,
  hideNextButton,
}) => (
  <div className={ classes.tabStepper }>
    <Button
      size="medium"
      className={ classNames(classes.button, { [classes.hidden]: hidePrevButton }) }
      onClick={ handlePrev }
    >
      <ArrowLeft className="mr-2" />
      <span>Prev</span>
    </Button>
    <Button
      size="medium"
      className={ classNames(classes.button, { [classes.hidden]: hideNextButton }) }
      onClick={ handleNext }
    >
      <span>Next</span>
      <ArrowRight className="ml-2" />
    </Button>
  </div>
);

StepTransitions.defaultProps = {
  hidePrevButton: false,
  hideNextButton: false,
  handlePrev: Function.prototype,
  handleNext: Function.prototype,
};

StepTransitions.propTypes = {
  hidePrevButton: PropTypes.bool,
  hideNextButton: PropTypes.bool,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
};

export default memo(StepTransitions);
