// CRUD
import crud from 'actions';

// Types
import types from './actionTypes/shortListActionTypes';

/**
 * GET SHORT list of ad units.
 */
const getShortAdUnitList = (params) => crud.get('short-info/adunits', types.GET_AD_UNIT_LIST, { size: 10000, ...params }, false);

/**
 * GET SHORT list of advertisers.
 */
const getShortAdvertiserList = (params) => crud.get('short-info/advertisers', types.GET_ADVERTISER_LIST, { size: 10000, ...params }, false);

/**
 * GET SHORT list of campaigns.
 */
const getShortCampaignList = (params) => crud.get('short-info/campaigns', types.GET_CAMPAIGN_LIST, { size: 10000, ...params }, false);

/**
 * GET SHORT list of creatives.
 */
const getShortCreativeList = (params) => crud.get('short-info/creatives', types.GET_CREATIVE_LIST, { size: 10000, ...params }, false);

/**
 * GET SHORT list of deals.
 */
const getShortDealList = (params) => crud.get('short-info/deals', types.GET_DEAL_LIST, { size: 10000, ...params }, false);

/**
 * GET SHORT list of dsps.
 */
const getShortDemandPartnerList = (params) => crud.get('short-info/dsps', types.GET_DSP_LIST, { size: 10000, ...params }, false);

/**
 * GET SHORT list of inventories.
 */
const getShortSiteAppList = (params) => crud.get('short-info/inventories', types.GET_INVENTORY_LIST, { size: 10000, ...params }, false);

/**
 * GET SHORT list of roles.
 */
const getShortRoleList = (params) => crud.get('short-info/roles', types.GET_ROLE_LIST, params || {}, false);

/**
 * GET SHORT list of ssps.
 */
const getShortSupplySourceList = (params) => crud.get('short-info/ssps', types.GET_SSP_LIST, { size: 10000, ...params }, false);

/**
 * GET SHORT list of supply partners.
 */
const getShortSupplyPartnerList = (params) => crud.get(
  'short-info/ssps',
  types.GET_SUPPLY_PARTNER_LIST,
  Object.assign(params || {}, { size: 10000, type: 'EXCHANGE' }),
  false,
);

/**
 * GET SHORT list of users.
 */
const getShortUserList = (params) => crud.get('short-info/users', types.GET_USER_LIST, { size: 10000, ...params }, false);

/**
 * GET SHORT dsps embedded.
 */
const getShortDSPEmbedded = (params) => crud.get('short-info/dsps/embedded', types.GET_DSP_EMBEDDED, params, false);

export default {
  getShortAdUnitList,
  getShortAdvertiserList,
  getShortCampaignList,
  getShortCreativeList,
  getShortDealList,
  getShortDemandPartnerList,
  getShortSiteAppList,
  getShortRoleList,
  getShortSupplySourceList,
  getShortSupplyPartnerList,
  getShortUserList,
  getShortDSPEmbedded,
};
