export class CampaignPricingStrategyClass {
  cpm = '';
  dimensions = [];
  dspFixedBidShavingFactor = '';
  dspMargin = '';
  dspMarginAsFee = null;
  dspMaxBidShavingFactor = '';
  dspMinBidShavingFactor = '';
  dspPricingStrategy = '';
  maxCpm = '';
  targetMetric = '';
  targetRate = '';
  type = 'FIXED_CPM';

  constructor(item) {
    this.cpm = item?.cpm ?? null;
    this.dimensions = item?.dimensions || [];
    this.dspFixedBidShavingFactor = item?.dspFixedBidShavingFactor || null;
    this.dspMargin = item?.dspMargin || null;
    this.dspMarginAsFee = item?.dspMarginAsFee || null;
    this.dspMaxBidShavingFactor = item?.dspMaxBidShavingFactor || null;
    this.dspMinBidShavingFactor = item?.dspMinBidShavingFactor || null;
    this.dspPricingStrategy = item?.dspPricingStrategy || null;
    this.maxCpm = item?.maxCpm || null;
    this.targetMetric = item?.targetMetric || null;
    this.targetRate = item?.targetRate || null;
    this.type = item?.type || 'FIXED_CPM';
  }
}
