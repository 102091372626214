import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import classes from './CreativeListTitle.module.scss';
import AddCreativeButton from './AddCreativeButton';

const CreativeListTitle = ({ title, selectedLength, allowAddCreative, addCreative, creativeInitialValues }) => (
  <Grid container spacing={ 4 } justify="space-between" className={ classes.title }>
    <Grid item>
      <Typography variant="h6" className={ classes.titleText }>{ title }</Typography>
      <span className={ classes.subtitleText }>{ `${selectedLength} creative(s) uploaded` }</span>
    </Grid>
    {allowAddCreative && (
      <Grid item>

        <AddCreativeButton onClick={ addCreative } creativeInitialValues={ creativeInitialValues } />
        {/* <Button variant="contained" color="secondary" onClick={ addCreative }>
          Add Creative
        </Button> */}
      </Grid>
    )}
  </Grid>
);

CreativeListTitle.defaultProps = {
  title: null,
  selectedLength: 0,
  allowAddCreative: true,
  addCreative: Function.prototype,
  creativeInitialValues: undefined,
};

CreativeListTitle.propTypes = {
  title: PropTypes.string,
  selectedLength: PropTypes.number,
  allowAddCreative: PropTypes.bool,
  addCreative: PropTypes.func,
  creativeInitialValues: PropTypes.shape(),
};

export default memo(CreativeListTitle);
