import { useDispatch } from 'react-redux';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Actions
import authActions from 'actions/authActions';
import AccountClass from 'classes/accountClass';
import config from 'config/config';

const getHashValue = (hash, key) => {
  const value = hash.split('#')
    .reduce((accum, cur) => [...accum, cur.split('=')], [])
    .find((item) => item[0] === key)?.[1];

  return value;
};

const useLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [tenantData, setTenantData] = useState({
    accounts: [],
    openModal: false,
  });

  const lastEnteredCredentials = useRef(null);
  const accountId = useRef(null);

  useEffect(() => {
    const user = getHashValue(location.hash, 'user');
    accountId.current = user;
  }, [location.hash]);

  const login = useCallback((credentials) => dispatch(authActions.login(credentials)), [dispatch]);

  const handleCloseTenantModal = useCallback(() => {
    setTenantData((prevData) => ({ ...prevData, openModal: false }));
  }, [setTenantData]);

  const loginWrapper = useCallback(async (credentials) => {
    try {
      lastEnteredCredentials.current = credentials;
      const data = await login({ ...credentials, account: accountId.current });
      history.push('/dashboard');
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }, [login, history]);

  const selectAccount = useCallback((account) => {
    const domain = AccountClass.getDomain({ ...account, domain: config.appDomain });
    if (domain.includes(window.location.host)) {
      accountId.current = account.id;
      loginWrapper(lastEnteredCredentials.current);
    } else {
      window.location.href = `${domain}/login#user=${account.id}`;
    }
  }, [loginWrapper]);

  return {
    loginWrapper,
    selectAccount,
    tenantData,
    handleCloseTenantModal,
  };
};

export default useLogin;
