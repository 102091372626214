import React, { memo } from 'react';
import { Select as MUISelect, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import { Statuses } from 'constants/index';
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';
import classes from './StatusField.module.scss';

const ITEM_HEIGHT = 96;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: '16rem',
      borderRadius: '16px',
    },
  },
};

const StatusField = ({ value, disabled, handleChange, options }) => {
  const getRenderValue = (selected) => {
    const selectedOption = options.find((option) => option.id === selected) || {};

    return (
      <div className={ classes.value }>
        <span className={ classNames(classes.status, classes[selectedOption.id.toLowerCase()]) } />
        <MaterialTableText variant="regular" fontWeight="bold">
          {selectedOption.name}
        </MaterialTableText>
      </div>
    );
  };
  return (
    <MUISelect
      displayEmpty
      value={ value }
      classes={ { select: classes.select } }
      renderValue={ getRenderValue }
      MenuProps={ MenuProps }
      disableUnderline
      disabled={ disabled }
      // Events
      onChange={ handleChange }
      onKeyDownCapture={ (e) => {
        e.stopPropagation();
      } }
    >
      {options.map((option) => (
        <MenuItem
          key={ option.id }
          value={ option.id }
          className={ classes.option }
        >
          {option.name}
        </MenuItem>
      ))}
    </MUISelect>
  );
};

StatusField.defaultProps = {
  options: Statuses,
  handleChange: Function.prototype,
  disabled: false,
  value: null,
};

export default memo(StatusField);
