import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import authActions from 'actions/authActions';

const useForgotPassword = () => {
  const dispatch = useDispatch();

  const resetPassword = useCallback((email) => dispatch(authActions.resetPassword(email)), [
    dispatch,
  ]);

  return { resetPassword };
};

export default useForgotPassword;
