import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';

// icons
import { ReactComponent as CalendarIcon } from 'assets/images/icons/calendar.svg';

// Modules
import Input from 'modules/_Factories/Input/Input';
import InputAdornmentEnd from 'modules/_Factories/Input/InputAdornmentEnd/InputAdornmentEnd';
import InputAdornmentButton from 'modules/_Factories/Input/InputAdornmentEnd/InputAdornmentButton/InputAdornmentButton';
import InputAdornmentIcon from 'modules/_Factories/Input/InputAdornmentEnd/InputAdornmentIcon/InputAdornmentIcon';

// Styles
import classes from './InputDatepicker.module.scss';

const InputDatepicker = React.forwardRef((props, ref) => {
  const { label, name, onClick, onChange, onFocus, onBlur, value, disabled } = props;
  // Options (do not rename requiredField into required, because there is some problem with it)
  const { labelBackgroundColor, fullWidth, requiredField } = props;
  // Extra
  const { errors, touched } = props;

  return (
    <Input
      name={ name }
      label={ label }
      value={ value }
      innerRef={ ref }
      // Options
      className={ classes.wrapper }
      labelBackgroundColor={ labelBackgroundColor }
      fullWidth={ fullWidth }
      required={ requiredField }
      disabled={ disabled }
      endAdornment={ (
        <InputAdornmentEnd active={ !disabled }>
          <InputAdornmentButton
            onClick={ onClick }
            disableRipple={ disabled }
            className={ classNames({ [classes.buttonDisabled]: disabled }) }
          >
            <InputAdornmentIcon icon={ CalendarIcon } />
          </InputAdornmentButton>
        </InputAdornmentEnd>
      ) }
      // Events
      onChange={ onChange }
      onFocus={ onFocus }
      onBlur={ onBlur }
      // Extra
      errors={ errors }
      touched={ touched }
    />
  );
});

InputDatepicker.defaultProps = {
  onClick: () => {},
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  value: '',
  // Options
  disabled: false,
  fullWidth: false,
  requiredField: false,
  labelBackgroundColor: '#f9f9f9',
  // Extra
  errors: {},
  touched: {},
};

InputDatepicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Options
  labelBackgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  requiredField: PropTypes.bool,
  // Extra
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
};

export default InputDatepicker;
