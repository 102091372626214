export default class AssetBaseClass {
  id = null;
  type = null;
  name = '';
  advertiser = '';

  constructor(item = {}) {
    this.id = item.id || null;
    this.type = item.type || null;
    this.name = item.name || '';
    this.advertiser = item.advertiser || '';
  }
}
