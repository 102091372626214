import React, { memo, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import FormCreative from '../../FormCreative';
import useAddCreative from './useAddCreative';

const AddCreative = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { transformCreativeValues, createCreative } = useAddCreative();

  /*
  when creating native creative should create asset immediately and then attached it to creative
  */
  const handleSubmit = useCallback(async (values, callbackFinally) => {
    try {
      const formData = await transformCreativeValues(values);
      const creative = await createCreative(formData);
      history.push('/creatives');
      enqueueSnackbar(`Creative (id: ${creative.id}) created successfully`, { variant: 'success' });
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong! Please, try again.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      callbackFinally();
    }
  }, [createCreative, enqueueSnackbar, transformCreativeValues, history]);

  return (
    <FormCreative handleSubmit={ handleSubmit } />
  );
};

export default memo(AddCreative);
