export class CreativeClass {
  id = null;
  name = null;
  advertiser = null;
  campaigns = [];
  categories = [];
  content = null;
  duration = null;
  mime = null;
  pageUrl = null;
  size = null;
  status = 'ACTIVE';
  type = 'VIDEO';
  url = null;

  constructor(...args) {
    args.forEach((item) => {
      this.id = item.id || null;
      this.name = item.name || null;
      this.advertiser = item.advertiser || null;
      this.campaigns = item.campaigns || [];
      this.categories = item.categories || [];
      this.content = item.content || null;
      this.duration = item.duration || null;
      this.mime = item.mime || null;
      this.pageUrl = item.pageUrl || null;
      this.size = item.size || null;
      this.status = item.status || 'ACTIVE';
      this.type = item.type || 'VIDEO';
      this.url = item.url || null;
    });
  }
}
