import React, { memo, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import ImageAssetClass from 'classes/asset/imageAssetClass';
import CollapsableCreative from 'modules/PreviewCreative/CollapsableCreative';
import UploadFile from '../../UploadFile';
import classes from './Options.module.scss';

const findAssetBySize = (assets, size) => assets.find((item) => {
  const itemSize = item.size || item.expectedSize;
  return isEqual(itemSize, size);
});

const getAssetUrl = (asset) => {
  if (!asset) return null;
  return asset.file ? URL.createObjectURL(asset.file) : asset.url;
};

const COLLAPSABLE_CREATIVE_DATA_TYPES = CREATIVE_DATA_TYPES.dictionary
  .filter((item) => item.id !== CREATIVE_DATA_TYPES.types.STOCK_LIB);

const CollapsableAd = ({ assets, onChangeAssets, disabled }) => {
  const expandedAsset = findAssetBySize(assets, { width: 1920, height: 360 }) || {};
  const collapsedAsset = findAssetBySize(assets, { width: 695, height: 360 }) || {};

  const expandedUrl = getAssetUrl(expandedAsset);
  const collapsedUrl = getAssetUrl(collapsedAsset);

  const updateAsset = useCallback((prevValue, currentValue) => {
    const updatedAssets = assets.map((item) => (item === prevValue ? currentValue : item));
    onChangeAssets(updatedAssets);
  }, [assets, onChangeAssets]);

  return (
    <Grid container spacing={ 4 }>
      <Grid item xs={ 8 }>
        <UploadFile
          title="Expanded image asset - 1920x360"
          dataTypeList={ COLLAPSABLE_CREATIVE_DATA_TYPES }
          value={ expandedAsset.file || expandedAsset.url }
          expectedFileSize={ expandedAsset.expectedSize }
          disabled={ disabled }
          allowEditImage
          fileExtensions=".jpeg,.jpg,.png"
          onChangeValue={ (value) => updateAsset(
            expandedAsset,
            {
              ...expandedAsset,
              ...typeof value === 'string' ?
                {
                  file: null,
                  url: value,
                } : { file: value, url: null },
            },
          ) }
        />
        <UploadFile
          title="Collapsed image asset - 695x360"
          dataTypeList={ COLLAPSABLE_CREATIVE_DATA_TYPES }
          value={ collapsedAsset.file || collapsedAsset.url }
          expectedFileSize={ collapsedAsset.expectedSize }
          disabled={ disabled }
          allowEditImage
          fileExtensions=".jpeg,.jpg,.png"
          onChangeValue={ (value) => updateAsset(
            collapsedAsset,
            {
              ...collapsedAsset,
              ...typeof value === 'string' ?
                {
                  file: null,
                  url: value,
                } : { file: value, url: null },
            },
          ) }
        />
      </Grid>
      <Grid item xs={ 4 }>
        <CollapsableCreative expandedUrl={ expandedUrl } collapsedUrl={ collapsedUrl } className={ classes.collapsablePreviewWrapper } />
      </Grid>
    </Grid>
  );
};

CollapsableAd.defaultProps = {
  onChangeAssets: Function.prototype,
  disabled: false,
  assets: [],
};

CollapsableAd.propTypes = {
  onChangeAssets: PropTypes.func,
  disabled: PropTypes.bool,
  assets: PropTypes.arrayOf(PropTypes.instanceOf(ImageAssetClass)),
};

export default memo(CollapsableAd);
