import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, FormHelperText } from '@material-ui/core';
import classes from './InputFile.module.scss';

const InputFile = ({ name, label, labelButton, accept, value, onChange, errors, touched, disabled }) => {
  const refInputFile = useRef();
  const error = errors[name];
  const hasError = Boolean(touched[name] && error);

  return (
    <FormControl fullWidth>
      <div className={ classes.wrapper }>
        <input
          ref={ refInputFile }
          type="file"
          className="d-none"
          name={ name }
          accept={ accept }
          onChange={ onChange }
          disabled={ disabled }
        />
        <div className={ classes.filename }>
          <span className={ classes.label }>{label}</span>
          <span>{value?.name || ''}</span>
        </div>
        {!disabled && (
          <Button
            color="primary"
            size="medium"
            classes={ { label: classes.button } }
            onClick={ () => refInputFile.current?.click() }
          >
            {labelButton}
          </Button>
        )}
      </div>
      { hasError && <FormHelperText error={ hasError } id={ name }>{ error }</FormHelperText> }
    </FormControl>
  );
};

InputFile.defaultProps = {
  label: 'Filename:',
  labelButton: 'Upload File',
  accept: '*',
  value: null,
  disabled: false,
  name: '',
  onChange: Function.prototype,
  errors: {},
  touched: {},
};

InputFile.propTypes = {
  label: PropTypes.string,
  labelButton: PropTypes.string,
  accept: PropTypes.string,
  value: PropTypes.shape({ name: PropTypes.string }),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
};

export default memo(InputFile);
