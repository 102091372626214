import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { Checkbox } from '@material-ui/core';

// Icons
import { ReactComponent as IconCheckboxFilled } from 'assets/images/icons/checkboxFilled.svg';
import { ReactComponent as IconCheckboxFilledChecked } from 'assets/images/icons/checkboxFilledChecked.svg';

// Styles
import classes from './FilledCheckbox.module.scss';

const FilledCheckbox = (props) => {
  const { className, label, onChange, value, name, checked, disabled, simpleCheckbox } = props;

  return (
    <label
      htmlFor={ name }
      className={
        classNames({
          [classes.checkboxChecked]: checked,
          [classes.disabled]: disabled,
          [classes.simpleCheckbox]: simpleCheckbox,
          [classes.checkboxWrapper]: !simpleCheckbox,
        }, className)
      }
    >
      <Checkbox
        icon={ <IconCheckboxFilled /> }
        checkedIcon={ <IconCheckboxFilledChecked /> }
        id={ name }
        checked={ checked }
        onChange={ onChange }
        value={ value }
        name={ name }
        disabled={ disabled }
        disableRipple
      />
      <span className={ classes.label }>{ label }</span>
    </label>
  );
};

FilledCheckbox.defaultProps = {
  className: '',
  value: '',
  name: '',
  onChange: () => {},
  checked: false,
  disabled: false,
  simpleCheckbox: false,
};

FilledCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  simpleCheckbox: PropTypes.bool,
};

export default FilledCheckbox;
