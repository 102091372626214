import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import Link from 'modules/_Router/RouterLink/RouterLink';
import SignupForm from './SignupForm/SignupForm';
import classes from './Signup.module.scss';
import useSignup from './useSignup';

const Signup = () => {
  const { signup } = useSignup();
  return (
    <>
      <Typography variant="h2" className={ classes.title }>
        Sign Up
      </Typography>

      <SignupForm className={ classes.form } onSubmit={ signup } />

      <p className="mb-0">Already on Telly? <Link to="login">Sign in</Link></p>
    </>
  );
};

export default memo(Signup);
