import React, { memo, useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ImageAssetClass from 'classes/asset/imageAssetClass';
import DataAssetClass from 'classes/asset/dataAssetClass';
import TitleAssetClass from 'classes/asset/titleAssetClass';
import Input from 'modules/_Factories/Input/Input';
import CustomNativeCreative from 'modules/PreviewCreative/CustomNativeCreative';
import UploadFile from '../../UploadFile';

const getAssetUrl = (asset) => {
  if (!asset) return null;
  return asset.file ? URL.createObjectURL(asset.file) : asset.url;
};

const CustomNativeAd = ({ assets, onChangeAssets, disabled }) => {
  const mainAsset = assets.find((item) => item.imageType === 3) || {};
  const logoAsset = assets.find((item) => item.imageType === 1) || {};
  const titleAsset = assets.find((item) => item.type === 'TITLE') || {};
  const descriptionAsset = assets.find((item) => item.type === 'DATA' && item.dataType === 2) || {};
  const ctaAsset = assets.find((item) => item.type === 'DATA' && item.dataType === 12) || {};
  const attributionAsset = assets.find((item) => item.type === 'DATA' && item.dataType === 1) || {};

  const mainUrl = getAssetUrl(mainAsset);
  const logoUrl = getAssetUrl(logoAsset);

  const updateAsset = useCallback((prevValue, currentValue) => {
    const updatedAssets = assets.map((item) => (item === prevValue ? currentValue : item));
    onChangeAssets(updatedAssets);
  }, [assets, onChangeAssets]);

  return (
    <Grid container spacing={ 4 }>
      <Grid container item xs={ 8 } spacing={ 2 }>
        <Grid item xs={ 12 }>
          <UploadFile
            title="Main image asset- 150x250"
            value={ mainAsset.file || mainAsset.url }
            expectedFileSize={ mainAsset.expectedSize }
            disabled={ disabled }
            allowEditImage
            fileExtensions=".jpeg,.jpg,.png"
            onChangeValue={ (value) => updateAsset(
              mainAsset,
              {
                ...mainAsset,
                ...typeof value === 'string' ?
                  {
                    file: null,
                    url: value,
                  }
                  : { file: value, url: null },
              },
            ) }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <UploadFile
            title="Logo image asset"
            value={ logoAsset.file || logoAsset.url }
            expectedFileSize={ logoAsset.expectedSize }
            disabled={ disabled }
            allowEditImage
            fileExtensions=".jpeg,.jpg,.png"
            onChangeValue={ (value) => updateAsset(
              logoAsset,
              {
                ...logoAsset,
                ...typeof value === 'string' ? { file: null, url: value } : { file: value, url: null },
              },
            ) }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <Typography variant="h6">Ad title, description, call to action and attribution assets</Typography>
        </Grid>
        <Grid item xs={ 12 }>
          <Input
            name="title"
            label="Title"
            value={ titleAsset.text }
            disabled={ disabled }
            // Options
            fullWidth
            // Events
            onChange={ (e) => updateAsset(
              titleAsset,
              { ...titleAsset, text: e.target.value },
            ) }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <Input
            name="description"
            label="Description"
            value={ descriptionAsset.value }
            // Options
            disabled={ disabled }
            fullWidth
            // Events
            onChange={ (e) => updateAsset(
              descriptionAsset,
              { ...descriptionAsset, value: e.target.value },
            ) }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <Input
            name="cta"
            label="Call To Action"
            value={ ctaAsset.value }
            // Options
            disabled={ disabled }
            fullWidth
            // Events
            onChange={ (e) => updateAsset(
              ctaAsset,
              { ...ctaAsset, value: e.target.value },
            ) }
          />
        </Grid>

        <Grid item xs={ 12 }>
          <Input
            name="attribution"
            label="Attribution"
            value={ attributionAsset.value }
            // Options
            disabled={ disabled }
            fullWidth
            // Events
            onChange={ (e) => updateAsset(
              attributionAsset,
              { ...attributionAsset, value: e.target.value },
            ) }
          />
        </Grid>
      </Grid>
      <Grid item xs={ 4 }>
        <CustomNativeCreative
          mainUrl={ mainUrl }
          logoUrl={ logoUrl }
          title={ titleAsset.text || 'Title will be here' }
          description={ descriptionAsset.value || 'Description will be here' }
          buttonText={ ctaAsset.value || 'CTA will be here' }
          attributionText={ attributionAsset.value || 'Attribution will be here' }
        />
      </Grid>
    </Grid>
  );
};

CustomNativeAd.defaultProps = {
  onChangeAssets: Function.prototype,
  disabled: false,
  assets: [],
};

CustomNativeAd.propTypes = {
  onChangeAssets: PropTypes.func,
  disabled: PropTypes.bool,
  assets: PropTypes.arrayOf(PropTypes.oneOf([
    PropTypes.instanceOf(ImageAssetClass),
    PropTypes.instanceOf(DataAssetClass),
    PropTypes.instanceOf(TitleAssetClass),
  ])),
};

export default memo(CustomNativeAd);
