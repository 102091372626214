import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Grid, Typography } from '@material-ui/core';

// Icons
import LogoGray from 'assets/images/logoGray.png';

// Classes
import { SchedulePeriodClass } from 'classes/periodClass';

// Dictionaries
import dayPartTargeting from 'constants/dictionary/dayPartTargetingDictionary';

// Modules
import Chip from 'modules/_Factories/Chip/Chip';
import StepContainer from 'modules/Steps/StepContainer';
import stepContainerClasses from 'modules/Steps/StepContainer/StepContainer.module.scss';

// Styles
import classes from './TimeOfDay.module.scss';

const TimeOfDay = ({ formik, editable }) => {
  const periods = formik.values.targetingProfile.periods?.values || [];

  const { dayDictionary, intervalTypes, amIntervals, pmIntervals } = dayPartTargeting;
  const [period, setPeriod] = useState({ type: intervalTypes.AM, intervals: amIntervals });
  const [selected, setSelected] = useState(periods.map((item) => `${item.dayOfWeek}-${item.start}`));

  const CELL_DAY_IDS = useMemo(() => dayDictionary.reduce((accum, day) => {
    const rowIds = period.intervals.map((interval) => `${day.id}-${interval.id}`);
    return [...accum, ...rowIds];
  }, []), [dayDictionary, period]);

  /**
   * Build period list
   */

  const setPeriods = useCallback((selectedItems = []) => {
    if (!formik.values.targetingProfile.periods) return;

    const curPeriods = selectedItems.map((item) => {
      const values = item.split('-');
      const dayOfWeek = values[0];
      const start = values[1];
      const end = values[1] ? values[1].split(':').map((val, i) => {
        if (start.indexOf('30') >= 0) {
          // eslint-disable-next-line no-nested-ternary
          return (i === 0) ? (+val < 9) ? `0${+val + 1}` : (+val === 23) ? '00' : `${+val + 1}` : (i === 1) ? '00' : val;
        }
        return (i === 1) ? '30' : val;
      }).join(':') : null;

      return new SchedulePeriodClass({ dayOfWeek, start, end });
    });
    formik.setFieldValue('targetingProfile.periods.values', curPeriods);
  }, [formik]);

  const addItem = (item) => {
    item.classList.add('selected');
    if (!selected.includes(item.id)) {
      const newSelected = [...selected, item.id];
      setSelected(newSelected);
      setPeriods(newSelected);
    }
  };

  const removeItem = (item) => {
    item.classList.remove('selected');
    if (selected.includes(item.id)) {
      const newSelected = selected.filter((interval) => interval !== item.id);
      setSelected(newSelected);
      setPeriods(newSelected);
    }
  };

  const setMouseDown = (item) => {
    if (item && item.id) {
      if (item.classList.value.includes('selected')) {
        removeItem(item);
      } else {
        addItem(item);
      }
    }
  };

  const removeInterval = (item) => {
    if (selected.includes(item)) {
      const filtered = selected.filter((interval) => interval !== item);
      setPeriods(filtered);
      setSelected(filtered);
    }
  };

  const getDayChip = (day, time, formattedTime) => {
    const chip = { day, time, formattedTime };
    return (
      <div>
        <Chip
          key={ chip.time }
          labelKey="formattedTime"
          valueKey="time"
          // Options
          option={ chip }
          disabled={ !editable }
          // Events
          onClick={ () => editable && removeInterval(`${day}-${time}`) }
        />
      </div>
    );
  };

  const toggleSelectedDays = useCallback((selectedDays) => {
    if (!editable) return;

    const isSelectedAll = selectedDays.every((item) => selected.includes(item));
    let newSelected = null;
    if (isSelectedAll) newSelected = selected.filter((item) => !selectedDays.includes(item));
    else newSelected = Array.from(new Set([...selected, ...selectedDays]));
    setSelected(newSelected);
    setPeriods(newSelected);
  }, [editable, selected, setPeriods]);

  const renderDays = useMemo(() => (
    <Grid item xs sm>
      <div
        className={ classNames(classes.tableItem, classes.dayItem, classes.tableItemHead, { [classes.disabled]: !editable }) }
        role="button"
        aria-label="button"
        tabIndex="0"
        onClick={ () => toggleSelectedDays(CELL_DAY_IDS) }
        onKeyPress={ () => toggleSelectedDays(CELL_DAY_IDS) }
      >
        <img src={ LogoGray } alt="LogoGray" />
      </div>
      { dayDictionary.map((day) => (
        <div
          role="button"
          aria-label="button"
          tabIndex="0"
          className={ classNames(classes.tableItem, classes.dayItem, { [classes.disabled]: !editable }) }
          key={ day.id }
          onClick={ () => toggleSelectedDays(CELL_DAY_IDS.filter((item) => item.includes(day.id))) }
          onKeyPress={ () => toggleSelectedDays(CELL_DAY_IDS.filter((item) => item.includes(day.id))) }
        >
          { day.name }
        </div>
      )) }
    </Grid>
  ), [editable, dayDictionary, toggleSelectedDays, CELL_DAY_IDS]);

  const renderPeriods = useMemo(() => (
    period.intervals.map((interval) => (
      <Grid item xs sm key={ interval.id }>
        <div
          role="button"
          aria-label="button"
          tabIndex="0"
          className={ classNames(classes.tableItem, classes.tableItemHead, classes.intervalItem, { [classes.disabled]: !editable }) }
          onClick={ () => toggleSelectedDays(CELL_DAY_IDS.filter((item) => item.includes(interval.id))) }
          onKeyPress={ () => toggleSelectedDays(CELL_DAY_IDS.filter((item) => item.includes(interval.id))) }
        >
          <div className={ classes.rotate45 }>{ interval.name }</div>
        </div>
        { dayDictionary.map((day) => (
          <div
            className={
              classNames(
                classes.tableItem,
                classes.intervalItem,
                {
                  selected: selected.includes(`${day.id}-${interval.id}`),
                  selectedDisabled: !editable && selected.includes(`${day.id}-${interval.id}`),
                  [classes.disabled]: !editable,
                },
              )
            }
            key={ `${day.id}-${interval.id}` }
            id={ `${day.id}-${interval.id}` }
            role="button"
            aria-label="button"
            tabIndex={ 0 }
          />
        )) }
      </Grid>
    ))
  ), [dayDictionary, editable, period.intervals, selected, toggleSelectedDays, CELL_DAY_IDS]);

  const selectedPeriods = useMemo(() => dayDictionary
    .reduce((cur, next) => {
      const intervals = periods.reduce((acc, val) => {
        if (val.dayOfWeek === next.id) acc.push(val.start);
        return acc;
      }, []);
      cur.push(Object.assign(next, { intervals }));
      return cur;
    }, [])
    .filter((val) => !!val.intervals.length)
    .map((val) => { val.intervals = val.intervals.sort(); return val; }), [dayDictionary, periods]);

  const renderSelection = (values) => {
    const getTimeValue = (fullTime = '00:00:00') => {
      const isAm = fullTime.split(':')[0] < 12;
      const dayIntervals = isAm ? amIntervals : pmIntervals;
      const dayInterval = dayIntervals.find((item) => item.id === fullTime) || {};
      return `${dayInterval.name} ${dayInterval.type}`;
    };

    return (
      values.map((day) => (
        <Grid container spacing={ 2 } key={ day.id }>
          <Grid item xs={ 2 } sm={ 2 }>
            <div className={ classNames(classes.selectedDayItem, 'text-truncate') }>
              { day.fullName }
            </div>
          </Grid>

          <Grid container item xs sm>
            { day.intervals.map((time) => (
              <Grid item xs="auto" sm="auto" key={ `${day.id}-${time}` }>
                <div className={ classNames(classes.selectedDayChip, 'text-truncate') }>
                  { getDayChip(day.id, time, getTimeValue(time)) }
                </div>
              </Grid>
            )) }
          </Grid>
        </Grid>
      ))
    );
  };

  const renderTitle = useCallback(() => (
    <Grid container spacing={ 4 } alignItems="center" justify="space-between" className={ classes.titleContainer }>
      <Grid item>
        <Typography className={ stepContainerClasses.title } variant="h4">
          Day part targeting
        </Typography>
      </Grid>

      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={ classNames(
            classes.intervalButton,
            { [classes.intervalButtonActive]: period.type.includes(intervalTypes.AM) },
          ) }
          onClick={ () => setPeriod({ type: intervalTypes.AM, intervals: amIntervals }) }
        >
          AM
        </Button>

        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={ classNames(
            classes.intervalButton,
            { [classes.intervalButtonActive]: period.type.includes(intervalTypes.PM) },
          ) }
          onClick={ () => setPeriod({ type: intervalTypes.PM, intervals: pmIntervals }) }
        >
          PM
        </Button>
      </Grid>
    </Grid>

  ), [amIntervals, pmIntervals, intervalTypes, period]);

  return (
    <div className={ classes.container }>
      <StepContainer renderTitle={ renderTitle }>
        <Grid
          container
          spacing={ 0 }
          role="button"
          aria-label="button"
          tabIndex={ 0 }
          onMouseDown={ (e) => editable && setMouseDown(e.target) }
          className={ classes.wrapper }
        >
          { renderDays }
          { renderPeriods }
        </Grid>
      </StepContainer>

      {!!selectedPeriods.length && (
        <Grid container spacing={ 8 }>
          <Grid item xs={ 12 } sm={ 12 }>
            <div className={ classes.selection }>
              Your selection
            </div>

            { renderSelection(selectedPeriods) }
          </Grid>
        </Grid>
      )}
    </div>
  );
};

TimeOfDay.defaultProps = { editable: false };

TimeOfDay.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      targetingProfile: PropTypes.shape({
        periods: PropTypes.shape({
          values: PropTypes.arrayOf(PropTypes.shape({
            dayOfWeek: PropTypes.string,
            start: PropTypes.string,
            end: PropTypes.string,
          })),
        }),
      }),
    }),
    handleChange: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  editable: PropTypes.bool,
};

export default TimeOfDay;
