import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';

// Constants
import dashboardTop from 'constants/dashboard-top';
import userPermissionsDictionary from 'constants/dictionary/userPermissionsDictionary';

// Libs
import { textFormat } from 'libs/textFormat';
import { hasPermission } from 'libs/storageLibs';

// Modules
import { MainTabs, MainTab } from 'modules/Tabs/MainTabs';
import MaterialTableContainer from 'modules/_MaterialTable/MaterialTableContainer/MaterialTableContainer';
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';
import MaterialTablesSortIcon from 'modules/_MaterialTable/MaterialTablesSortIcon/MaterialTablesSortIcon';

// Styles
import classes from './Top.module.scss';

const Top = ({ topAdvertisers, topCampaigns, topCreatives }) => {
  const topTabs = [
    {
      value: dashboardTop.TOP_ADVERTISERS,
      label: 'Top Advertisers',
      visible: hasPermission([userPermissionsDictionary.types.ADVERTISER_READ]),
    },
    {
      value: dashboardTop.TOP_CAMPAIGNS,
      label: 'Top Campaigns',
      visible: hasPermission([userPermissionsDictionary.types.CAMPAIGN_READ]),
    },
    {
      value: dashboardTop.TOP_CREATIVES,
      label: 'Top Creatives',
      visible: hasPermission([userPermissionsDictionary.types.CREATIVE_READ]),
    },
  ].filter((item) => item.visible);

  const [top, setTop] = useState(topTabs[0]?.value);

  const topData = {
    [dashboardTop.TOP_ADVERTISERS]: {
      data: topAdvertisers,
      columnNameTitle: 'Advertiser',
    },
    [dashboardTop.TOP_CAMPAIGNS]: {
      data: topCampaigns,
      columnNameTitle: 'Campaign',
    },
    [dashboardTop.TOP_CREATIVES]: {
      data: topCreatives,
      columnNameTitle: 'Creative',
    },
  };

  if (!topData[top]?.data) return null;

  const columns = [
    {
      title: 'ID',
      field: 'item.id',
      render: (item) => <MaterialTableText variant="light" noWrap>{ item && item.item && item.item.id }</MaterialTableText>,
    },
    {
      title: topData[top].columnNameTitle,
      field: 'item.name',
      render: (item) => <MaterialTableText variant="regular" noWrap>{ item && item.item && item.item.name }</MaterialTableText>,
    },
    {
      title: 'Impressions',
      field: 'metric.impressions',
      defaultSort: 'desc',
      render: (item) => (
        <MaterialTableText
          variant="light"
          noWrap
        >
          {textFormat(item && item.metric && item.metric.impressions, 'number')}
        </MaterialTableText>
      ),
    },
    {
      title: 'Spend',
      field: 'metric.spend',
      render: (item) => (
        <MaterialTableText
          variant="regular"
          noWrap
        >
          {textFormat(item && item.metric && item.metric.spend, 'currency')}
        </MaterialTableText>
      ),
    },
    {
      title: 'VCR',
      field: 'metric.vastCompleteRate',
      render: (item) => (
        <MaterialTableText
          variant="light"
          noWrap
        >
          {textFormat(item?.metric?.vastCompleteRate, 'percent')}
        </MaterialTableText>
      ),
    },
    {
      title: 'CPM',
      field: 'metric.ecpm',
      render: (item) => (
        <MaterialTableText
          variant="regular"
          noWrap
        >
          {textFormat(item && item.metric && item.metric.ecpm, 'currency')}
        </MaterialTableText>
      ),
    },
  ];

  const { data } = topData[top];

  return (
    <Grid container spacing={ 3 } className={ classes.wrapper }>
      <Grid item md>
        <MainTabs value={ top } onChange={ (event, value) => setTop(value) } className={ classes.tabs }>
          {topTabs.map((tab) => (
            <MainTab key={ tab.value } disableRipple label={ tab.label } value={ tab.value } />
          ))}
        </MainTabs>

        <div className={ classes.materialTableWrapper }>
          <MaterialTable
            title="Toolbar Overriding Preview"
            columns={ columns }
            data={ data }
            options={ {
              toolbar: false,
              paging: false,
              draggable: false,
            } }
            icons={ { SortArrow: MaterialTablesSortIcon } }
            components={ { Container: (props) => <MaterialTableContainer { ...props } /> } }
          />
        </div>
      </Grid>
    </Grid>
  );
};

Top.defaultProps = {
  topAdvertisers: [],
  topCampaigns: [],
  topCreatives: [],
};

Top.propTypes = {
  topAdvertisers: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({}).isRequired,
      metric: PropTypes.shape({}).isRequired,
    }),
  ),
  topCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({}).isRequired,
      metric: PropTypes.shape({}).isRequired,
    }),
  ),
  topCreatives: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({}).isRequired,
      metric: PropTypes.shape({}).isRequired,
    }),
  ),
};

export default Top;
