import axios from 'axios';
import { stringify } from 'qs';
// Interceptors
import { headersRequestHandler } from './interceptors/headersInterceptor';
// eslint-disable-next-line import/no-cycle
import { refreshRequestHandler, refreshResponseHandler } from './interceptors/refreshTokenInterceptor';

const instance = axios.create({
  baseURL: '',
  headers: {},
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
});

instance.interceptors.request.use(headersRequestHandler);
instance.interceptors.request.use(refreshRequestHandler);

instance.interceptors.response.use(null, refreshResponseHandler);

export default instance;
