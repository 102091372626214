export const convertIsoToDate = (isoStr) => (isoStr ? new Date(`${isoStr}T00:00:00`) : new Date());

export const getIsoDate = (d) => {
  if (d instanceof Date && d.toString() !== 'Invalid Date') {
    return d.toLocaleString('ja', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
  }
  return null;
};

export const getFormattedDate = (d) => {
  if (d instanceof Date && d.toString() !== 'Invalid Date') {
    return d.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '/');
  }
  return null;
};
