import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import userActions from '../actions/userActions';

// Hooks
import useUser from '../hooks/useUser';

const useMain = () => {
  const dispatch = useDispatch();
  // Hooks
  const { user } = useUser({ initialState: true });

  // Callback
  const getEnvironment = useCallback(() => dispatch(userActions.getEnvironment()), [dispatch]);

  const getGlobalSettings = useCallback(() => dispatch(userActions.getGlobalSettings()), [dispatch]);

  // Selectors
  const { environment } = useSelector((state) => state.user);

  useEffect(() => {
    if (user && user.id) {
      getEnvironment();
      getGlobalSettings();
    }
  }, [getEnvironment, getGlobalSettings, user]);

  return { user, environment };
};

export default useMain;
