import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import dictionaryActions from 'actions/dictionaryActions';

// Selectors
import dictionarySelectors from 'selectors/dictionarySelectors';

const useCountries = (sendRequest = false) => {
  const dispatch = useDispatch();

  const getCountries = useCallback(() => dispatch(dictionaryActions.getCountries()), [dispatch]);

  useEffect(() => {
    if (sendRequest) {
      getCountries();
    }
  }, [getCountries, sendRequest]);

  const countries = useSelector(dictionarySelectors.countriesSelector);

  return { countries };
};

export default useCountries;
