import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

// Constants
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import campaignSelectors from 'selectors/campaignSelectors';
import { separateByStatus } from 'libs/separateByStatus';

const useCreatives = (form) => {
  const { initialValues, values, setFieldValue } = form;
  const creativeList = useSelector(campaignSelectors.creativeListSelector);

  const groupedCreatives = separateByStatus(creativeList);

  useEffect(() => {
    if ([CampaignStatuses.types.PAUSED, CampaignStatuses.types.FINISHED].includes(initialValues.status)) {
      const newStatus = isEqual(values.creatives, initialValues.creatives) ? initialValues.status : CampaignStatuses.types.DRAFT;
      setFieldValue('status', newStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.creatives, setFieldValue, initialValues]);

  return { creativeList, groupedCreatives };
};

useCreatives.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({}),
    initialValues: PropTypes.shape({}),
  }).isRequired,
};

export default useCreatives;
