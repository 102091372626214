import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import BALANCE_OPERATIONS from 'constants/dictionary/balanceOperationDictionary';
import PAYMENT_METHODS from 'constants/dictionary/paymentMethodDictionary';

// Classes
import { AdvertiserBalanceClass } from 'classes/advertiser/advertiserBalanceClass';

// Actions
import advertiserActions from 'actions/advertiserActions';
import usePermissions from 'hooks/usePermissions';

const useAdvertiserBalance = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { settings } = useSelector((state) => state.user);
  const { advertiserBalanceManual } = usePermissions();

  const updateAdvertiserBalance = useCallback(
    (advId, model) => dispatch(advertiserActions.updateAdvertiserBalance(advId, model)),
    [dispatch],
  );

  const requestAdvertiserBalance = useCallback(
    (advId, model) => dispatch(advertiserActions.requestAdvertiserBalance(advId, model)),
    [dispatch],
  );

  const fillAdvertiserBalanceByCard = useCallback(
    (advId, model) => dispatch(advertiserActions.fillAdvertiserBalanceByCard(advId, model)),
    [dispatch],
  );

  const updateBalance = useCallback(async (advertiserId, values) => {
    try {
      if (!advertiserId) return null;

      const balanceModel = new AdvertiserBalanceClass({
        ...values,
        amount: values.operation === BALANCE_OPERATIONS.types.ADD ? +values.amount : -values.amount,
      });
      const result = await updateAdvertiserBalance(advertiserId, balanceModel);
      enqueueSnackbar('Balance updated successfully', { variant: 'success' });
      return result;
    } catch (e) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
      return null;
    }
  }, [updateAdvertiserBalance, enqueueSnackbar]);

  const requestBalance = useCallback(async (advertiserId, values) => {
    try {
      if (!advertiserId) return null;
      const balanceModel = new AdvertiserBalanceClass({
        ...values,
        amount: values.operation === BALANCE_OPERATIONS.types.ADD ? +values.amount : -values.amount,
      });
      const result = await requestAdvertiserBalance(advertiserId, balanceModel);
      enqueueSnackbar('Balance requested successfully', { variant: 'success' });
      return result;
    } catch (e) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
      return null;
    }
  }, [requestAdvertiserBalance, enqueueSnackbar]);

  const payByCard = useCallback(async (advertiserId, values) => {
    try {
      if (!advertiserId) return;
      const paymentLink = await fillAdvertiserBalanceByCard(advertiserId, {
        amount: values.amount,
        successUrl: `${window.location.origin}${location.pathname}`,
        cancelUrl: `${window.location.origin}${location.pathname}`,
      });
      window.location.href = paymentLink;
    } catch (e) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
    }
  }, [fillAdvertiserBalanceByCard, enqueueSnackbar, location.pathname]);

  const fillAdvertiserBalance = useCallback(async (advertiserId, values) => {
    const { paymentMethod } = values;
    if (paymentMethod === PAYMENT_METHODS.types.PAY_BY_CARD) {
      return payByCard(advertiserId, values);
    }

    if (advertiserBalanceManual) return updateBalance(advertiserId, values);

    return requestBalance(advertiserId, values);
  }, [payByCard, updateBalance, requestBalance, advertiserBalanceManual]);

  return {
    fillAdvertiserBalance,
    enablePaymentByCard: settings.stripeEnabled,
  };
};

export default useAdvertiserBalance;
