import { createSelector } from 'reselect';
import { keyBy } from 'lodash';

const statsByStates = (state) => state.dashboard.statsByStates;

const statsByStatesHashMapSelector = createSelector(
  statsByStates,
  (items) => keyBy(items, (o) => o.item.id),
);

export default statsByStatesHashMapSelector;
