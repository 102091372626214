import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Libs
import { sortByKey } from 'libs/sortByKey';

// Actions
import dictionaryActions from 'actions/dictionaryActions';

// Selectors
import dictionarySelectors from 'selectors/dictionarySelectors';

const buildCatList = (sourceList, arrKey) => (
  sourceList.reduce((cur, next) => {
    if (next[arrKey] && next[arrKey].length) {
      const result = next[arrKey].map((item) => { item.path = `${next.path} > ${item.name}`; return item; });
      cur = cur.concat(result);
    }
    return cur;
  }, [])
);

const useDemographics = (sendRequest = false) => {
  const dispatch = useDispatch();

  const getDemographics = useCallback(() => dispatch(dictionaryActions.getDemographics()), [dispatch]);

  useEffect(() => {
    if (sendRequest) {
      getDemographics();
    }
  }, [getDemographics, sendRequest]);

  const demographicList = useSelector(dictionarySelectors.demographicsSelector);

  const categoryList = buildCatList(demographicList, 'categories');
  const subCategoryList = buildCatList(categoryList, 'subcategories');
  const categorySegmentList = buildCatList(categoryList, 'segments');
  const subCategorySegmentList = buildCatList(subCategoryList, 'segments');
  const segmentList = sortByKey(categorySegmentList.concat(subCategorySegmentList));

  return { demographicList, categoryList, segmentList };
};

export default useDemographics;
