export const textFormat = (value, type, currency = '$') => {
  if (![null, undefined].includes(value)) {
    switch (type) {
      case 'percent': {
        const formattedValue = value > 1 ? 1 : value;
        return `${(formattedValue * 100).toFixed(2)}%`;
      }
      case 'totalPercent': {
        return `${(value * 100).toFixed(2)}%`;
      }
      case 'currency': {
        const number = (Math.round(value * 100) / 100).toFixed(2);
        return currency + Intl.NumberFormat('en-US').format(number);
      }
      case 'number':
        return Intl.NumberFormat('en-US').format(value);

      case 'date':

        return value.replace(/-/g, '/');
      case 'prettyTotal':
        if (Math.round(value / 1e6) > 0) {
          return `${Math.round(value / 1e6)} M`;
        }
        if (Math.round(value / 1e3) > 0) {
          return `${Math.round(value / 1e3)} K`;
        }
        return value.toLocaleString();

      default:
        return value;
    }
  }
  return value;
};
