import { useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { hasPermission } from 'libs/storageLibs';

// Actions
import campaignActions from 'actions/campaignActions';

// Hooks
import useCountries from 'hooks/dictionary/useCountries';
import useDMAs from 'hooks/dictionary/useDMAs';
import useFilterableStates from 'hooks/dictionary/useFilterableStates';
import useFilterableCities from 'hooks/dictionary/useFilterableCities';
import useFilterableCounties from 'hooks/dictionary/useFilterableCounties';
import useFilterableCongressionalDistricts from 'hooks/dictionary/useFilterableCongressionalDistricts';
import userPermissionsDictionary from 'constants/dictionary/userPermissionsDictionary';

const getInitialTargetingList = (values) => {
  const checkItem = (target, key) => get(values, target) && key;

  if (values.targetingProfile || values.zipCodes) {
    return [
      checkItem('targetingProfile.zips.values.length', 'zips'),
      checkItem('targetingProfile.counties.values.length', 'counties'),
      checkItem('targetingProfile.districts.values.length', 'districts'),
      checkItem('targetingProfile.dmas.values.length', 'dmas'),
      checkItem('targetingProfile.cities.values.length', 'cities'),
      checkItem('targetingProfile.states.values.length', 'states'),
      checkItem('targetingProfile.countries.values.length', 'countries'),
    ].filter(Boolean);
  }
  return [];
};

const useGeography = ({ values }) => {
  const [targetingList, setTargetingList] = useState(getInitialTargetingList(values));

  const { countries } = useCountries();
  const { DMAs } = useDMAs();

  const filterableStates = useFilterableStates();
  const filterableCities = useFilterableCities();
  const filterableCounties = useFilterableCounties();
  const filterableCongressionalDistricts = useFilterableCongressionalDistricts();

  const { updateSearchParams: updateCities } = filterableCities;
  const { targetingProfile: targeting } = values;

  useEffect(() => {
    const countryCodes = targeting.countries.values.map((item) => item.id);
    updateCities({ country: countryCodes });
  }, [targeting.countries.values, updateCities]);

  const parseFile = useCallback((file) => {
    const formData = new FormData();
    formData.append('file', file);
    return campaignActions.parseFile(formData)();
  }, []);

  const fields = [
    {
      id: 'countries',
      name: 'targetingProfile.countries.values',
      label: 'Country',
      list: countries,
      loading: false,
      updateSearchParams: () => null,
      visible: true,
    },
    {
      id: 'states',
      name: 'targetingProfile.states.values',
      label: 'State',
      list: filterableStates.filteredStates,
      loading: filterableStates.loading,
      updateSearchParams: filterableStates.updateSearchParams,
      visible: true,
    },
    {
      id: 'cities',
      name: 'targetingProfile.cities.values',
      label: 'City',
      list: filterableCities.filteredCities,
      loading: filterableCities.loading,
      updateSearchParams: filterableCities.updateSearchParams,
      visible: true,
    },
    {
      id: 'dmas',
      name: 'targetingProfile.dmas.values',
      label: 'DMA',
      list: DMAs,
      loading: false,
      updateSearchParams: () => null,
      visible: true,
    },
    {
      id: 'districts',
      name: 'targetingProfile.districts.values',
      label: 'Congressional district',
      list: filterableCongressionalDistricts.filteredCongressionalDistricts,
      loading: filterableCongressionalDistricts.loading,
      updateSearchParams: filterableCongressionalDistricts.updateSearchParams,
      visible: hasPermission([userPermissionsDictionary.types.TARGET_CD]),
    },
    {
      id: 'counties',
      name: 'targetingProfile.counties.values',
      label: 'County',
      list: filterableCounties.filteredCounties,
      loading: filterableCounties.loading,
      updateSearchParams: filterableCounties.updateSearchParams,
      visible: hasPermission([userPermissionsDictionary.types.TARGET_COUNTY]),
    },
    {
      id: 'zips',
      name: 'targetingProfile.zips.values',
      label: 'Zip Codes',
      list: [],
      loading: false,
      updateSearchParams: () => null,
      visible: true,
    },
  ];

  return { fields, targetingList, setTargetingList, parseFile };
};

useGeography.propTypes = {
  campaignForm: PropTypes.shape({
    values: PropTypes.shape({
      targeting: PropTypes.shape({
        cities: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        districts: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        counties: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        countries: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        dmas: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        states: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
        zipCodes: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.string) }),
      }),
    }),
  }).isRequired,
};

export default useGeography;
