import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TargetingGeography from 'constants/dictionary/targetingGeographyDictionary';
import StepTitle from '../StepTitle';
import classes from '../Review.module.scss';

const Geography = ({ categories }) => (
  <section>
    <StepTitle name="Geography" />
    {Object.keys(categories).map((key) => !!categories[key].values.length && (
    <Grid container spacing={ 3 } key={ key }>
      <Grid item xs={ 2 } sm={ 2 } className={ classes.primaryText }>
        { TargetingGeography.hashMap[key] }
      </Grid>
      <Grid container item xs sm>
        <Grid item xs="auto" sm="auto" className={ classes.secondaryText }>
          { categories[key].values.map((item) => item.name).join(', ')}
        </Grid>
      </Grid>

    </Grid>
    ))}
  </section>
);

Geography.defaultProps = { categories: {} };

Geography.propTypes = {
  categories: PropTypes.shape({
    cities: PropTypes.shape({
      allow: PropTypes.bool,
      values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
    districts: PropTypes.shape({
      allow: PropTypes.bool,
      values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
    counties: PropTypes.shape({
      allow: PropTypes.bool,
      values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
    countries: PropTypes.shape({
      allow: PropTypes.bool,
      values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
    dmas: PropTypes.shape({
      allow: PropTypes.bool,
      values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
    states: PropTypes.shape({
      allow: PropTypes.bool,
      values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
    zipCodes: PropTypes.shape({
      allow: PropTypes.bool,
      values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }),
  }),
};

export default memo(Geography);
