import { lighten, withStyles } from '@material-ui/core';

// Material
import LinearProgress from '@material-ui/core/LinearProgress';

export default withStyles({
  root: {
    height: 8,
    borderRadius: 4,
    backgroundColor: lighten('#6A237C', 0.85),
  },
  bar: {
    borderRadius: 4,
    backgroundColor: '#6A237C',
  },
})(LinearProgress);
