// Libs
import { buildHashMap } from 'libs/buildHashMap';

const dayTypes = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
};
const dayDictionary = [
  { id: dayTypes.SUNDAY, name: 'Sun', fullName: 'Sunday' },
  { id: dayTypes.MONDAY, name: 'Mon', fullName: 'Monday' },
  { id: dayTypes.TUESDAY, name: 'Tue', fullName: 'Tuesday' },
  { id: dayTypes.WEDNESDAY, name: 'Wed', fullName: 'Wednesday' },
  { id: dayTypes.THURSDAY, name: 'Thu', fullName: 'Thursday' },
  { id: dayTypes.FRIDAY, name: 'Fri', fullName: 'Friday' },
  { id: dayTypes.SATURDAY, name: 'Sat', fullName: 'Saturday' },
];
const dayHashMap = buildHashMap(dayDictionary, 'id', 'fullName');

const intervalTypes = {
  AM: 'AM',
  PM: 'PM',
};
const intervalDictionary = [
  { id: dayTypes.AM, name: 'AM' },
  { id: dayTypes.PM, name: 'PM' },
];

const getTimeIntervals = (periods) => {
  const amIntervals = [];
  const pmIntervals = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 12; i++) {
    if (i === 12) {
      if (periods === 48) amIntervals.unshift({ id: '00:30:00', name: '12:30', type: 'am' });
      amIntervals.unshift({ id: '00:00:00', name: '12:00', type: 'am' });

      if (periods === 48) pmIntervals.unshift({ id: '12:30:00', name: '12:30', type: 'pm' });
      pmIntervals.unshift({ id: '12:00:00', name: '12:00', type: 'pm' });
    } else {
      amIntervals.push({ id: (i < 10) ? `0${i}:00:00` : `${i}:00:00`, name: `${i}:00`, type: 'am' });
      if (periods === 48) amIntervals.push({ id: (i < 10) ? `0${i}:30:00` : `${i}:30:00`, name: `${i}:30`, type: 'am' });

      pmIntervals.push({ id: `${i + 12}:00:00`, name: `${i}:00`, type: 'pm' });
      if (periods === 48) pmIntervals.push({ id: `${i + 12}:30:00`, name: `${i}:30`, type: 'pm' });
    }
  }

  return { amIntervals, pmIntervals };
};

const { amIntervals, pmIntervals } = getTimeIntervals(48);

const amIntervalsIds = amIntervals.map((i) => i.id);
const pmIntervalsIds = pmIntervals.map((i) => i.id);

const allIntervals = dayDictionary.reduce((cur, next) => ({ ...cur, ...({ [next.id]: amIntervalsIds.concat(pmIntervalsIds) }) }), []);

export default {
  dayTypes,
  dayDictionary,
  dayHashMap,
  intervalTypes,
  intervalDictionary,
  amIntervals,
  pmIntervals,
  allIntervals,
};
