import React from 'react';
import { SnackbarProvider as NisSnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import CloseButton from 'modules/CloseButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles((styles) => ({
  icon: {
    marginRight: styles.spacing(1),
    opacity: 0.9,
  },
  variantError: { backgroundColor: styles.palette.error.main },
  variantInfo: { backgroundColor: styles.palette.info.main },
  variantSuccess: { backgroundColor: styles.palette.success.main },
  variantWarning: { backgroundColor: styles.palette.warning.main },
}));

const SnackbarProvider = ({ children }) => {
  const classes = useStyles();
  const MAX_SNACK_COUNT = 3;

  const iconVariant = {
    error: <ErrorIcon className={ classes.icon } fontSize="small" />,
    info: <InfoIcon className={ classes.icon } fontSize="small" />,
    success: <SuccessIcon className={ classes.icon } fontSize="small" />,
    warning: <WarningIcon className={ classes.icon } fontSize="small" />,
  };

  const snackbarClasses = {
    variantError: classes.variantError,
    variantInfo: classes.variantInfo,
    variantSuccess: classes.variantSuccess,
    variantWarning: classes.variantWarning,
  };
  return (
    <NisSnackbarProvider
      action={ (key) => <CloseButton keyNotification={ key } /> }
      iconVariant={ iconVariant }
      classes={ snackbarClasses }
      preventDuplicate
      maxSnack={ MAX_SNACK_COUNT }
      anchorOrigin={ {
        vertical: 'bottom',
        horizontal: 'center',
      } }
    >
      {children}
    </NisSnackbarProvider>
  );
};

SnackbarProvider.defaultProps = { children: null };

SnackbarProvider.propTypes = { children: PropTypes.node };

export default SnackbarProvider;
