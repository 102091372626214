import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Tabs } from '@material-ui/core';

// Styles
import classes from './AltTabs.module.scss';

const AltTabs = (props) => {
  const { value, onChange, children, variant, ...TabsProps } = props;

  const getCustomClasses = (val) => {
    switch (val) {
      case 1:
        return { root: classes.rootAddCampaign, indicator: classes.indicatorAddCampaign };
      default:
        return { root: classes.root, indicator: classes.indicator };
    }
  };

  const customClasses = getCustomClasses(variant);

  return (
    <Tabs
      { ...TabsProps }
      value={ value }
      onChange={ onChange }
      classes={ customClasses }
    >
      { children }
    </Tabs>
  );
};

AltTabs.defaultProps = { variant: 0 };

AltTabs.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node]).isRequired,
  variant: PropTypes.number,
};

export default AltTabs;
