import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import StepTitle from '../StepTitle';
import classes from '../Review.module.scss';

const TimeOfDay = ({ schedule }) => (
  <section>
    <StepTitle name="Flight Schedule" />
    { schedule.map((day) => (
      <Grid container spacing={ 2 } key={ day.id }>
        <Grid item xs={ 2 } sm={ 2 } className={ classes.primaryText }>
          { day.fullName }
        </Grid>

        <Grid container item xs sm>
          <Grid item xs="auto" sm="auto" className={ classes.secondaryText }>
            { day.intervals.join(', ')}
          </Grid>
        </Grid>
      </Grid>
    )) }
  </section>
);

TimeOfDay.defaultProps = { schedule: [] };

TimeOfDay.propTypes = {
  schedule: PropTypes.arrayOf(PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    intervals: PropTypes.arrayOf(PropTypes.string),
  })),
};

export default memo(TimeOfDay);
