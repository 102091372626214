import types from 'actions/actionTypes/creativeActionTypes';
import { Statuses } from 'constants/index';

export const INITIAL_FILTERS = {
  search: '',
  type: '',
  status: '',
  advertisers: [],
  campaigns: [],
};

export const DEFAULT_FILTERS = {
  ...INITIAL_FILTERS,
  status: Statuses[0].id,
};

const initialState = {
  creativeList: {
    payload: [],
    _meta: {
      page: 0,
      size: 0,
      total: 0,
    },
  },
  creative: {},
  filters: DEFAULT_FILTERS,
};

const creativeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CREATIVE_LIST:
    case types.CLEAR_CREATIVE_LIST:
      return {
        ...state,
        creativeList: action.payload,
      };

    case types.GET_CREATIVE_BY_ID:
    case types.CLEAR_CREATIVE_BY_ID:
      return {
        ...state,
        creative: action.payload,
      };

    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    default:
      return state;
  }
};

export default creativeReducer;
