import crud from 'actions';

// Targeting Profiles
export const getTargetingProfile = (id) => crud.get(`targetings/${id}`);

export const createTargetingProfile = (model) => crud.post('targetings', model);

export const updateTargetingProfile = (id, model) => crud.put(`targetings/${id}`, model);

export const getTargetingList = (id) => crud.get(`targetings/lists/${id}`);

export const createTargetingList = (file, name, type = 'ZIP') => {
  const formData = new FormData();
  formData.append('file', file);

  return crud.postWithFile('targetings/lists', formData, { params: { name, type } });
};

export const updateTargetingList = (id, file, name, type = 'ZIP') => {
  const formData = new FormData();
  formData.append('file', file);

  return crud.putWithFile(`targetings/lists/${id}`, formData, { params: { name, type } });
};

export const getTargetingListValues = (id) => crud.get(`targetings/lists/${id}/values`);

// Actions
export const createAction = (model) => crud.post('actions', model);
