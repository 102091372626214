// Libs
import { buildHashMap } from 'libs/buildHashMap';

const types = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  PENDING_PUBLISHER_REVIEW: 'PENDING_PUBLISHER_REVIEW',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
  CLOSED: 'CLOSED',
  FINISHED: 'FINISHED',
  CANCELED: 'CANCELED',
};

const dictionary = [
  { id: types.DRAFT, name: 'Draft' },
  { id: types.PENDING, name: 'Pending' },
  { id: types.PENDING_PUBLISHER_REVIEW, name: 'Deal Pending' },
  { id: types.APPROVED, name: 'Approved' },
  { id: types.DECLINED, name: 'Declined' },
  { id: types.RUNNING, name: 'Running' },
  { id: types.PAUSED, name: 'Paused' },
  { id: types.CLOSED, name: 'Closed' },
  { id: types.FINISHED, name: 'Finished' },
  { id: types.CANCELED, name: 'Canceled' },
];

const hashMap = buildHashMap(dictionary);

export default {
  types,
  dictionary,
  hashMap,
};
