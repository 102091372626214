import { buildHashMap } from 'libs/buildHashMap';

const types = {
  COLLAPSABLE: 'COLLAPSABLE',
  CUSTOM_NATIVE: 'CUSTOM_NATIVE',
};

const dictionary = [
  { id: types.COLLAPSABLE, name: 'Telly Collapsable Ad' },
  { id: types.CUSTOM_NATIVE, name: 'Telly Custom Native Ad' },
];

const hashMap = buildHashMap(dictionary);

export default {
  types,
  dictionary,
  hashMap,
};
