export class ActionClass {
  id = null;
  name = null;
  samplingRate = null;
  status = null;
  type = null;

  constructor(item) {
    this.id = item?.id || null;
    this.name = item?.name || 'Telly Action';
    this.samplingRate = item?.samplingRate || null;
    this.status = item?.status || 'ACTIVE';
    this.type = item?.type || 'FILTER';
  }
}
