import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import classes from './AllowOptions.module.scss';

const AllowOptions = ({ allow, onChangeAllow, onClear }) => (
  <Grid container justify="space-between">
    <Grid item>
      <span
        onClick={ () => onChangeAllow(true) }
        onKeyDown={ () => onChangeAllow(true) }
        className={ classNames(classes.option, { [classes.active]: allow }) }
      >
        Include
      </span>
      <span
        onClick={ () => onChangeAllow(false) }
        onKeyDown={ () => onChangeAllow(false) }
        className={ classNames(classes.option, { [classes.active]: !allow }) }
      >
        Exclude
      </span>
    </Grid>
    <Grid item className={ classes.clear } onClick={ onClear }>Clear All</Grid>
  </Grid>
);

AllowOptions.defaultProps = {
  allow: true,
  onChangeAllow: Function.prototype,
  onClear: Function.prototype,
};

AllowOptions.propTypes = {
  allow: PropTypes.bool,
  onChangeAllow: PropTypes.func,
  onClear: PropTypes.func,
};

export default memo(AllowOptions);
