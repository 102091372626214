export default class CreativeClass {
  advertiser = '';
  assets = [];
  campaigns = [];
  categories = [];
  content = '';
  duration = '';
  id = null;
  language = null;
  mime = null;
  name = '';
  pageUrl = '';
  size = null;
  status = '';
  type = '';
  url = '';
  template = '';
  subType = '';

  constructor(item = {}) {
    this.advertiser = item.advertiser || '';
    this.assets = item.assets || '';
    this.campaigns = item.campaigns || [];
    this.categories = item.categories || [];
    this.content = item.content || '';
    this.duration = item.duration || '';
    this.id = item.id || null;
    this.language = item.language || null;
    this.mime = item.mime || null;
    this.name = item.name || '';
    this.pageUrl = item.pageUrl || '';
    this.size = item.size || null;
    this.status = item.status || '';
    this.type = item.type || '';
    this.url = item.url || '';
    this.template = item.template || '';
    this.subType = item.subType || '';
  }
}
