import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid } from '@material-ui/core';

// Modules
import Logo from './Logo/Logo';
import Nav from './Nav/Nav';
import Profile from './Profile/Profile';

// Styles
import classes from './Header.module.scss';

const Header = ({ user }) => (
  <Grid container className={ classes.wrapper } wrap="nowrap">
    <Grid item xs="auto" sm="auto">
      <Logo className={ classes.logo } to="/dashboard" />
    </Grid>
    <Grid container item xs sm justify="center" spacing={ 4 }>
      <Nav />
    </Grid>
    <Grid item xs="auto" sm="auto">
      <Profile user={ user } />
    </Grid>
  </Grid>
);

Header.defaultProps = { user: {} };
Header.propTypes = { user: PropTypes.shape({}) };

export default Header;
