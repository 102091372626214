import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

// Libs
import { get } from 'lodash';
import { separateByStatus } from 'libs/separateByStatus';

// Modules
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';

// Hooks
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';
import { StepContainer } from 'modules/Steps';
import FilledCheckbox from 'modules/_Factories/Checkbox/FilledCheckbox/FilledCheckbox';

import classes from './Inventory.module.scss';

const Inventory = ({
  formik,
  editable,
  shortSupplySourceList,
  shortAdUnitList,
}) => {
  const { ssps, systemType, adUnits } = formik.values;
  const { setFieldValue } = formik;

  const [runOfNetwork, setRunOfNetwork] = useState(!ssps?.values?.length && !adUnits?.values?.length);

  const isProgrammaticDirect = systemType === CampaignTypes.types.PROGRAMMATIC_DIRECT;

  const adUnitOptions = useMemo(() => separateByStatus(shortAdUnitList), [shortAdUnitList]);
  const sspOptions = useMemo(() => separateByStatus(shortSupplySourceList), [shortSupplySourceList]);

  useEffect(() => {
    if (!runOfNetwork) return;
    setFieldValue('adUnits.values', []);
    setFieldValue('ssps.values', []);
  }, [runOfNetwork, setFieldValue]);

  const renderSubtitle = useCallback(() => (
    <FilledCheckbox
      simpleCheckbox
      label="Run Of Network"
      onChange={ (_, checked) => setRunOfNetwork(checked) }
      name="runOfNetwork"
      checked={ runOfNetwork }
      disabled={ !editable }
    />
  ), [runOfNetwork, editable, setRunOfNetwork]);

  return (
    <>
      <StepContainer
        title={ `Choose supply ${isProgrammaticDirect ? 'partner' : 'partners'} to run the campaign on` }
        renderSubtitle={ renderSubtitle }
      >
        {!runOfNetwork && (
          <>
            <SelectVirtualized
              tree
              multiple
              showChip
              className={ classes.adunits }
              name="ssps.values"
              label="Supply Sources"
              value={ get(formik.values, 'ssps.values') }
              list={ sspOptions }
              onChange={ formik.handleChange }
              setFieldTouched={ formik.setFieldTouched }
              errors={ formik.errors }
              isTouched={ formik.touched?.adUnits }
            />
            <SelectVirtualized
              tree
              multiple
              showChip
              className={ classes.adunits }
              name="adUnits.values"
              label="Ad Units"
              value={ get(formik.values, 'adUnits.values') }
              list={ adUnitOptions }
              onChange={ formik.handleChange }
              setFieldTouched={ formik.setFieldTouched }
              errors={ formik.errors }
              isTouched={ formik.touched?.adUnits }
            />
          </>
        )}
      </StepContainer>

    </>
  );
};

Inventory.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      systemType: PropTypes.string,
      advertiser: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string]),
      deals: PropTypes.arrayOf(PropTypes.string),
      adUnits: PropTypes.shape({
        allow: PropTypes.bool.isRequired,
        values: PropTypes.arrayOf(PropTypes.number).isRequired,
        valuesCoverEmpty: PropTypes.bool,
      }),
      ssps: PropTypes.shape({
        allow: PropTypes.bool.isRequired,
        values: PropTypes.arrayOf(PropTypes.number).isRequired,
        valuesCoverEmpty: PropTypes.bool,
      }),
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({ ssps: PropTypes.string }),
    touched: PropTypes.shape({
      adUnits: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.bool }),
      ssps: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.bool }),
    }),
  }).isRequired,
  shortSupplySourceList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shortAdUnitList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editable: PropTypes.bool.isRequired,
};

export default Inventory;
