import { useState } from 'react';
import PropTypes from 'prop-types';
import useDemographics from 'hooks/dictionary/useDemographics';

const useAddDemography = (props) => {
  const { formik, currentGroupItem, setView, unsavedSegments, setUnsavedSegments } = props;

  const segmentGroups = formik.values.targetingProfile.segmentGroups?.values || [];

  // Hooks
  const { segmentList, demographicList } = useDemographics();

  // State
  const [sourceSegmentList] = useState(segmentList || []);
  const [selected, setSelected] = useState(unsavedSegments || []);
  const [index] = useState(segmentGroups.reduce((cur, next, i) => {
    if (next.id === currentGroupItem.id) cur = i;
    return cur;
  }, 0));

  const addDemographicItem = (isChecked, id, curItem) => {
    if (id && curItem) {
      if (isChecked) {
        const item = sourceSegmentList.find((seg) => seg.id === id);
        setSelected((state) => [...state, { cpm: null, segment: item }]);
      } else {
        const filtered = selected.filter((val) => val.segment.id !== id);
        setSelected(filtered);
      }
    }
  };

  const saveDemographicItems = () => {
    setUnsavedSegments(selected);
    setView('group');
  };

  const handleChangeCpm = (value, id) => {
    setSelected((state) => state.map((item) => (item.segment.id === id ? { ...item, cpm: +value || 0 } : item)));
  };

  return { index, sourceSegmentList, selected, demographicList, addDemographicItem, saveDemographicItems, handleChangeCpm };
};

useAddDemography.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({ targeting: PropTypes.shape({}) }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  currentGroupItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.any]),
    name: PropTypes.string,
    segments: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setView: PropTypes.func.isRequired,
  unsavedSegments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default useAddDemography;
