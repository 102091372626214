import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Material
import { Typography } from '@material-ui/core';

// Modules
import Modal from 'modules/Modal/Modal';
import AdvertiserBalance from 'modules/AdvertiserBalance/AdvertiserBalance';

// Styles
import classes from './RefillBalanceModal.module.scss';

const RefillBalanceModal = ({ handleSubmit, isOpen, handleClose, advertiserId }) => (
  <Modal open={ isOpen } onClose={ handleClose }>
    <div className={ classes.wrapper }>
      <Typography variant="h3" className="mb-5">Refill Balance</Typography>
      <AdvertiserBalance advertiserId={ advertiserId } onPayment={ handleSubmit } />
    </div>
  </Modal>
);

RefillBalanceModal.defaultProps = { advertiserId: null };

RefillBalanceModal.propTypes = {
  advertiserId: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default memo(RefillBalanceModal);
