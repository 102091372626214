import React, { Suspense } from 'react';
import GlobalPreloader from 'modules/GlobalPreloader/GlobalPreloader';

const lazyLoad = (Component) => (props) => (
  <Suspense fallback={ <GlobalPreloader show /> }>
    <Component { ...props } />
  </Suspense>
);

export default lazyLoad;
