export const isRememberMe = () => {
  const rememberMe = localStorage.getItem('rememberMe');
  return rememberMe === 'true';
};

export const getToken = () => localStorage.getItem('token') || sessionStorage.getItem('token');
export const getRefreshToken = () => localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken');
export const getExpireDate = () => localStorage.getItem('expires') || sessionStorage.getItem('expires');
export const tokenHasExpired = () => Date.now() > +getExpireDate() - 30 * 1000; // -30 sec

export const getPermissions = () => {
  const permissionsStr = localStorage.getItem('permissions') || sessionStorage.getItem('permissions');
  return permissionsStr ? permissionsStr.split(',') : [];
};

export const hasPermission = (checklist) => {
  const permissions = getPermissions();
  return checklist.some((item) => permissions.includes(item));
};

export const setLocalStorageData = (newToken, rememberMe) => {
  if (newToken.permissions) localStorage.setItem('permissions', newToken.permissions);
  if (newToken.token) localStorage.setItem('token', newToken.token);
  if (newToken.refreshToken) localStorage.setItem('refreshToken', newToken.refreshToken);
  if (newToken.expires) localStorage.setItem('expires', newToken.expires.toString());
  if (rememberMe) localStorage.setItem('rememberMe', `${rememberMe}`);
};

export const setSessionStorageData = (newToken) => {
  if (newToken.permissions) sessionStorage.setItem('permissions', newToken.permissions);
  if (newToken.token) sessionStorage.setItem('token', newToken.token);
  if (newToken.refreshToken) sessionStorage.setItem('refreshToken', newToken.refreshToken);
  if (newToken.expires) sessionStorage.setItem('expires', newToken.expires.toString());
};

export const clearLocalStorage = () => {
  localStorage.removeItem('permissions');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('expires');
  localStorage.removeItem('rememberMe');
};

export const clearSessionStorage = () => {
  sessionStorage.removeItem('permissions');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem('expires');
};
