import types from 'actions/actionTypes/userActionTypes';

const initialState = {
  user: {},
  environment: {},
  scripts: [],
  settings: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SETTINGS:
      return {
        ...state,
        settings: { ...action.payload },
      };
    case types.GET_CURRENT_USER:
      return {
        ...state,
        user: { ...action.payload },
      };

    case types.GET_ENVIRONMENT:
      return {
        ...state,
        environment: { ...action.payload },
      };

    case types.UPDATE_CURRENT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    case types.GET_SCRIPTS:
      return {
        ...state,
        scripts: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
