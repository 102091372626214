import { CustomFilterClass } from './customFilterClass';

export class TargetingClass {
  cities = new CustomFilterClass();
  counties = new CustomFilterClass();
  countries = new CustomFilterClass();
  districts = new CustomFilterClass();
  dmas = new CustomFilterClass();
  periods = new CustomFilterClass();
  segmentGroups = new CustomFilterClass();
  states = new CustomFilterClass();
  zips = new CustomFilterClass();
  id = null;
  name = null;
  type = 'GENERIC';
  status = 'ACTIVE';

  constructor(...args) {
    args.forEach((item) => {
      this.cities = this.checkValues(item.cities);
      this.counties = this.checkValues(item.counties);
      this.countries = this.checkValues(item.countries);
      this.districts = this.checkValues(item.districts);
      this.dmas = this.checkValues(item.dmas);
      this.periods = this.checkValues(item.periods);
      this.states = this.checkValues(item.states);
      this.segmentGroups = this.checkValues(item.segmentGroups);
      this.zips = this.checkValues(item.zips);
      this.id = item.id || null;
      this.name = item.name || null;
      this.type = item.type || 'GENERIC';
      this.status = item.status || 'ACTIVE';
    });
  }

  checkValues(allowValuesInstance) {
    if (allowValuesInstance && allowValuesInstance.values) {
      if (allowValuesInstance.values.length) {
        return new CustomFilterClass(allowValuesInstance);
      }
      return null;
    }
    return new CustomFilterClass();
  }
}
