import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

// Modules
import DemographicTableHead from './DemographicTableHead/DemographicTableHead';

// Styles
import classes from './DemographicTable.module.scss';

const DemographicTable = ({ data, columns, component, renderFiltersComponent }) => {
  const rowsPerPage = 5;
  const emptyRows = (rowsPerPage - data.length > 0) ? rowsPerPage - data.length : 0;

  const renderEmptyRows = (count) => {
    const arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(i);
    }
    return arr.map((val) => (
      <TableRow key={ val } style={ { height: 72 } }>
        { columns.map((column) => (
          <TableCell key={ column.id } />
        )) }
      </TableRow>
    ));
  };

  const handleClick = (e, column, rowData) => {
    if (column.callbackFn) {
      column.callbackFn(rowData.id, e);
    }
  };

  return (
    <div className={ classes.tableWrapper }>
      <Paper>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="demographic-table">
            <DemographicTableHead
              data={ data }
              headColumns={ columns }
            />
            <TableBody>
              {renderFiltersComponent && (
                <TableRow>
                  <TableCell className={ classes.filterComponenrWrapper }>
                    {renderFiltersComponent()}
                  </TableCell>
                </TableRow>
              )}
              { data.map((rowData) => (
                <TableRow
                  tabIndex={ -1 }
                  key={ rowData.name || rowData.segment?.name }
                  style={ { minHeight: 72, opacity: rowData.status === 'INACTIVE' ? 0.3 : 1 } }
                >
                  { columns.map((column) => {
                    const value = rowData[column.id];
                    return (
                      <TableCell key={ column.id } onClick={ (e) => handleClick(e, column, rowData) }>
                        { column.render ? column.render(rowData) : value }
                      </TableCell>
                    );
                  }) }
                </TableRow>
              )) }

              { emptyRows ? renderEmptyRows(emptyRows) : null }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      { component }
    </div>
  );
};

DemographicTable.defaultProps = {
  component: null,
  renderFiltersComponent: null,
};

DemographicTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.object,
  renderFiltersComponent: PropTypes.func,
};

export default DemographicTable;
