import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { Grid } from '@material-ui/core';

// Icons
import { ReactComponent as LogoSVG } from 'assets/images/logos/logo.svg';

// Styles
import Button from '@material-ui/core/Button';
import classes from './Footer.module.scss';

const Footer = (props) => {
  const { version } = props;

  return (
    <div className={ classes.wrapper }>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid container item xs sm className="figure-flex-start px-2" alignItems="center" wrap="nowrap">
          <Grid item xs sm>
            <span className={ classNames(classes.copy, 'align-middle', 'text-truncate') }>
              &#xa9; {new Date().getFullYear()} Telly Corp. All rights reserved.
            </span>
          </Grid>
        </Grid>

        <Grid container item xs sm className="px-2" alignItems="center" wrap="nowrap">
          <Grid item xs="auto" sm="auto" className="px-2">
            <Button
              target="_blank"
              href="https://www.freetelly.com/terms-of-service"
              rel="noopener"
              className={ classNames(classes.link, 'align-middle', 'text-truncate') }
            >
              Terms of Service
            </Button>
          </Grid>
          <Grid item xs="auto" sm="auto" className="px-2">
            <Button
              target="_blank"
              href="https://www.freetelly.com/privacy-policy"
              rel="noopener"
              className={ classNames(classes.link, 'align-middle', 'text-truncate') }
            >
              Privacy Policy
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs sm className="figure-flex-end px-2" alignItems="center" wrap="nowrap">
          <Grid item xs="auto" sm="auto" className="px-2">
            <span className={ classNames(classes.version, 'align-middle', 'text-truncate') }>
              { version ? `Version: 1.0.0-${version}` : 'Version: 1.0.0' }
            </span>
          </Grid>
          <Grid item xs="auto" sm="auto" className="px-2">
            <LogoSVG className="align-middle text-truncate" />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Footer.defaultProps = { version: undefined };

Footer.propTypes = { version: PropTypes.string };

export default Footer;
