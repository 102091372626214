import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Paper, IconButton, InputBase } from '@material-ui/core';

// Icons
import SearchIcon from '@material-ui/icons/Search';

// Libs
import { handleStateChange } from 'libs/handleStateChange';

// Styles
import classes from './SearchFilterInput.module.scss';

const SearchFilterInput = ({ setSearchFilter, ...props }) => (
  <Paper elevation={ 0 } className={ classes.searchWrapper }>
    <IconButton className={ classes.searchIconButton } aria-label="search">
      <SearchIcon />
    </IconButton>

    <InputBase
      onChange={ (e) => handleStateChange(e, setSearchFilter) }
      className={ classes.searchInput }
      { ...props }
    />
  </Paper>
);

SearchFilterInput.defaultProps = {};

SearchFilterInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
};

export default SearchFilterInput;
