import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

// Constants
import CREATIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';

// Hooks
import classNames from 'classnames';
import useFormCreative from './useFormCreative';

// Components
import Steps from './Steps';

// Styles
import classes from './FormCreative.module.scss';

const FormCreative = ({
  handleSubmit,
  initialValues,
  permissionEdit,
  isEditForm,
}) => {
  const { formik } = useFormCreative({ initialValues, handleSubmit });

  const pageTitle = formik.values?.id ? 'Edit Creative' : 'Add Creative';
  return (
    <div>
      <div className={ classNames(classes.header, 'mb-5') }>
        <PageTitle title={ pageTitle } showBreadcrumbs lastElementName={ formik.values?.id && formik.values.name } />

        <div className={ classes.buttons }>
          <Button
            variant="text"
            size="large"
            className="mr-2"
            component={ Link }
            to="/creatives"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={ formik.handleSubmit }
            disabled={ !formik.isValid || formik.isSubmitting || !formik.dirty }
          >
            Save
          </Button>
        </div>
      </div>

      <Steps formik={ formik } isEditForm={ isEditForm } permissionEdit={ permissionEdit } />
    </div>
  );
};

FormCreative.defaultProps = {
  initialValues: {
    name: '',
    advertiser: null,
    status: 'ACTIVE',
    subType: CREATIVE_TYPES.types.DISPLAY,
    type: CREATIVE_TYPES.types.NATIVE,
    language: '',
    categories: [],
    dataType: CREATIVE_DATA_TYPES.types.UPLOAD_FILE,
    url: '',
    file: null,
    pageUrl: '',
    content: '',
    size: null,
    template: CREATIVE_TEMPLATES.types.COLLAPSABLE,
    assets: [],
  },
  handleSubmit: Function.prototype,
  permissionEdit: true,
  isEditForm: false,
};

FormCreative.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    advertiser: PropTypes.number,
    status: PropTypes.string,
    type: PropTypes.string,
    subType: PropTypes.string,
    language: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    dataType: PropTypes.string,
    url: PropTypes.string,
    file: PropTypes.object,
    pageUrl: PropTypes.string,
    content: PropTypes.string,
    size: PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
    assets: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(['DATA', 'IMAGE', 'TITLE']),
      dataType: PropTypes.string,
      imageType: PropTypes.number,
      expectedSize: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
      url: PropTypes.string,
    })),
  }),
  handleSubmit: PropTypes.func,
  permissionEdit: PropTypes.bool,
  isEditForm: PropTypes.bool,
};

export default memo(FormCreative);
