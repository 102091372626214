import { createSelector } from 'reselect';

// Classes
import { AdvertiserClass } from 'classes/advertiser/advertiserClass';
import { AdvertiserBalanceClass } from 'classes/advertiser/advertiserBalanceClass';
import { AdvertiserListClass } from 'classes/advertiser/advertiserListClass';

const advertiser = (state) => state.advertisers.advertiser;
const advertiserList = (state) => state.advertisers.advertiserList;
const advertiserBalance = (state) => state.advertisers.advertiserBalance;

const advertiserSelector = createSelector(
  advertiser,
  (item) => ({ ...new AdvertiserClass(item) }),
);

const advertiserListSelector = createSelector(
  advertiserList,
  (advertisersData) => ({
    ...advertisersData,
    payload: advertisersData.payload.map((item) => new AdvertiserListClass(item)),
  }),
);

const advertiserBalanceSelector = createSelector(
  advertiserBalance,
  (item) => ({ ...new AdvertiserBalanceClass(item) }),
);

export default {
  advertiserSelector,
  advertiserListSelector,
  advertiserBalanceSelector,
};
