export class AdvertiserListClass {
  availableBalance = 0;
  campaignsCount = 0;
  creativesCount = 0;
  domain = null;
  id = null;
  missingBalance = null;
  name = null;
  status = null;

  constructor(item) {
    this.availableBalance = item.availableBalance || 0;
    this.campaignsCount = item.campaignsCount || 0;
    this.creativesCount = item.creativesCount || 0;
    this.domain = item.domain || null;
    this.id = item.id || null;
    this.missingBalance = item.missingBalance || null;
    this.name = item.name || null;
    this.status = item.status || null;
  }
}
