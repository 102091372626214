import React from 'react';

// Material
import { Typography } from '@material-ui/core';

// Modules
import Link from 'modules/_Router/RouterLink/RouterLink';
import Modal from 'modules/Modal/Modal';

// Components
import LoginForm from './LoginForm/LoginForm';

// Hooks
import useLogin from './useLogin';

// Styles
import classes from './Login.module.scss';
import TenantList from './TenantList';

const Login = () => {
  const {
    loginWrapper,
    selectAccount,
    tenantData,
    handleCloseTenantModal,
  } = useLogin();

  return (
    <>
      <Typography variant="h2" className={ classes.title }>
        Sign In
      </Typography>

      <LoginForm login={ loginWrapper } className={ classes.form } />

      <div className={ classes.links }>
        <Link to="/signup">Sign up</Link>
        <Link to="/forgot-password">
          Forgot password?
        </Link>
      </div>

      <Modal open={ tenantData.openModal } onClose={ handleCloseTenantModal }>
        <Typography variant="h4">Please select account you want to login</Typography>
        <TenantList list={ tenantData.accounts } selectAccount={ selectAccount } />
      </Modal>
    </>
  );
};

export default Login;
