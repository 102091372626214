import { useFormik } from 'formik';
import { validateEmail, validateRequired } from 'libs/validateErrors';

const FORM_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  timeZone: 'GMT',
  company: '',
  website: '',
  phone: '',
};

const useSignupForm = ({ initialValues, handleSubmit }) => {
  const validate = (values) => {
    const firstName = validateRequired(values.firstName, 'First Name');
    const lastName = validateRequired(values.lastName, 'Last Name');
    const email = validateRequired(values.email, 'Email') || validateEmail(values.email, 'Email');
    const timeZone = validateRequired(values.timeZone, 'Time Zone');

    return {
      ...firstName && { firstName },
      ...lastName && { lastName },
      ...email && { email },
      ...timeZone && { timeZone },
    };
  };

  const formik = useFormik({
    initialValues: {
      ...FORM_VALUES,
      ...initialValues,
    },
    onSubmit: (values, { setSubmitting }) => handleSubmit(values, () => setSubmitting(false)),
    validate,
    enableReinitialize: true,
    validateOnMount: true,
  });

  return {
    validate,
    formik,
  };
};

export default useSignupForm;
