import React from 'react';
import PropTypes from 'prop-types';

const getColor = (statusId) => {
  switch (statusId) {
    case 'APPROVED':
    case 'RUNNING':
    case 'FINISHED':
    case 'CLOSED':
      return '#3bb948';

    case 'PENDING':
    case 'PAUSED':
      return '#0092dd';

    case 'DECLINED':
    case 'CANCELED':
      return '#ec8078';

    default:
      return '#bbbbbb';
  }
};

const getBackgroundColor = (statusId) => {
  switch (statusId) {
    case 'APPROVED':
    case 'RUNNING':
    case 'FINISHED':
    case 'CLOSED':
      return '#e7f3e8';

    case 'PENDING':
    case 'PAUSED':
      return '#ddf3ff';

    case 'DECLINED':
    case 'CANCELED':
      return '#fff8f7';

    default:
      return '#ebebeb';
  }
};

const StatusTableCell = ({ statusId, statusName }) => (
  <>
    <span style={ {
      backgroundColor: getBackgroundColor(statusId),
      color: getColor(statusId),
      borderRadius: '6px',
      padding: '4px 8px',
    } }
    >
      { statusName }
    </span>
  </>
);

StatusTableCell.defaultProps = { };

StatusTableCell.propTypes = {
  statusId: PropTypes.string.isRequired,
  statusName: PropTypes.string.isRequired,
};

export default StatusTableCell;
