import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classes from './InfoValue.module.scss';
import classNames from 'classnames';

const InfoValue = ({ label, value, className }) => (
  <div className={ classNames(classes.wrapper, className) }>
    <span className={ classes.label }>{label || '-'}</span>
    <span className={ classes.value }>{value || '-'}</span>
  </div>
);

InfoValue.defaultProps = {
  label: null,
  value: null,
  className: null,
};

InfoValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  className: PropTypes.string,
};

export default memo(InfoValue);
