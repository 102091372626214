const types = {
  CUSTOM: 'CUSTOM',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_WEEK: 'THIS_WEEK',
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_WEEK: 'LAST_WEEK',
  LAST_14_DAYS: 'LAST_14_DAYS',
  LAST_30_DAYS: 'LAST_30_DAYS',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  LAST_90_DAYS: 'LAST_90_DAYS',
};

const dictionary = [
  { id: types.CUSTOM, name: 'Custom Range' },
  { id: types.TODAY, name: 'Today' },
  { id: types.YESTERDAY, name: 'Yesterday' },
  { id: types.THIS_WEEK, name: 'This Week' },
  { id: types.LAST_7_DAYS, name: 'Last 7 Days' },
  { id: types.LAST_WEEK, name: 'Last Week' },
  { id: types.LAST_14_DAYS, name: 'Last 14 Days' },
  { id: types.LAST_30_DAYS, name: 'Last 30 Days' },
  { id: types.THIS_MONTH, name: 'This Month' },
  { id: types.LAST_MONTH, name: 'Last Month' },
  { id: types.LAST_90_DAYS, name: 'Last 90 Days' },
];

export default {
  types,
  dictionary,
};
