import { useState } from 'react';

const useDatepicker = () => {
  const [calendarReference, setCalendarReference] = useState({});

  const handlerClickOutside = () => {
    calendarReference.setState(() => ({ open: false }));
  };

  const handlerReset = (callback) => {
    callback();
    handlerClickOutside();
  };

  return {
    calendarReference,
    setCalendarReference,
    handlerClickOutside,
    handlerReset,
  };
};

export default useDatepicker;
