import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// Material
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

// Store
import store from './store/store';

// Styles
import theme from './theme/theme';
import 'assets/styles/defaults.scss';
import 'assets/styles/bootstrap.scss';
import 'assets/styles/main.scss';
import 'react-phone-input-2/lib/material.css';

// Components
import SnackbarProvider from './modules/SnackbarProvider';
import App from './App';

ReactDOM.render(
  <Provider store={ store }>
    <BrowserRouter>
      <ThemeProvider theme={ theme }>
        <CssBaseline />
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
