/**
 * Sort array by param.
 */
export const sortByKey = (list, key = 'name', descendingOrder) => {
  let resultList = list;

  if (list instanceof Array && list.length) {
    resultList = list.sort((cur, next) => {
      if (typeof cur[key] === 'string' && typeof next[key] === 'string') {
        if (descendingOrder) {
          return (cur[key].toLowerCase()) > (next[key].toLowerCase()) ? -1 : 1;
        }

        return (cur[key].toLowerCase()) > (next[key].toLowerCase()) ? 1 : -1;
      }

      if (descendingOrder) {
        return cur[key] > next[key] ? -1 : 1;
      }

      return cur[key] > next[key] ? 1 : -1;
    });
  }
  return resultList;
};
