import types from 'actions/actionTypes/shortListActionTypes';

// Libs
import { sortByKey } from 'libs/sortByKey';

const initialState = {
  shortAdUnitList: [],
  shortAdvertiserList: [],
  shortCampaignList: [],
  shortCreativeList: [],
  shortDealList: [],
  shortDemandPartnerList: [],
  shortSiteAppList: [],
  shortPublisherList: [],
  shortRoleList: [],
  shortSupplySourceList: [],
  shortSupplyPartnerList: [],
  shortUserList: [],
  shortDSPEmbedded: {},
};

const shortListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_AD_UNIT_LIST:
      return {
        ...state,
        shortAdUnitList: sortByKey([...action.payload.payload]),
      };
    case types.GET_ADVERTISER_LIST:
      return {
        ...state,
        shortAdvertiserList: sortByKey([...action.payload.payload]),
      };
    case types.GET_CAMPAIGN_LIST:
      return {
        ...state,
        shortCampaignList: sortByKey([...action.payload.payload]),
      };
    case types.GET_CREATIVE_LIST:
      return {
        ...state,
        shortCreativeList: sortByKey([...action.payload.payload]),
      };
    case types.GET_DEAL_LIST:
      return {
        ...state,
        shortDealList: sortByKey([...action.payload.payload]),
      };
    case types.GET_DSP_LIST:
      return {
        ...state,
        shortDemandPartnerList: sortByKey([...action.payload.payload]),
      };
    case types.GET_INVENTORY_LIST:
      return {
        ...state,
        shortSiteAppList: sortByKey([...action.payload.payload]),
      };
    case types.GET_PUBLISHER_LIST:
      return {
        ...state,
        shortPublisherList: sortByKey([...action.payload.payload]),
      };
    case types.GET_ROLE_LIST:
      return {
        ...state,
        shortRoleList: sortByKey([...action.payload]),
      };
    case types.GET_SSP_LIST:
      return {
        ...state,
        shortSupplySourceList: sortByKey([...action.payload.payload]),
      };
    case types.GET_SUPPLY_PARTNER_LIST:
      return {
        ...state,
        shortSupplyPartnerList: sortByKey([...action.payload.payload]),
      };
    case types.GET_USER_LIST:
      return {
        ...state,
        shortUserList: sortByKey([...action.payload.payload]),
      };
    case types.GET_DSP_EMBEDDED:
      return {
        ...state,
        shortDSPEmbedded: { ...action.payload },
      };
    default:
      return state;
  }
};

export default shortListReducer;
