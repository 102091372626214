import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import authActions from 'actions/authActions';

const useProfile = ({ firstName = '', lastName = '' }) => {
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  const getFullName = () => `${firstName} ${lastName}`;

  return { getFullName, logOut };
};

export default useProfile;
