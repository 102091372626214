import types from 'actions/actionTypes/dashboardActionTypes';

const initialState = {
  statsByStates: [],
  advertisersActivity: {},
  topAdvertisers: [],
  topCampaigns: [],
  topCreatives: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STATS_BY_STATES:
      return {
        ...state,
        statsByStates: [...action.payload],
      };
    case types.GET_ADVERTISERS_ACTIVITY:
      return {
        ...state,
        advertisersActivity: { ...action.payload },
      };
    case types.GET_TOP_ADVERTISERS:
      return {
        ...state,
        topAdvertisers: [...action.payload],
      };
    case types.GET_TOP_CAMPAIGNS:
      return {
        ...state,
        topCampaigns: [...action.payload],
      };
    case types.GET_TOP_CREATIVES:
      return {
        ...state,
        topCreatives: [...action.payload],
      };
    case types.CLEAR_STATS_BY_STATES:
      return {
        ...state,
        statsByStates: [],
      };
    case types.CLEAR_ADVERTISERS_ACTIVITY:
      return {
        ...state,
        advertisersActivity: {},
      };
    case types.CLEAR_TOP_ADVERTISERS:
      return {
        ...state,
        topAdvertisers: [],
      };
    case types.CLEAR_TOP_CAMPAIGNS:
      return {
        ...state,
        topCampaigns: [],
      };
    case types.CLEAR_TOP_CREATIVES:
      return {
        ...state,
        topCreatives: [],
      };
    default:
      return state;
  }
};

export default dashboardReducer;
