// CRUD
import crud from 'actions';
import config from 'config/config';
import client from 'client/axios';

// Types
import { downloadFile } from 'libs/downloadFile';
import types from './actionTypes/advertiserActionTypes';

const { serverUrl, apiVersion } = config;

/** *********************************************** POST **************************************************** */

/**
 * CREATE Advertiser
 */
const createAdvertiser = (model) => crud.post('advertisers', model, true);

/** ************************************************ GET ****************************************************** */

/**
 * GET list of Advertisers.
 * @param {{
 *  balanceStates: string[],
 *  ids: number[],
 *  page: number;
 *  search: string;
 *  size: number;
 *  sortBy: string;
 *  sortOrder: 'ASC' | 'DESC';
 *  statuses: string[];
 * }}
 */
const getAdvertiserList = (params) => crud.get('advertisers', types.GET_ADVERTISER_LIST, params, true);

/**
 * GET Advertiser by ID.
 */
const getAdvertiserById = (id) => crud.get(`advertisers/${id}`, types.GET_ADVERTISER_BY_ID, {}, true);

/** ************************************************ PUT ****************************************************** */

/**
 * UPDATE Advertiser by ID.
 */
const updateAdvertiser = (model) => crud.put(`advertisers/${model.id}`, model, true);

/**
 * UPDATE Advertiser by ID.
 */
const updateAdvertiserBalance = (id, model) => crud.put(`advertisers/${id}/balance`, model, true);

const requestAdvertiserBalance = (id, model) => crud.put(`advertisers/${id}/request-balance`, model, true);

const fillAdvertiserBalanceByCard = (id, model) => crud.post(`advertisers/${id}/balance/stripe`, model, true);

/**
 * UPDATE Advertiser status by ID.
 */
const updateAdvertiserStatus = (id, status) => crud.put(`advertisers/${id}/status`, JSON.stringify(status), true);

const getBalanceHistory = (id) => crud.get(
  `advertisers/${id}/balancehistories`, types.GET_BALANCE_HISTORY, {}, true,
);

/**
 * Set filters
 */

const setFilters = (filters) => (dispatch) => dispatch({
  type: types.SET_FILTERS,
  payload: filters,
});

/** ************************************************ Clear Store ****************************************************** */
/**
 * CLEAR Advertiser(s).
 */
const clearAdvertiserList = () => crud.clear(types.GET_ADVERTISER_LIST, { payload: [], _meta: { page: 0, size: 0, total: 0 } });
const clearAdvertiserById = () => crud.clear(types.CLEAR_ADVERTISER_BY_ID, {});
const clearBalanceHistory = () => ({
  type: types.CLEAR_BALANCE_HISTORY,
  payload: [],
});

/**
 * GET Download biling history
 */
const downloadBillingHistory = (advertiserId, start, end, format = 'XLSX') => async () => {
  const { data } = await client.get(`${serverUrl}${apiVersion}advertisers/${advertiserId}/balancehistories/_download`, {
    params: {
      id: advertiserId,
      format,
      ...start && { start },
      ...end && { end },
    },
    responseType: 'blob',
  });

  downloadFile(data, `Telly-billing-history.${format.toLowerCase()}`);
};

/** ************************************************ EXPORTS ****************************************************** */
export default {
  createAdvertiser,
  getAdvertiserList,
  getAdvertiserById,
  updateAdvertiser,
  requestAdvertiserBalance,
  updateAdvertiserBalance,
  updateAdvertiserStatus,
  getBalanceHistory,
  downloadBillingHistory,
  fillAdvertiserBalanceByCard,
  setFilters,
  // Clear
  clearAdvertiserList,
  clearAdvertiserById,
  clearBalanceHistory,
};
