import client from 'client/axios';

// History
import { createBrowserHistory } from 'history';

// Config
import config from 'config/config';

// Libs
import { clearLocalStorage, clearSessionStorage, setLocalStorageData, setSessionStorageData } from 'libs/storageLibs';

// Types
import types from 'actions/actionTypes/authActionTypes';

// Actions
import loadingActions from './loadingActions';

const history = createBrowserHistory();
const { serverUrl, apiVersion } = config;

const signup = (signupRequest) => async (dispatch) => {
  try {
    dispatch(loadingActions.increaseCounter());
    return await client.post(`${serverUrl}${apiVersion}auth/signup`, { ...signupRequest, client: config.clientId });
  } catch (e) {
    return Promise.reject(e);
  } finally {
    dispatch(loadingActions.decreaseCounter());
  }
};

const login = (credentials, rememberMe = true) => async (dispatch) => {
  try {
    dispatch(loadingActions.increaseCounter());

    const { data, headers } = await client.post(
      `${serverUrl}${apiVersion}auth/login`,
      {
        ...credentials,
        asMaster: false,
        client: config.clientId,
      },
    );
    const expiresDate = new Date(headers.expires || 0).getTime();
    const tokenInstance = { ...data, expires: expiresDate };

    if (rememberMe) {
      setLocalStorageData(tokenInstance, rememberMe);
    } else {
      setSessionStorageData(tokenInstance);
    }

    history.push('/dashboard');

    dispatch({
      type: types.LOGIN,
      payload: { isAuthenticated: true },
    });

    dispatch(loadingActions.decreaseCounter());

    return tokenInstance;
  } catch (e) {
    dispatch(loadingActions.decreaseCounter());

    return Promise.reject(e);
  }
};

const logout = (sendLogoutRequest = true, redirectTo = '/login') => async (dispatch) => {
  const applyLogout = () => {
    clearLocalStorage();
    clearSessionStorage();

    history.push('/login');
    if (redirectTo) history.push(redirectTo);

    dispatch({
      type: types.LOGOUT,
      payload: { isAuthenticated: false },
    });

    dispatch(loadingActions.decreaseCounter());
  };

  try {
    dispatch(loadingActions.increaseCounter());
    if (sendLogoutRequest) {
      await client.get(`${serverUrl}${apiVersion}auth/logout`);
    }
    applyLogout();
    return true;
  } catch (e) {
    applyLogout();
    return Promise.reject(e);
  }
};

const resetPassword = ({ email }) => async (dispatch) => {
  try {
    dispatch(loadingActions.increaseCounter());

    await client.post(`${serverUrl}${apiVersion}auth/password-reset`, JSON.stringify(email));
    dispatch(loadingActions.decreaseCounter());

    return true;
  } catch (e) {
    dispatch(loadingActions.decreaseCounter());
    return Promise.reject(e);
  }
};

const setPassword = (passwordInfo) => async (dispatch) => {
  try {
    dispatch(loadingActions.increaseCounter());

    await client.post(`${serverUrl}${apiVersion}auth/password-set`, passwordInfo);

    dispatch(loadingActions.decreaseCounter());
    return true;
  } catch (e) {
    dispatch(loadingActions.decreaseCounter());
    return Promise.reject(e);
  }
};

const checkResetPasswordToken = (token) => async (dispatch) => {
  try {
    dispatch(loadingActions.increaseCounter());

    const { data } = await client.get(`${serverUrl}${apiVersion}auth/tokens/${token}`);
    dispatch(loadingActions.decreaseCounter());

    return data;
  } catch (e) {
    dispatch(loadingActions.decreaseCounter());
    return Promise.reject(e);
  }
};

const setIsAuthenticated = (isAuthenticated) => (dispatch) => {
  dispatch({
    type: types.SET_IS_AUTHENTICATED,
    payload: { isAuthenticated },
  });

  return true;
};

export default {
  signup,
  login,
  logout,
  resetPassword,
  setPassword,
  checkResetPasswordToken,
  setIsAuthenticated,
};
