import { createSelector } from 'reselect';

// Classes
import { CampaignClass } from 'classes/campaign/campaignClass';
import { CampaignListClass } from 'classes/campaign/campaignListClass';
import { CreativeClass } from 'classes/campaign/creativeClass';

const campaign = (state) => state.campaigns.campaign;
const campaignList = (state) => state.campaigns.campaignList;
const creativeList = (state) => state.campaigns.creativeList;

const campaignSelector = createSelector(
  campaign,
  (item) => ({ ...new CampaignClass(item) }),
);

const campaignListSelector = createSelector(
  campaignList,
  (campaignsData) => ({
    ...campaignsData,
    payload: campaignsData.payload.map((item) => new CampaignListClass(item)),
  }),
);

const creativeListSelector = createSelector(
  creativeList,
  (items) => items.map((creative) => new CreativeClass(creative)),
);

export default {
  campaignSelector,
  campaignListSelector,
  creativeListSelector,
};
