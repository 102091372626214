import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Libraries
import { format } from 'date-fns';
import { get } from 'lodash';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Label, ReferenceLine } from 'recharts';

// Components
import periodDictionary from 'constants/dictionary/periodDictionary';
import dashboardMetrics from 'constants/dashboard-metrics';
import CustomTooltip from './Tooltip/Tooltip';
import XTick from './XTick/XTick';

const ActivityLineChart = ({ period, metric, data, xDataKey, yDataKey }) => {
  const palette = useMemo(() => ({
    gradientColor: '#BFE3F7',
    gridColor: '#F9F9F9',
    referenceLine: '#EBEBEB',
    axisColor: '#BBBBBB',
    monthLabelColor: '#BBBBBB',
    cursorColor: '#0092DD',
    dotColor: '#6a237c',
    lineColor: '#0092DD',
  }), []);

  const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);

  const monthLabel = useMemo(() => {
    const firstElement = data[0];
    const lastElement = data[data.length - 1];
    if (!firstElement && !lastElement) return null;

    const startMonth = format(firstElement.item, 'MMMM');
    const endMonth = format(lastElement.item, 'MMMM');

    if (startMonth === endMonth) return startMonth;

    return `${startMonth} - ${endMonth}`;
  }, [data]);

  const getDataKeyMin = useCallback((dataKey) => {
    if (!data.length) return 0;

    const minValue = Math.min(...data.map((item) => get(item, dataKey)));
    const decreaseIndex = 0.9;

    return minValue * decreaseIndex;
  }, [data]);

  const getDataKeyMax = useCallback((dataKey) => {
    if (!data.length) return 1;

    const maxValue = Math.max(...data.map((item) => get(item, dataKey)));
    const increaseIndex = 1.1;

    return maxValue * increaseIndex;
  }, [data]);

  const isTimePeriod = useMemo(
    () => [periodDictionary.types.TODAY, periodDictionary.types.YESTERDAY].includes(period),
    [period],
  );

  const tooltipTextFormat = useMemo(() => {
    switch (metric) {
      case dashboardMetrics.SPEND:
      case dashboardMetrics.ECPM:
        return 'currency';
      case dashboardMetrics.ENGAGEMENT_RATE:
        return 'percent';
      case dashboardMetrics.IMPRESSIONS:
        return 'number';
      default:
        return 'number';
    }
  }, [metric]);

  return (
    <ResponsiveContainer height={ 420 }>
      <AreaChart
        margin={ { top: 5, right: 25, left: 25, bottom: 5 } }
        data={ data }
        onMouseMove={ (e) => setActiveTooltipIndex(e.activeTooltipIndex) }
        onMouseLeave={ () => setActiveTooltipIndex(null) }
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={ palette.gradientColor } stopOpacity={ 0.3 } />
            <stop offset="80%" stopColor={ palette.gradientColor } stopOpacity={ 0 } />
          </linearGradient>
        </defs>
        <CartesianGrid horizontal={ false } stroke={ palette.gridColor } />
        {data.map((item) => {
          if (format(item.item, 'd') % 5 === 0) {
            return <ReferenceLine key={ item.item } x={ item.item } stroke={ palette.referenceLine } />;
          }
          return null;
        })}
        <YAxis
          stroke={ palette.axisColor }
          tickCount={ 10 }
        />
        <XAxis
          domain={ [getDataKeyMin(xDataKey), getDataKeyMax(xDataKey)] }
          dataKey={ xDataKey }
          tickLine={ false }
          stroke={ palette.axisColor }
          interval={ 0 }
          height={ 50 }
          tick={ <XTick activeTooltipIndex={ activeTooltipIndex } stroke={ palette.axisColor } isTimePeriod={ isTimePeriod } /> }
        >
          <Label
            value={ monthLabel }
            offset={ 0 }
            position="insideBottomLeft"
            dy={ -60 }
            dx={ 5 }
            fill={ palette.monthLabelColor }
          />
        </XAxis>
        <Tooltip content={ CustomTooltip } tooltipTextFormat={ tooltipTextFormat } cursor={ { stroke: palette.cursorColor } } />
        <Area
          activeDot={ { r: 8, fill: palette.dotColor } }
          type="monotone"
          dataKey={ yDataKey }
          stroke={ palette.lineColor }
          fill="url(#colorUv)"
          strokeWidth={ 6 }
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

ActivityLineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.number,
      metrics: PropTypes.shape({
        impressions: PropTypes.number,
        spend: PropTypes.number,
        vastCompleteRate: PropTypes.number,
        ecpm: PropTypes.number,
      }),
    }),
  ).isRequired,
  xDataKey: PropTypes.string.isRequired,
  yDataKey: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
};

export default ActivityLineChart;
