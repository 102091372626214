import { AllowValuesItemClass } from './allowValuesItemClass';

export class AllowValuesClass {
  allow = true;
  values = [];

  constructor(item = {}, isTransformValues = true) {
    this.allow = typeof item.allow === 'boolean' ? item.allow : true;
    this.values = (item && item.values && this.getValues(item.values, isTransformValues)) || [];
  }

  getValues(values, isTransformValues) {
    if (values && values.length && isTransformValues) {
      if (values[0] instanceof Object) {
        return values.map((item) => item.id);
      }
      return values.map((item) => new AllowValuesItemClass({ id: item, name: item }));
    }
    return values;
  }
}
