import { useState } from 'react';
import PropTypes from 'prop-types';

const useDemographicGroup = ({
  formik,
  currentGroupItem,
  editMode,
  setView,
  unsavedSegments,
  setUnsavedSegments,
}) => {
  const segmentGroups = formik.values.targetingProfile.segmentGroups?.values || [];
  // State
  const [index] = useState(segmentGroups.reduce((cur, next, i) => {
    if (next.id === currentGroupItem.id) cur = i;
    return cur;
  }, 0));

  const closeDemographicGroup = (curItem) => {
    if (!editMode) {
      const filtered = segmentGroups.filter((val) => val.id !== curItem.id);
      formik.setFieldValue('targetingProfile.segmentGroups.values', filtered);
    }
    setView('general');
  };

  const saveDemographicGroup = (segments) => {
    formik.setFieldValue(`targetingProfile.segmentGroups.values[${index}].segments`, segments);
    setView('general');
  };

  const removeDemographicItem = (id) => {
    if (id) {
      const filtered = unsavedSegments.filter((val) => val.id !== id);
      setUnsavedSegments(filtered);
    }
  };

  return { index, saveDemographicGroup, closeDemographicGroup, removeDemographicItem };
};

useDemographicGroup.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({ segmentGroups: PropTypes.arrayOf(PropTypes.shape({})) }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  currentGroupItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.any]),
    name: PropTypes.string,
    segments: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  editMode: PropTypes.bool.isRequired,
  setView: PropTypes.func.isRequired,
  unsavedSegments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default useDemographicGroup;
