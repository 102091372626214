import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import authActions from 'actions/authActions';

// Libs
import { getToken } from 'libs/storageLibs';

const useAuth = (props) => {
  const { initialState } = props;

  const dispatch = useDispatch();

  // State
  const [isChecked, setIsChecked] = useState(false);

  // Selectors
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // useCallback
  const setIsAuthenticated = useCallback(
    (value) => dispatch(authActions.setIsAuthenticated(value)),
    [dispatch],
  );

  // useEffects
  useEffect(() => {
    if (initialState && getToken()) {
      setIsAuthenticated(true);
    }
    setIsChecked(true);
  }, [initialState, setIsAuthenticated]);

  return {
    isAuthenticated,
    isChecked,
  };
};

useAuth.defaultProps = { initialState: false };

useAuth.propTypes = { initialState: PropTypes.bool };

export default useAuth;
