import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

// Modules
import Modal from 'modules/Modal/Modal';

const PreviewModal = ({ isOpen, handleClose, creative }) => (
  <Modal open={ isOpen } onClose={ handleClose }>
    <Typography variant="h3" className="mb-2">Creative Preview</Typography>
    <div>
      {creative?.url && (
        <video width={ 640 } hegiht={ 480 } controls>
          <source src={ creative.url } />
          <track src="" kind="captions" srcLang="en" label="english_captions" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  </Modal>
);

PreviewModal.defaultProps = {
  isOpen: false,
  creative: null,
  handleClose: null,
};

PreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  creative: PropTypes.shape({ url: PropTypes.string }),
};

export default memo(PreviewModal);
