import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import dictionaryActions from 'actions/dictionaryActions';

// Selectors
import dictionarySelectors from 'selectors/dictionarySelectors';

const useFilterableCities = () => {
  const dispatch = useDispatch();
  const filteredCities = useSelector(dictionarySelectors.filteredCitiesSelector);

  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    filter: '',
    country: [],
    limit: 100,
  });

  const updateSearchParams = useCallback((newParams) => {
    setTouched(true);

    setSearchParams((prevParams) => ({
      ...prevParams,
      ...newParams,
    }));
  }, [setTouched]);

  const getFilteredCities = useCallback(
    async (params) => {
      setLoading(true);
      await dispatch(dictionaryActions.getFilteredCities(params));
      setLoading(false);
    }, [dispatch],
  );

  const clearFilteredCities = useCallback(
    () => dispatch(dictionaryActions.clearFilteredCities()), [dispatch],
  );

  useEffect(() => {
    if (!touched) return;

    if (searchParams.filter) {
      getFilteredCities(searchParams);
    } else {
      clearFilteredCities();
    }
  }, [clearFilteredCities, getFilteredCities, searchParams, touched]);

  return {
    updateSearchParams,
    filteredCities,
    loading,
  };
};

export default useFilterableCities;
