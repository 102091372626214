export class CustomFilterClass {
  allow = true;
  includeEmpty = false;
  type = 'CUSTOM_LIST'
  values = [];

  constructor(item) {
    this.allow = item?.allow ?? true;
    this.includeEmpty = item?.includeEmpty ?? false;
    this.type = item?.type || 'CUSTOM_LIST';
    this.values = item?.values || [];
  }
}
