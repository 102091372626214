import types from 'actions/actionTypes/insightActionTypes';

export const INITIAL_FILTERS = { period: 'FOR_1_DAYS', threshold: 10 };

const initialState = {
  insightList: [],
  insightTotal: 0,
  insightTarget: 0,
  insightCpm: {},
  filters: { period: 'FOR_1_DAYS', threshold: 10 },
};

const insightReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...INITIAL_FILTERS,
          ...action.payload,
        },
      };
    case types.GET_INSIGHT_LIST:
      return {
        ...state,
        insightList: [...action.payload],
      };

    case types.GET_INSIGHT_TOTAL:
      return {
        ...state,
        insightTotal: action.payload,
      };

    case types.GET_INSIGHT_TARGET:
      return {
        ...state,
        insightTarget: action.payload,
      };
    case types.GET_INSIGHT_CPM:
      return {
        ...state,
        insightCpm: action.payload,
      };

    default:
      return state;
  }
};

export default insightReducer;
