import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';

// Material
import { Button, Grid } from '@material-ui/core';

// Components
import DemographicTable from 'components/Campaigns/AddCampaign/Steps/Demographics/DemographicTable/DemographicTable';

// Modules
import EditableTitle from 'modules/_Factories/EditableTitle/EditableTitle';
import CustomTableCell from 'modules/_Table/TableCell/TableCell';

// Icons
import { ReactComponent as RecycleIcon } from 'assets/images/icons/recycle.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close_white.svg';

// Libs
import { textFormat } from 'libs/textFormat';

// Hooks
import useDemographicGroup from './useDemographicGroup';

// Styles
import classes from './DemographicGroup.module.scss';

const DemographicGroup = ({
  formik,
  currentGroupItem,
  editMode,
  setView,
  unsavedSegments,
  setUnsavedSegments,
}) => {
  const {
    index,
    saveDemographicGroup,
    closeDemographicGroup,
    removeDemographicItem,
  } = useDemographicGroup({ formik, currentGroupItem, editMode, setView, unsavedSegments, setUnsavedSegments });

  const fields = [
    { name: `targetingProfile.segmentGroups.values[${index}].name`, label: 'Total budget', placeholder: 'Group name...' },
  ];

  const columns = [
    {
      label: '',
      id: 'name',
      renderHeaderItem: () => (
        <EditableTitle
          name={ fields[0].name }
          placeholder={ fields[0].placeholder }
          touched={ formik.touched }
          value={ get(formik.values, fields[0].name) }
          className={ classes.editableTitle }
          // Options
          fullWidth
          required
          // Events
          onBlur={ formik.handleBlur }
          onChange={ formik.handleChange }
          // Extra
          errors={ formik.errors }
        />
      ),
      render: (rowData) => (
        <CustomTableCell
          firstRow={ rowData.segment.name }
          secondRow={ rowData.segment.path }
        />
      ),
    },
    {
      label: '',
      id: 'cpm',
      renderHeaderItem: () => (
        <div className={ classes.groupInfo }>
          <span>Segments:&nbsp;&nbsp;</span>
          <span>{ unsavedSegments.length }</span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;CPM:&nbsp;&nbsp;</span>
          <span>
            {textFormat(unsavedSegments.reduce((cur, next) => {
              cur += (next.cpm || next.segment.cpm);
              return cur;
            }, 0), 'currency')}
          </span>
        </div>
      ),
      render: (rowData) => (
        <>
          <div className={ classes.cpmInfo }>
            <span>{ textFormat(rowData.segment.cpm || rowData.cpm, 'currency') }</span>
          </div>
          <div className={ classes.cpmInfoText }>
            CPM
          </div>
        </>
      ),
    },
    {
      label: '',
      id: 'options',
      headCallbackFn: () => closeDemographicGroup(currentGroupItem),
      callbackFn: (id) => removeDemographicItem(id),
      renderHeaderItem: () => <CloseIcon />,
      render: () => <RecycleIcon />,
    },
  ];

  return (
    <DemographicTable
      data={ unsavedSegments }
      columns={ columns }
      component={ (
        <Grid container className={ classNames('figure-flex-end', classes.buttonsWrapper) }>
          <Grid item sm="auto" xs="auto">
            <Button
              className={ classes.cancelButton }
              variant="outlined"
              size="large"
              onClick={ () => closeDemographicGroup(currentGroupItem) }
              onKeyPress={ () => closeDemographicGroup(currentGroupItem) }
            >
              Cancel
            </Button>
          </Grid>

          <Grid item sm="auto" xs="auto">
            <Button
              className={
                classNames(
                  classes.addButton,
                  { [classes.disabledButton]: !currentGroupItem.name || !unsavedSegments.length },
                )
              }
              variant="outlined"
              size="large"
              disabled={ !currentGroupItem.name || !unsavedSegments.length }
              onClick={ () => saveDemographicGroup(unsavedSegments) }
              onKeyPress={ () => saveDemographicGroup(unsavedSegments) }
            >
              Save
            </Button>
          </Grid>

          <Grid item sm="auto" xs="auto">
            <Button
              className={ classes.addButton }
              variant="outlined"
              size="large"
              onClick={ () => setView('add') }
              onKeyPress={ () => setView('add') }
            >
              Add segment
            </Button>
          </Grid>
        </Grid>
      ) }
    />
  );
};

DemographicGroup.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  currentGroupItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.any]),
    name: PropTypes.string,
    segments: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  editMode: PropTypes.bool.isRequired,
  setView: PropTypes.func.isRequired,
  unsavedSegments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setUnsavedSegments: PropTypes.func.isRequired,
};

export default DemographicGroup;
