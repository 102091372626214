import config from 'config/config';
import client from 'client/axios';
import loadingActions from './loadingActions';

/** ************************************************ CRUD ****************************************************** */
const { serverUrl, apiVersion } = config;

/**
 * CREATE
 */
const post = (apiKey, model, withLoading) => async (dispatch) => {
  try {
    if (withLoading) dispatch(loadingActions.increaseCounter());
    const { data } = await client.post(`${serverUrl}${apiVersion}${apiKey}`, model);

    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return data;
  } catch (e) {
    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return Promise.reject(e);
  }
};

/**
 *
 * @param {string} apiKey
 * @param {FormData} data
 * @param {any} config
 */
const postWithFile = (apiKey, formData, conf, withLoading) => async (dispatch) => {
  try {
    if (withLoading) dispatch(loadingActions.increaseCounter());
    const { data } = await client.post(`${serverUrl}${apiVersion}${apiKey}`, formData, conf);

    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return data;
  } catch (e) {
    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return Promise.reject(e);
  }
};

/**
 * READ
 */
const getByPost = (apiKey, storeKey, model, params, withLoading) => async (dispatch) => {
  try {
    if (withLoading) dispatch(loadingActions.increaseCounter());
    const { data } = await client.post(`${serverUrl}${apiVersion}${apiKey}`, model, { params });

    dispatch({
      type: storeKey,
      payload: data,
    });

    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return data;
  } catch (e) {
    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return Promise.reject(e);
  }
};

const get = (apiKey, storeKey, params, withLoading) => async (dispatch) => {
  try {
    if (withLoading) dispatch(loadingActions.increaseCounter());
    const { data } = await client.get(`${serverUrl}${apiVersion}${apiKey}`, { params });

    if (storeKey) {
      dispatch({
        type: storeKey,
        payload: data,
      });
    }

    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return data;
  } catch (e) {
    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return Promise.reject(e);
  }
};

/**
 * UPDATE
 */
const put = (apiKey, model, withLoading, storeKey) => async (dispatch) => {
  try {
    if (withLoading) dispatch(loadingActions.increaseCounter());
    const { data } = await client.put(`${serverUrl}${apiVersion}${apiKey}`, model);
    if (storeKey) {
      dispatch({
        type: storeKey,
        payload: data,
      });
    }
    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return data;
  } catch (e) {
    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return Promise.reject(e);
  }
};

/**
 *
 * @param {string} apiKey
 * @param {FormData} data
 * @param {any} config
 */
const putWithFile = (apiKey, formData, conf, withLoading) => async (dispatch) => {
  try {
    if (withLoading) dispatch(loadingActions.increaseCounter());
    const { data } = await client.put(`${serverUrl}${apiVersion}${apiKey}`, formData, conf);

    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return data;
  } catch (e) {
    if (withLoading) dispatch(loadingActions.decreaseCounter());
    return Promise.reject(e);
  }
};

/** ********************************************** END OF CRUD ************************************************* */

/**
 * CLEAR METHODS
 */
const clear = (storeKey, value) => async (dispatch) => {
  dispatch({
    type: storeKey,
    payload: value,
  });
};

/** ************************************************ EXPORTS ****************************************************** */
export default { post, get, getByPost, put, clear, postWithFile, putWithFile };
