import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { getQueryParamsFromObject } from 'libs/query-string';

const useQueryParams = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const replaceQueryParams = useCallback((obj, callback) => {
    const queryParams = getQueryParamsFromObject(obj);
    history.replace(`${pathname}?${queryParams}`);
    if (callback) {
      callback();
    }
  }, [history, pathname]);

  const debouncedReplaceQueryParams = useCallback(debounce(replaceQueryParams, 500), [replaceQueryParams]);

  return { replaceQueryParams, debouncedReplaceQueryParams };
};

export default useQueryParams;
