import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import creativeActions from 'actions/creativeActions';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';
import VideoCreative from 'modules/PreviewCreative/VideoCreative';
import CollapsableCreative from 'modules/PreviewCreative/CollapsableCreative';
import CustomNativeCreative from 'modules/PreviewCreative/CustomNativeCreative';
import ImageCreative from 'modules/PreviewCreative/ImageCreative';
import classes from './CreativePreview.module.scss';

const CreativePreview = ({ id, className }) => {
  const dispatch = useDispatch();
  const [creative, setCreative] = useState(null);

  const getCreative = useCallback(async (creativeId) => dispatch(creativeActions.getCreativeById(creativeId, null, {}, false)), [dispatch]);
  const getAsset = useCallback(async (assetId) => dispatch(creativeActions.getAssetById(assetId, null, {}, false)), [dispatch]);

  const fetchCreativeData = useCallback(async (creativeId) => {
    const creativeData = await getCreative(creativeId);
    const promiseAssets = creativeData.assets?.map((assetId) => getAsset(assetId));
    const assets = await Promise.all(promiseAssets);

    setCreative({ ...creativeData, assets });
  }, [getCreative, getAsset]);

  useEffect(() => {
    if (!id) return;
    fetchCreativeData(id);
  }, [id, fetchCreativeData]);

  const renderPreview = () => {
    const { type, subType, template, assets } = creative;
    if (type === CREATIVE_TYPES.types.VIDEO) {
      return (
        <VideoCreative url={ creative.url } className={ classes.videoCreative } />
      );
    }

    if (type === CREATIVE_TYPES.types.NATIVE && template === CREATIVE_TEMPLATES.types.COLLAPSABLE) {
      const expandedAsset = assets.find((item) => isEqual(item.size, { width: 1920, height: 360 })) || {};
      const collapsedAsset = assets.find((item) => isEqual(item.size, { width: 695, height: 360 })) || {};

      return (
        <CollapsableCreative
          classNamePreviewWrapper={ classes.collapsablePreviewWrapper }
          classNamePreview={ classes.collapsablePreview }
          expandedUrl={ expandedAsset.url }
          collapsedUrl={ collapsedAsset.url }
        />
      );
    }

    if (type === CREATIVE_TYPES.types.NATIVE && template === CREATIVE_TEMPLATES.types.CUSTOM_NATIVE) {
      const mainAsset = assets.find((item) => item.imageType === 3) || {};
      const logoAsset = assets.find((item) => item.imageType === 1) || {};
      const titleAsset = assets.find((item) => item.type === 'TITLE') || {};
      const descriptionAsset = assets.find((item) => item.type === 'DATA' && item.dataType === 2) || {};
      const ctaAsset = assets.find((item) => item.type === 'DATA' && item.dataType === 12) || {};
      const attributionAsset = assets.find((item) => item.type === 'DATA' && item.dataType === 1) || {};

      return (
        <CustomNativeCreative
          className={ classes.customNativePreviewWrapper }
          mainUrl={ mainAsset.url }
          logoUrl={ logoAsset.url }
          title={ titleAsset.text || 'Title will be here' }
          description={ descriptionAsset.value || 'Description will be here' }
          buttonText={ ctaAsset.value || 'CTA will be here' }
          attributionText={ attributionAsset.value || 'Attribution will be here' }
        />
      );
    }

    if (type === CREATIVE_TYPES.types.NATIVE && subType === CREATIVE_TYPES.types.DISPLAY) {
      const displayAsset = assets[0] || {};
      return (
        <ImageCreative url={ displayAsset.value } />
      );
    }
    return null;
  };

  return (
    <div className={ classNames(classes.preview, className, { [classes.placeholder]: !creative }) }>
      { creative && renderPreview() }
    </div>
  );
};

export default memo(CreativePreview);
