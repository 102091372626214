import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Constants
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';

// Classes
import CreativeClass from 'classes/creative/creativeClass';

// Actions
import creativeActions from 'actions/creativeActions';
import ImageAssetClass from 'classes/asset/imageAssetClass';
import DataAssetClass from 'classes/asset/dataAssetClass';
import TitleAssetClass from 'classes/asset/titleAssetClass';
import AssetBaseClass from 'classes/asset/assetBaseClass';

const useAddCreative = () => {
  const dispatch = useDispatch();

  const createCreative = useCallback(async (model) => dispatch(creativeActions.createCreative(model)), [dispatch]);
  const createAsset = useCallback(async (model) => dispatch(creativeActions.createAsset(model)), [dispatch]);

  const buildAssetFormData = useCallback((assetModel, file) => {
    const formData = new FormData();
    const blobAssetModel = new Blob([JSON.stringify(assetModel, null, 2)], { type: 'application/json' });
    formData.append('asset', blobAssetModel);
    if (file) {
      formData.append('file', file, file.name);
    }
    return formData;
  }, []);

  const buildCreativeFormData = useCallback((values) => {
    const formData = new FormData();
    const creativeModel = new CreativeClass(values);
    const blobCreativeModel = new Blob([JSON.stringify(creativeModel, null, 2)], { type: 'application/json' });
    formData.append('creative', blobCreativeModel);

    if (values.file) {
      formData.append('file', values.file, values.file.name);
    }

    return formData;
  }, []);

  const buildDisplayCreativeFormData = useCallback(async (values) => {
    const assetValues = {
      name: 'Telly Data Asset',
      advertiser: values.advertiser,
      dataType: 501,
      type: 'DATA',
      url: values.url,
      expectedSize: {
        width: 300,
        height: 250,
      },
    };

    const assetFormData = buildAssetFormData(new DataAssetClass(assetValues), values.file);
    const asset = await createAsset(assetFormData);
    return buildCreativeFormData({
      ...values,
      template: null,
      type: CREATIVE_TYPES.types.NATIVE,
      assets: [asset.id],
    });
  }, [buildAssetFormData, buildCreativeFormData, createAsset]);

  const buildNativeCreativeFormData = useCallback(async (values) => {
    const { advertiser, assets } = values;
    const models = {
      IMAGE: ImageAssetClass,
      DATA: DataAssetClass,
      TITLE: TitleAssetClass,
    };
    const assetsFormData = assets.map((item) => {
      const { file, ...itemValues } = item;
      const ModelClass = models[item.type] || AssetBaseClass;
      const model = new ModelClass({ ...itemValues, advertiser });
      return buildAssetFormData(model, file);
    });

    const assetPromises = assetsFormData.map((item) => createAsset(item));
    const createdAssets = await Promise.all(assetPromises);
    return buildCreativeFormData({
      ...values,
      type: CREATIVE_TYPES.types.NATIVE,
      assets: createdAssets.map((item) => item.id),
    });
  }, [buildAssetFormData, buildCreativeFormData, createAsset]);

  const transformCreativeValues = useCallback(async (values) => {
    const { subType } = values;
    switch (subType) {
      case CREATIVE_TYPES.types.VIDEO: return buildCreativeFormData({ ...values, type: CREATIVE_TYPES.types.VIDEO });
      case CREATIVE_TYPES.types.DISPLAY: return buildDisplayCreativeFormData(values);
      case CREATIVE_TYPES.types.NATIVE: return buildNativeCreativeFormData(values);
      default: return null;
    }
  }, [buildCreativeFormData, buildDisplayCreativeFormData, buildNativeCreativeFormData]);

  return {
    buildAssetFormData,
    buildCreativeFormData,
    buildDisplayCreativeFormData,
    buildNativeCreativeFormData,
    transformCreativeValues,
    createCreative,
    createAsset,
  };
};

export default useAddCreative;
