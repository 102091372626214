import React, { memo, useCallback, useState } from 'react';
import { Button, Dialog, Grid, Slide } from '@material-ui/core';
import PropTypes from 'prop-types';
import CreativeFormSteps from 'components/Creatives/FormCreative/Steps';
import useFormCreative from 'components/Creatives/FormCreative/useFormCreative';
import PageTitle from 'modules/PageTitle/PageTitle';
import classes from './AddCreativeButton.module.scss';

function Transition(props) {
  return <Slide direction="up" { ...props } />;
}
const AddCreativeButton = ({ creativeInitialValues, onClick }) => {
  const [openCreativeModal, setOpenCreativeModal] = useState(false);

  const handleSubmit = useCallback(async (values, callbackFinally) => {
    const result = await onClick(values, () => {
      callbackFinally();
    });

    if (result) setOpenCreativeModal(false);
  }, [onClick, setOpenCreativeModal]);

  const { formik } = useFormCreative({ initialValues: creativeInitialValues, handleSubmit });

  return (
    <>
      <Button variant="contained" color="secondary" onClick={ () => setOpenCreativeModal(true) }>
        Add Creative
      </Button>

      <Dialog
        fullScreen
        open={ openCreativeModal }
        onClose={ () => setOpenCreativeModal(false) }
        TransitionComponent={ Transition }
      >
        <Grid className={ classes.creativeFormWrapper } container spacing={ 4 }>
          <Grid item container justify="space-between" xs={ 12 } spacing={ 2 }>
            <Grid item>
              <PageTitle title="Add Creative" />
            </Grid>
            <Grid item container xs justify="flex-end" spacing={ 2 }>
              <Grid item>
                <Button
                  variant="text"
                  size="large"
                  className="mr-2"
                  onClick={ () => setOpenCreativeModal(false) }
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={ formik.handleSubmit }
                  disabled={ !formik.isValid || formik.isSubmitting || !formik.dirty }
                >
                  Save
                </Button>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={ 12 }>
            <CreativeFormSteps formik={ formik } />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

AddCreativeButton.defaultProps = { onClick: Function.prototype };

AddCreativeButton.propTypes = { onClick: PropTypes.func };

export default memo(AddCreativeButton);
