import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get, intersectionWith } from 'lodash';

// Constants
import { CampaignSteps } from 'constants/index';

// Classes
import { CampaignClass } from 'classes/campaign/campaignClass';
import { TargetingClass } from 'classes/targetingClass_new';
import { ActionClass } from 'classes/campaign/actionClass';

// Hooks
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';

// Libs
import { validateErrors } from 'libs/validateErrors';
import { checkSegments } from 'libs/checkSegments';

// Dictionaries
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import DeliveryTypes from 'constants/dictionary/deliveryTypesDictionary';

// Actions
import campaignActions from 'actions/campaignActions';
import dictionaryActions from 'actions/dictionaryActions';
import {
  getTargetingProfile,
  createTargetingProfile,
  updateTargetingProfile,
  createAction,
} from 'actions/targetingProfilesActions';

// Selectors
import campaignSelectors from 'selectors/campaignSelectors';
import userSelectors from 'selectors/userSelectors';
import dictionarySelectors from 'selectors/dictionarySelectors';

export const buildThirdPartyBeacons = (beaconValues) => {
  const values = ['start', 'firstQuartile', 'midpoint', 'thirdQuartile', 'complete', 'click'];
  const events = {
    start: 'START',
    firstQuartile: 'FIRST_QUARTILE',
    midpoint: 'MIDPOINT',
    thirdQuartile: 'THIRD_QUARTILE',
    complete: 'COMPLETE',
    click: 'CLICK_THROUGH',
  };
  const beacons = values.reduce((accum, name) => [
    ...accum,
    ...beaconValues[name].map((item) => ({ event: events[name], url: item })),
  ], []);

  return beacons;
};

export const parseThirdPartyBeacons = (beacons) => {
  const events = {
    START: 'start',
    FIRST_QUARTILE: 'firstQuartile',
    MIDPOINT: 'midpoint',
    THIRD_QUARTILE: 'thirdQuartile',
    COMPLETE: 'complete',
    CLICK_THROUGH: 'click',
  };

  return beacons.reduce((accum, cur) => {
    const name = events[cur.event];
    return { ...accum, [name]: [...accum[name], cur.url] };
  }, {
    start: [],
    firstQuartile: [],
    midpoint: [],
    thirdQuartile: [],
    complete: [],
    click: [],
  });
};

export const createCampaignModel = (values) => {
  const campaignModel = new CampaignClass({
    ...values,
    thirdPartyBeacons: buildThirdPartyBeacons(values.thirdPartyBeacons),
  });
  return campaignModel;
};

export const transformTargetingProfileValues = (targeting) => {
  const transform = (arr) => arr.map((item) => item.id);

  const geoTargeting = ['cities', 'states', 'counties', 'countries', 'dmas', 'districts', 'zips'];

  geoTargeting.forEach((name) => {
    if (!targeting[name]?.values?.length) return;

    targeting[name] = {
      ...targeting[name],
      values: transform(targeting[name].values),
    };
  });

  if (targeting.segmentGroups?.values?.length) {
    const copySegmentGroups = targeting.segmentGroups.values.map((group) => ({ ...group }));
    targeting.segmentGroups.values = checkSegments(copySegmentGroups);
  }

  return targeting;
};

const useAddCampaign = ({ id, systemType, setEditable, isCopy }) => {
  const history = useHistory();

  // State
  const [innerId] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [targetingProfile, setTargetingProfile] = useState(new TargetingClass());

  // Hooks
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const createCampaign = useCallback((model) => dispatch(campaignActions.createCampaign(model)), [dispatch]);
  const updateCampaign = useCallback((model) => dispatch(campaignActions.updateCampaign(model)), [dispatch]);
  const clearCampaign = useCallback(() => dispatch(campaignActions.clearCampaignById()), [dispatch]);

  const updateTargeting = useCallback(
    (targetingProfileId, model) => dispatch(updateTargetingProfile(targetingProfileId, model)),
    [dispatch],
  );
  const createTargeting = useCallback((model) => dispatch(createTargetingProfile(model)), [dispatch]);
  const dispatchCreateAction = useCallback((model) => dispatch(createAction(model)), [dispatch]);

  const getTargeting = useCallback((targetingProfileId) => dispatch(getTargetingProfile(targetingProfileId)), [dispatch]);

  const getCampaignById = useCallback(
    () => dispatch(campaignActions.getCampaignById(innerId)),
    [innerId, dispatch],
  );

  // Selectors
  const campaign = useSelector(campaignSelectors.campaignSelector);
  const user = useSelector(userSelectors.userSelector);
  const DMAs = useSelector(dictionarySelectors.DMAsSelector);
  const countries = useSelector(dictionarySelectors.countriesSelector);

  // Formik
  const initialValues = useMemo(() => {
    if (!campaign.timeZone) campaign.timeZone = user.timeZone;

    return ({
      ...campaign,
      ...!campaign.delivery && { delivery: DeliveryTypes.types.SMOOTH },
      ...isCopy && {
        id: null,
        name: `${campaign.name} copy`,
        status: CampaignStatuses.types.DRAFT,
      },
      systemType,
      targetingProfile,
      thirdPartyBeacons: parseThirdPartyBeacons(campaign.thirdPartyBeacons),
    });
  }, [campaign, targetingProfile, user.timeZone, isCopy, systemType]);

  const { cities, states, counties, countries: countryCodes, dmas, districts, zips } = initialValues.targetingProfile;
  const initialStatus = useRef(initialValues.status);

  // TODO: the function needs refactoring
  const validateCreatives = (creatives) => {
    let adError = null;
    if (!creatives.length) adError = 'Should be at least 1 creative';

    return adError && { creatives: { adCreatives: adError } };
  };

  const validateCapping = (capping, valueError, periodError) => {
    let value = null;
    let period = null;
    if (!capping || (!capping.value && !capping.period)) return null;
    if (!capping.value) value = valueError;
    if (!capping.period) period = periodError;
    return (value || period) && { value, period };
  };

  const validateIfaCapping = (ifaCapping) => {
    const error = validateCapping(ifaCapping, 'Device Frequency Capping is required', 'Period is required');
    return error && { ifaCapping: error };
  };

  const validateBudgetCapping = (budgetCapping) => {
    const error = validateCapping(budgetCapping, 'Budget Capping is required', 'Period is required');
    return error && { budgetCapping: error };
  };

  const validateImpressionsCapping = (impCapping) => {
    const error = validateCapping(impCapping, 'Impressions Capping is required', 'Period is required');
    return error && { impCapping: error };
  };

  const validateSsps = (ssps) => {
    const isProgrammaticDirect = systemType === CampaignTypes.types.PROGRAMMATIC_DIRECT;

    if (isProgrammaticDirect && !get(ssps, 'values.length', 0)) {
      return { ssps: 'Supply partner is required' };
    }

    return null;
  };

  const validate = (values) => {
    const requiredValues = ['name', 'advertiser', 'budgetTarget', 'pricingStrategy.cpm', 'delivery'];

    const validation = { requiredValues };

    return {
      ...validateErrors(values, validation),
      ...values.status !== 'DRAFT' && validateCreatives(values.creatives),
      ...validateBudgetCapping(values.budgetCapping),
      ...validateImpressionsCapping(values.impCapping),
      ...validateIfaCapping(values.ifaCapping),
      ...validateSsps(values.ssps, values.systemType),
    };
  };

  const createActionAndTargetingProfile = (actionModel, targetingProfileModel) => {
    const actionFormData = new FormData();
    actionFormData.append('action', JSON.stringify(actionModel));
    return Promise.all([
      dispatchCreateAction(actionFormData),
      createTargeting(targetingProfileModel),
    ]);
  };

  const getCampaignActionsModel = (action, profile) => [{
    action: { id: action.id, name: action.name, type: action.type },
    targetings: [profile.id],
    conj: 'AND',
  }];

  const processCreatingCampaign = async (values) => {
    /* process creating campaign
      1) create campaign
      2) create action and create targeting profile
      3) attach created action and targeting profile to created campaign
    */
    const { targetingProfile: targetingProfileValues, ...campaignValues } = values;
    const campaignModel = createCampaignModel(campaignValues);

    const createdCampaign = await createCampaign(campaignModel);
    const actionModel = new ActionClass({ name: `Action for Telly Campaign ${createdCampaign.id}` });
    const targetingProfileModel = new TargetingClass({
      ...targetingProfileValues,
      ...transformTargetingProfileValues(targetingProfileValues),
      name: `Targeting Profile for Telly campaign ${createdCampaign.id}`,
    });
    const [createdAction, createdTargetingProfile] = await createActionAndTargetingProfile(actionModel, targetingProfileModel);
    const updatedCampaign = await updateCampaign({
      ...createdCampaign,
      actions: getCampaignActionsModel(createdAction, createdTargetingProfile),
    });
    return updatedCampaign;
  };

  const processUpdatingCampaign = async (values) => {
    /* process updating campaign
      1) targeting profile has attached to campaign
        a) update targeting profile and campaign parallel
      2) campaign does not have targetin profile
        a) create action and targeting profile
        b) attach created action and targeting profile to campaign model
        c) create campaign
    */
    const { targetingProfile: targetingProfileValues, ...campaignValues } = values;
    const campaignModel = createCampaignModel(campaignValues);

    const { targeting } = (campaignModel.actions?.[0] || {});
    const actionModel = new ActionClass({ name: `Action for Telly Campaign ${innerId}` });
    const targetingProfileModel = new TargetingClass({
      ...targetingProfileValues,
      ...transformTargetingProfileValues(targetingProfileValues),
      name: `Targeting Profile for Telly campaign ${innerId}`,
    });

    if (targeting) {
      await Promise.all([
        updateTargeting(targeting, targetingProfileModel),
        updateCampaign(campaignModel),
      ]);
    } else {
      const [createdAction, createdTargetingProfile] = await createActionAndTargetingProfile(actionModel, targetingProfileModel);
      await updateCampaign({ ...campaignModel, actions: getCampaignActionsModel(createdAction, createdTargetingProfile) });
    }
  };

  const submitHandler = async (values, { setSubmitting }) => {
    try {
      if (innerId && !isCopy) {
        await processUpdatingCampaign(values);
        // enqueueSnackbar(`Campaign (id: ${innerId}) updated successfully`, { variant: 'success' });
      } else {
        const createdCampaign = await processCreatingCampaign(values);
        enqueueSnackbar(`Campaign (id: ${createdCampaign.id}) created successfully`, { variant: 'success' });
      }

      history.push('/campaigns');
    } catch (e) {
      // If error - set default campaign status
      values.status = initialStatus.current;
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: submitHandler,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const { setFieldValue } = formik;

  // Add reset method TODO replace it to native formik method
  formik.resetField = (name) => {
    formik.setFieldValue(name, initialValues[name]);
  };

  const getCampaignTargetingProfile = useCallback(async (profileId) => {
    const data = await getTargeting(profileId);

    const profile = new TargetingClass({ ...data, ...isCopy && { id: null } });
    setTargetingProfile(profile);
  }, [getTargeting, setTargetingProfile, isCopy]);

  // useEffect
  useEffect(() => {
    if (!initialValues.status) return;

    switch (initialValues.status) {
      case CampaignStatuses.types.CLOSED:
      case CampaignStatuses.types.FINISHED:
      case CampaignStatuses.types.CANCELED:
        setEditable(false);
        break;
      default: break;
    }
  }, [initialValues.status, setEditable]);

  useEffect(() => {
    (async () => {
      try {
        if (!innerId) return;
        await getCampaignById();
      } finally {
        setIsLoading(false);
      }
    })();
  }, [innerId, getCampaignById, setIsLoading]);

  useEffect(() => {
    if (!campaign && !campaign.id) return;
    const targetingId = campaign.actions?.[0]?.targetings?.[0];

    if (targetingId) getCampaignTargetingProfile(targetingId);
  }, [campaign, getCampaignTargetingProfile]);

  useEffect(() => () => {
    CampaignSteps.forEach((item, i) => { if (i > 0) item.setTouched(false); });
    clearCampaign();
  }, [clearCampaign]);

  // Validate Datepicker after reset action
  useEffect(() => {
    if (formik && systemType !== CampaignTypes.types.AUDIENCE) {
      const { values } = formik;
      if (!values.start) {
        formik.setFieldError('start', 'Start date is required');
      }
      if (!values.end) {
        formik.setFieldError('end', 'End date is required');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, systemType]);

  // Should for receiving and show needed data by countries on Geography step
  useEffect(() => {
    if (!zips.values.length) return;

    const zipCodes = zips.values.map((zipId) => ({ id: zipId, name: zipId }));
    setFieldValue('targetingProfile.zips.values', zipCodes);
  }, [setFieldValue, zips]);

  // Should for receiving and show needed data by countries on Geography step
  useEffect(() => {
    if (!countryCodes.values.length) return;

    const intersectionCountries = intersectionWith(countries, countryCodes.values, (a, b) => a.id === b);
    setFieldValue('targetingProfile.countries.values', intersectionCountries);
  }, [setFieldValue, countryCodes, countries]);

  // Should for receiving and show needed data by DMA on Geography step
  useEffect(() => {
    if (!dmas.values.length) return;

    const intersectionDma = intersectionWith(DMAs, dmas.values, (a, b) => a.id === b);
    setFieldValue('targetingProfile.dmas.values', intersectionDma);
  }, [setFieldValue, dmas, DMAs]);

  // Should for receiving and show needed data by states on Geography step
  useEffect(() => {
    if (!states.values.length) return;

    (async () => {
      const stateIds = states.values;
      const data = await dictionaryActions.getFilteredStates({ id: stateIds })();
      const intersectionStates = intersectionWith(data, states.values, (a, b) => a.id === b).map((item) => ({
        ...item,
        name: `${item.name} (${item.id})`,
      }));
      setFieldValue('targetingProfile.states.values', intersectionStates);
    })();
  }, [setFieldValue, states]);

  // Should for receiving and show needed data by congressional districts on Geography step
  useEffect(() => {
    if (!districts.values.length) return;

    (async () => {
      const districtIds = districts.values;
      const data = await dictionaryActions.getFilteredCongressionalDistricts({ id: districtIds })();
      const intersectionDistricts = intersectionWith(data, districts.values, (a, b) => a.id === b).map((item) => ({
        ...item,
        name: `${item.name} (${item.id})`,
      }));
      setFieldValue('targetingProfile.districts.values', intersectionDistricts);
    })();
  }, [setFieldValue, districts]);

  // Should for receiving and show needed data by counties on Geography step
  useEffect(() => {
    if (!counties.values.length) return;

    (async () => {
      const countyIds = counties.values;
      const data = await dictionaryActions.getFilteredCounties({ id: countyIds })();
      const intersectionCounties = intersectionWith(data, counties.values, (a, b) => a.id === b).map((item) => ({
        ...item,
        name: `${item.name} (${item.id})`,
      }));
      setFieldValue('targetingProfile.counties.values', intersectionCounties);
    })();
  }, [setFieldValue, counties, dispatch]);

  // Should for receiving and show needed data by cities on Geography step
  useEffect(() => {
    if (!cities.values.length) return;

    (async () => {
      const cityIds = cities.values;
      const data = await dictionaryActions.getFilteredCities({ id: cityIds })();
      const intersectionCities = intersectionWith(data, cities.values, (a, b) => a.code === b).map((item) => ({
        ...item,
        id: item.code,
        name: `${item.name} (${item.state ? item.state.name : item.code})`,
      }));

      setFieldValue('targetingProfile.cities.values', intersectionCities);
    })();
  }, [setFieldValue, cities, dispatch]);

  return { formik, validateCreatives, buildThirdPartyBeacons, isLoading };
};

export default useAddCampaign;
