import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { get } from 'lodash';

// Images
import { ReactComponent as StateMapSVG } from 'assets/images/maps/states.svg';
import variables from 'assets/styles/variables.scss';

// Modules
import ZoomButton from 'modules/Map/ZoomButton/zoomButton';
import Legend from 'modules/Map/MapSvg/Legend';
import useZoomSvg from 'hooks/useZoomSvg';
import MapPopover from './MapPopover/MapPopover';

// Hooks
import useStateMap from './useStateMap';

// Styles
import classes from './StateMap.module.scss';

const getColorByImpressions = (impressions) => {
  if (impressions < 1000) return variables.lightBlue;
  if (impressions >= 1000 && impressions < 10000) return variables.paleCornflowerBlue;
  if (impressions >= 10000 && impressions < 100000) return variables.cornflowerBlue;
  if (impressions >= 100000 && impressions < 1000000) return variables.blue;
  if (impressions >= 1000000 && impressions < 10000000) return variables.darkBlue;
  if (impressions >= 10000000) return variables.royalBlue;
  return null;
};

const StateMap = () => {
  const mapRef = useRef();
  const popoverId = useMemo(() => 'popover', []);
  const {
    mouseMoveDebounced,
    top,
    left,
    stateInfo,
    stateIdToMapIdHashMap,
    statsByStatesHashMap,
    closeTooltip,
  } = useStateMap(popoverId);
  const { increaseZoom, decreaseZoom, isMoving, isMinZoom, isMaxZoom } = useZoomSvg(mapRef.current);

  const handleClickMinus = useCallback(() => {
    decreaseZoom();
    closeTooltip();
  }, [decreaseZoom, closeTooltip]);

  const handleClickPlus = useCallback(() => {
    increaseZoom();
    closeTooltip();
  }, [increaseZoom, closeTooltip]);

  useEffect(() => {
    if (!mapRef.current) return;

    Object.keys(statsByStatesHashMap).forEach((key) => {
      const id = stateIdToMapIdHashMap[key];
      if (!id) return;
      const element = mapRef.current.getElementById(id);
      const color = getColorByImpressions(get(statsByStatesHashMap[key], 'metric.impressions'));
      if (color) element.setAttribute('style', `fill: ${color}`);
    });
  }, [mapRef, stateIdToMapIdHashMap, statsByStatesHashMap]);

  return (
    <>
      <div className={ classes.wrapper }>
        <StateMapSVG
          ref={ mapRef }
          className={ classes.map }
          onMouseMove={ (e) => {
            if (isMoving) return;
            e.persist();
            mouseMoveDebounced(e);
          } }
        />
        <ZoomButton
          increaseZoom={ handleClickPlus }
          decreaseZoom={ handleClickMinus }
          increaseDisabled={ isMaxZoom }
          decreaseDisabled={ isMinZoom }
        />
        {!isMoving && (
          <MapPopover
            id={ popoverId }
            top={ top }
            left={ left }
            data={ stateInfo }
            onMouseEnter={ (e) => {
              e.persist();
              mouseMoveDebounced(e);
            } }
          />
        )}
        <Legend />
      </div>
    </>
  );
};

export default StateMap;
