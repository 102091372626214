import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { Grid } from '@material-ui/core';

// Libs
import { get } from 'lodash';

// Modules
import Select from 'modules/_Factories/Select/Select';
import Input from 'modules/_Factories/Input/Input';
import { StepContainer } from 'modules/Steps';

// Dictionaries
import DeliveryTypes from 'constants/dictionary/deliveryTypesDictionary';
import CappingPeriodDictionary from 'constants/dictionary/cappingPeriodDictionary';

// Styles
import FilledCheckbox from 'modules/_Factories/Checkbox/FilledCheckbox/FilledCheckbox';
import classes from './BudgetAndConstraints.module.scss';

const BudgetAndConstraints = ({ formik, editable }) => {
  const [showMoreSettings, setShowMoreSettings] = useState(() => !!formik.values.budgetCapping?.period ||
    !!formik.values.impCapping?.period ||
    !!formik.values.ifaCapping?.period);

  const fields = [
    { name: 'budgetTarget', label: 'Campaign Budget' },
    { name: 'impsTarget', label: 'Total impressions' },
    { name: 'pricingStrategy.cpm', label: 'CPM' },
    { name: 'delivery', label: 'Pacing *' },
    { name: 'budgetCapping', label: 'Spend Limit' },
    { name: 'budgetCapping', label: 'Per period' },
    { name: 'impCapping', label: 'Impression Limit' },
    { name: 'impCapping', label: 'Per period' },
    { name: 'ifaCapping', label: 'Impression Limit per Device' },
    { name: 'ifaCapping', label: 'Per period' },
  ];

  return (
    <StepContainer
      title="Budget your campaign"
      subtitle="You can provide the total daily budget as well as put a cap on daily spend"
    >
      <Grid container spacing={ 4 }>

        {/* CAMPAIGN BUDGET/IMPRESSIONS */}
        <Grid container item spacing={ 4 }>
          <Grid item xs={ 12 } sm={ 6 }>
            <Input
              type="number"
              name={ fields[0].name }
              label={ fields[0].label }
              value={ get(formik.values, fields[0].name) || '' }
              // Options
              fullWidth
              required
              disabled={ !editable }
              // Events
              onChange={ formik.handleChange }
              onBlur={ formik.handleBlur }
              setFieldTouched={ formik.setFieldTouched }
              // Extra
              errors={ formik.errors }
              touched={ formik.touched }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <Input
              type="number"
              name={ fields[1].name }
              label={ fields[1].label }
              value={ get(formik.values, fields[1].name) || '' }
              // Options
              fullWidth
              disabled={ !editable }
              // Events
              onChange={ formik.handleChange }
              onBlur={ formik.handleBlur }
              setFieldTouched={ formik.setFieldTouched }
              // Extra
              errors={ formik.errors }
              touched={ formik.touched }
            />
          </Grid>
        </Grid>

        {/* NET CPM/PACING */}
        <Grid container item spacing={ 4 }>
          <Grid item xs={ 12 } sm={ 6 }>
            <Input
              type="number"
              name={ fields[2].name }
              label={ fields[2].label }
              value={ get(formik.values, fields[2].name) }
              // Options
              fullWidth
              required
              disabled={ !editable }
              // Events
              onChange={ formik.handleChange }
              onBlur={ formik.handleBlur }
              setFieldTouched={ formik.setFieldTouched }
              // Extra
              errors={ formik.errors }
              touched={ formik.touched }
            />
          </Grid>

          <Grid item xs={ 12 } sm={ 6 }>
            <Select
              name={ fields[3].name }
              label={ fields[3].label }
              value={ get(formik.values, fields[3].name) }
              itemList={ DeliveryTypes.dictionary }
              // Options
              showNoneItem={ false }
              isFilter
              fullWidth
              required
              disabled={ !editable }
              // Events
              onChange={ formik.handleChange }
              setFieldTouched={ formik.setFieldTouched }
              // Extra
              errors={ formik.errors }
              touched={ formik.touched }
            />
          </Grid>
        </Grid>

        <Grid item>
          <FilledCheckbox
            simpleCheckbox
            label="Advanced"
            name="advanced"
            checked={ showMoreSettings }
            onChange={ () => setShowMoreSettings((prev) => !prev) }
          />
        </Grid>

        {showMoreSettings && (
          <>
            <Grid container item spacing={ 4 } className={ classes.border }>
              <legend className={ classes.legend }>Limits</legend>
              {/* BUDGET CAPPING */}
              <Grid container item spacing={ 4 }>
                <Grid item xs={ 12 } sm={ 6 }>
                  <Input
                    type="number"
                    name={ fields[4].name }
                    label={ fields[4].label }
                    value={ get(formik, `values.${fields[4].name}.value`) || '' }
                    // Options
                    fullWidth
                    disabled={ !editable }
                    // Events
                    onChange={ (e) => {
                      formik.setFieldValue(e.target.name, {
                        ...get(formik, `values.${fields[4].name}`),
                        value: e.target.value,
                      });
                    } }
                    onBlur={ formik.handleBlur }
                    setFieldTouched={ formik.setFieldTouched }
                    // Extra
                    err={ get(formik, `errors.${fields[5].name}.value`) }
                    touched={ formik.touched }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                  <Select
                    name={ fields[5].name }
                    label={ fields[5].label }
                    value={ get(formik, `values.${fields[5].name}.period`) || '' }
                    itemList={ CappingPeriodDictionary.dictionary }
                    // Options
                    isFilter
                    isFilterText="none"
                    fullWidth
                    required
                    disabled={ !editable }
                    // Events
                    onChange={ (e) => {
                      formik.setFieldValue(e.target.name, {
                        ...get(formik, `values.${fields[4].name}`),
                        period: e.target.value || null,
                      });
                    } }
                    setFieldTouched={ formik.setFieldTouched }
                    // Extra
                    err={ get(formik, `errors.${fields[5].name}.period`) }
                    touched={ formik.touched }
                  />
                </Grid>
              </Grid>

              {/* IMPRESSIONS CAPPING */}
              <Grid container item spacing={ 4 }>
                <Grid item xs={ 12 } sm={ 6 }>
                  <Input
                    type="number"
                    name={ fields[6].name }
                    label={ fields[6].label }
                    value={ get(formik, `values.${fields[6].name}.value`) || '' }
                    // Options
                    fullWidth
                    disabled={ !editable }
                    // Events
                    onChange={ (e) => {
                      formik.setFieldValue(e.target.name, {
                        ...get(formik, `values.${fields[6].name}`),
                        value: e.target.value,
                      });
                    } }
                    onBlur={ formik.handleBlur }
                    setFieldTouched={ formik.setFieldTouched }
                    // Extra
                    err={ get(formik, `errors.${fields[7].name}.value`) }
                    touched={ formik.touched }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                  <Select
                    name={ fields[7].name }
                    label={ fields[7].label }
                    value={ get(formik, `values.${fields[7].name}.period`) || '' }
                    itemList={ CappingPeriodDictionary.dictionary }
                    // Options
                    isFilter
                    isFilterText="none"
                    fullWidth
                    required
                    disabled={ !editable }
                    // Events
                    onChange={ (e) => {
                      formik.setFieldValue(e.target.name, {
                        ...get(formik, `values.${fields[6].name}`),
                        period: e.target.value || null,
                      });
                    } }
                    setFieldTouched={ formik.setFieldTouched }
                    // Extra
                    err={ get(formik, `errors.${fields[7].name}.period`) }
                    touched={ formik.touched }
                  />
                </Grid>
              </Grid>

            </Grid>

            {/* DEVICE FREQUENCY CAPPING */}
            <Grid container item spacing={ 4 } className={ classes.border }>
              <legend className={ classes.legend }>Device Limits</legend>

              <Grid item xs={ 12 } sm={ 6 }>
                <Input
                  type="number"
                  name={ fields[8].name }
                  label={ fields[8].label }
                  value={ get(formik, `values.${fields[8].name}.value`) || '' }
                  // Options
                  fullWidth
                  disabled={ !editable }
                  // Events
                  onChange={ (e) => {
                    formik.setFieldValue(e.target.name, {
                      ...get(formik, `values.${fields[8].name}`),
                      value: e.target.value,
                    });
                  } }
                  onBlur={ formik.handleBlur }
                  setFieldTouched={ formik.setFieldTouched }
                  // Extra
                  err={ get(formik, `errors.${fields[9].name}.value`) }
                  touched={ formik.touched }
                />
              </Grid>
              <Grid item xs={ 12 } sm={ 6 }>
                <Select
                  name={ fields[9].name }
                  label={ fields[9].label }
                  value={ get(formik, `values.${fields[9].name}.period`) || '' }
                  itemList={ CappingPeriodDictionary.dictionary }
                  // Options
                  isFilter
                  isFilterText="none"
                  fullWidth
                  required
                  disabled={ !editable }
                  // Events
                  onChange={ (e) => {
                    formik.setFieldValue(e.target.name, {
                      ...get(formik, `values.${fields[8].name}`),
                      period: e.target.value || null,
                    });
                  } }
                  setFieldTouched={ formik.setFieldTouched }
                  // Extra
                  err={ get(formik, `errors.${fields[9].name}.period`) }
                  touched={ formik.touched }
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

    </StepContainer>
  );
};

BudgetAndConstraints.defaultProps = { };

BudgetAndConstraints.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      systemType: PropTypes.string,
      budgetTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      budgetCapping: PropTypes.shape({
        period: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      impsTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      impCapping: PropTypes.shape({
        period: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      cpm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      delivery: PropTypes.string,
      ifaCapping: PropTypes.shape({
        period: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  editable: PropTypes.bool.isRequired,
};

export default BudgetAndConstraints;
