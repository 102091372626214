import { useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useDynamicBackground = () => {
  const location = useLocation();

  const { pathname } = location;

  const isLoginRoute = {
    path: '/login',
    className: 'login-page',
  };

  const isSignupRoute = {
    path: '/signup',
    className: 'signup-page',
  };

  const isForgotPasswordRoute = {
    path: '/forgot-password',
    className: 'forgot-password-page',
  };

  const isResetPasswordRoute = {
    path: '/reset-password',
    className: 'reset-password-page',
  };

  const root = useMemo(() => document.getElementById('root'), []);

  const setClassToRootElement = useCallback(
    (className) => {
      root.className = '';

      if (className) {
        root.className = className;
      }
    },
    [root.className],
  );

  const findRouteMatch = useCallback(() => {
    if (pathname.includes(isLoginRoute.path) || pathname.includes(isSignupRoute.path)) {
      setClassToRootElement(isLoginRoute.className);
    } else if (pathname.includes(isForgotPasswordRoute.path)) {
      setClassToRootElement(isForgotPasswordRoute.className);
    } else if (pathname.includes(isResetPasswordRoute.path)) {
      setClassToRootElement(isResetPasswordRoute.className);
    } else {
      setClassToRootElement('client-page');
    }
  }, [
    isLoginRoute.className,
    isLoginRoute.path,
    isForgotPasswordRoute.className,
    isForgotPasswordRoute.path,
    isResetPasswordRoute.className,
    isResetPasswordRoute.path,
    isSignupRoute.path,
    pathname,
    setClassToRootElement,
  ]);

  useEffect(() => {
    findRouteMatch();
  }, [findRouteMatch]);
};

export default useDynamicBackground;
