import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import Input from 'modules/_Factories/Input/Input';
import useSignupForm from './useSignupForm';
import classes from './SignupForm.module.scss';

const SignupForm = ({ className, onSubmit }) => {
  const { formik } = useSignupForm({ handleSubmit: onSubmit });

  return (
    <form className={ classNames(className) }>
      <div className={ classes.fieldWrapper }>
        <Input
          name="firstName"
          label="First Name"
          value={ formik.values.firstName }
          fullWidth
          required
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
        <Input
          name="lastName"
          label="Last Name"
          value={ formik.values.lastName }
          fullWidth
          required
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
        <Input
          name="email"
          label="Email"
          value={ formik.values.email }
          fullWidth
          required
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
        <Input
          name="company"
          label="Company Name"
          value={ formik.values.company }
          fullWidth
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
        <Input
          name="website"
          label="Website"
          value={ formik.values.website }
          fullWidth
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
        <Input
          name="phone"
          label="Phone"
          value={ formik.values.phone }
          fullWidth
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          errors={ formik.errors }
          touched={ formik.touched }
        />
      </div>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        type="submit"
        size="large"
        disabled={ formik.isSubmitting }
        onClick={ formik.handleSubmit }
      >
        SIGN UP
      </Button>
    </form>
  );
};

SignupForm.defaultProps = { className: '' };

SignupForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(SignupForm);
