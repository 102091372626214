export class CreativeListClass {
  advertiser = null;
  campaignsCount = 0;
  duration = null;
  id = null;
  mime = null;
  name = null;
  sizeValue = null;
  status = null;
  type = null;
  url = null;

  constructor(item) {
    this.advertiser = item.advertiser || null;
    this.campaignsCount = item.campaignsCount || 0;
    this.duration = item.duration || null;
    this.id = item.id || null;
    this.mime = item.mime || null;
    this.name = item.name || null;
    this.sizeValue = item.sizeValue || null;
    this.status = item.status || null;
    this.type = item.type || null;
    this.url = item.url || null;
  }
}
