import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

// Router
import ProtectedRoute from 'router/ProtectedRoute';

// Modules
import Header from 'modules/Header/Header';
import Footer from 'modules/Footer/Footer';

// Utils
import lazyLoad from 'utils/lazyLoad';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';

// Hooks
import useTimeZones from 'hooks/dictionary/useTimeZones';
import useCategories from 'hooks/dictionary/useCategories';
import useLanguages from 'hooks/dictionary/useLanguages';
import useDMAs from 'hooks/dictionary/useDMAs';
import useCountries from 'hooks/dictionary/useCountries';
import useDemographics from 'hooks/dictionary/useDemographics';
import useMain from './useMain';

// Components
import Dashboard from './Dashboard/Dashboard';
import Advertisers from './Advertisers/Advertisers';
import Campaigns from './Campaigns/Campaigns';
import Creatives from './Creatives';

// Styles
import classes from './Main.module.scss';

const ReportsPromise = import('./Reports/Reports');
const InsightsPromise = import('./Insights/Insights');
const ProfilePromise = import('./Profile/Profile');
const NotFoundPromise = import('./NotFound/NotFound');

const LazyReports = lazyLoad(React.lazy(() => ReportsPromise));
const LazyInsights = lazyLoad(React.lazy(() => InsightsPromise));
const LazyProfile = lazyLoad(React.lazy(() => ProfilePromise));
const LazyNotFound = lazyLoad(React.lazy(() => NotFoundPromise));

const Main = () => {
  useTimeZones(true);
  useDMAs(true);
  useCountries(true);
  useDemographics(true);
  useCategories(true);
  useLanguages(true);

  const { user, environment } = useMain();

  return (
    <>
      <div className={ classes.container }>
        <Header user={ user } />
        <Switch>
          <ProtectedRoute
            path="/dashboard"
            component={ Dashboard }
            requiredPermissions={ [UserPermissions.types.DASHBOARD_READ] }
          />
          <ProtectedRoute
            path="/advertisers"
            component={ Advertisers }
            requiredPermissions={ [UserPermissions.types.ADVERTISER_READ] }
          />
          <ProtectedRoute
            path="/campaigns"
            component={ Campaigns }
            requiredPermissions={ [UserPermissions.types.CAMPAIGN_READ] }
          />
          <ProtectedRoute
            path="/creatives"
            component={ Creatives }
            requiredPermissions={ [UserPermissions.types.CREATIVE_READ] }
          />
          <ProtectedRoute
            path="/reports"
            component={ LazyReports }
            requiredPermissions={ [UserPermissions.types.REPORT_READ] }
          />
          <ProtectedRoute
            path="/insights"
            component={ LazyInsights }
            requiredPermissions={ [UserPermissions.types.INSIGHTS] }
          />
          <ProtectedRoute
            path="/profile"
            component={ LazyProfile }
          />
          <ProtectedRoute
            path="/404-not-found"
            component={ LazyNotFound }
          />
        </Switch>
      </div>
      <Footer version={ environment.version } />
    </>
  );
};

Main.propTypes = { match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired };

export default Main;
