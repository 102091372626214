import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

// Material
import { Checkbox } from '@material-ui/core';

// Icons
import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';

// Styles
import classes from './OutlineCheckbox.module.scss';

const OutlineCheckbox = (props) => {
  const { className, label, value, counter, showCounter, logoBase64, renderContent } = props;
  // Events
  const { onChange } = props;
  // Options
  const { checked, disabled } = props;

  const UID = useMemo(() => uniqueId('checkbox_'), []);

  return (
    <label
      htmlFor={ UID }
      className={
        classNames(
          classes.checkboxWrapper,
          {
            [classes.checkboxChecked]: checked,
            [classes.disabled]: disabled,
          },
          className,
        )
      }
    >
      { showCounter && (
        <div className={ classNames(classes.counter, { [classes.counterChecked]: checked, [classes.hideCounter]: disabled }) }>
          { checked && counter }
        </div>
      ) }

      { !showCounter && (
        <div className={ classNames(classes.counter, { [classes.counterChecked]: checked, [classes.hideCounter]: disabled }) }>
          { checked && <CheckIcon /> }
        </div>
      ) }

      { logoBase64 && (
        <>
          <div className={ classes.logoBase64 }>
            <img alt="" src={ `${'data:image/png;base64,'}${logoBase64}` } />
          </div>

          <div className={ classes.logoBase64Label }>
            { label }
          </div>
        </>
      )}

      { renderContent && renderContent() }

      { !logoBase64 && !renderContent && label }

      <Checkbox
        id={ UID }
        name={ UID }
        onChange={ onChange }
        value={ value }
        classes={ { root: classes.hiddenCheckbox } }
        // Options
        checked={ checked }
        disabled={ disabled }
      />
    </label>
  );
};

OutlineCheckbox.defaultProps = {
  className: '',
  showCounter: false,
  counter: '',
  value: '',
  label: '',
  logoBase64: '',
  onChange: () => {},
  renderContent: null,
  // Options
  checked: false,
  disabled: false,
};

OutlineCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  renderContent: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logoBase64: PropTypes.string,
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCounter: PropTypes.bool,
  // Options
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default OutlineCheckbox;
