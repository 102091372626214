import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

// Libs
import { get } from 'lodash';

// Selectors
import dictionarySelectors from 'selectors/dictionarySelectors';

// Modules
import SerchInput from 'modules/_Factories/Input/SearchInput/SearchInput';
import Select from 'modules/_Factories/Select/Select';

const FilterSegments = ({ onChange }) => {
  const data = useSelector(dictionarySelectors.demographicsSelector);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    company: '',
    category: '',
    subcategory: '',
  });

  const demographicMap = new Map();
  const companyList = [];

  useEffect(() => {
    if (!onChange) return;
    onChange({ filters, search });
  }, [filters, onChange, search]);

  const selectCompany = (company) => {
    setFilters((state) => ({
      ...state,
      company,
      category: state.company === company ? state.category : '',
      subcategory: state.company === company ? state.subcategory : '',
    }));
  };

  const selectCategory = (category) => {
    setFilters((state) => ({
      ...state,
      category,
      subcategory: state.category === category ? state.subcategory : '',
    }));
  };

  const selectSubcategory = (subcategory) => {
    setFilters((state) => ({
      ...state,
      subcategory,
    }));
  };

  data.forEach((item) => {
    companyList.push({ id: item.id, name: item.name });
    const value = new Map();
    item.categories.forEach((category) => {
      value.set(category.id, category);
    });
    demographicMap.set(item.id, value);
  });

  const categoryList = demographicMap.get(filters.company) || new Map();

  const subcategoryList = get(categoryList.get(filters.category), 'subcategories') || [];

  return (
    <div>
      <SerchInput
        name="search"
        fullWidth
        placeholder="Search segments"
        isShowEndAdornment={ false }
        value={ search }
        label=""
        onChange={ (e) => setSearch(e.target.value) }
      />
      <Grid container spacing={ 4 }>
        <Grid item xs={ 4 }>
          <Select
            fullWidth
            itemList={ companyList }
            setValue={ selectCompany }
            value={ filters.company }
            label="Company"
          />
        </Grid>
        <Grid item xs={ 4 }>
          <Select
            fullWidth
            disabled={ !demographicMap.has(filters.company) }
            itemList={ Array.from(categoryList.values()) }
            setValue={ selectCategory }
            value={ filters.category }
            label="Category"
          />
        </Grid>
        <Grid item xs={ 4 }>
          <Select
            fullWidth
            disabled={ !categoryList.has(filters.category) }
            itemList={ subcategoryList }
            setValue={ selectSubcategory }
            value={ filters.subcategory }
            label="Subcategory"
          />
        </Grid>
      </Grid>
    </div>
  );
};

FilterSegments.defaultProps = { onChange: Function.prototype };

FilterSegments.propTypes = { onChange: PropTypes.func };

export default memo(FilterSegments);
