import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Libs
import { hasPermission } from 'libs/storageLibs';

// Hooks
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';
import useAddAdvertiser from './useAddAdvertiser';

// Dictionaries

import FormAdvertiser from '../../FormAdvertiser';

const AddAdvertiser = ({ history }) => {
  const { handleSubmit } = useAddAdvertiser(history);
  const permissionEdit = hasPermission([UserPermissions.types.ADVERTISER_WRITE]);

  return (
    <FormAdvertiser handleSubmit={ handleSubmit } permissionEdit={ permissionEdit } />
  );
};

AddAdvertiser.propTypes = { history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired };

export default memo(AddAdvertiser);
