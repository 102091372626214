export default {
  GET_TIME_ZONES: 'DICTIONARY/GET_TIME_ZONES',
  GET_SIZES: 'DICTIONARY/GET_SIZES',
  GET_CATEGORIES: 'DICTIONARY/GET_CATEGORIES',
  GET_LANGUAGES: 'DICTIONARY/GET_LANGUAGES',
  GET_COUNTRIES: 'DICTIONARY/GET_COUNTRIES',
  GET_DMAS: 'DICTIONARY/GET_DMAS',
  GET_DEMOGRAPHICS: 'DICTIONARY/GET_DEMOGRAPHICS',
  GET_FILTERED_STATES: 'DICTIONARY/GET_FILTERED_FILTERED_STATES',
  CLEAR_FILTERED_STATES: 'DICTIONARY/CLEAR_FILTERED_FILTERED_STATES',
  GET_FILTERED_CITIES: 'DICTIONARY/GET_FILTERED_CITIES',
  CLEAR_FILTERED_CITIES: 'DICTIONARY/CLEAR_FILTERED_CITIES',
  GET_FILTERED_CONGRESSIONAL_DISTRICTS: 'DICTIONARY/GET_FILTERED_CONGRESSIONAL_DISTRICTS',
  CLEAR_FILTERED_CONGRESSIONAL_DISTRICTS: 'DICTIONARY/CLEAR_FILTERED_CONGRESSIONAL_DISTRICTS',
  GET_FILTERED_COUNTIES: 'DICTIONARY/GET_FILTERED_COUNTIES',
  CLEAR_FILTERED_COUNTIES: 'DICTIONARY/CLEAR_FILTERED_COUNTIES',
};
