import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import AdvertiserList from './routes/AdvertiserList';
import AddAdvertiser from './routes/AddAdvertiser';
import EditAdvertiser from './routes/EditAdvertiser';

const Advertisers = ({ match }) => (
  <Switch>
    <Route exact path={ match.url } component={ AdvertiserList } />
    <Route exact path={ `${match.url}/add` } component={ AddAdvertiser } />
    <Route exact path={ `${match.url}/:id` } component={ EditAdvertiser } />
  </Switch>
);

Advertisers.propTypes = { match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired };

export default memo(Advertisers);
