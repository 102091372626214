import React, { useState } from 'react';
import { useRouteMatch, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

// Material
import { Button, Grid } from '@material-ui/core';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';

// Dictionaries
import CampaignTypesDictionary from 'constants/dictionary/campaignTypesDictionary';
import CampaignStatusesDictionary from 'constants/dictionary/campaignStatusesDictionary';

// Components
import StatusTableCell from 'modules/_Table/TableCell/StatusTableCell/StatusTableCell';
import usePermissions from 'hooks/usePermissions';
import Steps from './Steps/Steps';

// Hooks
import useAddCampaign from './useAddCampaign';

// Styles
import classes from './AddCampaign.module.scss';

const AddCampaign = () => {
  const match = useRouteMatch();
  const { state } = useLocation();

  // State
  const permissions = usePermissions();
  const [editable, setEditable] = useState(permissions.campaignWrite);

  const id = state?.id || match?.params?.id;
  const isCopy = Boolean(state?.id);

  const { formik, isLoading } = useAddCampaign({ id, systemType: CampaignTypesDictionary.types.AUDIENCE, setEditable, isCopy });

  if (isLoading) return null;

  const pageTitle = formik.values?.id ? 'Edit Campaign' : 'Add Campaign';

  return (
    <>
      <Grid container className="mb-5" wrap="nowrap" justify="space-between">
        <Grid item container sm spacing={ 4 }>
          <Grid item>
            <PageTitle title={ pageTitle } showBreadcrumbs lastElementName={ formik.values?.id && formik.values.name } />
          </Grid>

          {formik.initialValues?.id && (
            <Grid item className={ classes.statusBadge }>
              <StatusTableCell
                statusId={ formik.initialValues.status }
                statusName={ CampaignStatusesDictionary.hashMap[formik.initialValues.status] }
              />
            </Grid>
          )}
        </Grid>
        { editable && (
          <Grid item xs={ 12 } sm="auto">
            <Grid container item xs sm wrap="nowrap">
              <Grid item xs sm>
                <Link to="/campaigns" className="buttonLink">
                  <Button
                    variant="text"
                    size="large"
                    className="mr-2"
                  >
                    Cancel
                  </Button>
                </Link>
              </Grid>

              <Grid item xs sm>
                <Button
                  className={ classNames({ [classes.validForm]: formik.isValid }) }
                  variant="outlined"
                  size="large"
                  onClick={ formik.handleSubmit }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) }
      </Grid>

      <Steps
        formik={ formik }
        editable={ editable }
        approval={ permissions.campaignApproval }
        isEditForm={ !!id }
      />
    </>
  );
};

export default AddCampaign;
