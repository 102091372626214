import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import classNames from 'classnames';
import classes from './InputAdornmentEnd.module.scss';

const InputAdornmentEnd = ({ children, active, className }) => (
  <InputAdornment
    position="end"
    className={ classNames(classes.wrapper, { [classes.active]: active }, className) }
  >
    {children}
  </InputAdornment>
);

InputAdornmentEnd.defaultProps = {
  active: false,
  className: '',
};

InputAdornmentEnd.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
};

export default InputAdornmentEnd;
