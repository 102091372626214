import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dashboardActions from 'actions/dashboardActions';
import periodDictionary from 'constants/dictionary/periodDictionary';
import useFetchDataWithLoading from '../../hooks/useFetchDataWithLoading';

const useDashboard = (
  {
    period: defaultPeriod = periodDictionary.types.TODAY,
    advertisers: defaultAdvertisers = [],
    campaigns: defaultCampaigns = [],
  } = {},
  defaultThreshold = 10,
) => {
  const [period, setPeriod] = useState(defaultPeriod);
  const [advertisers, setAdvertisers] = useState(defaultAdvertisers);
  const [campaigns, setCampaigns] = useState(defaultCampaigns);
  const [threshold, setThreshold] = useState(defaultThreshold);

  const dispatch = useDispatch();

  const {
    isFetching: fetchingStatsByState,
    setIsFetching: setFetchingStatsByState,
    fetchData: fetchStatsByState,
  } = useFetchDataWithLoading(dashboardActions.getStatsByStates);
  const {
    isFetching: fetchingAdvertisersActivity,
    setIsFetching: setFetchingAdvertisersActivity,
    fetchData: fetchAdvertisersActivity,
  } = useFetchDataWithLoading(dashboardActions.getAdvertisersActivity);
  const {
    isFetching: fetchingTopAdvertisers,
    setIsFetching: setFetchingTopAdvertisers,
    fetchData: fetchTopAdvertisers,
  } = useFetchDataWithLoading(dashboardActions.getTopAdvertisers);

  const {
    isFetching: fetchingTopCampaigns,
    setIsFetching: setFetchingTopCampaigns,
    fetchData: fetchTopCampaigns,
  } = useFetchDataWithLoading(dashboardActions.getTopCampaigns);

  const {
    isFetching: fetchingTopCreatives,
    setIsFetching: setFetchingTopCreatives,
    fetchData: fetchTopCreatives,
  } = useFetchDataWithLoading(dashboardActions.getTopCreatives);

  const { statsByStates, advertisersActivity, topAdvertisers, topCampaigns, topCreatives } = useSelector(
    (state) => state.dashboard,
  );

  const fetchData = useCallback(async () => {
    try {
      setFetchingStatsByState(true);
      setFetchingAdvertisersActivity(true);
      setFetchingTopAdvertisers(true);
      setFetchingTopCampaigns(true);
      setFetchingTopCreatives(true);

      const params = {
        period,
        advertiserId: advertisers,
        campaignId: campaigns,
      };

      await Promise.all([
        fetchAdvertisersActivity(params),
        fetchStatsByState(params),
        fetchTopAdvertisers(period, threshold),
        fetchTopCampaigns(period, threshold),
        fetchTopCreatives(period, threshold),
      ]);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setFetchingStatsByState(false);
      setFetchingAdvertisersActivity(false);
      setFetchingTopAdvertisers(false);
      setFetchingTopCampaigns(false);
      setFetchingTopCreatives(false);
    }

    return true;
  }, [
    fetchAdvertisersActivity,
    fetchStatsByState,
    fetchTopAdvertisers,
    fetchTopCampaigns,
    fetchTopCreatives,
    period,
    advertisers,
    campaigns,
    setFetchingAdvertisersActivity,
    setFetchingStatsByState,
    setFetchingTopAdvertisers,
    setFetchingTopCampaigns,
    setFetchingTopCreatives,
    threshold,
  ]);

  const handleFilterChange = useCallback(async (fieldName, value) => {
    switch (fieldName) {
      case 'period':
        setPeriod(value);
        break;
      case 'advertisers':
        setAdvertisers(value);
        break;
      case 'campaigns':
        setCampaigns(value);
        break;
      case 'threshold':
        setThreshold(value);
        await Promise.all([
          fetchTopAdvertisers(period, value),
          fetchTopCampaigns(period, value),
          fetchTopCreatives(period, value),
        ]);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('Invalid fieldName');
    }
  }, [
    fetchTopAdvertisers,
    fetchTopCampaigns,
    fetchTopCreatives,
    period,
    setPeriod,
    setAdvertisers,
    setCampaigns,
  ]);

  const resetFilters = useCallback(() => {
    setPeriod(periodDictionary.types.TODAY);
    setAdvertisers([]);
    setCampaigns([]);
  }, [setPeriod, setAdvertisers, setCampaigns]);

  useEffect(() => {
    (async () => fetchData())();

    return () => {
      dispatch(dashboardActions.clearStatsByStates());
      dispatch(dashboardActions.clearAdvertisersActivity());
      dispatch(dashboardActions.clearTopAdvertisers());
      dispatch(dashboardActions.clearTopCampaigns());
    };
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch, fetchData]);

  return {
    statsByStates,
    advertisersActivity,
    topAdvertisers,
    topCampaigns,
    topCreatives,
    period,
    advertisers,
    campaigns,
    threshold,
    fetchData,
    handleFilterChange,
    resetFilters,
    fetchingStatsByState,
    fetchingAdvertisersActivity,
    fetchingTopCampaigns,
    fetchingTopAdvertisers,
    fetchingTopCreatives,
  };
};

export default useDashboard;
