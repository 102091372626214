import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

// Hooks
import useAuth from 'hooks/useAuth';

const PublicRoute = ({ component: Component, path, redirectTo }) => {
  const { isAuthenticated } = useAuth({});

  return (
    <Route
      path={ path }
      render={ () => (!isAuthenticated ? <Component /> : <Redirect to={ redirectTo } />) }
    />
  );
};

PublicRoute.defaultProps = { redirectTo: '/dashboard' };

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType, PropTypes.func]).isRequired,
  path: PropTypes.string.isRequired,
  redirectTo: PropTypes.string,
};

export default PublicRoute;
