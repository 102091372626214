import types from './actionTypes/dictionaryActionTypes';
import config from '../config/config';
import client from '../client/axios';

const { serverUrl, apiVersion } = config;

const getTimeZones = () => async (dispatch) => {
  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}timezones`);

    dispatch({
      type: types.GET_TIME_ZONES,
      payload: data,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getCountries = () => async (dispatch) => {
  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}countries`);

    dispatch({
      type: types.GET_COUNTRIES,
      payload: data,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getDMAs = () => async (dispatch) => {
  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}dmas`);

    dispatch({
      type: types.GET_DMAS,
      payload: data,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getDemographics = () => async (dispatch) => {
  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}segments`);

    dispatch({
      type: types.GET_DEMOGRAPHICS,
      payload: data,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getFilteredStates = ({ limit, filter, country, id }) => async (dispatch) => {
  const params = { };

  if (id && id.length) {
    params.id = id.join(',');
  }

  if (limit && limit > 0) {
    params.limit = limit;
  }

  if (filter) {
    params.filter = filter;
  }

  if (country && country.length) {
    params.country = country.join(',');
  }

  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}states`, { params });

    if (dispatch) {
      dispatch({
        type: types.GET_FILTERED_STATES,
        payload: data,
      });
    }

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const clearFilteredStates = () => ({
  type: types.CLEAR_FILTERED_STATES,
  payload: [],
});

const getFilteredCities = ({ limit, filter, country, id }) => async (dispatch) => {
  const params = { limit: 100 };

  if (id && id.length) {
    params.id = id.join(',');
  }

  if (limit && limit > 0) {
    params.limit = limit;
  }

  if (filter) {
    params.filter = filter;
  }

  if (country && country.length) {
    params.country = country.join(',');
  }

  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}cities`, { params });

    if (dispatch) {
      dispatch({
        type: types.GET_FILTERED_CITIES,
        payload: data,
      });
    }

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const clearFilteredCities = () => ({
  type: types.CLEAR_FILTERED_CITIES,
  payload: [],
});

const getFilteredCongressionalDistricts = ({ limit, filter, state, id }) => async (dispatch) => {
  const params = { limit: 100 };

  if (id && id.length) {
    params.id = id.join(',');
  }

  if (limit && limit > 0) {
    params.limit = limit;
  }

  if (filter) {
    params.filter = filter;
  }

  if (state && state.length) {
    params.state = state.join(',');
  }

  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}congressional-districts`, { params });

    if (dispatch) {
      dispatch({
        type: types.GET_FILTERED_CONGRESSIONAL_DISTRICTS,
        payload: data,
      });
    }

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const clearFilteredCongressionalDistricts = () => ({
  type: types.CLEAR_FILTERED_CONGRESSIONAL_DISTRICTS,
  payload: [],
});

const getFilteredCounties = ({ limit, filter, state, id }) => async (dispatch) => {
  const params = { limit: 100 };

  if (id && id.length) {
    params.id = id.join(',');
  }

  if (limit && limit > 0) {
    params.limit = limit;
  }

  if (filter) {
    params.filter = filter;
  }

  if (state && state.length) {
    params.country = state.join(',');
  }

  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}counties`, { params });

    if (dispatch) {
      dispatch({
        type: types.GET_FILTERED_COUNTIES,
        payload: data,
      });
    }
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getSizes = () => async (dispatch) => {
  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}sizes`);

    dispatch({
      type: types.GET_SIZES,
      payload: data,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getLanguages = () => async (dispatch) => {
  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}languages`);

    dispatch({
      type: types.GET_LANGUAGES,
      payload: data,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getCategories = () => async (dispatch) => {
  try {
    const { data } = await client.get(`${serverUrl}${apiVersion}categories`);

    dispatch({
      type: types.GET_CATEGORIES,
      payload: data,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const clearFilteredCounties = () => ({
  type: types.CLEAR_FILTERED_COUNTIES,
  payload: [],
});

export default {
  getTimeZones,
  getCountries,
  getDMAs,
  getDemographics,
  getFilteredStates,
  clearFilteredStates,
  getFilteredCities,
  clearFilteredCities,
  getFilteredCongressionalDistricts,
  clearFilteredCongressionalDistricts,
  getFilteredCounties,
  clearFilteredCounties,
  getSizes,
  getLanguages,
  getCategories,
};
