const {
  REACT_APP_API_VERSION,
  REACT_APP_COPYRIGHT,
  REACT_APP_NAME,
  REACT_APP_SERVER_URL,
  REACT_APP_DOMAIN,
  REACT_APP_CLIENT_ID,
} = process.env;

const config = {
  apiVersion: REACT_APP_API_VERSION,
  copyright: REACT_APP_COPYRIGHT,
  name: REACT_APP_NAME,
  serverUrl: REACT_APP_SERVER_URL,
  appDomain: REACT_APP_DOMAIN,
  clientId: REACT_APP_CLIENT_ID,
};

export default config;
