import { useSelector } from 'react-redux';
import loadingSelectors from '../selectors/loadingSelectors';

const useLoading = () => {
  const isLoading = useSelector(loadingSelectors);

  return { isLoading };
};

export default useLoading;
