export default {
  GET_STATS_BY_STATES: 'DASHBOARD/GET_STATS_BY_STATES',
  GET_ADVERTISERS_ACTIVITY: 'DASHBOARD/GET_ADVERTISERS_ACTIVITY',
  GET_TOP_ADVERTISERS: 'DASHBOARD/GET_TOP_ADVERTISERS',
  GET_TOP_CAMPAIGNS: 'DASHBOARD/GET_TOP_CAMPAIGNS',
  GET_TOP_CREATIVES: 'DASHBOARD/GET_TOP_CREATIVES',
  CLEAR_STATS_BY_STATES: 'DASHBOARD/CLEAR_STATS_BY_STATES',
  CLEAR_ADVERTISERS_ACTIVITY: 'DASHBOARD/CLEAR_ADVERTISERS_ACTIVITY',
  CLEAR_TOP_ADVERTISERS: 'DASHBOARD/CLEAR_TOP_ADVERTISERS',
  CLEAR_TOP_CAMPAIGNS: 'DASHBOARD/CLEAR_TOP_CAMPAIGNS',
  CLEAR_TOP_CREATIVES: 'DASHBOARD/CLEAR_TOP_CREATIVES',
};
