import DictionaryClass from '../dictionaryClass';

export default class InsightClass {
  constructor(props) {
    this.impressions = props.impressions || null;
    this.impressionsPercent = props.impressionsPercent || null;
    this.devices = props.devices || null;
    this.devicesPercent = props.devicesPercent || null;
    this.item = props.item ? new DictionaryClass(props.item) : new DictionaryClass();
  }
}
