export class CampaignListClass {
  advertiser = null;
  advertiserMissingBalance = null;
  budgetTarget = null;
  creativesCount = 0;
  id = null;
  name = null;
  pacingRate = null;
  spendAmount = null;
  status = null;

  constructor(item) {
    this.advertiser = item.advertiser || null;
    this.advertiserMissingBalance = item.advertiserMissingBalance || null;
    this.budgetTarget = item.budgetTarget || null;
    this.creativesCount = item.creativesCount || null;
    this.id = item.id || null;
    this.name = item.name || null;
    this.pacingRate = item.pacingRate || null;
    this.spendAmount = item.spendAmount || null;
    this.status = item.status || null;
  }
}
