export class AllowValuesItemClass {
  id = null;
  name = null;

  constructor(...args) {
    args.forEach((item) => {
      this.id = item.id || null;
      this.name = item.name || null;
    });
  }
}
