import React from 'react';
import { Typography } from '@material-ui/core';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';
import classes from './ResetPasswordForm.module.scss';
import useResetPassword from './useResetPassword';
import ResetPasswordExpired from './ResetPasswordExpired/ResetPasswordExpired';

const ResetPassword = () => {
  const { setPassword, isValidToken, token } = useResetPassword();

  return (
    <>
      <Typography variant="h2" className={ classes.title }>
        {isValidToken ? 'New Password' : 'Reset Password'}
      </Typography>

      {isValidToken ?
        <ResetPasswordForm setPassword={ setPassword } token={ token } className={ classes.form } /> :
        <ResetPasswordExpired />}
    </>
  );
};

export default ResetPassword;
