import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';

// Modules
import Input from 'modules/_Factories/Input/Input';
import StepContainer from 'modules/Steps/StepContainer';

const Contacts = ({ formik, permissionEdit }) => (
  <StepContainer subtitle="Provide optional contact information">
    <Grid container spacing={ 4 }>
      <Grid item container xs={ 12 } sm={ 6 } spacing={ 4 }>
        <Grid item xs={ 12 } sm={ 12 }>
          <Input
            name="contacts.email"
            label="Email"
            value={ get(formik.values, 'contacts.email') }
            // Options
            fullWidth
            disabled={ !permissionEdit }
            // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 12 }>
          <Input
            name="contacts.skype"
            label="Skype"
            value={ get(formik.values, 'contacts.skype') }
            // Options
            fullWidth
            disabled={ !permissionEdit }
            // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 12 }>
          <Input
            name="contacts.phone"
            label="Phone"
            value={ get(formik.values, 'contacts.phone') }
            // Options
            fullWidth
            disabled={ !permissionEdit }
            // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>
      </Grid>
    </Grid>
  </StepContainer>
);

Contacts.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      contacts: PropTypes.shape({
        email: PropTypes.string,
        skype: PropTypes.string,
        phone: PropTypes.string,
      }),
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  permissionEdit: PropTypes.bool.isRequired,
};

export default memo(Contacts);
