import React from 'react';
import PropTypes from 'prop-types';
import MUIPagination from '@material-ui/lab/Pagination';
import classes from './Pagination.module.scss';

const Pagination = ({ count, defaultPage, onChange }) => {
  const customClasses = { root: classes.root };

  return (
    <MUIPagination
      classes={ customClasses }
      count={ count }
      defaultPage={ defaultPage }
      onChange={ onChange }
    />
  );
};

Pagination.defaultProps = { defaultPage: 1 };
Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  defaultPage: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
