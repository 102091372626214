import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';

// Constants
import { BalanceTypes, Statuses } from 'constants/index';

// Modules
import Select from 'modules/_Factories/Select/Select';
import SearchFilterInput from 'modules/_Factories/Input/SearchFilterInput/SearchFilterInput';

const Filters = ({ values, onChange, onReset }) => {
  const { search, status, balance } = values;

  const handleChangeFilters = (name) => (value) => {
    onChange({ ...values, [name]: value });
  };

  return (
    <>
      <Grid item sm="auto">
        <Button
          variant="text"
          color="primary"
          onClick={ onReset }
        >
          Reset
        </Button>
      </Grid>
      <Grid item xs sm>
        <SearchFilterInput
          value={ search }
          placeholder="Search by advertiser name or id"
          setSearchFilter={ handleChangeFilters('search') }
        />
      </Grid>
      <Grid item xs={ 3 } sm={ 2 }>
        <Select
          isFilter
          itemList={ Statuses }
          label="Status"
          setValue={ handleChangeFilters('status') }
          value={ status }
        />
      </Grid>
      <Grid item xs={ 3 } sm={ 2 }>
        <Select
          isFilter
          itemList={ BalanceTypes }
          label="Balance"
          setValue={ handleChangeFilters('balance') }
          value={ balance }
        />
      </Grid>
    </>
  );
};

Filters.defaultProps = {
  values: {
    search: '',
    status: '',
    balance: '',
  },
  onChange: Function.prototype,
  onReset: Function.prototype,
};

Filters.propTypes = {
  values: PropTypes.shape({
    search: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['ACTIVE', 'INACTIVE', '']).isRequired,
    balance: PropTypes.oneOf(['POSITIVE', 'NEGATIVE', '']).isRequired,
  }),
  onChange: PropTypes.func,
  onReset: PropTypes.func,
};

export default memo(Filters);
