import React from 'react';
import PropTypes from 'prop-types';

// Material
import { TableCell, TableHead, TableRow } from '@material-ui/core';

// Libs

const DemographicTableHead = (props) => {
  const { headColumns } = props;

  const handleClick = (headCell) => {
    if (headCell.headCallbackFn) {
      headCell.headCallbackFn();
    }
  };

  return (
    <TableHead>
      <TableRow>
        { headColumns.map((headCell) => (
          <TableCell onClick={ () => handleClick(headCell) } key={ headCell.id }>
            { headCell.renderHeaderItem() }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

DemographicTableHead.propTypes = { headColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired };

export default DemographicTableHead;
