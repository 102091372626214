import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

// Actions
import advertiserActions from 'actions/advertiserActions';

// Classes
import { AdvertiserClass } from 'classes/advertiser/advertiserClass';

const useAddAdvertiser = (history) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const createAdvertiser = useCallback(async (model) => dispatch(advertiserActions.createAdvertiser(model)), [dispatch]);

  const handleSubmit = useCallback(async (values, callbackFinally) => {
    try {
      const model = new AdvertiserClass(values);
      const advertiser = await createAdvertiser(model);
      enqueueSnackbar(`Advertiser (id: ${advertiser.id}) created successfully`, { variant: 'success' });
      history.push('/advertisers', { refillBalanceAdvertiserId: advertiser.id });
    } catch (error) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
    } finally {
      callbackFinally();
    }
  }, [createAdvertiser, enqueueSnackbar, history]);

  return { handleSubmit };
};

export default useAddAdvertiser;
