import { buildHashMap } from 'libs/buildHashMap';

const types = {
  HOUR: 'HOUR',
  DAY: 'DAY',
};

const dictionary = [
  { id: types.HOUR, name: 'Hour' },
  { id: types.DAY, name: 'Day' },
];

export default {
  types,
  dictionary,
  hashMap: buildHashMap(dictionary),
};
