import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import dictionaryActions from 'actions/dictionaryActions';

const useTimeZones = (sendRequest = false) => {
  const dispatch = useDispatch();

  const getTimeZones = useCallback(() => dispatch(dictionaryActions.getTimeZones()), [dispatch]);

  useEffect(() => {
    if (sendRequest) {
      getTimeZones();
    }
  }, [getTimeZones, sendRequest]);

  const timeZones = useSelector((state) => state.dictionary.timeZones);

  return { timeZones };
};

export default useTimeZones;
