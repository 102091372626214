import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// Actions
import advertiserActions from 'actions/advertiserActions';

// Selectors
import advertiserSelectors from 'selectors/advertiserSelectors';

// Classes
import { AdvertiserClass } from 'classes/advertiser/advertiserClass';
import { AdvertiserBalanceClass } from 'classes/advertiser/advertiserBalanceClass';
import usePermissions from 'hooks/usePermissions';

const useEditAdvertiser = (advertiserId) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const advertiser = useSelector(advertiserSelectors.advertiserSelector);
  const { advertiserBalanceManual } = usePermissions();

  const getAdvertiserById = useCallback((id) => dispatch(advertiserActions.getAdvertiserById(id)), [dispatch]);
  const clearAdvertiser = useCallback(() => dispatch(advertiserActions.clearAdvertiserById()), [dispatch]);

  const updateAdvertiser = useCallback((model) => dispatch(advertiserActions.updateAdvertiser(model)), [dispatch]);
  const updateAdvertiserBalance = useCallback(async (advId, model) => {
    const request = advertiserBalanceManual ? advertiserActions.updateAdvertiserBalance : advertiserActions.requestAdvertiserBalance;
    dispatch(request(advId, model));
  }, [advertiserBalanceManual, dispatch]);

  React.useEffect(() => {
    if (!advertiserId) return null;

    getAdvertiserById(advertiserId);
    return () => clearAdvertiser();
  }, [getAdvertiserById, clearAdvertiser, advertiserId]);

  const handleSubmit = useCallback(async (values, callbackFinally) => {
    try {
      const model = new AdvertiserClass(values);
      const balanceModel = new AdvertiserBalanceClass(values.balanceModel);

      const { id } = await updateAdvertiser(model);
      if (balanceModel.amount && balanceModel.amount > 0 && id) {
        await updateAdvertiserBalance(id, balanceModel);
      }

      enqueueSnackbar(`Advertiser (id: ${id}) updated successfully`, { variant: 'success' });
      history.push('/advertisers');
    } catch (error) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
    } finally {
      callbackFinally();
    }
  }, [enqueueSnackbar, history, updateAdvertiser, updateAdvertiserBalance]);

  const initialValues = useMemo(() => ({
    ...advertiser,
    balanceModel: new AdvertiserBalanceClass(),
  }), [advertiser]);

  return { initialValues, handleSubmit };
};

export default useEditAdvertiser;
