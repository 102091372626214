import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';

// Libs
import { textFormat } from 'libs/textFormat';

// Modules
import InfoValue from 'modules/InfoValue';
import PageTitle from 'modules/PageTitle/PageTitle';
import Steps from './Steps';

// Hooks
import useFormAdvertiser from './useFormAdvertiser';

// Styles
import classes from './FormAdvertiser.module.scss';

const FormAdvertiser = ({
  handleSubmit,
  initialValues,
  permissionEdit,
  isEditForm,
}) => {
  const { formik } = useFormAdvertiser({ initialValues, handleSubmit });

  const pageTitle = formik.values?.id ? 'Edit Advertiser' : 'Add Advertiser';

  return (
    <div>
      <div className={ classNames(classes.header, 'mb-5') }>
        <PageTitle title={ pageTitle } showBreadcrumbs lastElementName={ formik.values?.id && formik.values.name } />

        {formik.values.id && (
          <InfoValue className="mr-3" label="Balance:" value={ textFormat(formik.values.balance, 'currency') } />
        )}
        <div className={ classes.buttons }>
          <Button
            variant="text"
            size="large"
            className="mr-2"
            component={ Link }
            to="/advertisers"
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            size="large"
            onClick={ formik.handleSubmit }
            disabled={ !formik.isValid || formik.isSubmitting || !formik.dirty }
          >
            Save
          </Button>
        </div>

      </div>

      <Steps formik={ formik } permissionEdit={ permissionEdit } isEditForm={ isEditForm } />
    </div>
  );
};

FormAdvertiser.defaultProps = {
  initialValues: {
    id: null,
    balance: 0,
    name: '',
    domain: '',
    status: 'ACTIVE',
    contacts: {
      email: '',
      skype: '',
      phone: '',
    },
  },
  handleSubmit: Function.prototype,
  permissionEdit: true,
  isEditForm: false,
};

FormAdvertiser.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    balance: PropTypes.number,
    name: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    contacts: PropTypes.shape({
      email: PropTypes.string.isRequired,
      skype: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
    }),
  }),
  handleSubmit: PropTypes.func,
  permissionEdit: PropTypes.bool,
  isEditForm: PropTypes.bool,
};

export default memo(FormAdvertiser);
