// Classes
import { CappingClass } from 'classes/cappingClass';
import { AllowValuesClass } from 'classes/allowValuesClass';

// Libs
import { toNumber } from 'lodash';
import { convertIsoToDate, getIsoDate } from 'libs/dateConvertors';

// Dictionaries
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import { CampaignPricingStrategyClass } from './campaignPricingStrategyClass';

export class CampaignClass {
  actions = [];
  adUnits = new AllowValuesClass();
  advertiser = '';
  // allowIndirect = false;
  approvalNotes = '';
  budgetCapping = new CappingClass();
  budgetTarget = '';
  creativeRotationScriptId = null;
  creatives = [];
  deals = [];
  delivery = '';
  end = '';
  id = null;
  ifaCapping = null;
  impCapping = new CappingClass();
  impsTarget = '';
  name = '';
  pacingRate = null;
  pricingStrategy = new CampaignPricingStrategyClass();
  runningAfterApproval = false;
  ssps = new AllowValuesClass();
  start = '';
  status = CampaignStatuses.types.DRAFT;
  systemType = null;
  targetSettings = [];
  thirdPartyBeacons = [];
  thirdPartyImpressionBeacons = [];
  timeZone = 'GMT';
  type = 'VIDEO';
  user = null;

  constructor(...args) {
    args.forEach((item) => {
      this.id = item.id || null;
      this.name = item.name || '';
      this.adUnits = item.adUnits || new AllowValuesClass();
      this.advertiser = item.advertiser || '';
      this.actions = item.actions || [];
      this.approvalNotes = item.approvalNotes || '';
      this.budgetCapping = this.checkCappingValues(item.budgetCapping);
      this.budgetTarget = this.getNumericValue(item.budgetTarget);
      this.creativeRotationScriptId = item.creativeRotationScriptId || null;
      this.creatives = item.creatives || [];
      this.deals = item.deals || [];
      this.delivery = item.delivery || '';
      this.end = this.getDate(item.end);
      this.impCapping = this.checkCappingValues(item.impCapping);
      this.impsTarget = this.getNumericValue(item.impsTarget);
      this.ifaCapping = item.ifaCapping || null;
      this.pacingRate = item.pacingRate || null;
      this.pricingStrategy = new CampaignPricingStrategyClass(item.pricingStrategy);
      this.runningAfterApproval = item.runningAfterApproval || false;
      this.ssps = item.ssps || new AllowValuesClass();
      this.start = this.getDate(item.start);
      this.status = item.status || CampaignStatuses.types.DRAFT;
      this.systemType = item.systemType || null;
      this.targetSettings = item.targetSettings || [];
      this.thirdPartyImpressionBeacons = item.thirdPartyImpressionBeacons || [];
      this.thirdPartyBeacons = item.thirdPartyBeacons || [];
      this.timeZone = item.timeZone || null;
      this.type = item.type || 'VIDEO';
      this.user = item.user || null;
    });
  }

  getDate(date) {
    if (typeof date === 'string') {
      return convertIsoToDate(date);
    } if (date instanceof Date) {
      return getIsoDate(date);
    }
    return null;
  }

  getNumericValue(val) {
    if (val === null) {
      return '';
    }
    if (typeof val === 'string') {
      return toNumber(val) ? toNumber(val) : null;
    }
    return val;
  }

  checkCappingValues(instance) {
    if (instance && instance.value) {
      return new CappingClass(instance);
    }
    return null;
  }
}
