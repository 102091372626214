import { createSelector } from 'reselect';

const loading = (state) => state.loading;

const loadingSelectors = createSelector(
  loading,
  (loadingCounter) => Boolean(loadingCounter),
);

export default loadingSelectors;
