// Libs
import { buildHashMap } from 'libs/buildHashMap';

const types = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const dictionary = [
  { id: types.ACTIVE, name: 'Active' },
  { id: types.INACTIVE, name: 'Inactive' },
];

const hashMap = buildHashMap(dictionary);

export default {
  types,
  dictionary,
  hashMap,
};
