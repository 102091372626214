import types from 'actions/actionTypes/userActionTypes';
import crud from './index';

/** ************************************************ GET ****************************************************** */

/**
 * GET CurrentUser.
 */
const getCurrentUser = () => crud.get('users/_current', types.GET_CURRENT_USER, {}, true);

/**
 * GET Environment.
 */
const getEnvironment = () => crud.get('environment', types.GET_ENVIRONMENT, {}, false);

/**
 * GET Settings.
 */
const getGlobalSettings = () => crud.get('settings', types.GET_SETTINGS, {}, false);

/** ************************************************ PUT ****************************************************** */

/**
 * UPDATE CurrentUser.
 */
const updateCurrentUser = (user) => crud.put(`users/${user.id}`, user, true, types.UPDATE_CURRENT_USER);

/**
 * GET scripts
 */

const getScripts = () => crud.get('scripts', types.GET_SCRIPTS, {}, false);

/**
 * check is exist user email
 */

const checkUserEmail = (model) => crud.post('users/_email', model, false);

/** ************************************************ EXPORTS ****************************************************** */

export default {
  getCurrentUser,
  getEnvironment,
  updateCurrentUser,
  getScripts,
  getGlobalSettings,
  checkUserEmail,
};
