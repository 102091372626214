export class DemographicGroupClass {
  id = null;
  name = '';
  segments = [];

  constructor(...args) {
    args.forEach((item) => {
      this.id = item.id || null;
      this.name = item.name || null;
      this.segments = item.segments || [];
    });
  }
}
