import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import StepTitle from '../StepTitle';
import classes from '../Review.module.scss';

const TimeOfDay = ({
  isProgrammaticDirect,
  budgetTarget,
  budgetCapping,
  impsTarget,
  impCapping,
  cpm,
  segmentCpm,
}) => {
  const fields = [
    { id: 'budgetTarget', name: 'Campaign Budget', value: budgetTarget, visible: true },
    { id: 'budgetCapping', name: `Max Spend Per ${budgetCapping.period}`, value: budgetCapping.value, visible: !isProgrammaticDirect },
    { id: 'impsTarget', name: 'Campaign Impressions', value: impsTarget, visible: true },
    { id: 'impCapping', name: `Max Impressions Per ${impCapping.period}`, value: impCapping.value, visible: !isProgrammaticDirect },
    { id: 'cpm', name: 'CPM', value: cpm, visible: true },
    { id: 'segmentCpm', name: 'Gross CPM', value: segmentCpm, visible: !isProgrammaticDirect },
  ];

  return (
    <section>
      <StepTitle name="Budget and Limits" />
      <Grid container spacing={ 1 }>
        {fields.map((field) => (
          <Grid item xs={ 6 } sm={ 6 } key={ field.id }>
            <div className={ classes.primaryText }>
              {field.name}&nbsp;&nbsp;
              <span className={ classes.secondaryText }>
                { field.value || '-' }
              </span>
            </div>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

TimeOfDay.defaultProps = {
  isProgrammaticDirect: false,
  budgetTarget: null,
  budgetCapping: null,
  impsTarget: null,
  impCapping: null,
  cpm: null,
  segmentCpm: null,

};

TimeOfDay.propTypes = {
  isProgrammaticDirect: PropTypes.bool,
  budgetTarget: PropTypes.string,
  budgetCapping: PropTypes.shape({
    period: PropTypes.string,
    value: PropTypes.string,
  }),
  impsTarget: PropTypes.string,
  impCapping: PropTypes.shape({
    period: PropTypes.string,
    value: PropTypes.string,
  }),
  cpm: PropTypes.string,
  segmentCpm: PropTypes.string,
};

export default memo(TimeOfDay);
