import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, InputAdornment } from '@material-ui/core';
import classNames from 'classnames';

// Modules
import Select from 'modules/_Factories/Select';
import Input from 'modules/_Factories/Input/Input';
import InputAdornmentEnd from 'modules/_Factories/Input/InputAdornmentEnd/InputAdornmentEnd';
import TextField from 'modules/_Factories/TextField/TextField';
// Constants
import PAYMENT_METHODS from 'constants/dictionary/paymentMethodDictionary';
import BALANCE_OPERATIONS from 'constants/dictionary/balanceOperationDictionary';
// Hooks
import usePermissions from 'hooks/usePermissions';
import useFormikAdvertiserBalance from './useFormikAdvertiserBalance';
import useAdvertiserBalance from './useAdvertiserBalance';
// Styles
import classes from './AdvertiserBalance.module.scss';

const AdvertiserBalance = ({ advertiserId, onPayment }) => {
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const { advertiserBalanceManual } = usePermissions();
  const { fillAdvertiserBalance, enablePaymentByCard } = useAdvertiserBalance();
  const { formAdvertiserBalance, setFormValue } = useFormikAdvertiserBalance();

  const { paymentMethod, operation, amount, notes } = formAdvertiserBalance.values;

  useEffect(() => {
    const accessiblePaymentMethods = PAYMENT_METHODS.dictionary
      .filter((item) => (advertiserBalanceManual ?
        item.id !== PAYMENT_METHODS.types.REQUEST_DEPOSIT
        : item.id !== PAYMENT_METHODS.types.DEPOSIT))
      .filter((item) => (enablePaymentByCard ? true : item.id !== PAYMENT_METHODS.types.PAY_BY_CARD));

    setPaymentMethodList(accessiblePaymentMethods);
    setFormValue('paymentMethod', accessiblePaymentMethods[0]?.id);
  }, [setPaymentMethodList, setFormValue, enablePaymentByCard, advertiserBalanceManual]);

  useEffect(() => {
    setFormValue('notes', null);
  }, [setFormValue, paymentMethod]);

  const handlePayment = () => {
    if (onPayment) return onPayment(advertiserId, formAdvertiserBalance.values);
    return fillAdvertiserBalance(advertiserId, formAdvertiserBalance.values);
  };

  const getAmountButtonText = () => {
    if (paymentMethod === PAYMENT_METHODS.types.PAY_BY_CARD) {
      return 'Pay';
    }
    return advertiserBalanceManual ? 'Confirm' : 'Request Deposit';
  };

  return (
    <Grid container spacing={ 4 }>
      <Grid item xs={ 12 }>
        <Select
          label="Payment Method"
          name="paymentMethod"
          value={ paymentMethod }
          itemList={ paymentMethodList }
          showNoneItem={ false }
          onChange={ formAdvertiserBalance.handleChange }
          onBlur={ formAdvertiserBalance.handleBlur }
          setFieldTouched={ formAdvertiserBalance.setFieldTouched }
          errors={ formAdvertiserBalance.errors }
          touched={ formAdvertiserBalance.touched }
        />
      </Grid>

      {paymentMethod === PAYMENT_METHODS.types.DEPOSIT && (
        <Grid item xs={ 12 }>
          <Select
            label="Operation"
            name="operation"
            value={ operation }
            itemList={ BALANCE_OPERATIONS.dictionary }
            showNoneItem={ false }
            onChange={ formAdvertiserBalance.handleChange }
            onBlur={ formAdvertiserBalance.handleBlur }
            setFieldTouched={ formAdvertiserBalance.setFieldTouched }
            errors={ formAdvertiserBalance.errors }
            touched={ formAdvertiserBalance.touched }
          />
        </Grid>
      )}

      <Grid item xs={ 12 }>
        <Input
          type="number"
          name="amount"
          label="Amount"
          value={ amount }
          className={ classes.deposit }
          fullWidth
          required
          startAdornment={
            <InputAdornment position="start" className={ classes.depositStartAdornment }>$</InputAdornment>
          }
          endAdornment={ (
            <InputAdornmentEnd className={ classes.depositButtonWrapper }>
              <Button
                className={ classNames(classes.depositButton, { [classes.disabled]: !formAdvertiserBalance.isValid }) }
                disabled={ !formAdvertiserBalance.isValid }
                onClick={ handlePayment }
                onKeyPress={ handlePayment }
              >
                {getAmountButtonText()}
              </Button>
            </InputAdornmentEnd>
          ) }
          onChange={ (e) => setFormValue(e.target.name, +e.target.value || null) }
          onBlur={ formAdvertiserBalance.handleBlur }
          setFieldTouched={ formAdvertiserBalance.setFieldTouched }
          errors={ formAdvertiserBalance.errors }
          touched={ formAdvertiserBalance.touched }
        />
      </Grid>

      {paymentMethod !== PAYMENT_METHODS.types.PAY_BY_CARD && (
        <Grid item xs={ 12 }>
          <TextField
            label="Comment"
            name="notes"
            value={ notes }
            rowsNumber={ 4 }
            fullWidth
            onChange={ formAdvertiserBalance.handleChange }
            onBlur={ formAdvertiserBalance.handleBlur }
            setFieldTouched={ formAdvertiserBalance.setFieldTouched }
            errors={ formAdvertiserBalance.errors }
            touched={ formAdvertiserBalance.touched }
          />
        </Grid>
      )}
    </Grid>
  );
};

AdvertiserBalance.defaultProps = {
  onPayment: null,
  advertiserId: null,
};

AdvertiserBalance.propTypes = {
  advertiserId: PropTypes.number,
  onPayment: PropTypes.func,
};

export default memo(AdvertiserBalance);
