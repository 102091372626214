import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';

// Modules
import { StepContainer } from 'modules/Steps';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';
import Input from 'modules/_Factories/Input/Input';

// Hooks
import useCategories from 'hooks/dictionary/useCategories';
import useLanguages from 'hooks/dictionary/useLanguages';

const Details = ({ formik }) => {
  const { categories } = useCategories();
  const { languages } = useLanguages();

  const helpText = 'Enter optional details for your creative';

  return (
    <StepContainer subtitle={ helpText }>
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 } sm={ 6 }>
          <SelectVirtualized
            multiple
            name="categories"
            label="Categories"
            value={ get(formik.values, 'categories') }
            list={ categories }
            // Events
            onChange={ formik.handleChange }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            error={ formik.errors?.categories }
            isTouched={ formik.touched?.categories }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <SelectVirtualized
            name="language"
            label="Language"
            list={ languages }
            value={ get(formik.values, 'language') }
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            errors={ get(formik.errors, 'language') }
            isTouched={ get(formik.touched, 'language') }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <Input
            name="pageUrl"
            label="Destination URL"
            value={ get(formik, 'values.pageUrl') }
            // Options
            fullWidth
            // Events
            onChange={ formik.handleChange }
            onBlur={ formik.handleBlur }
            setFieldTouched={ formik.setFieldTouched }
            // Extra
            errors={ formik.errors }
            touched={ formik.touched }
          />
        </Grid>
      </Grid>
    </StepContainer>
  );
};

Details.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      categories: PropTypes.array,
      language: PropTypes.string,
      pageUrl: PropTypes.string,
      type: PropTypes.string,
    }),
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default memo(Details);
