import React, { memo, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';

// Constants
import CREATIVE_TEMPLATES from 'constants/dictionary/creativeTemplateDictionary';

import Select from 'modules/_Factories/Select';
import ImageAssetClass from 'classes/asset/imageAssetClass';
import { isEqual } from 'lodash';
import TitleAssetClass from 'classes/asset/titleAssetClass';
import DataAssetClass from 'classes/asset/dataAssetClass';
import CustomNativeAd from './CustomNativeAd';
import CollapsableAd from './CollapsableAd';

const getAssetsDepenedingOnTemplate = (template) => {
  switch (template) {
    case CREATIVE_TEMPLATES.types.COLLAPSABLE: {
      return [
        new ImageAssetClass({
          name: 'Telly Image Expanded',
          expectedSize: { width: 1920, height: 360 },
          imageType: 3,
        }),
        new ImageAssetClass({
          name: 'Telly Image Collapsed',
          expectedSize: { width: 695, height: 360 },
          imageType: 3,
        }),
      ];
    }
    case CREATIVE_TEMPLATES.types.CUSTOM_NATIVE: {
      return [
        new ImageAssetClass({
          name: 'Telly Main Image',
          expectedSize: { width: 150, height: 250 },
          imageType: 3,
        }),
        new ImageAssetClass({
          name: 'Telly Logo Image',
          imageType: 1,
        }),
        new TitleAssetClass({
          name: 'Telly Title',
          text: '',
        }),
        new DataAssetClass({
          name: 'Telly Description',
          dataType: 2,
          value: '',
        }),
        new DataAssetClass({
          name: 'Telly Call To Action',
          dataType: 12,
          value: '',
        }),
        new DataAssetClass({
          name: 'Telly Attribution',
          dataType: 1,
          value: '',
        }),
      ];
    }
    default: return [];
  }
};

const isEqualAssets = (assets, expectedAssets) => {
  if (assets.length !== expectedAssets.length) return false;

  // eslint-disable-next-line
  for (const expectedAsset of expectedAssets) {
    const isMatch = assets.find((item) => {
      switch (expectedAsset.type) {
        case 'IMAGE': {
          const size = expectedAsset.expectedSize || expectedAsset.size || null;
          const itemSize = size !== null ? (item.expectedSize || item.size) : null;
          return isEqual(size, itemSize) && expectedAsset.imageType === item.imageType;
        }
        case 'DATA': return item.dataType === expectedAsset.dataType;
        case 'TITLE': return item.type === expectedAsset.type;
        default: return false;
      }
    });
    if (!isMatch) return false;
  }

  return true;
};

const NativeOptions = ({ formik, isEditForm }) => {
  const { setFieldValue, values } = formik;
  const { template, assets } = values;

  useEffect(() => {
    const expectedAssets = getAssetsDepenedingOnTemplate(template);

    if (isEqualAssets(assets, expectedAssets)) return;

    setFieldValue('assets', expectedAssets);
  }, [template, assets, setFieldValue]);

  const handleChangeAssets = useCallback((newAssets) => {
    setFieldValue('assets', newAssets);
  }, [setFieldValue]);

  return (
    <Grid container alignItems="flex-start" spacing={ 4 }>
      <Grid item xs={ 12 }>
        <Select
          name="template"
          label="Template"
          value={ template }
          itemList={ CREATIVE_TEMPLATES.dictionary }
          // Options
          isFilter
          isFilterText="None"
          fullWidth
          required
          disabled={ isEditForm }
          showNoneItem={ false }
          // Events
          onChange={ formik.handleChange }
          onBlur={ formik.handleBlur }
          setFieldTouched={ formik.setFieldTouched }
          // Extra
          errors={ formik.errors }
          touched={ formik.touched }
        />
      </Grid>
      <Grid item xs={ 12 }>
        {template === CREATIVE_TEMPLATES.types.COLLAPSABLE ?
          <CollapsableAd assets={ assets } onChangeAssets={ handleChangeAssets } disabled={ isEditForm } />
          : <CustomNativeAd assets={ assets } onChangeAssets={ handleChangeAssets } disabled={ isEditForm } />}
      </Grid>
    </Grid>
  );
};

export default memo(NativeOptions);
