import React, { useState, useCallback, useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Modules
import ClearableInput from 'modules/_Factories/Input/ClearableInput/ClearableInput';
import FilledCheckbox from 'modules/_Factories/Checkbox/FilledCheckbox/FilledCheckbox';
import Chip from 'modules/_Factories/Chip/Chip';
import { StepContainer } from 'modules/Steps';

// Constants
import CAMPAIGN_TYPES from 'constants/dictionary/campaignTypesDictionary';

// Styles
import classes from './Options.module.scss';

const Options = ({ formik, editable }) => {
  const { thirdPartyImpressionBeacons, thirdPartyBeacons, deals, systemType } = formik.values;

  const [showOptions, setShowOptions] = useState(() => !!thirdPartyImpressionBeacons.length ||
      !!deals.length ||
    Object.values(thirdPartyBeacons).some((item) => item.length));

  const [beacons, setBeacons] = useState({
    start: '',
    firstQuartile: '',
    midpoint: '',
    thirdQuartile: '',
    complete: '',
    click: '',
  });

  const [dealId, setDealId] = useState('');
  const [impressionBeacon, setImpressionBeacon] = useState('');
  const [editableBeacons, setEditableBeacons] = useState({
    impression: '',
    start: '',
    firstQuartile: '',
    midpoint: '',
    thirdQuartile: '',
    complete: '',
    click: '',
  });

  const isOmi = systemType === CAMPAIGN_TYPES.types.ORIGIN_MEDIA;
  const isAudience = systemType === CAMPAIGN_TYPES.types.AUDIENCE;

  const sortAlphabetically = (a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  };

  const addDeal = useCallback((e) => {
    const isComma = e.key === ',';
    const newDealId = dealId.split(',').slice(-1)[0].trim();
    if (
      (e.keyCode !== 13 && !isComma) ||
      !newDealId ||
      deals.includes(newDealId)
    ) return;

    formik.setFieldValue('deals', [...deals, newDealId]);
  }, [dealId, deals, formik]);

  const removeDeal = useCallback((removedDeal) => {
    const newDeals = deals.filter((deal) => deal !== removedDeal);
    formik.setFieldValue('deals', newDeals);
  }, [deals, formik]);

  const setBeaconsValue = useCallback((name, value = '') => {
    if (name === 'impression') setImpressionBeacon(value);
    else setBeacons((prevValue) => ({ ...prevValue, [name]: value }));
  }, [setBeacons, setImpressionBeacon]);

  const handleEditBeacon = useCallback((name) => (value) => {
    setBeaconsValue(name, value);
    setEditableBeacons((prevState) => ({ ...prevState, [name]: value }));
  }, [setBeaconsValue]);

  const addBeacon = useCallback((beacon, values, name, e) => {
    const isComma = e.key === ',';
    const newBeacon = beacon.split(',').slice(-1)[0].trim();
    if (
      (e.keyCode !== 13 && !isComma) ||
      !newBeacon ||
      values.includes(newBeacon)
    ) return null;

    if (e.keyCode === 13) setBeaconsValue(name, '');

    let prevBeacons = values;
    if (editableBeacons[name]) {
      prevBeacons = prevBeacons.filter((item) => item !== editableBeacons[name]);
      setEditableBeacons((prevState) => ({ ...prevState, [name]: '' }));
    }

    return [...prevBeacons, newBeacon];
  }, [setEditableBeacons, editableBeacons, setBeaconsValue]);

  const addThirdPartyBeacon = useCallback((name) => (e) => {
    const newBeacons = addBeacon(beacons[name], thirdPartyBeacons[name], name, e);
    if (newBeacons) formik.setFieldValue('thirdPartyBeacons', { ...thirdPartyBeacons, [name]: newBeacons });
  }, [beacons, addBeacon, thirdPartyBeacons, formik]);

  const removeBeacon = useCallback((name) => (removedBeacon) => {
    const newBeacons = thirdPartyBeacons[name].filter((beacon) => beacon !== removedBeacon);
    formik.setFieldValue('thirdPartyBeacons', { ...thirdPartyBeacons, [name]: newBeacons });
  }, [thirdPartyBeacons, formik]);

  const removeImpressionBeacon = useCallback((removedBeacon) => {
    const newImpressionBeacons = thirdPartyImpressionBeacons.filter((beacon) => beacon !== removedBeacon);
    formik.setFieldValue('thirdPartyImpressionBeacons', newImpressionBeacons);
  }, [thirdPartyImpressionBeacons, formik]);

  const addImpressionBeacon = useCallback((e) => {
    const newBeacons = addBeacon(impressionBeacon, thirdPartyImpressionBeacons, 'impression', e);
    if (newBeacons) formik.setFieldValue('thirdPartyImpressionBeacons', newBeacons);
  }, [impressionBeacon, addBeacon, thirdPartyImpressionBeacons, formik]);

  const sortedImpressionBeacons = useMemo(() => thirdPartyImpressionBeacons.sort(sortAlphabetically), [thirdPartyImpressionBeacons]);
  const sortedStartBeacons = useMemo(() => thirdPartyBeacons.start.sort(sortAlphabetically), [thirdPartyBeacons.start]);

  const sortedFirstQuatileBeacons = useMemo(
    () => thirdPartyBeacons.firstQuartile.sort(sortAlphabetically),
    [thirdPartyBeacons.firstQuartile],
  );
  const sortedMidpointBeacons = useMemo(() => thirdPartyBeacons.midpoint.sort(sortAlphabetically), [thirdPartyBeacons.midpoint]);
  const sortedThirdQuatileBeacons = useMemo(
    () => thirdPartyBeacons.thirdQuartile.sort(sortAlphabetically),
    [thirdPartyBeacons.thirdQuartile],
  );
  const sortedCompleteBeacons = useMemo(() => thirdPartyBeacons.complete.sort(sortAlphabetically), [thirdPartyBeacons.complete]);
  const sortedClickBeacons = useMemo(() => thirdPartyBeacons.click.sort(sortAlphabetically), [thirdPartyBeacons.click]);

  return (
    <StepContainer
      title="Enter Third Party Beacons to run campaign on"
      subtitle="You can enter Deal IDs and Third Party Beacons, separate them by pressing enter or by comma input"
    >
      <FilledCheckbox
        className={ classes.checkbox }
        simpleCheckbox
        label="Advanced"
        name="advanced"
        checked={ showOptions }
        onChange={ () => setShowOptions((prev) => !prev) }
      />

      {showOptions && (
        <>
          <Grid container spacing={ 4 }>
            <Grid item xs={ 12 } sm={ 12 }>
              <Typography variant="h6">Third Party Beacons</Typography>
            </Grid>
            {/* Impression */}
            <Grid item xs={ 12 } sm={ 6 }>
              <ClearableInput
                fullWidth
                className={ classes.beacon }
                placeholder="Impression"
                name="thirdPartyImpressionBeacons"
                label=""
                onKeyDown={ addImpressionBeacon }
                onChange={ (e) => setImpressionBeacon(e.target.value) }
                onClear={ () => setImpressionBeacon('') }
                onBlur={ formik.handleBlur }
                value={ impressionBeacon }
                labelBackgroundColor="#fff"
                disabled={ !editable }
                errors={ formik.errors }
                touched={ formik.touched }
              />
              <Grid container wrap="wrap">
                {sortedImpressionBeacons.map((beacon) => (
                  <Chip
                    key={ beacon }
                    labelKey={ beacon }
                    valueKey={ beacon }
                    option={ { [beacon]: beacon } }
                    onClick={ removeImpressionBeacon }
                    getContent={ handleEditBeacon('impression') }
                    disabled={ !editable }
                  />
                ))}
              </Grid>
            </Grid>
            {/* Start */}
            <Grid item xs={ 12 } sm={ 6 }>
              <ClearableInput
                fullWidth
                className={ classes.beacon }
                name="thirdPartyBeacons.start"
                placeholder="Start"
                onKeyDown={ addThirdPartyBeacon('start') }
                onChange={ (e) => setBeaconsValue('start', e.target.value) }
                onClear={ (e) => setBeaconsValue('start', e.target.value) }
                onBlur={ formik.handleBlur }
                value={ beacons.start }
                labelBackgroundColor="#fff"
                disabled={ !editable }
                errors={ formik.errors }
                touched={ formik.touched }
              />
              <Grid container wrap="wrap">
                {sortedStartBeacons.map((beacon) => (
                  <Chip
                    key={ beacon }
                    labelKey={ beacon }
                    valueKey={ beacon }
                    option={ { [beacon]: beacon } }
                    onClick={ removeBeacon('start') }
                    getContent={ handleEditBeacon('start') }
                    disabled={ !editable }
                  />
                ))}
              </Grid>
            </Grid>
            {/* First Quatile */}
            <Grid item xs={ 12 } sm={ 6 }>
              <ClearableInput
                fullWidth
                className={ classes.beacon }
                name="thirdPartyBeacons.firstQuartile"
                placeholder="1st Quartile"
                onKeyDown={ addThirdPartyBeacon('firstQuartile') }
                onChange={ (e) => setBeaconsValue('firstQuartile', e.target.value) }
                onClear={ (e) => setBeaconsValue('firstQuartile', e.target.value) }
                onBlur={ formik.handleBlur }
                value={ beacons.firstQuartile }
                labelBackgroundColor="#fff"
                disabled={ !editable }
                errors={ formik.errors }
                touched={ formik.touched }
              />
              <Grid container wrap="wrap">
                {sortedFirstQuatileBeacons.map((beacon) => (
                  <Chip
                    key={ beacon }
                    labelKey={ beacon }
                    valueKey={ beacon }
                    option={ { [beacon]: beacon } }
                    onClick={ removeBeacon('firstQuartile') }
                    getContent={ handleEditBeacon('firstQuartile') }
                    disabled={ !editable }
                  />
                ))}
              </Grid>
            </Grid>
            {/* Midpoint */}
            <Grid item xs={ 12 } sm={ 6 }>
              <ClearableInput
                fullWidth
                className={ classes.beacon }
                name="thirdPartyBeacons.midpoint"
                placeholder="Midpoint"
                onKeyDown={ addThirdPartyBeacon('midpoint') }
                onChange={ (e) => setBeaconsValue('midpoint', e.target.value) }
                onClear={ (e) => setBeaconsValue('midpoint', e.target.value) }
                onBlur={ formik.handleBlur }
                value={ beacons.midpoint }
                labelBackgroundColor="#fff"
                disabled={ !editable }
                errors={ formik.errors }
                touched={ formik.touched }
              />
              <Grid container wrap="wrap">
                {sortedMidpointBeacons.map((beacon) => (
                  <Chip
                    key={ beacon }
                    labelKey={ beacon }
                    valueKey={ beacon }
                    option={ { [beacon]: beacon } }
                    onClick={ removeBeacon('midpoint') }
                    getContent={ handleEditBeacon('midpoint') }
                    disabled={ !editable }
                  />
                ))}
              </Grid>
            </Grid>
            {/* Third Quatile */}
            <Grid item xs={ 12 } sm={ 6 }>
              <ClearableInput
                fullWidth
                className={ classes.beacon }
                name="thirdPartyBeacons.thirdQuartile"
                placeholder="3rd Quartile"
                onKeyDown={ addThirdPartyBeacon('thirdQuartile') }
                onChange={ (e) => setBeaconsValue('thirdQuartile', e.target.value) }
                onClear={ (e) => setBeaconsValue('thirdQuartile', e.target.value) }
                onBlur={ formik.handleBlur }
                value={ beacons.thirdQuartile }
                labelBackgroundColor="#fff"
                disabled={ !editable }
                errors={ formik.errors }
                touched={ formik.touched }
              />
              <Grid container wrap="wrap">
                {sortedThirdQuatileBeacons.map((beacon) => (
                  <Chip
                    key={ beacon }
                    labelKey={ beacon }
                    valueKey={ beacon }
                    option={ { [beacon]: beacon } }
                    onClick={ removeBeacon('thirdQuartile') }
                    getContent={ handleEditBeacon('thirdQuartile') }
                    disabled={ !editable }
                  />
                ))}
              </Grid>
            </Grid>
            {/* Complete */}
            <Grid item xs={ 12 } sm={ 6 }>
              <ClearableInput
                fullWidth
                className={ classes.beacon }
                name="thirdPartyBeacons.complete"
                placeholder="Complete"
                onKeyDown={ addThirdPartyBeacon('complete') }
                onChange={ (e) => setBeaconsValue('complete', e.target.value) }
                onClear={ (e) => setBeaconsValue('complete', e.target.value) }
                onBlur={ formik.handleBlur }
                value={ beacons.complete }
                labelBackgroundColor="#fff"
                disabled={ !editable }
                errors={ formik.errors }
                touched={ formik.touched }
              />
              <Grid container wrap="wrap">
                {sortedCompleteBeacons.map((beacon) => (
                  <Chip
                    key={ beacon }
                    labelKey={ beacon }
                    valueKey={ beacon }
                    option={ { [beacon]: beacon } }
                    onClick={ removeBeacon('complete') }
                    getContent={ handleEditBeacon('complete') }
                    disabled={ !editable }
                  />
                ))}
              </Grid>
            </Grid>
            {/* Click */}
            <Grid item xs={ 12 } sm={ 6 }>
              <ClearableInput
                fullWidth
                className={ classes.beacon }
                name="thirdPartyBeacons.click"
                placeholder="Click"
                onKeyDown={ addThirdPartyBeacon('click') }
                onChange={ (e) => setBeaconsValue('click', e.target.value) }
                onClear={ (e) => setBeaconsValue('click', e.target.value) }
                onBlur={ formik.handleBlur }
                value={ beacons.click }
                labelBackgroundColor="#fff"
                disabled={ !editable }
                errors={ formik.errors }
                touched={ formik.touched }
              />
              <Grid container wrap="wrap">
                {sortedClickBeacons.map((beacon) => (
                  <Chip
                    key={ beacon }
                    labelKey={ beacon }
                    valueKey={ beacon }
                    option={ { [beacon]: beacon } }
                    onClick={ removeBeacon('click') }
                    getContent={ handleEditBeacon('click') }
                    disabled={ !editable }
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* Deal IDs */}
          {(isOmi || isAudience) && (
            <Grid container spacing={ 4 }>
              <Grid item xs={ 12 } sm={ 12 }>
                <Typography variant="h6">Deals</Typography>
              </Grid>

              <Grid item xs={ 12 } sm={ 6 }>
                <ClearableInput
                  fullWidth
                  className={ classes.beacon }
                  name="deals"
                  label="Deal IDs"
                  onKeyDown={ addDeal }
                  onChange={ (e) => setDealId(e.target.value) }
                  onClear={ () => setDealId('') }
                  onBlur={ formik.handleBlur }
                  value={ dealId }
                  disabled={ !editable }
                />
                <Grid container wrap="wrap">
                  {deals.map((deal) => (
                    <Chip
                      key={ deal }
                      labelKey={ deal }
                      valueKey={ deal }
                      option={ { [deal]: deal } }
                      onClick={ removeDeal }
                      disabled={ !editable }
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </StepContainer>
  );
};

Options.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      systemType: PropTypes.string,
      advertiser: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string]),
      targeting: PropTypes.shape({ ssps: PropTypes.shape({ allow: PropTypes.bool, values: PropTypes.arrayOf(PropTypes.any) }) }),
      deals: PropTypes.arrayOf(PropTypes.string),
      thirdPartyImpressionBeacons: PropTypes.arrayOf(PropTypes.string),
      thirdPartyBeacons: PropTypes.shape({
        start: PropTypes.arrayOf(PropTypes.string).isRequired,
        firstQuartile: PropTypes.arrayOf(PropTypes.string).isRequired,
        midpoint: PropTypes.arrayOf(PropTypes.string).isRequired,
        thirdQuartile: PropTypes.arrayOf(PropTypes.string).isRequired,
        complete: PropTypes.arrayOf(PropTypes.string).isRequired,
        click: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({ ssps: PropTypes.string }),
    touched: PropTypes.shape({}),
  }).isRequired,
  editable: PropTypes.bool.isRequired,
};

export default Options;
