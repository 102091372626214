import React from 'react';
import PropTypes from 'prop-types';

// Material
import { Button } from '@material-ui/core';

// Modules
import InputPassword from 'modules/_Factories/Input/InputPassword/InputPassword';

// Styles
import classNames from 'classnames';
import classes from './ResetPasswordForm.module.scss';

// Hooks
import useResetPasswordForm from './useResetPasswordForm';

const ResetPasswordForm = ({ className, setPassword, token }) => {
  const { resetPasswordForm } = useResetPasswordForm(setPassword, token);

  return (
    <form onSubmit={ resetPasswordForm.handleSubmit } className={ classNames(className) }>
      <div className={ classes.fieldWrapper }>
        <InputPassword
          errors={ resetPasswordForm.errors }
          touched={ resetPasswordForm.touched }
          name="password"
          onChange={ resetPasswordForm.handleChange }
          onBlur={ resetPasswordForm.handleChange }
          value={ resetPasswordForm.values.password }
          labelBackgroundColor="#fff"
          fullWidth
          label="Password"
        />
        <InputPassword
          errors={ resetPasswordForm.errors }
          touched={ resetPasswordForm.touched }
          name="confirmedPassword"
          onChange={ resetPasswordForm.handleChange }
          onBlur={ resetPasswordForm.handleChange }
          value={ resetPasswordForm.values.confirmedPassword }
          labelBackgroundColor="#fff"
          fullWidth
          label="Confirm Password"
        />
      </div>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        type="submit"
        size="large"
        disabled={ resetPasswordForm.isSubmitting }
      >
        RESET PASSWORD
      </Button>
    </form>
  );
};

ResetPasswordForm.defaultProps = { className: '' };

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
  setPassword: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default ResetPasswordForm;
