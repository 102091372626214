import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './zoomButton.module.scss';

const ZoomButton = ({
  increaseZoom,
  decreaseZoom,
  increaseDisabled,
  decreaseDisabled,
  className,
}) => (
  <div className={ classNames(className, classes.zoom) }>
    <button
      type="button"
      disabled={ increaseDisabled }
      className={ classes.item }
      onClick={ increaseZoom }
    >
      +
    </button>
    <button
      type="button"
      disabled={ decreaseDisabled }
      className={ classes.item }
      onClick={ decreaseZoom }
    >
      &#8211;
    </button>
  </div>
);

ZoomButton.defaultProps = {
  increaseZoom: Function.prototype,
  decreaseZoom: Function.prototype,
  increaseDisabled: false,
  decreaseDisabled: false,
  className: null,
};

ZoomButton.propTypes = {
  increaseZoom: PropTypes.func,
  decreaseZoom: PropTypes.func,
  increaseDisabled: PropTypes.bool,
  decreaseDisabled: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(ZoomButton);
