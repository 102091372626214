import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const styles = {
  appBar: { position: 'relative' },
  flex: { flex: 1 },
  imgContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function Transition(props) {
  return <Slide direction="up" { ...props } />;
}

const CropImageDialog = ({ classes, onClose, open, children }) => (
  <Dialog
    fullScreen
    open={ open }
    onClose={ onClose }
    TransitionComponent={ Transition }
  >
    <AppBar className={ classes.appBar }>
      <Toolbar>
        <Typography
          variant="h4"
          color="inherit"
          className={ classes.flex }
        >
          Crop Image
        </Typography>

        <IconButton
          color="inherit"
          onClick={ onClose }
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <div className={ classes.imgContainer }>
      {children}
    </div>
  </Dialog>
);

export default withStyles(styles)(CropImageDialog);
