import { buildHashMap } from 'libs/buildHashMap';

const types = {
  CITY_CODES: 'cities',
  CONGRESSIONAL_DISTRICTS: 'districts',
  COUNTIES: 'counties',
  COUNTRY_CODES: 'countries',
  DMA_CODES: 'dmas',
  STATES: 'states',
  ZIP_CODES: 'zipCodes',
};

const dictionary = [
  { id: types.CITY_CODES, name: 'City' },
  { id: types.CONGRESSIONAL_DISTRICTS, name: 'Congressional district' },
  { id: types.COUNTIES, name: 'County' },
  { id: types.COUNTRY_CODES, name: 'Country' },
  { id: types.DMA_CODES, name: 'DMA' },
  { id: types.STATES, name: 'State' },
  { id: types.ZIP_CODES, name: 'Zip Code' },
];

const hashMap = buildHashMap(dictionary);

export default {
  types,
  dictionary,
  hashMap,
};
