export default class AccountClass {
  active = false;
  domain = null;
  id = null;
  name = null;
  permissions = [];

  constructor(...args) {
    args.forEach((account) => {
      this.active = account.active || false;
      this.domain = account.domain || null;
      this.id = account.id || null;
      this.name = account.name || null;
      this.permissions = account.permissions || [];
    });
  }

  static getDomain(account) {
    return window.location.host.includes('localhost') ? 'http://localhost:3000' : account.domain;
  }
}
