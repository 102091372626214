import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import dictionaryActions from 'actions/dictionaryActions';

// Selectors
import dictionarySelectors from 'selectors/dictionarySelectors';

const useFilterableCounties = () => {
  const dispatch = useDispatch();
  const [touched, setTouched] = useState(false);

  const [searchParams, setSearchParams] = useState({
    filter: '',
    state: [],
    limit: 100,
  });

  const [loading, setLoading] = useState(false);

  const updateSearchParams = (newParams) => {
    setTouched(true);

    setSearchParams({
      ...searchParams,
      ...newParams,
    });
  };

  const getFilteredCounties = useCallback(
    async (params) => {
      setLoading(true);
      await dispatch(dictionaryActions.getFilteredCounties(params));
      setLoading(false);
    }, [dispatch],
  );

  const clearFilteredCounties = useCallback(
    () => dispatch(dictionaryActions.clearFilteredCounties()), [dispatch],
  );

  useEffect(() => {
    if (!touched) return;

    if (searchParams.filter) {
      getFilteredCounties(searchParams);
    } else {
      clearFilteredCounties();
    }
  }, [clearFilteredCounties, getFilteredCounties, searchParams, touched]);

  const filteredCounties = useSelector(dictionarySelectors.filteredCountiesSelector);

  return {
    updateSearchParams,
    filteredCounties,
    loading,
  };
};

export default useFilterableCounties;
