const stateMapMatching = {
  _1: { id: 'US-HI', name: 'Hawaii' },
  _2: { id: 'US-AK', name: 'Alaska' },
  _3: { id: 'US-FL', name: 'Florida' },
  _4: { id: 'US-NH', name: 'New Hampshire' },
  _5: { id: 'US-MI', name: 'Michigan' },
  _6: { id: 'US-VT', name: 'Vermont' },
  _7: { id: 'US-ME', name: 'Maine' },
  _8: { id: 'US-RI', name: 'Rhode Island' },
  _9: { id: 'US-NY', name: 'New York' },
  _10: { id: 'US-PA', name: 'Pennsylvania' },
  _11: { id: 'US-NJ', name: 'New Jersey' },
  _12: { id: 'US-DE', name: 'Delaware' },
  _13: { id: 'US-MD', name: 'Maryland' },
  _14: { id: 'US-VA', name: 'Virginia' },
  _15: { id: 'US-WV', name: 'West Virginia' },
  _16: { id: 'US-OH', name: 'Ohio' },
  _17: { id: 'US-IN', name: 'Indiana' },
  _18: { id: 'US-IL', name: 'Illinois' },
  _19: { id: 'US-CT', name: 'Connecticut' },
  _20: { id: 'US-WI', name: 'Wisconsin' },
  _21: { id: 'US-NC', name: 'North Carolina' },
  _22: { id: 'US-DC', name: 'District of Columbia' },
  _23: { id: 'US-MA', name: 'Massachusetts' },
  _24: { id: 'US-TN', name: 'Tennessee' },
  _25: { id: 'US-AR', name: 'Arkansas' },
  _26: { id: 'US-MO', name: 'Missouri' },
  _27: { id: 'US-GA', name: 'Georgia' },
  _28: { id: 'US-SC', name: 'South Carolina' },
  _29: { id: 'US-KY', name: 'Kentucky' },
  _30: { id: 'US-AL', name: 'Alabama' },
  _31: { id: 'US-LA', name: 'Louisiana' },
  _32: { id: 'US-MS', name: 'Mississippi' },
  _33: { id: 'US-IA', name: 'Iowa' },
  _34: { id: 'US-MN', name: 'Minnesota' },
  _35: { id: 'US-OK', name: 'Oklahoma' },
  _36: { id: 'US-NM', name: 'New Mexico' },
  _37: { id: 'US-KS', name: 'Kansas' },
  _38: { id: 'US-NE', name: 'Nebraska' },
  _39: { id: 'US-SD', name: 'South Dakota' },
  _40: { id: 'US-ND', name: 'North Dakota' },
  _41: { id: 'US-WY', name: 'Wyoming' },
  _42: { id: 'US-MT', name: 'Montana' },
  _43: { id: 'US-CO', name: 'Colorado' },
  _44: { id: 'US-ID', name: 'Idaho' },
  _45: { id: 'US-UT', name: 'Utah' },
  _46: { id: 'US-AZ', name: 'Arizona' },
  _47: { id: 'US-NV', name: 'Nevada' },
  _48: { id: 'US-OR', name: 'Oregon' },
  _49: { id: 'US-WA', name: 'Washington' },
  _50: { id: 'US-CA', name: 'California' },
  _51: { id: 'US-TX', name: 'Texas' },
  _52: { id: 'US-PR', name: 'Puerto Rico' },
  _53: { id: 'US-VI', name: 'U.S. Virgin Islands' },
  _54: { id: 'US-GU', name: 'Guam' },
  _55: { id: 'US-MP', name: 'Northern Mariana Islands' },
  _56: { id: 'US-AS', name: 'American Samoa' },
};

export const STATES = Object.values(stateMapMatching);

export default stateMapMatching;
