import React, { memo } from 'react';
import variables from 'assets/styles/variables.scss';
import classes from './Legend.module.scss';

const legends = [
  { id: 1, title: 'More than 10M', backgroundColor: variables.royalBlue },
  { id: 2, title: '1M - 10M', backgroundColor: variables.darkBlue },
  { id: 3, title: '100K - 1M', backgroundColor: variables.blue },
  { id: 4, title: '10K - 100K', backgroundColor: variables.cornflowerBlue },
  { id: 5, title: '1K - 10K', backgroundColor: variables.paleCornflowerBlue },
  { id: 6, title: 'Less than 1K', backgroundColor: variables.lightBlue },
];

const Legend = () => (
  <div className={ classes.legend }>
    {legends.map((item) => (
      <div key={ item.id } className={ classes.legendItem }>
        <div className={ classes.bubble } style={ { backgroundColor: item.backgroundColor } } />
        <span className={ classes.title }>{item.title}</span>
      </div>
    ))}
  </div>
);

export default memo(Legend);
