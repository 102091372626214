import { TabStep } from 'classes/tabStep';
import UserPermissions from './dictionary/userPermissionsDictionary';

export const AdvertiserSteps = [
  new TabStep({ id: 0, name: 'General', touched: true }),
  new TabStep({ id: 1, name: 'Contacts' }),
  new TabStep({ id: 2, name: 'Balance' }),
];

export const BalanceTypes = [
  { id: 'POSITIVE', name: 'Positive' },
  { id: 'NEGATIVE', name: 'Negative' },
];

export const CampaignSteps = [
  new TabStep({ id: 0, name: 'General', touched: true }),
  new TabStep({ id: 1, name: 'Inventory' }),
  new TabStep({ id: 2, name: 'Geography' }),
  new TabStep({ id: 3, name: 'Demographics' }),
  new TabStep({ id: 4, name: 'Time of day' }),
  new TabStep({ id: 5, name: 'Budget and Limits' }),
  new TabStep({ id: 6, name: 'Creatives' }),
  new TabStep({ id: 7, name: 'Options' }),
  new TabStep({ id: 8, name: 'Review' }),
];

export const NavLinks = [
  { id: '/dashboard', name: 'Overview', children: null, permissions: [UserPermissions.types.DASHBOARD_READ] },
  {
    id: '/advertisers',
    name: 'Advertisers',
    children: {
      id: '/advertisers/add',
      name: 'Add Advertiser',
    },
    permissions: [UserPermissions.types.ADVERTISER_READ],
  },
  {
    id: '/campaigns',
    name: 'Campaigns',
    children: {
      id: '/campaigns/create',
      name: 'Add Campaign',
    },
    permissions: [UserPermissions.types.CAMPAIGN_READ],
  },
  {
    id: '/creatives',
    name: 'Creatives',
    children: {
      id: '/creatives/add',
      name: 'Add Creative',
    },
    permissions: [UserPermissions.types.CREATIVE_READ],
  },
  { id: '/reports', name: 'Reports', children: null, permissions: [UserPermissions.types.REPORT_READ] },
  { id: '/insights', name: 'Insights', children: null, permissions: [UserPermissions.types.INSIGHTS] },
];

export const Statuses = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
];
