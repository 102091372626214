import { createSelector } from 'reselect';

// Classes
import CreativeClass from 'classes/creative/creativeClass';
import { CreativeListClass } from 'classes/creative/creativeListClass';

const creative = (state) => state.creatives.creative;
const creativeList = (state) => state.creatives.creativeList;

const creativeSelector = createSelector(
  creative,
  (item) => ({ ...new CreativeClass(item) }),
);

const creativeListSelector = createSelector(
  creativeList,
  (creativesData) => ({
    ...creativesData,
    paylaod: creativesData.payload.map((item) => new CreativeListClass(item)),
  }),
);

export default {
  creativeSelector,
  creativeListSelector,
};
