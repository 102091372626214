import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';

// Material
import { Button, Grid, Tooltip } from '@material-ui/core';

// Icons
import { ReactComponent as Help } from 'assets/images/icons/help.svg';
import CatImage from 'assets/images/cat.png';

// Classes
import { AllowValuesItemClass } from 'classes/allowValuesItemClass';
import { AllowValuesClass } from 'classes/allowValuesClass';

// Modules
import FilledCheckbox from 'modules/_Factories/Checkbox/FilledCheckbox/FilledCheckbox';
import SearchInput from 'modules/_Factories/Input/SearchInput/SearchInput';
import Autocomplete from 'modules/_Factories/Autocomplete/Autocomplete';
import Chip from 'modules/_Factories/Chip/Chip';
import AllowOptions from 'modules/AllowOptions';
import { StepContainer } from 'modules/Steps';

// Hooks
import useGeography from './useGeography';

// Styles
import classes from './Geography.module.scss';

const Geography = ({ formik, editable }) => {
  const [zipCode, setZipCode] = useState('');
  const uploadFileRef = useRef();
  const { fields, targetingList, setTargetingList, parseFile } = useGeography(formik);

  const { setFieldValue } = formik;
  const zipCodes = formik.values.targetingProfile.zips?.values || [];
  const selectedItems = fields.filter((field) => targetingList.some((item) => item === field.id));

  const handleChangeTargetingList = useCallback((event, checked) => {
    const { name } = event.target;

    const excludeItems = name === 'countries' ? ['countries', 'cities'] : [name];
    setTargetingList((list) => (checked ? [...list, name] : list.filter((listItem) => !excludeItems.includes(listItem))));

    if (!checked) {
      excludeItems.forEach((item) => {
        setFieldValue(`targetingProfile.${item}.values`, []);
      });
    }
  }, [setFieldValue, setTargetingList]);

  const setZipCodesValues = () => {
    const newZipCode = zipCode.split(',').slice(-1)[0].trim();
    if (!newZipCode || zipCodes.some((code) => code.name === newZipCode)) return;
    formik.setFieldValue('targetingProfile.zips.values', [...zipCodes, new AllowValuesItemClass({ id: newZipCode, name: newZipCode })]);
  };

  const handleChangeAllowTargeting = useCallback((name) => (allow) => {
    setFieldValue(name, allow);
  }, [setFieldValue]);

  const handleClearTargeting = useCallback((name) => () => {
    setFieldValue(name, []);
  }, [setFieldValue]);

  const addZipCode = (e) => {
    const isComma = e.key === ',';
    if (e.keyCode !== 13 && !isComma) return;
    setZipCodesValues();
  };

  const removeZipCode = (removedCode) => {
    const newCodes = zipCodes.filter((code) => code.id !== removedCode);
    formik.setFieldValue('targetingProfile.zips.values', newCodes);
  };

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    const data = await parseFile(file);
    const uniqueValues = Array.from(new Set(data));
    formik.setFieldValue('targetingProfile.zips.values', [...zipCodes, ...(new AllowValuesClass({ values: uniqueValues }).values)]);
  };

  const getTargetingItem = (item) => {
    switch (item.id) {
      case 'zips':
        return (
          <div key={ item.id }>
            <Grid container alignItems="baseline">
              <SearchInput
                className={ classes.zipCode }
                name="zips"
                label="Zip Code(s)"
                placeholder="Zip Code(s)"
                onKeyDown={ addZipCode }
                onChange={ (e) => setZipCode(e.target.value) }
                onClear={ () => setZipCode('') }
                onBlur={ setZipCodesValues }
                value={ zipCode }
                disabled={ !editable }
                isShowStartAdornment={ false }
                errors={ formik.errors }
                touched={ formik.touched }
              />
              <input
                ref={ uploadFileRef }
                type="file"
                onChange={ uploadFile }
                className="d-none"
                accept=".text,.txt,.csv,.xls,.xlsx"
              />

              <Button
                color="primary"
                variant="outlined"
                size="small"
                className={ classes.uploadButton }
                onClick={ () => uploadFileRef?.current?.click() }
                onKeyPress={ () => uploadFileRef?.current?.click() }
              >
                Upload CSV
              </Button>
            </Grid>
            <Grid container>
              <AllowOptions
                allow={ get(formik, 'values.targetingProfile.zips.allow') }
                onChangeAllow={ handleChangeAllowTargeting('targetingProfile.zips.allow') }
                onClear={ handleClearTargeting('targetingProfile.zips.values') }
              />
            </Grid>
            <Grid container wrap="wrap">
              {zipCodes.map((code) => (
                <Chip
                  key={ code.id }
                  labelKey="name"
                  valueKey="name"
                  option={ code }
                  onClick={ removeZipCode }
                  disabled={ !editable }
                />
              ))}
            </Grid>
          </div>
        );
      default: {
        const isDisabled = item.id === 'cities' &&
          (!targetingList.includes('countries') || !formik?.values?.targetingProfile?.countries?.values?.length);

        return (
          <Autocomplete
            key={ item.id }
            value={ formik.values.targetingProfile[item.id].values }
            allowValue={ formik.values.targetingProfile[item.id].allow }
            name={ item.name }
            placeholder={ `Search ${item.label}(s)` }
            options={ item.list }
            optionLabelKey="name"
            optionValueKey="id"
            loading={ item.loading }
            // Options
            disabled={ !editable || isDisabled }
            allowOptions
            // Events
            onChangeAllow={ (allow) => formik.setFieldValue(`targetingProfile.${item.id}.allow`, allow) }
            onChange={ (event, newValue) => {
              formik.setFieldValue(item.name, newValue);

              if (item.id === 'countries') {
                const countries = newValue.map((value) => value.id);
                const { targetingProfile } = formik.values;
                const cities = targetingProfile?.cities?.values?.filter((city) => countries.includes(city.countryAlpha2));
                formik.setFieldValue('targetingProfile.cities.values', cities || []);
              }
            } }
            onChangeSearchValue={ (e) => item.updateSearchParams({ filter: e.target.value }) }
          />
        );
      }
    }
  };

  const renderEmptyTargeting = () => (
    <div className={ classes.catWrapper }>
      <img src={ CatImage } alt="CatImage" />
      <div className="subtitle-text">
        Select a category
      </div>
    </div>
  );

  return (
    <StepContainer
      title="Geographical targeting"
      // eslint-disable-next-line
      subtitle="You can choose to target by country, state, DMA, county, city or congressional district as well as can upload a list of ZIP codes"
    >
      <Grid container spacing={ 4 }>
        <Grid item xs={ 4 } sm={ 4 } className={ classNames(classes.targeting, 'gray-container') }>
          {fields.map((field) => {
            const isDisabledCity = field.id === 'cities' && (
              !targetingList.includes('countries') ||
              !get(formik.values, 'targetingProfile.countries.values.length')
            );
            const isDisabled = !editable || isDisabledCity;
            return field.visible && (
              <div key={ field.id } className={ classes.checkboxWrapper }>
                <FilledCheckbox
                  simpleCheckbox
                  label={ field.label }
                  name={ field.id }
                  checked={ targetingList.some((targetItem) => targetItem === field.id) }
                  onChange={ handleChangeTargetingList }
                  disabled={ isDisabled }
                />
                {isDisabledCity && (
                  <Tooltip title="Please select at least 1 country">
                    <Help className={ classes.helpIcon } />
                  </Tooltip>
                )}
              </div>
            );
          })}
        </Grid>

        <Grid item xs={ 8 } sm={ 8 }>
          {selectedItems.length ? selectedItems.map(getTargetingItem) : renderEmptyTargeting() }
        </Grid>
      </Grid>
    </StepContainer>
  );
};

Geography.defaultProps = { };

Geography.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      targetingProfile: PropTypes.shape({
        zips: PropTypes.shape({ values: PropTypes.array, allow: PropTypes.bool }),
        countries: PropTypes.shape({ values: PropTypes.array, allow: PropTypes.bool }),
        cities: PropTypes.shape({ values: PropTypes.array, allow: PropTypes.bool }),
      }),
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  editable: PropTypes.bool.isRequired,
};

export default Geography;
