import React, { useMemo, memo, useCallback } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

// Icons
import { ReactComponent as EditIcon } from 'assets/images/icons/optionsEdit.svg';
import { ReactComponent as HistoryIcon } from 'assets/images/icons/optionsInvoice.svg';
import { ReactComponent as BalanceIcon } from 'assets/images/icons/optionsBalance.svg';

// Libs
import { textFormat } from 'libs/textFormat';
import { hasPermission } from 'libs/storageLibs';

// Modules
import TableCell from 'modules/_Table/TableCell/TableCell';
import MaterialTableText from 'modules/_MaterialTable/MaterialTableText/MaterialTableText';
import MaterialTablesSortIcon from 'modules/_MaterialTable/MaterialTablesSortIcon/MaterialTablesSortIcon';
import MaterialTableContainer from 'modules/_MaterialTable/MaterialTableContainer/MaterialTableContainer';
import MaterialTableToolbar from 'modules/_MaterialTable/MaterialTableToolbar/MaterialTableToolbar';
import MaterialTableBody from 'modules/_MaterialTable/MaterialTableBody/MaterialTableBody';
import OptionsButtonNav from 'modules/OptionsButtonNav/OptionsButtonNav';
import StatusField from 'modules/StatusField/StatusField';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';
import MaterialTableAjaxPagination from 'modules/_MaterialTable/MaterialTableAjaxPagination/MaterialTableAjaxPagination';

const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

const TableAdvertisers = ({
  getAdvertiserList,
  updateStatus,
  openRefillBalanceModal,
  openBillingHistoryModal,
  meta,
  tableRef,
}) => {
  const match = useRouteMatch();

  const isEditable = hasPermission([UserPermissions.types.ADVERTISER_WRITE]);

  const getOptionsItems = useCallback((rowData) => ([
    {
      type: 'button',
      label: 'Billing history',
      icon: HistoryIcon,
      onClick: () => openBillingHistoryModal({
        advertiserId: rowData.id,
        balance: rowData.balance,
      }),
    },
    {
      type: 'button',
      label: 'Refill balance',
      icon: BalanceIcon,
      onClick: () => openRefillBalanceModal({ advertiserId: rowData.id }),
    },
  ]), [openBillingHistoryModal, openRefillBalanceModal]);

  const columns = useMemo(() => [
    {
      title: 'ID',
      field: 'id',
      width: 120,
      minWidth: 120,
      render: (rowData) => (
        <MaterialTableText variant="light">
          {rowData.id}
        </MaterialTableText>
      ),
    },
    {
      title: 'NAME',
      field: 'name',
      defaultSort: 'asc',
      width: '100%',
      render: (rowData) => (
        <Link to={ `${match.url}/${rowData.id}` } className="link">
          <TableCell firstRow={ rowData.name } />
        </Link>
      ),
    },
    {
      title: 'BALANCE',
      field: 'availableBalance',
      width: 170,
      minWidth: 170,
      render: (rowData) => (
        <MaterialTableText variant="light">
          {textFormat(rowData.availableBalance, 'currency')}
        </MaterialTableText>
      ),
    },
    {
      title: 'CAMPAIGNS',
      field: 'campaignsCount',
      width: 160,
      minWidth: 160,
      render: (rowData) => (
        <Link to={ `campaigns?advertiser=${rowData.id}` } className="link">
          <TableCell firstRow={ rowData.campaignsCount || 0 } />
        </Link>
      ),
    },
    {
      title: 'CREATIVES',
      field: 'creativesCount',
      width: 160,
      minWidth: 160,
      render: (rowData) => (
        <Link to={ `creatives?advertisers=${rowData.id}` } className="link">
          <TableCell firstRow={ rowData.creativesCount || 0 } />
        </Link>
      ),
    },
    {
      title: 'STATUS',
      field: 'status',
      width: 120,
      minWidth: 120,
      render: (rowData) => (
        <StatusField
          value={ rowData.status }
          handleChange={ (e, element) => (updateStatus(rowData.id, element.props.value)) }
        />
      ),
    },
    {
      title: '',
      field: 'options',
      width: 24,
      minWidth: 24,
      sorting: false,
      cellStyle: { textAlign: 'right' },
      render: (rowData) => {
        const items = [
          { to: `${match.url}/${rowData.id}`, label: isEditable ? 'Edit' : 'Show', icon: EditIcon },
          ...getOptionsItems(rowData),
        ];
        return <OptionsButtonNav items={ items } />;
      },
    },
  ], [isEditable, match, getOptionsItems, updateStatus]);

  return (
    <MaterialTable
      columns={ columns }
      tableRef={ tableRef }
      data={ getAdvertiserList }
      localization={ { body: { emptyDataSourceMessage: <h4>Sorry, we couldn&apos;t find any results matching.</h4> } } }
      options={ {
        draggable: false,
        pageSizeOptions: ROWS_PER_PAGE_OPTIONS,
        pageSize: meta?.size || ROWS_PER_PAGE_OPTIONS[0],
      } }
      icons={ { SortArrow: MaterialTablesSortIcon } }
      components={ {
        Toolbar: (props) => <MaterialTableToolbar tableRef={ tableRef } entityName="Advertisers" { ...props } />,
        Pagination: MaterialTableAjaxPagination,
        Container: MaterialTableContainer,
        Body: MaterialTableBody,
      } }
    />
  );
};

TableAdvertisers.defaultProps = {
  openRefillBalanceModal: Function.prototype,
  openBillingHistoryModal: Function.prototype,
};

TableAdvertisers.propTypes = {
  tableRef: PropTypes.objectOf(PropTypes.object).isRequired,
  getAdvertiserList: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  openRefillBalanceModal: PropTypes.func,
  openBillingHistoryModal: PropTypes.func,
  meta: PropTypes.shape({
    perPage: PropTypes.number,
    total: PropTypes.number,
    size: PropTypes.number,
  }).isRequired,
};

export default memo(TableAdvertisers);
