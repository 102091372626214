import { useEffect, useState, useCallback } from 'react';
import { memoize } from 'lodash';

const calculateZoomCoords = memoize(() => (zoom, width, height) => {
  const viewBox = { x: 0, y: 0, width: 0, height: 0 };

  for (let i = 2; i <= zoom; i++) {
    viewBox.x += width / (2 ** zoom);
    viewBox.y += height / (2 ** zoom);
    viewBox.width += width / (2 ** (zoom - 1));
    viewBox.height += height / (2 ** (zoom - 1));
  }
  return viewBox;
})();

const useZoomSvg = (svgElement) => {
  // State
  const [zoom, setZoom] = useState(1); // possible values from 1 to 4;
  const [isMoving, setIsMoving] = useState(false);

  const isMinZoom = zoom === 1;
  const isMaxZoom = zoom === 4;

  const handleMouseDown = useCallback(() => {
    setIsMoving(true);
    svgElement.classList.add('movingBySvg');
  }, [svgElement, setIsMoving]);

  const handleMouseUp = useCallback(() => {
    setIsMoving(false);
    svgElement.classList.remove('movingBySvg');
  }, [svgElement, setIsMoving]);

  // mouseMove by svgElement
  const onMouseMoveSvg = useCallback((e) => {
    if (!isMoving || isMinZoom) return;

    const zoomCoords = calculateZoomCoords(zoom, +svgElement.getAttribute('width'), +svgElement.getAttribute('height'));
    const factor = 2 ** (zoom - 1);

    const deltaX = e.movementX / (2 ** zoom);
    const deltaY = e.movementY / (2 ** zoom);

    const [x, y, w, h] = svgElement.getAttribute('viewBox').split(' ').map(Number);

    let newX = x - 2 * deltaX;
    let newY = y - 2 * deltaY;

    if (newX < 1) {
      newX = 1;
    } else if (newX > zoomCoords.x * factor) {
      newX = zoomCoords.x * factor;
    }

    if (newY < 1) {
      newY = 1;
    } else if (newY > zoomCoords.y * factor) {
      newY = zoomCoords.y * factor;
    }

    svgElement.setAttribute('viewBox', `${newX} ${newY} ${w} ${h}`);
  }, [isMoving, svgElement, zoom, isMinZoom]);

  // useEffect
  useEffect(() => {
    if (!svgElement) return undefined;

    const setIsMovingFalse = () => setIsMoving(false);

    svgElement.addEventListener('mousedown', handleMouseDown);
    svgElement.addEventListener('mouseup', handleMouseUp);
    svgElement.addEventListener('mouseleave', setIsMovingFalse);

    return () => {
      svgElement.removeEventListener('mousedown', handleMouseDown);
      svgElement.removeEventListener('mouseup', handleMouseUp);
      svgElement.removeEventListener('mouseleave', setIsMovingFalse);
    };
  }, [svgElement, handleMouseUp, setIsMoving, handleMouseDown]);

  useEffect(() => {
    if (!svgElement) return undefined;

    if (isMoving) svgElement.addEventListener('mousemove', onMouseMoveSvg);

    return () => {
      svgElement.removeEventListener('mousemove', onMouseMoveSvg);
    };
  }, [svgElement, onMouseMoveSvg, isMoving]);

  // increazeZoom
  const increaseZoom = useCallback(() => {
    if (!svgElement || isMaxZoom) return;

    const [x, y, width, height] = svgElement.getAttribute('viewBox').split(' ').map(Number);

    svgElement.setAttribute('viewBox', `${x + width / 4} ${y + height / 4} ${width / 2} ${height / 2}`);
    setZoom((state) => state + 1);
  }, [svgElement, isMaxZoom, setZoom]);

  // decreaseZoom
  const decreaseZoom = useCallback(() => {
    if (!svgElement || isMinZoom) return;

    const [x, y, width, height] = svgElement.getAttribute('viewBox').split(' ').map(Number);
    const xPoint = zoom === 2 ? 0 : x - width / 2;
    const yPoint = zoom === 2 ? 0 : y - height / 2;

    svgElement.setAttribute('viewBox', `${xPoint} ${yPoint} ${width * 2} ${height * 2}`);
    setZoom((state) => state - 1);
  }, [svgElement, zoom, isMinZoom, setZoom]);

  return {
    zoom,
    increaseZoom,
    decreaseZoom,
    isMoving,
    isMinZoom,
    isMaxZoom,
  };
};

export default useZoomSvg;
