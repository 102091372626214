import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material
import { Tooltip } from '@material-ui/core';

// Icons
import { ReactComponent as IconOn } from 'assets/images/icons/on.svg';
import { ReactComponent as IconOff } from 'assets/images/icons/off.svg';

// Modules
import Input from 'modules/_Factories/Input/Input';
import InputAdornmentEnd from 'modules/_Factories/Input/InputAdornmentEnd/InputAdornmentEnd';
import InputAdornmentButton from 'modules/_Factories/Input/InputAdornmentEnd/InputAdornmentButton/InputAdornmentButton';
import InputAdornmentIcon from 'modules/_Factories/Input/InputAdornmentEnd/InputAdornmentIcon/InputAdornmentIcon';

const InputPassword = (props) => {
  const { label, name, value, autoComplete } = props;
  // Options
  const { fullWidth, labelBackgroundColor } = props;
  // Events
  const { onChange, onBlur } = props;
  // Extra
  const { errors, touched } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Input
      label={ label }
      type={ showPassword ? 'text' : 'password' }
      value={ value }
      name={ name }
      onChange={ onChange }
      onBlur={ onBlur }
      fullWidth={ fullWidth }
      labelBackgroundColor={ labelBackgroundColor }
      autoComplete={ autoComplete }
      errors={ errors }
      touched={ touched }
      endAdornment={ (
        <InputAdornmentEnd active={ showPassword }>
          <Tooltip title={ showPassword ? 'Hide password' : 'Show password' } arrow>
            <InputAdornmentButton onClick={ togglePassword }>
              {showPassword ? (
                <InputAdornmentIcon icon={ IconOn } />
              ) : (
                <InputAdornmentIcon icon={ IconOff } />
              )}
            </InputAdornmentButton>
          </Tooltip>
        </InputAdornmentEnd>
      ) }
    />
  );
};

InputPassword.defaultProps = {
  fullWidth: false,
  labelBackgroundColor: '#f9f9f9',
  autoComplete: 'true',
  errors: {},
  touched: {},
};

InputPassword.propTypes = {
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  labelBackgroundColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoComplete: PropTypes.string,
};

export default InputPassword;
