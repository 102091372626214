import React, { memo, useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

// Libs
import { separateByStatus } from 'libs/separateByStatus';
import { hasPermission } from 'libs/storageLibs';
import { transformParams, strToArrayNumber, strToArrayString } from 'libs/query-string';

// Constants
import userPermissionsDictionary from 'constants/dictionary/userPermissionsDictionary';
import CampaignStatuses from 'constants/dictionary/campaignStatusesDictionary';
import { DEFAULT_FILTERS, INITIAL_FILTERS } from 'reducers/campaignReducer';
import campaignActions from 'actions/campaignActions';

// Modules
import PageTitle from 'modules/PageTitle/PageTitle';
import Select from 'modules/_Factories/Select/Select';
import SelectVirtualized from 'modules/_Factories/SelectVirtualized/SelectVirtualized';
import SearchFilterInput from 'modules/_Factories/Input/SearchFilterInput/SearchFilterInput';
// eslint-disable-next-line
import CampaignTable from './CampaignTable/CampaignTable';

// Hooks
import useShortLists from 'hooks/useShortLists';
import useGetParameters from 'hooks/useGetParameters';
import useQueryParams from 'hooks/useQueryParams';
import usePermissions from 'hooks/usePermissions';
// eslint-disable-next-line
import useCampaigns from './useCampaigns';

const getParamNames = (permissions) => {
  const paramNames = ['search', 'status'];
  if (permissions.advertiserRead) paramNames.push('advertiser');
  if (permissions.creativeRead) paramNames.push('creative');

  return paramNames;
};

const CampaignList = ({ match }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.campaigns.filters);
  const setFilters = useCallback((values) => dispatch(campaignActions.setFilters(values)), [dispatch]);

  const tableRef = useRef();
  const refFilters = useRef(filters);

  const permissions = usePermissions();
  const PARAM_NAMES = useMemo(() => getParamNames(permissions), [permissions]);
  const params = useGetParameters(PARAM_NAMES);
  const { debouncedReplaceQueryParams, replaceQueryParams } = useQueryParams();
  const { meta, requestCampaigns, changeCampaignStatus } = useCampaigns(filters);
  const { shortAdvertiserList, shortCreativeList } = useShortLists(['getShortAdvertiserList', 'getShortCreativeList']);

  const [editable] = useState(hasPermission([userPermissionsDictionary.types.CAMPAIGN_WRITE]));
  const advertiserOptions = useMemo(() => separateByStatus(shortAdvertiserList), [shortAdvertiserList]);
  const creativeOptions = useMemo(() => separateByStatus(shortCreativeList), [shortCreativeList]);

  const { creative, advertiser, search, status } = filters;

  useEffect(() => {
    const config = {
      advertiser: strToArrayNumber,
      creative: strToArrayNumber,
      search: (val) => val,
      status: strToArrayString,
    };

    if (Object.keys(params).length) {
      const filterValues = { ...INITIAL_FILTERS, ...transformParams(params, config) };
      setFilters(filterValues);
      refFilters.current = filterValues;
    } else {
      replaceQueryParams(refFilters.current);
    }
  }, [params, setFilters, replaceQueryParams]);

  const refreshCampaignList = useCallback(() => {
    if (!tableRef.current) return;
    tableRef.current.onQueryChange();
  }, []);

  const updateFilters = useCallback((values) => {
    refFilters.current = values;
    setFilters(values);
    debouncedReplaceQueryParams(values, refreshCampaignList);
  }, [setFilters, debouncedReplaceQueryParams, refreshCampaignList]);

  const handleChangeFilters = (name) => (value) => {
    const values = { ...filters, [name]: value };
    updateFilters(values);
  };

  const resetFilters = useCallback(() => {
    updateFilters(DEFAULT_FILTERS);
  }, [updateFilters]);

  const updateStatus = useCallback(async (values, campaignStatus) => {
    await changeCampaignStatus(values, campaignStatus);
    refreshCampaignList();
  }, [changeCampaignStatus, refreshCampaignList]);

  return (
    <>
      <Grid container spacing={ 2 } justify="space-between">
        <Grid item>
          <PageTitle className="mb-5" title="Campaigns Management" showBreadcrumbs />
        </Grid>
        { editable && (
          <Grid item xs="auto" sm="auto">
            <Link to={ `${match.url}/create` } className="buttonLink">
              <Button variant="contained" color="primary">
                Add campaign
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={ 3 } alignItems="center" justify="space-around" className="mb-3">
        <Grid item sm="auto">
          <Button
            variant="text"
            color="primary"
            onClick={ resetFilters }
          >
            Reset
          </Button>
        </Grid>

        <Grid item xs sm>
          <SearchFilterInput
            value={ search }
            placeholder="Search by campaign name or id"
            setSearchFilter={ handleChangeFilters('search') }
          />
        </Grid>

        <Grid item xs={ 4 } sm={ 2 }>
          <Select
            multiple
            isFilter
            itemList={ CampaignStatuses.dictionary }
            label="Status"
            setValue={ handleChangeFilters('status') }
            value={ status }
          />
        </Grid>

        {permissions.advertiserRead && (
          <Grid item xs={ 4 } sm={ 2 }>
            <SelectVirtualized
              multiple
              tree
              list={ advertiserOptions }
              label="Advertisers"
              onChange={ (e) => handleChangeFilters('advertiser')(e.target.value) }
              value={ advertiser }
            />
          </Grid>
        )}
        {permissions.creativeRead && (
          <Grid item xs={ 4 } sm={ 2 }>
            <SelectVirtualized
              multiple
              tree
              list={ creativeOptions }
              label="Creatives"
              onChange={ (e) => handleChangeFilters('creative')(e.target.value) }
              value={ creative }
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={ 5 }>
        <Grid item xs={ 12 } sm={ 12 }>
          <CampaignTable
            tableRef={ tableRef }
            getCampaignList={ requestCampaigns }
            changeCampaignStatus={ updateStatus }
            meta={ meta }
            shortAdvertiserList={ shortAdvertiserList }
          />
        </Grid>
      </Grid>
    </>
  );
};

CampaignList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(CampaignList);
