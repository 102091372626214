import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import dictionaryActions from 'actions/dictionaryActions';

// Selectors
import dictionarySelectors from 'selectors/dictionarySelectors';

const useFilterableStates = ({ limit = 100 } = {}) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState({
    filter: '',
    country: [],
    limit,
  });

  const [loading, setLoading] = useState(false);

  const updateSearchParams = useCallback((newParams) => {
    setSearchParams((prevState) => ({
      ...prevState,
      ...newParams,
    }));
  }, [setSearchParams]);

  const getFilteredStates = useCallback(
    async (params) => {
      setLoading(true);
      await dispatch(dictionaryActions.getFilteredStates(params));
      setLoading(false);
    }, [dispatch],
  );

  const clearFilteredStates = useCallback(
    () => dispatch(dictionaryActions.clearFilteredStates()), [dispatch],
  );

  useEffect(() => {
    getFilteredStates(searchParams);
    return () => clearFilteredStates();
  }, [clearFilteredStates, getFilteredStates, searchParams]);

  const filteredStates = useSelector(dictionarySelectors.filteredStatesSelector);

  return {
    updateSearchParams,
    filteredStates,
    loading,
  };
};

export default useFilterableStates;
