import { useCallback } from 'react';
import { useFormik } from 'formik';
import { validateRequired, validateEmail, validateDomain } from 'libs/validateErrors';

const useFormAdvertiser = ({ initialValues, handleSubmit }) => {
  const validate = useCallback((values) => {
    const name = validateRequired(values.name, 'Name');
    const domain = validateDomain(values.domain, 'Domain');
    const status = validateRequired(values.status, 'Status');
    const email = validateEmail(values.contacts.email, 'Email');

    return {
      ...name && { name },
      ...domain && { domain },
      ...status && { status },
      ...email && { contacts: { email } },
    };
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => handleSubmit(values, () => setSubmitting(false)),
    validate,
    enableReinitialize: true,
    validateOnMount: true,
  });

  return { formik, validate };
};

export default useFormAdvertiser;
