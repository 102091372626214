import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import OutlineCheckbox from 'modules/_Factories/Checkbox/OutlineCheckbox/OutlineCheckbox';
import StepTitle from '../StepTitle';
import classes from '../Review.module.scss';

const Inventory = ({ ssps, adUnits }) => (
  <section>
    <StepTitle name="Inventory" />
    {!!ssps.length && (
      <Grid container spacing={ 3 }>
        {ssps.map((item) => {
          const label = item.clientName && item.name ? `${item.clientName} on ${item.name}` : '';
          return (
            <Grid item xs={ 3 } sm={ 3 } key={ item.id }>
              <OutlineCheckbox
                className="w-100 h-100"
                label={ label }
                logoBase64={ item.logoBase64 }
                value={ item.id }
                disabled
              />
            </Grid>
          );
        }) }
      </Grid>
    )}
    { !!adUnits.length && (
      <Grid container spacing={ 3 }>
        <Grid item xs={ 2 } sm={ 2 } className={ classes.primaryText }>
          Ad Untis
        </Grid>
        <Grid container item xs sm>
          <Grid item xs="auto" sm="auto" className={ classes.secondaryText }>
            {adUnits.map((item) => item.name).join(', ')}
          </Grid>
        </Grid>
      </Grid>

    )}
  </section>
);

Inventory.defaultProps = { ssps: [], adUnits: [] };

Inventory.propTypes = {
  ssps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    logoBase64: PropTypes.string,
    clientName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  adUnits: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default memo(Inventory);
