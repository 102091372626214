import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { FormControl, FormHelperText, InputBase, Typography, Tooltip } from '@material-ui/core';

// Icons
import { ReactComponent as EditSVG } from 'assets/images/icons/edit.svg';

// Modules
import RouterBreadcrumbs from 'modules/_Router/RouterBreadcrumbs/RouterBreadcrumbs';

// Styles
import classes from './EditableTitle.module.scss';

const EditableTitle = (props) => {
  const { autoComplete, name, placeholder, value, className } = props;
  // Options
  const { fullWidth, showBreadcrumbs, required, disabled } = props;
  // Events
  const { onBlur, onChange } = props;
  // Extra
  const { errors, touched } = props;
  const match = useRouteMatch();

  // States
  const [isEdit, setIsEdit] = useState(true);

  // Errors
  const error = errors[name];
  const hasError = Boolean(touched[name] && errors[name]);

  const toggleEdit = useCallback(() => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  const onBlurHandler = (e) => {
    if (e && e.target && e.target.value && !error) {
      toggleEdit();
    }
    onBlur(e);
  };

  const getTitle = () => {
    const getPlaceholder = (req) => (
      req ? (
        <>
          <span className={ classes.placeholder }>{ placeholder }</span>
        </>
      ) : (
        <span className={ classes.placeholder }>{ placeholder }</span>
      )
    );

    return (
      <Tooltip title={ value } arrow>
        <div
          className={ classes.titleContainer }
          onClick={ toggleEdit }
          onKeyDown={ toggleEdit }
        >
          { value ? (
            <span className={ classes.title }>{ value }</span>
          ) : (
            getPlaceholder(required)
          ) }
          { !disabled && <EditSVG className={ classes.editIcon } /> }

          { hasError && <FormHelperText error={ hasError } id={ name }>{ error }</FormHelperText> }
        </div>
      </Tooltip>
    );
  };

  return (
    <div className={ classNames(classes.wrapper, className) }>
      <Typography variant="h2" className="mb-3">
        {
          !isEdit ? (
            getTitle()
          ) : (
            <FormControl className={ classes.formControl }>
              <InputBase
                autoComplete={ autoComplete }
                autoFocus
                className={ classes.input }
                fullWidth={ fullWidth }
                onBlur={ onBlurHandler }
                onChange={ onChange }
                name={ name }
                placeholder={ placeholder }
                size="large"
                type="text"
                value={ value }
                error
              />

              { hasError && <FormHelperText error={ hasError } id={ name }>{ error }</FormHelperText> }
            </FormControl>
          )
        }
      </Typography>
      { showBreadcrumbs ? (
        <RouterBreadcrumbs
          currentPath={ `${match.path}` }
          lastElementName={ Object.keys(match.params).length ? value : null }
        />
      ) : <></> }
    </div>
  );
};

EditableTitle.defaultProps = {
  autoComplete: 'true',
  // Options
  showBreadcrumbs: null,
  fullWidth: false,
  required: false,
  disabled: false,
  // Extra
  errors: {},
  touched: {},
  className: '',
};

EditableTitle.propTypes = {
  autoComplete: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  // Options
  showBreadcrumbs: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  // Events
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // Extra
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
};

export default EditableTitle;
