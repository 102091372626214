import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { textFormat } from 'libs/textFormat';
import classes from './MapPopover.module.scss';

const MapPopover = (props) => {
  const { top, left, data, id, onMouseEnter } = props;
  const popoverElementRef = useRef(null);
  const additionalTop = useMemo(() => (top < 250 ? -266 : 16), [top]);
  const additionalLeft = useMemo(() => (left > 760 ? -200 : 128), [left]);

  const getTopPosition = useCallback(() => {
    if (!popoverElementRef.current) {
      return 'auto';
    }

    return top - popoverElementRef.current.offsetHeight - additionalTop;
  }, [additionalTop, popoverElementRef, top]);

  const getLeftPosition = useCallback(() => left + additionalLeft, [additionalLeft, left]);

  const showPopover = useMemo(() => top && left && data, [data, left, top]);

  if (!showPopover) {
    return null;
  }

  return (
    <div
      ref={ popoverElementRef }
      id={ id }
      className={ classes.wrapper }
      style={ { top: getTopPosition(), left: getLeftPosition() } }
      onMouseEnter={ onMouseEnter }
    >
      <div className={ classes.header }>
        <div className={ classes.title }>{data.item.name || '-'}</div>
        <div className={ classes.abbr }>{data.item.id.replace('US-', '') || '-'}</div>
      </div>
      <div className={ classes.body }>
        <div className={ classes.item }>
          <div className={ classes.itemValue }>
            {textFormat(data.metric.impressions, 'number') || '-'}
          </div>
          <div className={ classes.itemDescription }>Impressions</div>
        </div>
        <div className={ classes.item }>
          <div className={ classes.itemValue }>{textFormat(data.metric.spend, 'currency') || '-'}</div>
          <div className={ classes.itemDescription }>Spend</div>
        </div>
        <div className={ classes.item }>
          <div className={ classes.itemValue }>
            {textFormat(data.metric.vastCompleteRate, 'percent') || '-'}
          </div>
          <div className={ classes.itemDescription }>VCR</div>
        </div>
        <div className={ classes.item }>
          <div className={ classes.itemValue }>{textFormat(data.metric.ecpm, 'currency') || '-'}</div>
          <div className={ classes.itemDescription }>ECPM</div>
        </div>
      </div>
    </div>
  );
};

MapPopover.defaultProps = {
  data: null,
  top: null,
  left: null,
  id: null,
};

MapPopover.propTypes = {
  onMouseEnter: PropTypes.func.isRequired,
  top: PropTypes.number,
  left: PropTypes.number,
  id: PropTypes.string,
  data: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    metric: PropTypes.shape({
      ecpm: PropTypes.number,
      vastCompleteRate: PropTypes.number,
      spend: PropTypes.number,
      impressions: PropTypes.number,
    }).isRequired,
  }),
};

export default MapPopover;
