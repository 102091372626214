/* eslint-disable */ 
import { Menu, MenuItem, SvgIcon } from '@material-ui/core';
import React, { memo, useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { InfiniteLoader, List } from 'react-virtualized';
import cn from 'classnames';
import { debounce } from 'lodash';
import ClearableInput from '../Input/ClearableInput/ClearableInput';
import classes from './AsyncSelect.module.scss';

const ITEM_HEIGHT = 96;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderRadius: '16px',
      marginTop: '16px',
    },
  },
};

const getData = ({ page, search }) => {
  const searchApi = 'https://api.unsplash.com/search/photos';
  const perPage = 25;
  return fetch(`${searchApi}?client_id=g6TUeMNrN9dG093cPhwuHDwZ5ZAVyAx_fvM2sRAJBhc&page=${page}&query=${search}&per_page=${perPage}`)
    .then(res => res.json());
}

const INITIAL_LIST_DATA = {
  total: 0,
  total_pages: 0,
  results: [],
};

// TODO: make reusable component Async Select
const AsyncSelect = ({
  value,
  name,
  onChange,
  label,
  error,
  disabled,
  className,
  filterText,
}) => {
  const [listData, setListData] = useState(INITIAL_LIST_DATA);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const searchRef = useRef(search);
  const fakeSelectRef = useRef(null);

  const debouncedSearchData = useCallback(debounce((search) => {
    if (!search) {
      setListData(INITIAL_LIST_DATA);
      return;
    }
    getData({ page: 1, search }).then(res => setListData(res));
  }, 500), [setListData]);

  useEffect(() => {
    searchRef.current = search;
    debouncedSearchData(search);
  }, [debouncedSearchData, search]);

  const handleClick = useCallback(
    (event) => {
      if (disabled) return;
      setAnchorEl(event.currentTarget);
    },
    [disabled],
  );

  const handleClose = useCallback(
    () => {
      setAnchorEl(null);
    },
    [setAnchorEl],
  );

  const handleChange = useCallback((itemValue, itemName, isChecked) => {
    let result;
    result = itemValue;
    onChange({ target: { value: result, name: itemName } });
  }, [onChange, value]);

  const handleRow = useCallback((rowValue, rowName, isChecked) => {
    handleChange(rowValue, rowName, isChecked);
    setTimeout(handleClose, 0);
  }, [handleClose, handleChange]);

  const hasError = useMemo(() => Boolean(error), [error]);

  const isRowLoaded = useCallback(({ index }) => {
    return index < listData.results.length && listData.results[index] !== null;
  }, [listData]);

  const loadMoreRows = useCallback(({ startIndex, stopIndex }) => {
    const page = Math.ceil(stopIndex / 25);
    if (!searchRef.current || listData.results.length === listData.total || listData.results.length >= page * 25) return Promise.resolve();
    return getData({ search: searchRef.current, page }).then(res => {
      setListData(prevValue => {
        return {
          ...res,
          results: [...prevValue.results, ...res.results],
        }
      });
    });
  }, [setListData, listData]);

  const rowRenderer = useCallback(({ index, key, style }) => {
    const list = listData.results;
    const imageUrl = list[index]?.urls?.raw;
    let isChecked = value && value.includes(imageUrl);
    return (
      <MenuItem
        className={ cn(classes.menuItem, {
          [classes.menuItemChecked]: isChecked,
        }) }
        style={ style }
        key={ key }
        value={ list[index]?.urls?.raw }
        onClick={ () => !disabled && handleRow(list[index]?.urls?.raw, name, isChecked) }
      >
        {imageUrl && <img className={ classes.menuItemImage } src={ imageUrl + '&w=40&h=30'} alt="" /> }
        <span className={ classes.menuItemText }>{list[index]?.description || list[index]?.alt_description}</span>
      </MenuItem>
    );
  }, [listData, name, value, handleRow]);

  const rowCount = listData.total;

  const calculateHeight = () => {
    const height = rowCount * 35 + 80;
    return height > 360 ? 360 : height;
  };

  const handleChangeSearch = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const selectedValue = useMemo(() => {
    const selectedItem = listData.results.find((item) => value?.includes(item?.urls?.raw));
    return selectedItem?.description || selectedItem?.alt_description;
  }, [value, listData]);

  const selectText = selectedValue || filterText;
  return (
    <div className={cn([classes.wrapper, className, {
      [classes.opened]: Boolean(anchorEl),
      [classes.hasError]: hasError,
      [classes.isDisabled]: disabled,
    }])}
    >
      <div
        role="button"
        tabIndex={0}
        className={cn([classes.fakeSelect])}
        onClick={handleClick}
        onKeyPress={handleClick}
        ref={fakeSelectRef}
      >
        <span className={classes.fakeSelectText}>{label}</span>
        <span className={cn({
          [classes.fakeSelectCounter]: label,
          [classes.fakeSelectText]: !label,
        })}
        >
          {label ? `(${selectText})` : selectText}
        </span>
        <SvgIcon className={classes.fakeSelectArrow}>
          <path d="M7 10l5 5 5-5z" />
        </SvgIcon>
      </div>
        
      {hasError &&
        <span className={classes.fakeSelectHelperText}>{error}</span>}
        
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onKeyDownCapture={(e) => {
          e.stopPropagation();
        }}
        {...MenuProps}
      >
        <div className={classes.menuSearchWrapper}>
          <ClearableInput
            autoFocus
            placeholder="Search"
            value={search}
            onClear={handleClearSearch}
            label=""
            name="search"
            onChange={handleChangeSearch}
            fullWidth
          />
          {!search && <div className={ classes.noResults }>Start searching...</div>}
          {search && !rowCount && <div className={ classes.noResults }>No Results</div>}
        </div>

        
        {fakeSelectRef?.current && (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={rowCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                ref={registerChild}
                className={classes.menuList}
                width={fakeSelectRef?.current?.offsetWidth > 400 ? fakeSelectRef?.current?.offsetWidth : 400}
                height={calculateHeight()}
                onRowsRendered={onRowsRendered}
                rowRenderer={rowRenderer}
                rowCount={rowCount}
                rowHeight={35}
              />
            )}
          </InfiniteLoader>
        )}
      </Menu>
    </div>    
  );
};

AsyncSelect.defaultProps = {
  filterText: 'none',
  onChange: () => {},
}

export default memo(AsyncSelect);