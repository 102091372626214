import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';

// Components
import CampaignList from './CampaignList';
import AddCampaign from './AddCampaign/AddCampaign';

// TODO: remove CampaignType.js
const Campaigns = ({ match }) => (
  <Switch>
    <Route exact path={ `${match.path}/create` } component={ AddCampaign } />
    <Route exact path={ `${match.path}/:id` } component={ AddCampaign } />
    <Route exact path={ `${match.path}` } component={ CampaignList } />
  </Switch>
);

Campaigns.propTypes = { match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired };

export default Campaigns;
