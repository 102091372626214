import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import creativeSelectors from 'selectors/creativeSelectors';

// Actions
import creativeActions from 'actions/creativeActions';

function materialQueryToParams(query) {
  const { orderBy, orderDirection, page, pageSize, search, type, status, advertisers, campaigns } = query;
  return {
    ...page && { page },
    ...search && { search },
    ...pageSize && { size: pageSize },
    ...orderBy && { sortBy: orderBy.field },
    ...orderDirection && { sortOrder: orderDirection.toUpperCase() },
    ...type && { subtypes: [type] },
    ...status && { statuses: [status] },
    advertisers,
    campaigns,
  };
}

const useCreativeList = (filters) => {
  const dispatch = useDispatch();
  const { payload: creatives, _meta } = useSelector(creativeSelectors.creativeListSelector);

  const getCreativeList = useCallback((params) => dispatch(creativeActions.getCreativeList(params)), [dispatch]);
  const updateCreativeStatus = useCallback(
    async (creativeId, status) => dispatch(creativeActions.updateCreativeStatus(creativeId, status)),
    [dispatch],
  );

  const requestCreatives = useCallback(async (query = {}) => {
    const { _meta: meta = {}, payload = [] } = await getCreativeList(materialQueryToParams({ ...query, ...filters }));
    return {
      data: payload,
      page: meta.page,
      totalCount: meta.total,
    };
  }, [filters, getCreativeList]);

  return { creatives, meta: _meta, requestCreatives, updateCreativeStatus };
};

export default useCreativeList;
