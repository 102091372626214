import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'modules/Modal/Modal';
import { Button, Typography } from '@material-ui/core';
import classes from './DeclineCampaignModal.module.scss';

const DeclineCampaignModal = ({ isOpen, handleClose, campaign, handleDecline }) => {
  const [comment, setComment] = useState('');

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  return (
    <Modal open={ isOpen } onClose={ handleClose }>
      <div className={ classes.wrapper }>
        <Typography variant="h3">Decline Campaign</Typography>
        <p>Are you sure you want to decline the campaign <i>{campaign?.name}</i> ?</p>

        <label htmlFor="comment">
          Decline Reason:
          <textarea
            id="comment"
            name="comment"
            className={ classes.comment }
            value={ comment }
            onChange={ handleChangeComment }
            rows="10"
          />
        </label>

        <Button
          variant="contained"
          color="secondary"
          onClick={ () => handleDecline(comment) }
          fullWidth
        >
          Decline
        </Button>
      </div>
    </Modal>
  );
};

DeclineCampaignModal.defaultProps = {
  handleClose: Function.prototype,
  handleDecline: Function.prototype,
  campaign: null,
  isOpen: false,
};

DeclineCampaignModal.propTypes = {
  handleClose: PropTypes.func,
  handleDecline: PropTypes.func,
  campaign: PropTypes.shape({ name: PropTypes.string }),
  isOpen: PropTypes.bool,
};

export default memo(DeclineCampaignModal);
