import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Selectors
import campaignSelectors from 'selectors/campaignSelectors';

// Actions
import campaignActions from 'actions/campaignActions';

function materialQueryToParams(query) {
  const { orderBy, orderDirection, page, pageSize, search, advertiser, creative, status } = query;
  return {
    ...page && { page },
    ...search && { search },
    ...pageSize && { size: pageSize },
    ...orderBy && { sortBy: orderBy.field },
    ...orderDirection && { sortOrder: orderDirection.toUpperCase() },
    ...status && { statuses: [status] },
    advertisers: advertiser || [],
    creatives: creative || [],
  };
}

const useCampaigns = (filters) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // Selectors
  const { payload: campaignList, _meta } = useSelector(campaignSelectors.campaignListSelector);

  const getCampaignList = useCallback((params) => dispatch(campaignActions.getCampaignList(params)), [dispatch]);
  const updateCampaignStatus = useCallback((ids, status) => dispatch(campaignActions.updateCampaignStatus(ids, status)), [dispatch]);

  const requestCampaigns = useCallback(async (query = {}) => {
    const { _meta: meta = {}, payload = [] } = await getCampaignList(materialQueryToParams({ ...query, ...filters }));
    return {
      data: payload,
      page: meta.page,
      totalCount: meta.total,
    };
  }, [filters, getCampaignList]);

  const changeCampaignStatus = useCallback(async (values, status) => {
    try {
      if (values && values.creativesCount) {
        await updateCampaignStatus([values.id], status);
        enqueueSnackbar(`Campaign (id: ${values.id}) updated successfully`, { variant: 'success' });
      } else {
        enqueueSnackbar(`Campaign (id: ${values.id}) should have at least one creative added.`, { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong! Please, try again.', { variant: 'error' });
    }
  }, [updateCampaignStatus, enqueueSnackbar]);

  return { requestCampaigns, campaignList, meta: _meta, changeCampaignStatus };
};

export default useCampaigns;
