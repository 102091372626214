import types from './actionTypes/loadingActionTypes';

const increaseCounter = () => ({ type: types.INCREASE_COUNTER });

const decreaseCounter = () => ({ type: types.DECREASE_COUNTER });

export default {
  increaseCounter,
  decreaseCounter,
};
