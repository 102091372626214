import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, memoize } from 'lodash';
import classNames from 'classnames';

// Material
import { Button, Checkbox, Grid } from '@material-ui/core';

// Components
import DemographicTable from 'components/Campaigns/AddCampaign/Steps/Demographics/DemographicTable/DemographicTable';

// Modules
import EditableTitle from 'modules/_Factories/EditableTitle/EditableTitle';
import Input from 'modules/_Factories/Input/Input';
import CustomTableCell from 'modules/_Table/TableCell/TableCell';

// Icons
import { ReactComponent as UncheckedCheckbox } from 'assets/images/icons/checkbox.svg';
import { ReactComponent as CheckedCheckbox } from 'assets/images/icons/checkboxChecked.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close_white.svg';

// Libs
import { textFormat } from 'libs/textFormat';
import CampaignTypes from 'constants/dictionary/campaignTypesDictionary';
import FilterSegments from './FilterSegments/filterSegments';

// Constants

// Hooks
import useAddDemography from './useAddDemography';

// Styles
import classes from './AddDemography.module.scss';

const AddDemography = (props) => {
  const { formik, currentGroupItem, setView, unsavedSegments, setUnsavedSegments } = props;

  const {
    index,
    sourceSegmentList,
    selected,
    addDemographicItem,
    saveDemographicItems,
    demographicList,
    handleChangeCpm,
  } = useAddDemography({ formik, currentGroupItem, setView, unsavedSegments, setUnsavedSegments });
  const [filteredSegmentList, setFilteredSegmentList] = useState(sourceSegmentList);

  const getSegments = useCallback(memoize((data) => {
    let segments = [];
    if (Array.isArray(data)) {
      return data.reduce((accum, cur) => [...accum, ...getSegments(cur)], []);
    } if (data && typeof data === 'object') {
      if (data.segments) segments = [...segments, ...data.segments];

      Object.values(data).forEach((val) => {
        segments = [...segments, ...getSegments(val)];
      });
      return segments;
    }

    return [];
  }), []);

  const applyFilters = useCallback(memoize((company, category, subcategory) => {
    const filters = [
      { id: company, key: null },
      { id: category, key: 'categories' },
      { id: subcategory, key: 'subcategories' },
    ];

    let newData = demographicList;

    filters.forEach(({ id, key }) => {
      if (!id) return;
      const elements = key ? newData[key] : newData;
      newData = elements.find((item) => item.id === id);
    });

    return newData;
  }, (...args) => args.join(',')), [demographicList]);

  const handleChangeFilter = useCallback(({ filters, search }) => {
    const { company, category, subcategory } = filters;
    const newData = applyFilters(company, category, subcategory);
    let segments = getSegments(newData);
    if (search) segments = segments.filter((segment) => segment.name.toLowerCase().includes(search.toLowerCase()));

    setFilteredSegmentList(segments);
  }, [applyFilters, getSegments]);

  const fields = [
    { name: `segmentGroups[${index}].name`, label: 'Total budget', placeholder: 'Group name...' },
  ];

  const columns = [
    {
      label: '',
      id: 'name',
      renderHeaderItem: () => (
        <EditableTitle
          name={ fields[0].name }
          placeholder={ fields[0].placeholder }
          touched={ formik.touched }
          value={ get(formik.values, fields[0].name, '') }
          className={ classes.editableTitle }
          // Options
          fullWidth
          required
          // Events
          onBlur={ formik.handleBlur }
          onChange={ formik.handleChange }
          // Extra
          errors={ formik.errors }
        />
      ),
      render: (rowData) => (
        <CustomTableCell
          firstRow={ rowData.name }
          secondRow={ rowData.path }
        />
      ),
    },
    {
      label: '',
      id: 'cpm',
      renderHeaderItem: () => (
        <div className={ classes.groupInfo }>
          <span>Segments:&nbsp;&nbsp;</span>
          <span>{ selected.length }</span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;CPM:&nbsp;&nbsp;</span>
          <span>
            {textFormat(selected.reduce((cur, next) => cur + (next.cpm || next.segment.cpm), 0), 'currency')}
          </span>
        </div>
      ),
      render: (rowData) => {
        const element = selected.find((item) => item.segment.id === rowData.id);
        return (
          <>
            <div className={ classes.cpmInfo }>
              {!rowData.cpm ? (
                <Input
                  value={ get(element, 'cpm') }
                  onChange={ (e) => handleChangeCpm(e.target.value, rowData.id) }
                  disabled={ !element || rowData.status === 'INACTIVE' }
                  className={ classes.cpmInput }
                  startAdornment="$"
                />
              ) : (
                <span>{ textFormat(rowData.cpm, 'currency') }</span>
              )}
            </div>
            <div className={ classes.cpmInfoText }>
              CPM
            </div>
          </>
        );
      },
    },
    {
      label: '',
      id: 'options',
      headCallbackFn: () => setView('group'),
      renderHeaderItem: () => <CloseIcon />,
      render: (rowData) => (
        <Checkbox
          disabled={ rowData.status === 'INACTIVE' }
          color="primary"
          checked={ selected.some((seg) => seg.segment.id === rowData.id) }
          onChange={ (e) => addDemographicItem(e.target.checked, rowData.id, currentGroupItem) }
          icon={ <UncheckedCheckbox /> }
          checkedIcon={ <CheckedCheckbox /> }
        />
      ),
    },
  ];

  const isDisabledSaveButton = selected.some((item) => !item.cpm && !item.segment.cpm);

  return (
    <DemographicTable
      data={ filteredSegmentList }
      columns={ columns }
      renderFiltersComponent={ () => <FilterSegments onChange={ handleChangeFilter } /> }
      component={ (
        <Grid container className={ classNames('figure-flex-end', classes.buttonsWrapper) }>
          <Grid item sm="auto" xs="auto">
            <Button
              className={ classes.cancelButton }
              variant="outlined"
              size="large"
              onClick={ () => setView('group') }
            >
              Cancel
            </Button>
          </Grid>

          <Grid item sm="auto" xs="auto">
            <Button
              className={ classNames(classes.saveButton, { [classes.disabledButton]: isDisabledSaveButton }) }
              variant="outlined"
              size="large"
              disabled={ isDisabledSaveButton }
              onClick={ () => saveDemographicItems() }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      ) }
    />
  );
};

AddDemography.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      advertiser: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      systemType: PropTypes.oneOf(Object.values(CampaignTypes.types)),
    }),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetField: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  currentGroupItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.any]),
    name: PropTypes.string,
    segments: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setView: PropTypes.func.isRequired,
  unsavedSegments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setUnsavedSegments: PropTypes.func.isRequired,
};

export default AddDemography;
