import { buildHashMap } from 'libs/buildHashMap';

const types = {
  UPLOAD_FROM_URL: 'UPLOAD_FROM_URL',
  UPLOAD_FILE: 'UPLOAD_FILE',
  STOCK_LIB: 'STOCK_LIB',

};

const dictionary = [
  { id: types.UPLOAD_FROM_URL, name: 'Link to File' },
  { id: types.UPLOAD_FILE, name: 'Upload File' },
  { id: types.STOCK_LIB, name: 'Select File from Stock Library' },
];

const hashMap = buildHashMap(dictionary);

export default {
  types,
  dictionary,
  hashMap,
};
