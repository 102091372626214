import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@material-ui/core';

// Modules
import { AltTab, AltTabs } from 'modules/Tabs/AltTabs';
import { a11yProps } from 'modules/Tabs';
import { TabPanel } from 'modules/Tabs/TabPanel';
import StepTransitions from 'modules/Steps/StepTransitions';

// Hooks
import useStepMenu from 'hooks/useStepMenu';

// Components
import General from './General';
import Contacts from './Contacts';
import Balance from './Balance';

// Styles
import classes from './Steps.module.scss';

const getMenuSteps = (advertiserId, isEditForm) => [
  { id: 0, name: 'General', touched: true, valid: isEditForm, visible: true },
  { id: 1, name: 'Contacts', touched: isEditForm, valid: isEditForm, visible: true },
  { id: 2, name: 'Balance', touched: isEditForm, valid: isEditForm, visible: !!advertiserId },
];

const Steps = ({ formik, permissionEdit, isEditForm }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [menuSteps, setMenuSteps] = useState(getMenuSteps(formik.values.id, isEditForm));
  const { steps, setValuesStep, stepHasErrors, stepIsDisabled } = useStepMenu(menuSteps);

  useEffect(() => {
    setMenuSteps(getMenuSteps(formik.values.id, isEditForm));
  }, [formik.values.id, isEditForm, setMenuSteps]);

  const globalFields = useMemo(() => ['name'], []);

  const stepFields = useMemo(() => [
    { fields: ['domain', 'status'], visible: true },
    { fields: ['contacts.email', 'contacts.skype', 'contacts.phone', 'contacts.notes'], visible: true },
    { fields: [], visible: !!formik.values.id },
  ].filter((item) => item.visible).map((item) => item.fields), [formik.values.id]);

  const hasErrorsActiveTab = stepHasErrors([...globalFields, ...stepFields[activeTab]], formik.errors);

  const handleChangeTab = useCallback((index) => {
    setActiveTab(index);
    setValuesStep(index, { touched: true });
  }, [setActiveTab, setValuesStep]);

  const handleNextStep = useCallback(() => {
    if (steps[activeTab].valid) handleChangeTab(activeTab + 1);
    [...globalFields, ...stepFields[activeTab]].forEach((name) => formik.setFieldTouched(name, true));
  }, [steps, activeTab, handleChangeTab, globalFields, stepFields, formik]);

  const handlePrevStep = useCallback(() => {
    handleChangeTab(activeTab - 1);
  }, [handleChangeTab, activeTab]);

  useEffect(() => {
    setValuesStep(activeTab, { valid: !hasErrorsActiveTab });
  }, [activeTab, hasErrorsActiveTab, setValuesStep]);

  const getTabClass = useCallback((item, index) => {
    if (index === activeTab) return classes.active;
    if (item.valid && item.touched) return classes.valid;
    return null;
  }, [activeTab]);

  const TabPanelContentList = useMemo(() => {
    const tabList = [
      { id: 0, component: <General formik={ formik } permissionEdit={ permissionEdit } /> },
      { id: 1, component: <Contacts formik={ formik } permissionEdit={ permissionEdit } /> },
      { id: 2, component: <Balance formik={ formik } /> },
    ];
    return tabList.filter((tabItem) => steps.find((item) => item.id === tabItem.id));
  }, [steps, formik, permissionEdit]);

  return (
    <div>
      <AppBar position="relative" color="inherit" elevation={ 0 } className={ classes.bar }>
        <AltTabs value={ activeTab } onChange={ (e, index) => handleChangeTab(index) } variant={ 1 }>
          { steps.map((item, i) => (
            <AltTab
              key={ item.id }
              disableRipple
              label={ item.name }
              disabled={ (hasErrorsActiveTab && i > activeTab) || stepIsDisabled(i) }
              variant={ 1 }
              className={ getTabClass(item, i) }
              { ...a11yProps(item.id) }
            />
          )) }
        </AltTabs>
      </AppBar>

      {TabPanelContentList.map((item, index) => (
        <TabPanel key={ item.id } value={ activeTab } index={ index }>{item.component}</TabPanel>
      ))}

      <StepTransitions
        handleNext={ handleNextStep }
        handlePrev={ handlePrevStep }
        hidePrevButton={ activeTab === 0 }
        hideNextButton={ activeTab === steps.length - 1 }
      />

    </div>
  );
};

Steps.defaultProps = {
  permissionEdit: true,
  isEditForm: false,
};

Steps.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
  }).isRequired,
  permissionEdit: PropTypes.bool,
  isEditForm: PropTypes.bool,
};

export default memo(Steps);
