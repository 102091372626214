import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import advertiserSelectors from 'selectors/advertiserSelectors';

// Actions
import advertiserActions from 'actions/advertiserActions';

import useAdvertiserBalance from 'modules/AdvertiserBalance/useAdvertiserBalance';

function materialQueryToParams(query) {
  const { orderBy, orderDirection, page, pageSize, search, balance, status } = query;
  return {
    ...balance && { balanceStates: [balance] },
    ...page && { page },
    ...search && { search },
    ...pageSize && { size: pageSize },
    ...orderBy && { sortBy: orderBy.field },
    ...orderDirection && { sortOrder: orderDirection.toUpperCase() },
    ...status && { statuses: [status] },
  };
}

const useAdvertiserList = (filters) => {
  const dispatch = useDispatch();
  const { payload: advertisers, _meta } = useSelector(advertiserSelectors.advertiserListSelector);
  const { fillAdvertiserBalance } = useAdvertiserBalance();

  const [refillBalanceData, setRefillBalanceData] = useState({
    isOpen: false,
    advertiserId: null,
  });
  const [billingHistoryData, setBillingHistoryData] = useState({
    isOpen: false,
    advertiserId: null,
  });

  const toggleBillingHistoryModal = useCallback(({ advertiserId = null } = {}) => {
    setBillingHistoryData((prevState) => ({
      isOpen: !prevState.isOpen,
      advertiserId,
    }));
  }, [setBillingHistoryData]);

  const toggleRefillBalanceModal = useCallback(({ advertiserId = null, isOpen = null } = {}) => {
    setRefillBalanceData((prevState) => ({
      isOpen: isOpen || !prevState.isOpen,
      advertiserId,
    }));
  }, [setRefillBalanceData]);

  const getAdvertiserList = useCallback((params) => dispatch(advertiserActions.getAdvertiserList(params)), [dispatch]);
  const updateAdvertiserStatus = useCallback(
    async (advertiserId, status) => dispatch(advertiserActions.updateAdvertiserStatus(advertiserId, status)),
    [dispatch],
  );

  const refillBalanceByAdvertiserId = useCallback(async (advertiserId, values) => {
    if (!advertiserId) return;

    await fillAdvertiserBalance(advertiserId, values);
    toggleRefillBalanceModal();
  }, [fillAdvertiserBalance, toggleRefillBalanceModal]);

  const requestAdvertisers = useCallback(async (query = {}) => {
    const { _meta: meta = {}, payload = [] } = await getAdvertiserList(materialQueryToParams({ ...query, ...filters }));
    return {
      data: payload,
      page: meta.page,
      totalCount: meta.total,
    };
  }, [filters, getAdvertiserList]);

  return {
    advertisers,
    meta: _meta,
    requestAdvertisers,
    updateAdvertiserStatus,
    refillBalanceData,
    billingHistoryData,
    toggleRefillBalanceModal,
    toggleBillingHistoryModal,
    refillBalanceByAdvertiserId,
  };
};

export default useAdvertiserList;
