import types from 'actions/actionTypes/reportActionTypes';

const initialState = { report: {} };

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_REPORT:
      return {
        ...state,
        report: { ...action.payload },
      };

    case types.RESET_REPORT:
      return { ...initialState };

    default:
      return state;
  }
};

export default reportReducer;
