import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import authActions from 'actions/authActions';

const useSignup = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const signupRequest = useCallback((requestParams) => dispatch(authActions.signup(requestParams)), [dispatch]);

  const signup = useCallback(async (values, finallyCallback) => {
    try {
      await signupRequest(values);
      const message = 'Account created. A link to set your password has been sent to the specified e-mail address.';
      enqueueSnackbar(message, { variant: 'success' });
    } catch (e) {
      const { message, validationErrors } = e.response.data || {};
      const validationErrorMessage = (validationErrors || []).find((item) => item.message)?.message;
      enqueueSnackbar(validationErrorMessage || message, { variant: 'error' });
    } finally {
      finallyCallback();
    }
  }, [signupRequest, enqueueSnackbar]);

  return { signup };
};

export default useSignup;
