import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterNavLink } from 'react-router-dom';

// Styles
import classes from './NavLink.module.scss';

const NavLink = ({ to, children, exact }) => (
  <RouterNavLink to={ to } exact={ exact } className={ classes.link } activeClassName={ classes.active }>
    { children }
  </RouterNavLink>
);

NavLink.defaultProps = { exact: false };

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  exact: PropTypes.bool,
};

export default NavLink;
