import isEmail from 'validator/lib/isEmail';
import { useSnackbar } from 'notistack';

const useLoginForm = (login) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = { email: '', password: '' };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!isEmail(values.email)) {
      errors.email = 'Invalid email';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    }

    return errors;
  };

  const submitHandler = async (values, { setSubmitting, setFieldError }) => {
    try {
      await login(values);
    } catch (e) {
      enqueueSnackbar('Invalid email or password. Please, try again.', { variant: 'error' });
      setFieldError('email', ' ');
      setFieldError('password', 'Invalid email or password. Please, try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return {
    validate,
    submitHandler,
    initialValues,
  };
};

export default useLoginForm;
