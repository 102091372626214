import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Libs
import { hasPermission } from 'libs/storageLibs';

// Dictionaries
import UserPermissions from 'constants/dictionary/userPermissionsDictionary';

import useEditAdvertiser from './useEditAdvertiser';
import FormAdvertiser from '../../FormAdvertiser';

const EditAdvertiser = ({ match }) => {
  const { id } = match.params;
  const { initialValues, handleSubmit } = useEditAdvertiser(id);
  const permissionEdit = hasPermission([UserPermissions.types.ADVERTISER_WRITE]);

  return (
    <FormAdvertiser
      initialValues={ initialValues }
      handleSubmit={ handleSubmit }
      permissionEdit={ permissionEdit }
      isEditForm
    />
  );
};

EditAdvertiser.propTypes = { match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }).isRequired }).isRequired };

export default memo(EditAdvertiser);
