import { useEffect, useCallback, useRef } from 'react';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { validateRequired, validateFileExtension, validateUrl } from 'libs/validateErrors';
import CREATEIVE_DATA_TYPES from 'constants/dictionary/creativeDataTypesDictionary.js';
import CREATIVE_TYPES from 'constants/dictionary/creativeTypesDictionary';
import { MIME_TYPES } from 'constants/mimeTypes';

export const getAcceptableFileExtensions = (type) => {
  switch (type) {
    case CREATIVE_TYPES.types.NATIVE:
    case CREATIVE_TYPES.types.DISPLAY: return ['jfif', 'pjpeg', 'jpeg', 'pjp', 'jpg', 'png'];
    case CREATIVE_TYPES.types.VIDEO:
    default: return ['mp4', 'm4v', 'mov'];
  }
};

const useFormCreative = ({ initialValues, handleSubmit }) => {
  const validateUrlFunction = useRef(null);

  const validateCustomFile = useCallback((file, acceptableExtensions) => {
    const errorRequired = validateRequired(file, 'File');
    const errorExtension = file ? validateFileExtension(file.name, acceptableExtensions) : null;
    return errorRequired || errorExtension;
  }, []);

  const debouncedValidateUrl = useCallback(debounce((url, mimeTypes) => validateUrl(url, mimeTypes)
    .then((res) => {
      if (!res) return;
      if (res['Content-Type']) {
        validateUrlFunction.current.error = null;
        validateUrlFunction.current.setFieldValue('mime', res['Content-Type']);
      } else {
        validateUrlFunction.current.error = res;
        validateUrlFunction.current.setFieldError('url', res);
      }
    }), 500), [validateUrlFunction]);

  // TODO: logic connected with validation url should will be refactor
  const customValidateUrl = useCallback((url) => {
    if (!url || !validateUrlFunction.current) return null;

    const isChangeValue = validateUrlFunction.current.lastValue !== url;
    if (isChangeValue) {
      validateUrlFunction.current.lastValue = url;
      validateUrlFunction.current.call(url, MIME_TYPES);
      return null;
    }

    return validateUrlFunction.current?.error;
  }, [validateUrlFunction]);

  const validateContentByDataType = useCallback((values) => {
    let file = null;
    let url = null;
    switch (values.dataType) {
      case CREATEIVE_DATA_TYPES.types.UPLOAD_FROM_URL: {
        url = validateRequired(values.url, 'Creative URL') || customValidateUrl(values.url);
        break;
      }
      case CREATEIVE_DATA_TYPES.types.UPLOAD_FILE:
      default: {
        file = validateCustomFile(values.file, getAcceptableFileExtensions(values.subType));
        break;
      }
    }

    return {
      ...file && { file },
      ...url && { url },
    };
  }, [customValidateUrl, validateCustomFile]);

  const validateSize = useCallback((values) => {
    if (values.subType !== CREATIVE_TYPES.types.BANNER) return null;

    const sizeType = validateRequired(values.sizeType, 'Creative Size');
    const sizeWidth = values.sizeType === 'CUSTOM' && validateRequired(values.size.width);
    const sizeHeiht = values.sizeType === 'CUSTOM' && validateRequired(values.size.height);
    return {
      ...sizeType && { sizeType },
      ...sizeWidth && { 'size.width': sizeWidth },
      ...sizeHeiht && { 'size.height': sizeHeiht },
    };
  }, []);

  const validate = useCallback((values) => {
    const name = validateRequired(values.name, 'Name');
    const advertiser = validateRequired(values.advertiser, 'Advertiser');
    const status = validateRequired(values.status, 'Status');

    return {
      ...name && { name },
      ...advertiser && { advertiser },
      ...status && { status },
      ...values.subType === CREATIVE_TYPES.types.VIDEO && validateContentByDataType(values),
      ...validateSize(values),
    };
  }, [validateContentByDataType, validateSize]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => handleSubmit(values, () => setSubmitting(false)),
    validate,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const { setFieldValue, setFieldError, values } = formik;

  // This useEffect should for using formik methods in validate function
  useEffect(() => {
    validateUrlFunction.current = {
      lastValue: validateUrlFunction.current?.lastValue || null,
      error: validateUrlFunction.current?.error || null,
      setFieldValue,
      setFieldError,
      call: debouncedValidateUrl,
    };
  }, [debouncedValidateUrl, setFieldValue, setFieldError]);

  // set correct values when changing creative's type
  useEffect(() => {
    setFieldValue('sizeType', initialValues.sizeType);
    setFieldValue('size', initialValues.size);
    setFieldValue('dataType', initialValues.dataType);
    setFieldValue('file', initialValues.file);
    setFieldValue('url', initialValues.url);
    setFieldValue('template', initialValues.template);
  }, [values.subType, initialValues, setFieldValue]);

  // set correct values when changing creative's dataType
  useEffect(() => {
    setFieldValue('content', initialValues.content);
    setFieldValue('file', initialValues.file);
    setFieldValue('url', initialValues.url);
  }, [values.dataType, initialValues, setFieldValue]);
  return { formik };
};

export default useFormCreative;
