import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../_Table/Pagination/Pagination';
import classes from './MaterialTablePagination.module.scss';

const MaterialTablePagination = ({ count, rowsPerPage, onChangePage, onChangeRowsPerPage, perPage }) => {
  useEffect(() => {
    const event = { target: { value: perPage } };

    onChangeRowsPerPage(event);
  }, [onChangeRowsPerPage, perPage]);

  const countPages = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);
  const showPagination = useMemo(() => countPages > 1, [countPages]);

  const handlerOnChangePage = useCallback((e, page) => {
    onChangePage(e, page - 1);
  }, [onChangePage]);

  return (
    <td className={ classes.wrapper }>
      { showPagination && <Pagination count={ countPages } defaultPage={ 1 } onChange={ handlerOnChangePage } /> }
    </td>
  );
};

MaterialTablePagination.defaultProps = {
  onChangeRowsPerPage: () => {},
  perPage: () => {},
};
MaterialTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func,
  perPage: PropTypes.number,
};

export default MaterialTablePagination;
