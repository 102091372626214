// Types
import types from 'actions/actionTypes/dashboardActionTypes';

// Services
import crud from './index';

const getStatsByStates = (params) => crud.get(
  'telly/dashboards/_bystates',
  types.GET_STATS_BY_STATES,
  params,
  false,
);

const getAdvertisersActivity = (params) => crud.get(
  'telly/dashboards/_overall',
  types.GET_ADVERTISERS_ACTIVITY,
  params,
  false,
);

const getTopAdvertisers = (period, threshold) => crud.get(
  'telly/dashboards/_topadvertirsers',
  types.GET_TOP_ADVERTISERS,
  { period, threshold },
  false,
);

const getTopCampaigns = (period, threshold) => crud.get(
  'telly/dashboards/_topcampaigns',
  types.GET_TOP_CAMPAIGNS,
  { period, threshold },
  false,
);

const getTopCreatives = (period, threshold) => crud.get(
  'telly/dashboards/_topcreatives',
  types.GET_TOP_CREATIVES,
  { period, threshold },
  false,
);

const clearStatsByStates = () => ({ type: types.CLEAR_STATS_BY_STATES });

const clearAdvertisersActivity = () => ({ type: types.CLEAR_ADVERTISERS_ACTIVITY });

const clearTopAdvertisers = () => ({ type: types.CLEAR_TOP_ADVERTISERS });

const clearTopCampaigns = () => ({ type: types.CLEAR_TOP_CAMPAIGNS });

const clearTopCreatives = () => ({ type: types.CLEAR_TOP_CREATIVES });

export default {
  getAdvertisersActivity,
  getStatsByStates,
  getTopAdvertisers,
  getTopCampaigns,
  getTopCreatives,
  clearStatsByStates,
  clearAdvertisersActivity,
  clearTopAdvertisers,
  clearTopCampaigns,
  clearTopCreatives,
};
