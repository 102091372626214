import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dictionaryActions from 'actions/dictionaryActions';
import dictionarySelectors from 'selectors/dictionarySelectors';

const useDMAs = (sendRequest = false) => {
  const dispatch = useDispatch();

  const getDMAs = useCallback(() => dispatch(dictionaryActions.getDMAs()), [dispatch]);

  useEffect(() => {
    if (sendRequest) {
      getDMAs();
    }
  }, [getDMAs, sendRequest]);

  const DMAs = useSelector(dictionarySelectors.DMAsSelector);

  return { DMAs };
};

export default useDMAs;
