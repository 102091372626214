// Libs
import { buildHashMap } from 'libs/buildHashMap';

const types = {
  AUDIENCE: 'AUDIENCE',
  PROGRAMMATIC_DIRECT: 'PROGRAMMATIC_DIRECT',
  PROGRAMMATIC_GUARANTEED: 'PROGRAMMATIC_GUARANTEED',
  ORIGIN_MEDIA: 'ORIGIN_MEDIA',
};

const dictionary = [
  {
    id: types.AUDIENCE,
    name: 'Audience',
    link: 'audience',
    description: 'Programmatic audience buying across the entire inventory landscape. Geographical and demo targeting with TruOptik data',
  },
  {
    id: types.ORIGIN_MEDIA,
    name: 'Origin Media',
    link: 'origin-media',
    description: 'A special campaign type through our partner Origin Media. Unique creative structure. Programmatic audience ' +
          'buying across the entire inventory landscape Geographical and demo targeting with TruOptik data',
  },
  {
    id: types.PROGRAMMATIC_DIRECT,
    name: 'Programmatic Direct',
    link: 'programmatic-direct',
    description: 'A one-on-one deal with a selected CTV publisher with geographical targeting, fixed flight dates, ' +
          'CPM executed via the programmatic pipes',
  },
  // TODO: will uncomment when needed
  // {
  //   id: types.PROGRAMMATIC_GUARANTEED,
  //   name: 'Programmatic Guaranteed',
  //   link: 'programmatic-guaranteed',
  //   description: 'A one-on-one deal with a selected CTV publisher with the audience segment provided by the buyer, ' +
  //         'fixed flight dates, CPM executed via the programmatic pipes',
  // },
];

const hashMap = buildHashMap(dictionary);
const hashMapLinkId = buildHashMap(dictionary, 'link', 'id');

export default {
  types,
  dictionary,
  hashMap,
  hashMapLinkId,
};
